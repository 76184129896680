var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "page-permissions" } },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$root.loading,
              expression: "!$root.loading"
            }
          ]
        },
        [
          _c(
            "div",
            [
              _c("Tabs", {
                attrs: {
                  tabs: [
                    {
                      nome: "Permissões",
                      name: "Permissões",
                      ref: "permissions",
                      active: true,
                      icon: "fa-user-shield",
                      visible: true
                    },
                    {
                      nome: "Solicitações como profissional de saúde",
                      name: "Solicitações",
                      ref: "requestedPermissions",
                      active: false,
                      icon: "fa-user-md",
                      visible: _vm.$root.getAuthData().isHealthWorker
                    }
                  ]
                },
                scopedSlots: _vm._u([
                  {
                    key: "permissions",
                    fn: function() {
                      return [
                        _c(
                          "DxDataGrid",
                          {
                            attrs: {
                              id: "gridContainer",
                              "data-source": _vm.data,
                              "column-auto-width": true,
                              "show-borders": true,
                              "column-hiding-enabled": true
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "photo-template",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "" +
                                          _vm.defaultPath +
                                          (data.data.photoSolicitante !==
                                            null &&
                                          data.data.photoSolicitante !== "" &&
                                          data.data.photoSolicitante !==
                                            undefined
                                            ? data.data.photoSolicitante
                                            : _vm.defaultImage)
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cpf-template",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.customizeTextCPF(
                                              data.data.documentoSolicitante
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("DxSearchPanel", {
                              attrs: {
                                visible: true,
                                width: 240,
                                placeholder: "Procurar..."
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                width: 100,
                                "allow-filtering": false,
                                "allow-sorting": false,
                                caption: "Solicitante",
                                "data-field": "photoSolicitante",
                                "cell-template": "photo-template",
                                visibleIndex: !_vm.$root.mobile ? 0 : 2
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                width: !_vm.$root.mobile ? 250 : 130,
                                caption: "Nome",
                                "data-field": "solicitante",
                                customizeText: _vm.customizeTextNome,
                                visibleIndex: !_vm.$root.mobile ? 1 : 0
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Profissão",
                                "data-field": "profissao",
                                visibleIndex: !_vm.$root.mobile ? 2 : 3
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Tipo",
                                "data-field": "tipo",
                                visibleIndex: !_vm.$root.mobile ? 3 : 4
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Situação",
                                "data-field": "statusTxt",
                                visibleIndex: !_vm.$root.mobile ? 4 : 1
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Inicio",
                                "data-field": "dataInicioPermission",
                                "data-type": "datetime"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Termino",
                                "data-field": "dataFimPermission",
                                "data-type": "datetime"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Contratante",
                                "data-field": "empresa"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "E-mail",
                                "data-field": "emailSolicitante"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "CPF",
                                "data-field": "documentoSolicitante",
                                "cell-template": "cpf-template"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Telefones",
                                "data-field": "telefoneSolicitante"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Conselho",
                                "data-field": "conselho"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "UF do Conselho",
                                "data-field": "ufConselho"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Número de Registro",
                                "data-field": "numeroConselho"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Especialidades",
                                "data-field": "especialidade"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                caption: "Cadastro",
                                "data-field": "dataSolicitacao",
                                "data-type": "datetime"
                              }
                            }),
                            _c(
                              "DxColumn",
                              {
                                staticClass: "aprovar-negar",
                                attrs: { caption: "Aprovar", type: "buttons" }
                              },
                              [
                                _c("DxButton", {
                                  attrs: {
                                    name: "aprovar",
                                    text: _vm.$root.mobile ? "Sim" : "",
                                    icon: !_vm.$root.mobile ? "check" : "",
                                    "css-class": "btn-aprove",
                                    "on-click": _vm.aprovarClick,
                                    visible: _vm.canAprove,
                                    hint: "Aprovar"
                                  }
                                }),
                                _c("DxButton", {
                                  attrs: {
                                    name: "negar",
                                    "css-class": "btn-deny",
                                    text: _vm.$root.mobile ? "Não" : "",
                                    icon: !_vm.$root.mobile ? "clear" : "",
                                    "on-click": _vm.negarClick,
                                    visible: _vm.canDeny,
                                    hint: "Negar"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "requestedPermissions",
                    fn: function() {
                      return [
                        _c(
                          "DxDataGrid",
                          {
                            attrs: {
                              id: "gridContainerRequested",
                              "data-source": _vm.dataRequested,
                              "column-auto-width": false,
                              "show-borders": true,
                              "column-hiding-enabled": _vm.$root.mobile,
                              "column-resizing-mode": "nextColumn"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "photo-template",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "" +
                                          _vm.defaultPath +
                                          (data.data.photoSolicitado !== null &&
                                          data.data.photoSolicitado !== "" &&
                                          data.data.photoSolicitado !==
                                            undefined
                                            ? data.data.photoSolicitado
                                            : _vm.defaultImage)
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cpf-template",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.customizeTextCPF(
                                              data.data.documentoSolicitado
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("DxSearchPanel", {
                              attrs: {
                                visible: true,
                                width: 240,
                                placeholder: "Procurar..."
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                width: 100,
                                "allow-resizing": false,
                                "allow-filtering": false,
                                "allow-sorting": false,
                                caption: "Solicitado",
                                "data-field": "photoSolicitado",
                                "cell-template": "photo-template",
                                visibleIndex: !_vm.$root.mobile ? 0 : 1
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                width: !_vm.$root.mobile ? 250 : 130,
                                caption: "Nome",
                                "data-field": "solicitado",
                                visibleIndex: !_vm.$root.mobile ? 1 : 0
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                width: 100,
                                caption: "Contratante",
                                "data-field": "empresa"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                width: 100,
                                caption: "E-mail",
                                "data-field": "emailSolicitado"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Tipo",
                                "data-field": "tipo"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Situação",
                                "data-field": "statusTxt"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Telefones",
                                "data-field": "telefoneSolicitado"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Cadastro",
                                "data-field": "dataSolicitacao",
                                "data-type": "datetime",
                                "sort-index": 1,
                                "sort-order": "desc"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Inicio",
                                "data-field": "dataInicioPermission",
                                "data-type": "datetime"
                              }
                            }),
                            _c("DxColumn", {
                              attrs: {
                                "allow-resizing": true,
                                caption: "Termino",
                                "data-field": "dataFimPermission",
                                "data-type": "datetime",
                                "sort-index": 0,
                                "sort-order": "desc"
                              }
                            }),
                            _c(
                              "DxColumn",
                              {
                                attrs: {
                                  caption: "Reenviar",
                                  type: "buttons",
                                  "allow-resizing": true,
                                  width: 70
                                }
                              },
                              [
                                _c("DxButton", {
                                  attrs: {
                                    name: "resend",
                                    icon: "refresh",
                                    "on-click": _vm.resendNotification,
                                    hint: "Reenviar Solicitação"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }