<template>
  <div class="collapse navbar-collapse">
    <div
      class="nav navbar-nav navbar-right"
      :class="[hasCombos ? 'nav-w-combo' : 'nav-s-combo']"
    >
      <alter-user-combo
        class="nav-combo"
        :key="'alter-user-combo' + alteredSystem"
        @change-user="changeUser"
      />
      <alter-company-combo
        class="nav-combo"
        :key="'alter-company-combo' + alteredSystem"
        @empresa-selecionada="$emit('empresa-selecionada', $event)"
      />
      <alter-system-action
        :key="'alter-system' + alteredUser"
        @altered-system="chengeSystem"
      />
      <profile :class="isAcessedByHealthWorker() ? 'desabilitado' : ''" />
      <permissions
        :key="'permissions-action' + alteredUser"
        :disabled="isAcessedByHealthWorker()"
        :notification-counter="notificationCounter"
        :class="isAcessedByHealthWorker() ? 'desabilitado' : ''"
      />
      <contacts-action />
      <logout-action />
    </div>
  </div>
</template>

<script>
import AlterUserCombo from "@/components/topo-actions/alter-user.vue";
import AlterCompanyCombo from "@/components/topo-actions/alter-company.vue";
import AlterSystemAction from "@/components/topo-actions/alter-system.vue";
import ContactsAction from "@/components/topo-actions/contacts.vue";
import LogoutAction from "@/components/topo-actions/logout.vue";
import Permissions from "@/components/topo-actions/permissions.vue";
import Profile from "@/components/topo-actions/profile.vue";

export default {
  name: "TopoMenu",
  components: {
    AlterUserCombo,
    AlterCompanyCombo,
    AlterSystemAction,
    ContactsAction,
    LogoutAction,
    Permissions,
    Profile,
  },
  props: ["notificationCounter"],
  data: function () {
    return {
      alteredSystem: false,
      alteredUser: false,
    };
  },
  methods: {
    changeUser() {
      this.alteredUser = !this.alteredUser;
      this.$emit("change-user", true);
    },
    hasCombos: function () {
      return (
        this.$root.getAuthData().defaultSystem === "Admin" ||
        (this.$root.getAuthData().isHealthWorker &&
          this.$root.getAuthData().defaultSystem === "Cliente")
      );
    },
    isAcessedByHealthWorker: function () {
      return (
        this.$root.getAuthData().usuario !== this.$root.getAuthData().userLogged
      );
    },
    chengeSystem: function () {
      this.alteredSystem = !this.alteredSystem;
      this.$emit("altered-system", true);
    },
  },
};
</script>

<style scoped>
.nav-w-combo {
  width: 50%;
}
.nav-s-combo {
  width: 10%;
}
.nav-combo {
  width: 60%;
}

.desabilitado {
  display: none !important;
}

@media (min-width: 768px) {
  .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.menu-item {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.menu-text {
  /* float: left; */
  margin-left: 5px;
}

.m-icon {
  float: left;
}

.menu-item-m {
  color: inherit;
  font-weight: 500;
  font-size: 15px;
  /* text-transform: uppercase; */
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 35px;
  cursor: pointer;
}

.text-orange {
  color: #ff9000;
}

.currentColor,
.fas {
  color: #ff9800;
}

li .dx-texteditor.dx-editor-underlined:after {
  border-bottom: unset;
}
li .dx-texteditor.dx-editor-underlined {
  border: 1px solid #ff9000;
  border-radius: 20px;
  padding-left: 0.5em;
}
</style>
