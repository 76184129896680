var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row vertical-center" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          !_vm.useQr
            ? _c("img", {
                staticStyle: { width: "20%" },
                attrs: { src: require("../assets/img/logo.png") }
              })
            : _vm._e(),
          _vm.useQr && _vm.permissionUrl !== ""
            ? _c("qrcode-vue", {
                attrs: { value: _vm.permissionUrl, size: 150, level: "H" }
              })
            : _vm._e(),
          _vm.message !== ""
            ? _c("h4", {
                staticClass: "validation-text",
                class: {
                  "validation-text-center": _vm.askPermission,
                  "validation-text-justify": !_vm.askPermission
                },
                domProps: { innerHTML: _vm._s(_vm.message) }
              })
            : _vm._e(),
          _vm.askPermission
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning startBtn",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("ask-permission", true)
                      }
                    }
                  },
                  [_vm._v(" Solicitar Permissão ")]
                )
              ])
            : _vm._e(),
          !_vm.askPermission
            ? _c("div", [
                _vm.validatePermission
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-warning startBtn",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("validate-permission", true)
                          }
                        }
                      },
                      [_vm._v(" Entrar ")]
                    )
                  : _vm._e(),
                _c("input", {
                  attrs: { type: "hidden", id: "permission-url" },
                  domProps: { value: _vm.permissionUrl }
                }),
                _vm.permissionUrl !== ""
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-warning startBtn",
                        attrs: { type: "button" },
                        on: { click: _vm.copyPermissionUrl }
                      },
                      [_vm._v(" Copiar o link da Solicitação ")]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning startBtn",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("resend-permission", true)
                      }
                    }
                  },
                  [_vm._v(" Reenviar e-mail com Solicitação ")]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }