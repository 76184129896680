<template>
  <div id="questionario-indicador">
    <div class="row" v-for="(item, index) in data" :key="index">
      <div class="col-sm-4" v-for="(item2, index2) in item" :key="index2">
        <div class="card card-questionario">
          <div
            class="card-header card-header-text"
            data-background-color="orange"
            v-if="item2.grupo !== undefined"
          >
            <h4 class="card-title">Módulo {{ item2.nModulo + 1 }}</h4>
            <p class="category">{{ item2.grupo }}</p>
          </div>
          <div class="card-content">
            {{ `${item2.index + 1} - ${item2.questao}` }}
            <div v-for="(item3, index3) in item2.opcoes" :key="index3">
              <label>
                <input
                  type="radio"
                  :name="'optionsRadios' + item2.index + item2.nModulo"
                  @click="addMedidorValor(item2.id, item3.id)"
                />
                {{ item3.opcao }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-5 mb-5" style="margin: 0">
      <DxButton
        id="save"
        text="Salvar"
        class="btn btn-warning pull-right"
        @click="saveQuestionario"
        >Salvar</DxButton
      >
      <DxButton
        id="back"
        text="Voltar"
        class="btn btn-secondary pull-right btn-back separated"
        @click="back"
        >Voltar</DxButton
      >
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";

export default {
  name: "QuestionarioIndicador",
  components: {
    DxButton,
  },
  props: {
    id: {
      default: null,
      required: true,
    },
  },
  data: function () {
    const inst = this;
    return {
      data: [],
      qtdTotalQuestoes: 0,
      formData: {
        DataInicial: new Date(),
        Medidores: [],
        DataFinal: null,
        Indicador: this.id,
        PessoaFisica: inst.$root.getAuthData().usuario,
      },
    };
  },
  mounted: function () {
    this.getQuestoes();
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    addMedidorValor: function (Medidor, Opcao) {
      if (
        this.formData.Medidores.find((value) => value.Medidor === Medidor) ===
        undefined
      )
        this.formData.Medidores.push({ Medidor, Opcao });
      else {
        this.formData.Medidores.filter(
          (value) => value.Medidor === Medidor
        )[0].Opcao = Opcao;
      }
    },
    saveQuestionario: function () {
      this.formData.DataFinal = this.getStringDate(this.formData.DataInicial);
      this.formData.DataFinal = this.getStringDate(new Date());

      if (this.formData.Medidores.length < this.qtdTotalQuestoes) {
        notify("Existem opções não preenchidas", "error", 4000);
        return;
      }

      this.$ConAPI
        .post(`/Medicao/SaveMedicao/`, this.formData, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          notify("Questionário preenchido com sucesso", "success", 4000);
          this.$router.push({ path: `/indicador/${this.id}` });
        })
        .catch(function () {
          // console.log(error);
        });
    },
    getQuestoes: function () {
      this.$ConAPI
        .get(`/Medicao/GetQuestionariosOpcoes/${this.id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.$emit("breadcrumb-page-name", [
            { name: resposta[0].tipo },
            {
              name: resposta[0].indicador,
              link: `/indicador/${this.$route.params.id}`,
            },
          ]);
          const a = resposta.reduce((previousValue, currentValue, index) => {
            const b = [];

            currentValue.questoes.map((value, index2) => {
              b.push({
                index: index2,
                ...value,
                nModulo: index,
              });
            });
            b[0] = {
              idGrupo: currentValue.id,
              grupo: currentValue.nome,
              // nModulo: index,
              ...b[0],
            };

            b.map((value) => {
              previousValue.push(value);
            });

            return previousValue;
          }, []);
          const c = a.map((value, index) => {
            return { index, ...value };
          });
          this.qtdTotalQuestoes = c.length;
          let b = [];
          const indexes = Math.ceil(c.length / 3);
          for (let index = 0; index < indexes; index++) {
            b.push(c.slice(index * 3, (index + 1) * 3));
          }
          this.data = b;
        })
        .catch(function () {
          // console.log(error);
        });
    },
    getStringDate: function (date_ob) {
      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style scoped lang="scss">
#questionario-indicador {
  padding-bottom: 40px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}
.card-questionario {
  text-align: left;
}

.card .card-header {
  float: unset !important;
}

.btn-back {
  border-radius: 50px;
}

.separated {
  margin: 10px 13px !important;
}
</style>
