<template>
  <div>
    <div class="card" v-if="$root.getAuthData().defaultSystem === 'Admin'">
      <div class="card-content">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-floating">
              <label class="control-label">Riscos</label>
              <DxSelectBox
                :items="riscoCombo"
                display-expr="Name"
                value-expr="id"
                v-model="historicoDataFilters.grau"
                @value-changed="onValueChange"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-floating">
              <label class="control-label">Modulos</label>
              <DxSelectBox
                :items="medidores"
                display-expr="nome"
                value-expr="id"
                v-model="historicoDataFilters.medidor"
                @value-changed="onValueChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-content" v-show="loaded">
        <DxDataGrid
          :data-source="medicoes"
          :show-borders="false"
          :column-hiding-enabled="true"
          height="450"
          width="95%"
          :loadPanel="{ enabled: true }"
          :columnAutoWidth="true"
          @initialized="saveGridInstance"
          @row-removed="onRowRemoved"
          @toolbar-preparing="onToolbarPreparing($event)"
          @row-prepared="onRowPrepared"
        >
          <DxEditing
            refreshMode="full"
            mode="row"
            :useIcons="true"
            :allow-deleting="canRemove"
          >
            <DxTexts
              confirm-delete-message="Deseja realmente excluir a medição?"
            />
          </DxEditing>
          <DxColumn
            v-for="(item, index) in colunasMedicoes"
            :key="index"
            :data-field="item.dataField"
            :data-type="item.dataType"
            :format="item.format"
            :sortIndex="item.sortIndex"
            :sortOrder="item.sortOrder"
            :customizeText="item.customizeText"
          />
          <DxColumn
            type="buttons"
            v-if="$root.getAuthData().defaultSystem !== 'Admin'"
          >
            <DxButton
              item-template="default"
              :visible="isFilledByOther"
              icon="user"
              :on-click="verPopupUser"
              hint="Preenchido por outro usuário"
            >
            </DxButton>
            <DxButton
              item-template="default"
              :visible="$root.getAuthData().defaultSystem === 'Admin' && 1 != 1"
              text="Ver"
              :on-click="
                this.isBiomedic ? this.verPopupMedidor : this.verPopupGrupo
              "
              hint="Preenchido por outro usuário"
            >
            </DxButton>
            <DxButton name="delete" />
          </DxColumn>
          <DxPager :show-page-size-selector="false" :show-info="true" />
          <DxPaging :page-size="5" />
          <DxSearchPanel
            :visible="$root.getAuthData().defaultSystem === 'Admin'"
            :width="240"
            placeholder="Procurar..."
          />
          <template #title="{}">
            <div class="informer">
              <h4>
                {{ "Histórico" }}
              </h4>
            </div>
          </template>
        </DxDataGrid>
        <DxPopup
          :visible.sync="popupUserVisible"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :showTitle="false"
          :showCloseButton="false"
          :width="400"
          :height="200"
          :fullScreen="$root.mobile"
          id="tutorial-popup"
          @content-ready="onContentReady"
        >
          <div class="row user-control">
            <div class="col-md-6">
              <img
                class=""
                :src="`${defaultPath}${
                  userControlData.Foto !== null &&
                  userControlData.Foto !== undefined
                    ? userControlData.Foto
                    : defaultImage
                }`"
              />
            </div>
            <div class="col-md-6">
              <label>Nome:</label>
              <h4>{{ userControlData.Nome }}</h4>
            </div>
          </div>
        </DxPopup>
        <DxPopup
          :visible.sync="popupVisible"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :showTitle="false"
          :showCloseButton="false"
          :width="900"
          :height="450"
          :fullScreen="$root.mobile"
          id="tutorial-popup"
          @content-ready="onContentReady"
        >
          <DxScrollView
            width="100%"
            height="100%"
            :showScrollbar="!isBiomedic ? 'always' : 'onScroll'"
            :scroll-by-content="!$root.mobile"
            :scroll-by-thumb="$root.mobile"
          >
            <DadosMedicao
              :data="dadosMedicaoData"
              :data-medicao="dataMedicao"
              :indicador="indicador"
              :is-biomedic="isBiomedic"
              :person="pessoaMedicao"
              @close="popupVisible = !popupVisible"
            />
          </DxScrollView>
        </DxPopup>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxEditing,
  DxTexts,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxColumn,
  DxButton,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { DxPopup } from "devextreme-vue/popup";
import DadosMedicao from "@/components/dados-medicao.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxSelectBox from "devextreme-vue/select-box";

export default {
  name: "CardHistoricoMedicoes",
  components: {
    DxDataGrid,
    DxEditing,
    DxTexts,
    DxPopup,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxColumn,
    DxButton,
    DadosMedicao,
    DxScrollView,
    DxSelectBox,
  },
  props: {
    id: {
      default: null,
      required: true,
    },
    isBiomedic: {
      default: null,
    },
    reload: {
      default: false,
    },
    mostraJejum: {
      default: false,
    },
  },
  data: function () {
    const inst = this;
    const r = inst.$root.getAuthData().acessoGPS
      ? [
          { id: 0, Name: "Todos níveis de risco" },
          { id: 3, Name: "Risco Alto" },
          { id: 2, Name: "Risco Médio" },
          { id: 1, Name: "Risco Baixo" },
        ]
      : [
          { id: 0, Name: "Todos níveis de risco" },
          { id: 3, Name: "Risco Alto" },
          { id: 2, Name: "Risco Médio" },
          { id: 1, Name: "Risco Baixo" },
          { id: 4, Name: "Não autorizados" },
        ];

    return {
      medicoes: [],
      colunasMedicoes: [],
      dataGridInstance: null,
      canRemove: false,
      popupVisible: false,
      popupUserVisible: false,
      dadosMedicaoData: [],
      dataMedicao: "",
      indicador: "",
      loaded: false,
      pessoaMedicao: "",
      chaveDados: "",
      riscoCombo: r,
      riscoSelected: 0,
      userControlData: {},
      defaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=`,
      defaultImage: "default-non-user-no-photo-1.jpg",
      historicoDataFilters: {
        company: inst.$root.getAuthData().userCompany,
        indicador: inst.id,
        user: inst.$root.getAuthData().usuario,
        medidor: null,
        grau: 0,
        permissionado: null,
      },
      medidores: [],
    };
  },
  created: function () {
    if (this.$root.getAuthData().defaultSystem === "Cliente") {
      if (this.isBiomedic) this.getDataBio();
      else if (this.isBiomedic != null) this.getData();
    } else {
      this.getMedidoresIndicador();
    }
  },
  watch: {
    isBiomedic: function (v) {
      if (this.$root.getAuthData().defaultSystem === "Cliente") {
        if (v) this.getDataBio();
        else this.getData();
      } else {
        this.getAdminHistData();
      }

      this.dataGridInstance.endCustomLoading();
    },
    reload: function () {
      if (this.$root.getAuthData().defaultSystem === "Cliente") {
        if (this.isBiomedic) this.getDataBio();
        else if (this.isBiomedic != null) this.getData();
      } else {
        this.getAdminHistData();
      }
      this.dataGridInstance.endCustomLoading();
    },
  },
  methods: {
    saveGridInstance: function (e) {
      this.dataGridInstance = e.component;
    },
    getDataBio: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/2/${
            this.$root.getAuthData().usuario
          }/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.getColumnsAndDataSource(resposta.Medidores);
          if (
            this.colunasMedicoes.filter((v) => v.dataField === "idMedicao")
              .length > 0
          )
            this.dataGridInstance.option("keyExpr", "idMedicao");
          this.canRemove = true;
          this.dataGridInstance.refresh();
          this.loaded = true;
        })
        .catch(function (error) {
          notify(`Histórico: ${error.message}`, "error", 4000);
        });
    },
    getData: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/6/${
            this.$root.getAuthData().usuario
          }/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.medicoes = resposta.Medicoes;
          this.colunasMedicoes = [
            {
              dataField: "data",
              dataType: "date",
              format: "dd/MM/yyyy",
              sortIndex: 0,
              sortOrder: "desc",
            },
          ];
          this.canRemove = true;
          this.dataGridInstance.option("keyExpr", "idMedicao");
          this.dataGridInstance.refresh();
          this.loaded = true;
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    getMedidoresIndicador: function () {
      this.$ConAPI
        .get(`/DadosIndicadores/GetGruposMedidoresIndicador/${this.id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => JSON.parse(result.request.response))
        .then((resposta) => {
          this.medidores = resposta;
          this.historicoDataFilters.medidor = resposta[0].id;
          this.getAdminHistData();
        })
        .catch(function () {
          // console.log(error);
        });
    },
    getAdminHistData: function () {
      this.$ConAPI
        .post(
          `/DadosIndicadores/GetHistoricoIndicadorAdminData`,
          this.historicoDataFilters,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.medicoes = resposta.Medicoes;
          this.colunasMedicoes = [
            {
              dataField: "pessoa",
              caption: "Nome",
              dataType: "string",
              customizeText: function (cellInfo) {
                let a = cellInfo.value.split(" ");
                return `${a[0]} ${a[a.length - 1]}`;
              },
            },
            {
              dataField: "data",
              caption: "Data",
              dataType: "date",
              format: "dd/MM/yyyy",
              sortIndex: 0,
              sortOrder: "desc",
            },
            // {
            //   dataField: "departamento",
            //   caption: "Departamento",
            // },
          ];

          // if (this.$root.mobile) {
          //   this.colunasMedicoes.push({
          //     dataField: "risco",
          //     caption: "Nível de Risco",
          //   });
          // }

          // this.colunasMedicoes.push({
          //   type: "buttons",
          //   buttons: [
          //     {
          //       text: "Ver",
          //       icon: "",
          //       hint: "Ver",
          //       cssClass: "btn-ver",
          //       onClick: this.isBiomedic
          //         ? this.verPopupMedidor
          //         : this.verPopupGrupo,
          //     },
          //   ],
          // });
          this.canRemove = false;
          this.dataGridInstance.refresh();
          this.loaded = true;
        })
        .catch(function () {
          // console.log(error);
        });
    },
    getColumnsAndDataSource: function (arr) {
      if (arr === null) {
        this.medicoes = [];
      } else {
        const z = arr.reduce((p, c, currentIndex, array) => {
          let a = {};
          if (c.visible) {
            const b = array.filter((x) => x.idMedicao === c.idMedicao);
            b.map((value) => {
              a[value.Nome] = value.valor;
              a["idMedicao"] = value.idMedicao;
              a["Jejum"] = value.jejum;
              a["filledByOther"] = value.filledByOther;
              a["userControl"] = value.userControl;
            });
            a = {
              Data: c.data,
              ...a,
            };
            p.push(a);
          }
          return p;
        }, []);

        let y = [];

        if (z.length > 0) {
          y = Object.keys(z[0])
            .filter(
              (x) =>
                x !== "idMedicao" &&
                x !== "Jejum" &&
                x !== "Data" &&
                x !== "filledByOther" &&
                x !== "userControl"
            )
            .map((value) => {
              return {
                dataField: value,
                dataType: "text",
              };
            });
        }

        y.unshift({
          dataField: "Data",
          dataType: "date",
          format: "dd/MM/yyyy HH:mm",
          sortIndex: 0,
          sortOrder: "desc",
        });

        if (this.mostraJejum) {
          y.push({
            dataField: "Jejum",
            dataType: "string",
            customizeText: function (cellInfo) {
              return cellInfo.value ? "Sim" : "Não";
            },
          });
        }

        if (z.length > 0)
          this.medicoes = Array.from(new Set(z.map((x) => x.idMedicao))).map(
            (value) => {
              return {
                ...z.find((v) => v.idMedicao === value),
              };
            }
          );

        this.colunasMedicoes = y;
      }
    },
    onRowRemoved: function (e) {
      this.$ConAPI
        .delete(
          `/Medicao/${
            e.key.idMedicao !== undefined && e.key.idMedicao !== null
              ? e.key.idMedicao
              : e.key
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          //this.getDataBio();
          this.$emit("exclusao-concluida", true);
          notify(resposta.message, "success", 4000);
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    verPopupMedidor: function (e) {
      this.chaveDados = e.row.data.idMedicao;
      this.pessoaMedicao =
        e.row.data.pessoa !== undefined
          ? e.row.data.pessoa
          : this.$root.getAuthData().userName;
      this.$ConAPI
        .get(`/Medicao/Medidor/${e.row.data.idMedicao}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.dadosMedicaoData = resposta;
          this.dataMedicao = resposta[0].data;
          this.indicador = resposta[0].indicador;
          this.popupVisible = true;
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    verPopupUser: function (e) {
      if (e.row.data.userControl !== null) {
        this.$ConAPI
          .get(`/PessoaFisicaSearch/GetUser/${e.row.data.userControl}`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => JSON.parse(res.request.response))
          .then((odata) => {
            this.userControlData = odata;
            this.popupUserVisible = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    verPopupGrupo: function (e) {
      this.chaveDados = e.row.data.idMedicao;
      this.pessoaMedicao =
        e.row.data.pessoa !== undefined
          ? e.row.data.pessoa
          : this.$root.getAuthData().userName;
      this.$ConAPI
        .get(`/Medicao/Grupo/${e.row.data.idMedicao}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.dadosMedicaoData = resposta.reduce((p, c, i) => {
            p.push({ indice: i + 1, ...c });
            return p;
          }, []);
          this.dataMedicao = resposta[0].data;
          this.indicador = resposta[0].indicador;
          this.popupVisible = true;
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    onContentReady: function (e) {
      var html = e.component.content();
      html.setAttribute("style", "padding: 5px!important;");
    },
    onToolbarPreparing: function (e) {
      if (!this.$root.mobile)
        e.toolbarOptions.items.unshift({
          location: "before",
          template: "title",
          locateInMenu: false,
        });
    },
    onRowPrepared: function (info) {
      if (info.rowType === "data") {
        if (info.data.permissionado || this.$root.getAuthData().acessoGPS) {
          if (info.data.grau == 1)
            info.rowElement.classList.add("row-grau-baixo");
          else if (info.data.grau == 2)
            info.rowElement.classList.add("row-grau-medio");
          else if (info.data.grau == 3)
            info.rowElement.classList.add("row-grau-alto");
        }
      }
    },
    isFilledByOther: function (e) {
      return e.row.data.filledByOther;
    },
    onValueChange() {
      this.getAdminHistData();
    },
  },
};
</script>

<style scoped>
.btn-ver {
  color: white !important;
  background-color: #ff9800;
  padding: 2px 5px !important;
  border-radius: 7px;
  font-size: 15px !important;
}

.title-m {
  display: block;
}
</style>

<style>
.row-grau-alto {
  background-color: #d35461;
  color: white;
}
.row-grau-medio {
  background-color: #f7d97f;
  color: white;
}
.row-grau-baixo {
  background-color: #5ce27b;
  color: white;
}

.user-control,
.user-control div {
  height: 100%;
}
.user-control img {
  width: 100%;
  height: 100%;
}
</style>