var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-orange",
      class: { "menu-item": !_vm.$root.mobile, controle: _vm.$root.mobile },
      on: {
        click: function($event) {
          return _vm.goToProfile()
        },
        mouseenter: function($event) {
          _vm.ProfileVisible = !_vm.ProfileVisible
        },
        mouseleave: function($event) {
          _vm.ProfileVisible = !_vm.ProfileVisible
        }
      }
    },
    [
      !_vm.$root.mobile
        ? _c("i", { staticClass: "fas fa-user", attrs: { id: "profile" } })
        : _vm._e(),
      _vm.$root.mobile ? _c("i", { staticClass: "fas fa-user" }) : _vm._e(),
      _vm.$root.mobile
        ? _c("span", { staticClass: "titulo" }, [_vm._v("Perfil")])
        : _vm._e(),
      !_vm.$root.mobile
        ? _c(
            "DxTooltip",
            {
              attrs: {
                visible: _vm.ProfileVisible,
                "close-on-outside-click": false,
                target: "#profile"
              }
            },
            [_vm._v("Visualizar Perfil")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }