var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading && _vm.data.length > 0,
          expression: "!loading && data.length > 0"
        }
      ],
      staticClass: "card"
    },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "card-content" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("DxSelectBox", {
              attrs: { items: _vm.selectorData },
              on: { "value-changed": _vm.onValueChanged },
              model: {
                value: _vm.selectorFirstValue,
                callback: function($$v) {
                  _vm.selectorFirstValue = $$v
                },
                expression: "selectorFirstValue"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "DxPieChart",
                {
                  staticClass: "pie-chart-riscos",
                  attrs: {
                    type: "doughnut",
                    "size-group": "piesGroup",
                    "data-source": _vm.chartData,
                    "customize-point": _vm.customizePoint
                  }
                },
                [
                  _c(
                    "DxSeries",
                    {
                      attrs: {
                        "argument-field": "grau",
                        "value-field": "value"
                      }
                    },
                    [
                      _c(
                        "DxLabel",
                        {
                          attrs: {
                            visible: true,
                            format: "percent",
                            position: "inside"
                          }
                        },
                        [_c("DxFont", { attrs: { size: 20 } })],
                        1
                      )
                    ],
                    1
                  ),
                  _c("DxLegend", { attrs: { visible: false } }),
                  _c("DxTooltip", { attrs: { enabled: false } })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c("table", { staticClass: "table" }, [
            _c(
              "tbody",
              _vm._l(_vm.legendData, function(item, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", { attrs: { scope: "col" } }),
                  _c("td", { attrs: { scope: "col" } }, [
                    _vm._v(
                      " " +
                        _vm._s(idx + 1) +
                        " - " +
                        _vm._s(item.grau.toUpperCase()) +
                        " "
                    )
                  ]),
                  _c("td", { attrs: { scope: "col" } }, [
                    _vm._v(" " + _vm._s(item.value) + " ")
                  ]),
                  _c("td", { attrs: { scope: "col" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.cor === "#4caf50",
                            expression: "item.cor === '#4caf50'"
                          }
                        ]
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-compass",
                          style: {
                            color: "#4caf50",
                            "font-size": "20px"
                          },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.cor === "#e0c619",
                            expression: "item.cor === '#e0c619'"
                          }
                        ]
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-compass",
                          style: {
                            color: "#e0c619",
                            "font-size": "20px"
                          },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.cor === "#f44336",
                            expression: "item.cor === '#f44336'"
                          }
                        ]
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-compass",
                          style: {
                            color: "#f44336",
                            "font-size": "20px"
                          },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }