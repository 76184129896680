var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c(
        "a",
        {
          staticClass: "collapsed",
          attrs: { "data-toggle": "collapse", href: "#collapseExample" }
        },
        [
          _c("span", { staticStyle: { margin: "0 3px 0 7px" } }, [
            _vm._v(_vm._s(_vm.userShortenedName))
          ]),
          _vm.$root.getAuthData().usuario !== _vm.$root.getAuthData().userLogged
            ? _c("span", { staticClass: "acessado" }, [
                _vm._v(
                  "Acessado por " +
                    _vm._s(_vm.$root.getAuthData().userLoggedName)
                )
              ])
            : _vm._e(),
          _c("br")
        ]
      ),
      _c("ModaisTermos")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }