<template>
  <div>
    <div class="logo hidden-xs">
      <div style="cursor: pointer" @click="goToHome">
        <img src="../../assets/img/logo.png" style="width: 80%" />
      </div>
    </div>
    <div class="logo logo-mini">
      <a href="#">
        <img src="../../assets/img/logo_mini.png" style="width: 100%" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteLogo",
  methods: {
    goToHome: function () {
      this.$emit("page-name", "Indicadores");
      this.$router.push({
        path: `/indicador-resumo`,
      });
    },
  },
};
</script>

<style>
</style>
