var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "page-relatorios" } },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading || _vm.loading } }),
      _vm.hasNoPermission
        ? _c("PermissionValidation", {
            staticStyle: { "z-index": "100" },
            attrs: {
              "use-qr": _vm.hasPendingPermission,
              "permission-url": _vm.urlValidatePermission,
              message: _vm.mensagemSemPermissao,
              "ask-permission": _vm.hasPermitionButton,
              "validate-permission": _vm.hasPendingPermission
            },
            on: {
              "update:message": function($event) {
                _vm.mensagemSemPermissao = $event
              },
              "validate-permission": _vm.validateUserPermission,
              "ask-permission": _vm.askPermission,
              "resend-permission": _vm.resendNotification
            }
          })
        : _vm._e(),
      _vm.showFilters
        ? _c("IndicadoresFilters", {
            attrs: { "multiple-indicador-filter": true },
            on: {
              "filter-values": function($event) {
                return _vm.getTipos(
                  Object.assign({}, $event, {
                    idPessoaFisica: _vm.idPessoaFisica,
                    tipo: ""
                  })
                )
              }
            }
          })
        : _vm._e(),
      _vm.hasNoData
        ? _c("NoData", { attrs: { message: "Nenhum dado encontrado." } })
        : _vm._e(),
      _c(
        "DxScrollView",
        {
          attrs: {
            id: _vm.isExternal ? "content_view" : "",
            width: "100%",
            "show-scrollbar": "always",
            "scroll-by-content": !_vm.$root.mobile,
            "scroll-by-thumb": _vm.$root.mobile
          }
        },
        [
          !_vm.hasNoData
            ? _c("div", { staticClass: "container-fluid" }, [
                _c(
                  "div",
                  {
                    staticClass: "row",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "nav nav-pills nav-pills-warning nav-pills-icons",
                          staticStyle: { width: "100%", margin: "0 auto" },
                          attrs: { role: "tablist" }
                        },
                        _vm._l(_vm.tiposIndicadores, function(item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "nav-item",
                              class: {
                                active: _vm.currentActive === item.sigla
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "data-toggle": "tab",
                                    href:
                                      "#" +
                                      item.sigla
                                        .replace(" ", "")
                                        .replace(" ", "") +
                                      "tab",
                                    role: "tablist"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.loading = true
                                      _vm.setCurrent(item.sigla)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa logo-size",
                                    class: item.logo,
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _c("span", {}, [_vm._v(_vm._s(item.sigla))])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12" }, [
                    _c("div", { staticClass: "page-categories" }, [
                      _c(
                        "div",
                        { staticClass: "tab-content tab-subcategories" },
                        _vm._l(_vm.tiposIndicadores, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "tab-pane",
                              class: {
                                active: _vm.currentActive === item.sigla
                              },
                              attrs: {
                                id:
                                  "" +
                                  item.sigla.replace(" ", "").replace(" ", "") +
                                  "tab"
                              }
                            },
                            [
                              _vm.currentActive === item.sigla
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.getDataArray(item.sigla),
                                      function(item2, idx) {
                                        return _c("RelatorioConteudo", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.$root.loading,
                                              expression: "!$root.loading"
                                            }
                                          ],
                                          key: idx,
                                          attrs: {
                                            resource: item2,
                                            "show-recommendation-button":
                                              !_vm.isExternal && _vm.isSameUser
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }