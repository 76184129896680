<template>
  <div>
    <Grid
      :columns="columns"
      :action="action"
      class="grid-resize"
      :reload="reloadGrid"
      :other-buttons="otherButtons"
      :parent-id-field="parentIdField"
      :filter-value="filterValue"
      :filter-field="filterField"
      v-on:add-item="addItem"
      v-on:edit-item="editItem"
      :hasDetail="true"
      :can-reorder-by-drag="true"
      :can-sort="false"
    >
      <template slot-scope="{ master }">
        <Grid
          slot="relations-tab"
          :columns="detailColumns"
          :other-buttons="medidorOtherButtons"
          :action="'Gps_IndicadorMedidor'"
          :parent-id-field="'_IndicadorGrupo'"
          :filter-value="master.data.Id"
          :filter-field="'IndicadorGrupo.Id'"
          :edit-mode="'popup'"
          :init-inserting-row="initInsertingRowMedidor"
          :can-reorder-by-drag="true"
          :can-sort="false"
          :customize-columns="customizeColumns"
        />
      </template>
    </Grid>

    <DxPopup
      :visible.sync="openForm"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'85vh'"
      :height="'70vh'"
      :title="grupoPopupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <GrupoIndicadorForm
          @close="fecharPopupTipo"
          :indicador="indicador"
          :id="grupoId"
          :reload-data="grupoReload"
        />
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :visible.sync="openFaixaMedidorForm"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="faixas-grupo"
      :width="900"
      :height="500"
      :title="faixaMedidorPopupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <FaixasIndicador :indicador="indicador" :medidor="medidorFaixaId" />
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :visible.sync="openFaixaGrupoForm"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="faixas-grupo"
      :width="900"
      :height="500"
      :title="faixaGrupoPopupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <FaixasIndicador :indicador="indicador" :grupo="grupoFaixaId" />
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :visible.sync="openOpcaoMedidor"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="faixas-grupo"
      :width="900"
      :height="500"
      :title="opcaoMedidorPopupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <Grid
          :columns="opcaoColumns"
          :action="'GPS_Opcao'"
          class="grid-resize"
          :reload="reloadGridOpcao"
          :parent-id-field="'_IndicadorMedidor'"
          :filter-value="medidorId"
          :filter-field="'IndicadorMedidor.Id'"
          :edit-mode="'batch'"
          :init-inserting-row="initInsertingRowOpcao"
        />
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
import GrupoIndicadorForm from "@/views/forms/grupo-indicador.vue";
import FaixasIndicador from "@/components/cadastro-indicadores/faixas.vue";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "GrupoIndicador",
  components: {
    Grid,
    GrupoIndicadorForm,
    DxScrollView,
    DxPopup,
    FaixasIndicador,
  },
  props: {
    indicador: {
      default: null,
      required: true,
    },
  },
  data: function () {
    const inst = this;
    return {
      reloadGrid: false,
      action: "Gps_IndicadorGrupo",
      parentIdField: "_Indicador",
      filterField: "Indicador.Id",
      filterValue: new Guid(inst.indicador),
      columns: [
        { name: "Id", notVisible: true },
        { name: "_Indicador", notVisible: true },
        { name: "Nome", showColumn: true },
        {
          name: "Ordem",
          showColumn: true,
          type: "number",
          defaultSort: true,
        },
        { name: "help", showColumn: false },
        { name: "Peso", showColumn: true },
        { name: "Imagem", showColumn: false },
        { name: "Link", showColumn: false },
        { name: "MostrarGrafico", showColumn: true },
        { name: "Ativo", showColumn: true },
        { name: "CasasDecimais", caption: "Casas Decimais", showColumn: false },
        {
          name: "ValorTotalGrupo",
          caption: "Valor Total Grupo",
          showColumn: false,
        },
      ],
      otherButtons: [
        {
          text: "Faixas",
          key: new Guid().toString(),
          command: function (e) {
            inst.grupoFaixaId = e.row.key.Id;
            inst.openFaixaGrupoForm = !inst.openFaixaGrupoForm;
          },
        },
      ],
      openForm: false,
      grupoId: null,
      grupoReload: false,
      grupoPopupTitle: "",
      faixaGrupoPopupTitle: "",
      faixaMedidorPopupTitle: "",
      openFaixaGrupoForm: false,
      openFaixaMedidorForm: false,
      grupoFaixaId: null,
      medidorFaixaId: null,
      detailColumns: [
        { name: "Id", notVisible: true },
        { name: "_IndicadorGrupo", notVisible: true },
        {
          name: "NomeExibicao",
          caption: "Nome Exibição",
          showColumn: true,
          size: "150",
        },
        { name: "Texto", showColumn: true, size: "650" },
        { name: "NomeVariavel", caption: "Nome Variável", showColumn: true },
        { name: "Exemplo", showColumn: true },
        {
          name: "CasasDecimais",
          caption: "Casas Decimais",
          showColumn: true,
          type: "number",
        },
        { name: "Ordem", showColumn: true, type: "number", defaultSort: true },
        { name: "Ativo", showColumn: true, type: "boolean" },
        { name: "Calculado", showColumn: true, type: "boolean" },
        {
          name: "MostrarGrafico",
          caption: "Mostra Gráfico",
          showColumn: true,
          type: "boolean",
        },
        { name: "Formula", caption: "Fórmula", showColumn: true },
        { name: "Peso", showColumn: true, type: "number" },
        { name: "Link", showColumn: true },
        {
          name: "Sexo",
          showColumn: true,
          lookup: {
            allowClearing: true,
            dataSource: [
              { text: "Todos", value: "X" },
              { text: "Masculino", value: "M" },
              { text: "Feminino", value: "F" },
            ],
            displayExpr: "text",
            valueExpr: "value",
          },
        },
        {
          name: "Tipo",
          showColumn: true,
          lookup: {
            allowClearing: true,
            dataSource: [
              { text: "Calculado", value: "C" },
              { text: "Opções", value: "O" },
              { text: "Informado", value: "I" },
            ],
            displayExpr: "text",
            valueExpr: "value",
          },
        },
        { name: "CorExibicao", caption: "Cor Exibição", showColumn: true },
      ],
      customizeColumns: function (e) {
        e.map((value) => {
          if (value.dataField === "Ordem") {
            value.allowEditing = false;
          }
        });
      },
      medidorOtherButtons: [
        {
          text: "Faixas",
          key: new Guid().toString(),
          command: function (e) {
            inst.medidorFaixaId = e.row.key.Id;
            inst.openFaixaMedidorForm = !inst.openFaixaMedidorForm;
          },
        },
        {
          text: "Opções",
          key: new Guid().toString(),
          visible: function (e) {
            return e.row.data.Tipo === "O";
          },
          command: function (e) {
            inst.medidorId = e.row.key.Id;
            inst.reloadGridOpcao = !inst.reloadGridOpcao;
            inst.openOpcaoMedidor = !inst.openOpcaoMedidor;
          },
        },
      ],
      initInsertingRowMedidor: function (e) {
        e.data = {
          Ativo: true,
          Calculado: false,
          MostrarGrafico: false,
          Ordem: 0,
        };
      },
      opcaoMedidorPopupTitle: "",
      openOpcaoMedidor: false,
      opcaoColumns: [
        { name: "Id", notVisible: true },
        { name: "_IndicadorMedidor", notVisible: true },
        { name: "Nome", showColumn: true, defaultSort: true },
        { name: "Descricao", caption: "Descrição", showColumn: true },
        { name: "Ajuda", showColumn: true },
        { name: "Ordem", showColumn: true, type: "number" },
        { name: "Valor", showColumn: true, type: "number" },
        { name: "Ativo", showColumn: true, type: "boolean" },
      ],
      reloadGridOpcao: false,
      medidorId: null,
      initInsertingRowOpcao: function (e) {
        e.data = {
          Ativo: true,
        };
      },
    };
  },
  methods: {
    addItem: function () {
      this.grupoPopupTitle = "Cadastro Módulo";
      this.grupoId = null;
      this.openForm = true;
    },
    editItem: function (e) {
      this.grupoPopupTitle = "Editar Módulo";
      this.grupoId = e.data.Id;
      this.grupoReload = !this.grupoReload;
      this.openForm = true;
    },
    fecharPopupTipo() {
      this.openForm = !this.openForm;
      this.reloadGrid = !this.reloadGrid;
    },
  },
};
</script>

<style>
.faixas-grupo {
  width: 90vh;
  height: 80vh;
}
</style>