var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.exibeFaixaUnicoBloco && _vm.dadosTabela.length > 0
      ? _c(
          "div",
          [
            _vm.showTitle
              ? _c("h3", [_vm._v("Valores de referência")])
              : _vm._e(),
            _vm._l(_vm.dadosTabela, function(item, index) {
              return _c(
                "table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.ValoresReferencia.length > 0,
                      expression: "item.ValoresReferencia.length > 0"
                    }
                  ],
                  key: index,
                  staticClass: "table"
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticStyle: { color: "#b66cb3" },
                          attrs: { scope: "col" }
                        },
                        [_vm._v(" " + _vm._s(item.Nome) + " ")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { color: "#b66cb3" },
                          attrs: { scope: "col" }
                        },
                        [_vm._v("Classificação")]
                      )
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(item.ValoresReferencia, function(item2, index2) {
                      return _c("tr", { key: index2 }, [
                        _c("td", { attrs: { scope: "col" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "refValue",
                              class: { zeroValue: item2.ValorInicial === 0 }
                            },
                            [_vm._v(_vm._s(item2.ValorInicial))]
                          ),
                          _c("span", { staticClass: "refValue" }, [
                            _vm._v("-")
                          ]),
                          _c("span", { staticClass: "refValue" }, [
                            _vm._v(_vm._s(item2.ValorFinal))
                          ]),
                          _c("span", { staticClass: "refValue" }, [
                            _vm._v(
                              _vm._s(
                                item2.Unidade !== null &&
                                  item2.Unidade !== undefined
                                  ? item2.Unidade
                                  : ""
                              )
                            )
                          ])
                        ]),
                        _c("td", { attrs: { scope: "col" } }, [
                          _c("i", {
                            staticClass: "fas fa-compass",
                            class: {
                              "fa-flip-vertical":
                                item2.CorExibicao === "#f44336",
                              "rotate-45": item2.CorExibicao === "#e0c619"
                            },
                            style: {
                              color: item2.CorExibicao
                                ? item2.CorExibicao
                                : "gray",
                              "font-size": "20px"
                            },
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" " + _vm._s(item2.Nome) + " ")
                        ])
                      ])
                    }),
                    0
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.exibeFaixaUnicoBloco && _vm.dadosTabela.length > 0
      ? _c("div", [
          _vm.showTitle
            ? _c("h3", [_vm._v("Valores de referência")])
            : _vm._e(),
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.getUniqueDataKeys(_vm.dadosTabela[0]), function(
                  item,
                  idx
                ) {
                  return _c(
                    "th",
                    {
                      key: idx,
                      staticStyle: { color: "#b66cb3" },
                      attrs: { scope: "col" }
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.dadosTabela, function(item, idx) {
                return _c(
                  "tr",
                  { key: idx },
                  _vm._l(_vm.getUniqueDataKeys(_vm.dadosTabela[0]), function(
                    item2,
                    idx2
                  ) {
                    return _c("td", { key: idx2, attrs: { scope: "col" } }, [
                      item2 !== "Classificação"
                        ? _c("span", { staticClass: "refValue" }, [
                            _vm._v(" " + _vm._s(item[item2]) + " ")
                          ])
                        : _vm._e(),
                      item2 === "Classificação"
                        ? _c("div", [
                            _c("i", {
                              staticClass: "fas fa-compass",
                              style: {
                                color: item[item2].CorExibicao
                                  ? item[item2].CorExibicao
                                  : "gray",
                                "font-size": "20px"
                              },
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" " + _vm._s(item[item2].Nome) + " ")
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }