<template>
  <div class="row controles-indicadores">
    <div class="col-xs-4 text-left">
      <div @click="navegate('prev')" class="controle">
        <span class="fas fa-chevron-circle-left"></span>
        <span class="titulo">Anterior</span>
      </div>
    </div>
    <div class="col-xs-4 text-center">
      <a href="#" @click="goToHome()" class="controle">
        <span class="fas fa-home"></span>
        <span class="titulo">Página inicial</span>
      </a>
    </div>
    <div class="col-xs-4 text-right">
      <div @click="navegate('next')" class="controle">
        <span class="fas fa-chevron-circle-right"></span>
        <span class="titulo">Próximo</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "controles-indicadores",
  computed: {
    indicadores: function () {
      if (this.$root.getAuthData().defaultSystem === "Admin")
        return this.$root.indicadoresAdmin;
      else return this.$root.indicadores;
    },
  },
  methods: {
    navegate(type) {
      const i = this.indicadores.indexOf(this.$route.params.id);
      const l = this.indicadores.length - 1;
      const path = this.$router.options.routes
        .filter((x) => x.path === "/")[0]
        .children.filter((x) => x.meta.menu)
        .filter(
          (x) =>
            x.meta.menu.type === this.$root.getAuthData().defaultSystem &&
            x.name.includes("Indicador")
        )[0].path;

      if (i === 0 && type == "prev") {
        this.$router.push({
          path: `/${path.replace(":id", this.indicadores[l])}`,
        });
      } else if (i === l && type == "next") {
        this.$router.push({
          path: `/${path.replace(":id", this.indicadores[0])}`,
        });
      } else {
        switch (type) {
          case "prev":
            this.$router.push({
              path: `/${path.replace(":id", this.indicadores[i - 1])}`,
            });
            break;
          case "next":
            this.$router.push({
              path: `/${path.replace(":id", this.indicadores[i + 1])}`,
            });
            break;
          default:
            break;
        }
      }
    },
    goToHome() {
      this.$router.push({
        path: `/`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.controles-indicadores {
  margin-right: 0px;
  margin-top: -12px;
  .controle {
    color: orange;
    display: inline-block;
    text-align: center;
    font-size: 3rem;
    cursor: pointer;
    &.desabilitado {
      //color: gray !important;
      //pointer-events: none;
      //cursor: none;
      display: none;
    }
    span.fas {
      font-size: 3rem;
      display: block !important;
      margin: auto;
    }
    span.titulo {
      font-size: 1rem;
      display: block;
    }
  }
}
</style>
