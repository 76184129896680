import Vue from 'vue'
import RelatorioIndicadores from "@/views/relatorio-indicadores.vue";
import QuestionarioIndicador from "@/views/forms/questionario-indicador.vue";
import ClienteIndicador from "@/views/cliente-indicador.vue";
import IndicadoresResumo from "@/views/indicadores-resumo.vue";
import Permissions from "@/views/permissions.vue";
import RecomendacoesCliente from "@/views/recomendacoes-cliente.vue";
import UserValidateSolicitation from "@/views/user-validate-solicitation.vue";

const clientRoutes = [
    {
        path: 'relatorio-indicadores/:id',
        name: 'Resultados',
        component: RelatorioIndicadores,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            }, {
                name: 'Resultados'
            }],
            menu: {
                type: 'Cliente',
                order: 2,
                base: {
                    name: 'Resultados',
                    icon: 'fa-chart-line',
                    path: 'relatorio-indicadores/:id',
                },
                visible: true
            },
            type: '',
        },
        beforeEnter(to, from, next) {
            if (from.name === "Usuários" && to.name === "Relatórios") {
                to.meta.breadcrumb = [
                    {
                        name: "Administração",
                    }, {
                        name: 'Usuários',
                        link: '/usuario',
                    }, {
                        name: 'Relatórios'
                    }
                ];
            } else {
                to.meta.breadcrumb = [{
                    name: 'Indicadores',
                    link: '/indicador-resumo'
                }, {
                    name: 'Relatórios'
                }
                ];
            }
            next();
        },
    },
    {
        path: 'indicador/:id',
        name: 'Indicador Usuário',
        component: ClienteIndicador,
        // props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            }],
            menu: {
                type: 'Cliente',
                visible: true
            },
            type: 'Cliente'
        },
    },
    {
        path: 'indicador-resumo',
        name: 'Indicadores',
        component: IndicadoresResumo,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores'
            }],
            menu: {
                type: 'Cliente',
                order: 1,
                base: {
                    name: 'Indicadores',
                    icon: 'fa-tachometer-alt',
                    path: 'indicador-resumo',
                    // class: 'active'
                },
                visible: true
            },
            type: 'Cliente',
        }
    },
    {
        path: 'questionario-indicador/:id',
        name: 'Questionario',
        component: QuestionarioIndicador,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            }],
            menu: {
                type: 'Cliente',
                visible: true
            },
            type: 'Cliente',
        }
    },
    {
        path: 'recomendacoes/',
        name: 'Recomendações',
        component: RecomendacoesCliente,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            },
            {
                name: 'Recomendações'
            }],
            menu: {
                type: 'Cliente',
                order: 4,
                base: {
                    name: 'Recomendações',
                    icon: 'fa-lightbulb',
                    path: 'recomendacoes',
                },
                visible: true
            },
            type: 'Cliente',
        }
    },
    {
        path: 'permissions/',
        name: 'Permissões',
        component: Permissions,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            }, {
                name: 'Permissões'
            }],
            menu: {
                type: 'Cliente',
                order: 3,
                base: {
                    name: 'Permissões',
                    icon: 'fa-users-cog',
                    path: 'permissions',
                },
                visible: false
            },
            type: '',
        }
    },
    {
        path: 'recomendacoes/:id',
        name: 'Recomendacoes Redirect',
        component: RecomendacoesCliente,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/indicador-resumo'
            },],
            type: 'Cliente',
        },
        beforeEnter(to, from, next) {
            const auth = JSON.parse(localStorage.getItem(Vue.prototype.$keyNav));
            if (from.name === "Relatórios" && to.name === "Recomendacoes Redirect") {
                to.meta.breadcrumb = [
                    {
                        name: "Relatórios",
                        link: `/relatorio-indicadores/${auth.usuario}`,
                    },
                    // {
                    //     name: 'Recomendações'
                    // }
                ];
            } else {
                to.meta.breadcrumb = [{
                    name: 'Indicadores', link: '/indicador-resumo'
                },
                    // {
                    //     name: 'Recomendações'
                    // }
                ];
            }
            next();
        },
    },
    {
        path: 'validate-solicitation/:approve?/:id?',
        name: 'Valida Soliticação',
        component: UserValidateSolicitation,
        meta: {
            breadcrumb: [{
                name: '', link: '/'
            }],
            type: 'Cliente',
        },
    },
];


export default clientRoutes;
