<template>
  <div class="card-content">
    <div
      class="row"
      v-show="data.RegistrosMedico.find((x) => x.Sigla === 'CRM') !== undefined"
    >
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Especialidade Médica</label>
          <DxTagBox
            :items="especialidades"
            :search-enabled="true"
            display-expr="Nome"
            value-expr="Id"
            v-model="data._EspecialidadeMedico"
          >
          </DxTagBox>
        </div>
      </div>
    </div>
    <div class="row">
      <h5>Registros Profissionais</h5>
    </div>
    <div class="row" v-show="data.RegistrosMedico.length === 0">
      <div class="col-md-1">
        <div class="btns-container">
          <a
            class="btn-warning add-remove-fields-btns"
            @click="addRegistroData"
          >
            <i class="fas fa-plus"></i>
          </a>
        </div>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
    </div>
    <div class="row" v-for="(registro, idx) in data.RegistrosMedico" :key="idx">
      <div class="col-md-1">
        <div class="btns-container">
          <a
            class="btn-warning add-remove-fields-btns"
            @click="addRegistroData"
            v-show="idx === data.RegistrosMedico.length - 1"
          >
            <i class="fas fa-plus"></i>
          </a>
          <div
            class="add-remove-fields-btns"
            style="height: 18px; float: left; width: 15.68px"
            v-show="idx !== data.RegistrosMedico.length - 1"
          ></div>
          <a
            class="btn-warning add-remove-fields-btns"
            @click="removeRegistroData(idx)"
          >
            <i class="fas fa-trash"></i>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Conselho*</label>
          <DxSelectBox
            :data-source="conselhos"
            :show-clear-button="true"
            display-expr="Sigla"
            value-expr="Id"
            v-model="registro.Conselho"
            :id="idx"
            @selection-changed="onConselhoChanged"
          >
            <template #item="{ data }">
              <div class="product-name">{{ data.Sigla }} - {{ data.Nome }}</div>
            </template>
          </DxSelectBox>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">País Conselho</label>
          <DxSelectBox
            :data-source="paises"
            :show-clear-button="true"
            display-expr="Sigla"
            value-expr="Id"
            v-model="registro._Pais"
            @item-click="countryClick(idx)"
          >
            <template #item="{ data }">
              {{ data.Sigla }} - {{ data.Nome }}
            </template>
          </DxSelectBox>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">UF Conselho*</label>
          <DxSelectBox
            :data-source="ufs"
            :show-clear-button="true"
            display-expr="Sigla"
            value-expr="Id"
            v-model="registro._UF"
            :disabled="registro._Pais === null"
            @focus-in="ufFocus(idx)"
          >
          </DxSelectBox>
        </div>
      </div>
      <div class="col-md-2" v-if="showHistoricoProfissional">
        <div class="form-group">
          <label class="control-label">Histórico Profissional*</label>
          <DxTextBox v-model="registro.HistoricoProfissional"> </DxTextBox>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">Número Registro*</label>
          <DxTextBox :max-length="200" v-model="registro.NumeroRegistro">
          </DxTextBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";

export default {
  name: "ResgistroProfissional",
  components: {
    DxTagBox,
    DxTextBox,
    DxSelectBox,
  },
  props: {
    data: Object,
    restrictData: {
      type: Boolean,
      default: false,
    },
    showHistoricoProfissional: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      conselhos: null,
      especialidades: null,
      ufs: inst.$root.ufDataSource,
      paises: inst.$root.paisDataSource,
    };
  },
  mounted: function () {
    this.getConselhos();
    this.getEspecialidades();
  },
  methods: {
    getEspecialidades() {
      this.$ConAPI
        .get(`GPS_EspecialidadeMedica?%24orderby=Nome&%24select=Id%2CNome`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => {
          this.especialidades = res.data.value;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getConselhos() {
      this.$ConAPI
        .get(`GPS_Conselho?%24orderby=Sigla&%24select=Id%2CSigla%2CNome`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => {
          this.conselhos = res.data.value;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addRegistroData() {
      this.data.RegistrosMedico.push({
        _UF: null,
        _Pais: null,
        Conselho: null,
        HistoricoProfissional: "",
        NumeroRegistro: "",
      });
    },
    removeRegistroData(idx) {
      this.data.RegistrosMedico.splice(idx, 1);
    },
    onConselhoChanged: function (e) {
      if (e.selectedItem !== null && e.selectedItem !== undefined)
        this.data.RegistrosMedico[e.element.id].Sigla = e.selectedItem.Sigla;
      else this.data.RegistrosMedico[e.element.id].Sigla = "";
    },
    countryClick(i) {
      console.log(i);
      this.data.RegistrosMedico[i]._UF = null;
    },
    ufFocus(i) {
      console.log(i);
      if (this.data.RegistrosMedico[i]._Pais !== null)
        this.$root.updateUfDatasourceBy(this.data.RegistrosMedico[i]._Pais);
    },
  },
};
</script>

<style scoped>
.btns-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 71px;
}

.add-remove-fields-btns {
  margin: 0 5px;
  padding: 2px 8px;
  border-radius: 14px;
  font-size: 11px;
}
</style>