<template>
  <div id="formulario">
    <form v-on:submit.prevent>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                @click="onFormSubmit($event)"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div
          class="card-header card-header-icon"
          data-background-color="orange"
        >
          <i
            class="fa fa-th-large"
            aria-hidden="true"
            style="font-size: 20px"
          ></i>
        </div>
        <div class="card-content">
          <h4 class="card-title">Dados</h4>
          <div class="row">
            <div class="col-md-10">
              <div class="form-group">
                <label class="control-label">Nome*</label>
                <DxTextBox v-model="formData.Nome">
                  <DxValidator>
                    <DxRequiredRule message="Campo Obrigatório" />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="control-label">Situação</label>
                <DxSwitch class="switch" v-model="formData.Ativo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Indicadores</h4>
              <div class="row">
                <div class="col-md-12">
                  <Grid
                    :columns="columns"
                    :action="actionGrid"
                    :height="$root.mobile ? '550' : '500'"
                    :edit-mode="''"
                    :selection="{
                      mode: 'multiple',
                      showCheckBoxesMode: 'always',
                    }"
                    :selected-rows="selectedRows"
                    @selected-rows-ids="selectedRowsIds"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                @click="onFormSubmit($event)"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import Grid from "@/components/Grid.vue";
import { DxSwitch } from "devextreme-vue/switch";
import notify from "devextreme/ui/notify";
// import { DxScrollView } from "devextreme-vue/scroll-view";
import Guid from "devextreme/core/guid";

export default {
  name: "PacoteForm",
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    Grid,
    DxSwitch,
    // DxScrollView,
  },
  props: {
    id: {
      default: null,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "/GPS_Pacote",
      actionGrid: "GPS_Indicador",
      formData: inst.defaultFormData(),
      columns: [
        { name: "Id", notVisible: true },
        { name: "Tipo.Sigla", caption: "Tipo" },
        { name: "Nome", defaultSort: true },
        { name: "Ativo", caption: "Status" },
        { name: "Sigla" },
      ],
      selectedRows: [],
      gridInstance: null,
    };
  },
  mounted: function () {
    this.loadPacote();
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    onFormSubmit(e) {
      if (this.id === null) {
        this.$ConAPI
          .post(this.action, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.message, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Alterado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.value, "error", 4000);
          });
      }
      e.preventDefault();
    },
    defaultFormData: function () {
      return {
        Nome: "",
        Ativo: true,
        Indicadores: [],
      };
    },
    selectedRowsIds: function (e) {
      this.formData.Indicadores = [];
      this.selectedRows = e;

      e.map((value) => {
        this.formData.Indicadores.push(value.Id._value);
      });
    },
    setFormData: function (v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined && v[value] !== undefined) {
          if (value === "_UF") inst.formData[value] = v["UF"];
          else inst.formData[value] = v[value];
        }
      });
    },
    loadPacote: function () {
      if (this.id !== null) {
        this.$ConAPI
          .get(`${this.action}(Id=${this.id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.setFormData(res.data);
            const s = [];

            this.formData.Indicadores.map((value) => {
              s.push({ Id: new Guid(value) });
            });
            this.selectedRows = s;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getGridInstance: function (e) {
      this.gridInstance = e;
    },
  },
};
</script>

<style>
</style>