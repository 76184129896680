var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          columns: _vm.columns,
          action: _vm.action,
          "edit-mode": "batch",
          reload: _vm.reloadGrid,
          "parent-id-field": "_Indicador",
          "filter-value": _vm.filterValue,
          "filter-field": "Indicador.Id",
          "can-sort": false,
          "can-search": false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }