var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c(
        "DxTreeList",
        {
          ref: _vm.treeListRefKey,
          attrs: {
            id: "treelist",
            "data-source": _vm.dataSource,
            "show-borders": true,
            "key-expr": "id",
            "parent-id-expr": "parentId",
            "has-items-expr": "hasItems",
            "root-value": "",
            "column-hiding-enabled": true,
            "word-wrap-enabled": true
          },
          on: {
            "toolbar-preparing": _vm.onToolbarPreparing,
            "row-removing": _vm.onRowRemoving
          }
        },
        [
          _c("DxSearchPanel", { attrs: { visible: true } }),
          _c("DxEditing", {
            attrs: {
              "allow-updating": true,
              "allow-deleting": true,
              "allow-adding": true,
              mode: "form"
            }
          }),
          _c("DxSorting", { attrs: { mode: "none" } }),
          _c("DxRowDragging", {
            attrs: {
              "on-reorder": _vm.onReorder,
              "allow-drop-inside-item": false,
              "allow-reordering": true,
              "show-drag-icons": true
            }
          }),
          _c("DxRemoteOperations", { attrs: { filtering: true } }),
          _c("DxColumn", {
            attrs: {
              "data-field": "nome",
              caption: "Nome",
              "allow-resizing": true
            }
          }),
          _c("DxColumn", {
            attrs: {
              "data-field": "descricao",
              caption: "Descrição",
              "allow-resizing": true
            }
          }),
          _c("DxColumn", {
            attrs: {
              "data-field": "nrMedicoes",
              caption: "Número de medições",
              "allow-resizing": true
            }
          }),
          _c("DxColumn", {
            attrs: {
              "data-field": "status",
              caption: "Status",
              "data-type": "text",
              "customize-text": _vm.customizeStatusText,
              "allow-resizing": true
            }
          }),
          _c(
            "DxColumn",
            { attrs: { type: "buttons", "allow-resizing": true } },
            [
              _c("DxButton", {
                attrs: {
                  name: "duplicate",
                  "on-click": _vm.duplicateObject,
                  visible: _vm.duplicateIndicadorVisible,
                  hint: "Copiar Indicador",
                  icon: "copy"
                }
              }),
              _c("DxButton", {
                attrs: {
                  name: "add",
                  "on-click": _vm.addObject,
                  visible: _vm.addIndicadorVisible,
                  hint: "Adicionar Indicador"
                }
              }),
              _c("DxButton", {
                attrs: { name: "edit", "on-click": _vm.editObject }
              }),
              _c("DxButton", { attrs: { name: "delete" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openTypeForm,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "80vh",
            height: "70vh",
            title: _vm.typePopupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openTypeForm = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("TipoIndicador", {
                attrs: { id: _vm.typeId, "reload-data": _vm.typeReload },
                on: { close: _vm.fecharPopupTipo }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }