<template>
  <div class="card">
    <div id="collapseContato" class="card-content">
      <h4 class="card-title">Contato</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Celular</label>
            <DxTextBox
              v-model="data.TelefoneCelular"
              :use-masked-value="false"
              :disabled="restrictData"
            >
              <!-- <DxValidator @validated="onValidated">
                <DxPatternRule
                  :pattern="phonePattern"
                  message="Celular no formato inválido"
                />
              </DxValidator> -->
            </DxTextBox>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Telefone Residencial</label>
            <DxTextBox
              v-model="data.TelefoneResidencial"
              :use-masked-value="false"
              :disabled="restrictData"
            >
              <!-- <DxValidator @validated="onValidated">
                <DxPatternRule
                  :pattern="phonePattern"
                  message="Telefone no formato inválido"
                />
              </DxValidator> -->
            </DxTextBox>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Telefone Trabalho</label>
            <DxTextBox
              v-model="data.TelefoneTrabalho"
              :use-masked-value="false"
              :disabled="restrictData"
            >
              <!-- <DxValidator @validated="onValidated">
                <DxPatternRule
                  :pattern="phonePattern"
                  message="Telefone no formato inválido"
                />
              </DxValidator> -->
            </DxTextBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
// import { DxValidator, DxPatternRule } from "devextreme-vue/validator";

// mask="(00)00000-0000"
// mask-invalid-message="Telefone Trabalho no formato inválido"
// mask-invalid-message="Telefone Residencial no formato inválido"
// mask-invalid-message="Celular no formato inválido"

export default {
  name: "DadosContato",
  components: {
    DxTextBox,
    // DxValidator,
    // DxPatternRule,
  },
  props: {
    data: Object,
    restrictData: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      phonePattern: /^(\([0-9]{2}\)[0-9]{5}-[0-9]{4})|(\d{11})$/,
      phonePattern2: /^(\([0-9]{2}\)[0-9]{4}-[0-9]{4})|(\d{10})$/,
    };
  },
  methods: {
    onValidated() {
      this.$emit("validation", true);
    },
  },
};
</script>

<style>
</style>