var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "logo hidden-xs" }, [
      _c(
        "div",
        { staticStyle: { cursor: "pointer" }, on: { click: _vm.goToHome } },
        [
          _c("img", {
            staticStyle: { width: "80%" },
            attrs: { src: require("../../assets/img/logo.png") }
          })
        ]
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo logo-mini" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("../../assets/img/logo_mini.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }