var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "ul",
              {
                staticClass: "nav nav-pills nav-pills-warning nav-pills-icons",
                staticStyle: { width: "100%", margin: "0 auto" },
                attrs: { role: "tablist" }
              },
              _vm._l(_vm.tiposIndicadores, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "nav-item",
                    class: { active: _vm.currentActive === item.sigla }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          "data-toggle": "tab",
                          href: "#" + item.sigla + "tab",
                          role: "tablist"
                        },
                        on: {
                          click: function($event) {
                            _vm.loading = true
                            _vm.setCurrent(item.sigla)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa logo-size",
                          class: item.logo,
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(" " + _vm._s(item.sigla) + " ")
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12" }, [
            _c("div", { staticClass: "page-categories" }, [
              _c("div", { staticClass: "tab-content tab-subcategories" }, [
                _c(
                  "div",
                  { staticClass: "tab-pane active", attrs: { id: "mentetab" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card",
                        staticStyle: { padding: "0 15px" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-content" },
                          _vm._l(_vm.dadosComponent, function(indicador, idx1) {
                            return _c(
                              "div",
                              { key: idx1, staticClass: "row" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-bottom": "1px solid gray",
                                      "margin-bottom": "7px"
                                    }
                                  },
                                  [
                                    _c(
                                      "h4",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(indicador.indicador) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pull-right",
                                        staticStyle: {
                                          cursor: "pointer",
                                          display: "inline",
                                          color: "purple"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push({
                                              path:
                                                "indicador-admin/" +
                                                indicador.id
                                            })
                                          }
                                        }
                                      },
                                      [_vm._v("Detalhes")]
                                    )
                                  ]
                                ),
                                _vm._l(indicador.dados, function(item, idx2) {
                                  return _c(
                                    "div",
                                    {
                                      key: idx2,
                                      staticClass: "row",
                                      staticStyle: { "margin-bottom": "10px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-lg-3",
                                          staticStyle: { "line-height": "1.5" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                indicador.indicador !==
                                                  item.nome
                                                  ? item.nome
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-lg-9" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "progress progress-line-danger",
                                            staticStyle: {
                                              height: "23px",
                                              "font-weight": "500"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "progress-bar",
                                                staticStyle: { padding: "1px" },
                                                style: {
                                                  width:
                                                    (item.bom / item.total) *
                                                      100 +
                                                    "%",
                                                  display:
                                                    Math.round(
                                                      (item.bom / item.total) *
                                                        100
                                                    ) > 0
                                                      ? "block"
                                                      : "none",
                                                  "background-color": _vm.coresRisco.filter(
                                                    function(x) {
                                                      return x.grau === "bom"
                                                    }
                                                  )[0].cor
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        (item.bom /
                                                          item.total) *
                                                          100
                                                      ) +
                                                        "%(" +
                                                        item.bom +
                                                        ")"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "progress-bar",
                                                staticStyle: { padding: "1px" },
                                                style: {
                                                  width:
                                                    (item.medio / item.total) *
                                                      100 +
                                                    "%",
                                                  display:
                                                    Math.round(
                                                      (item.medio /
                                                        item.total) *
                                                        100
                                                    ) > 0
                                                      ? "block"
                                                      : "none",
                                                  "background-color": _vm.coresRisco.filter(
                                                    function(x) {
                                                      return x.grau === "medio"
                                                    }
                                                  )[0].cor
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        (item.medio /
                                                          item.total) *
                                                          100
                                                      ) +
                                                        "%(" +
                                                        item.medio +
                                                        ")"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "progress-bar",
                                                staticStyle: { padding: "1px" },
                                                style: {
                                                  width:
                                                    (item.ruim / item.total) *
                                                      100 +
                                                    "%",
                                                  display:
                                                    Math.round(
                                                      (item.ruim / item.total) *
                                                        100
                                                    ) > 0
                                                      ? "block"
                                                      : "none",
                                                  "background-color": _vm.coresRisco.filter(
                                                    function(x) {
                                                      return x.grau === "ruim"
                                                    }
                                                  )[0].cor
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        (item.ruim /
                                                          item.total) *
                                                          100
                                                      ) +
                                                        "%(" +
                                                        item.ruim +
                                                        ")"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }