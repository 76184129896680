<template>
  <div>
    <div class="card">
      <div class="card-header card-detalhes">
        <div class="pull-left">
          <h4>
            {{ person }} <br v-if="$root.mobile" />
            <span v-if="!$root.mobile">-</span> {{ indicador }}
          </h4>
          <h5>{{ dataMedicao }}</h5>
        </div>
        <div class="pull-right">
          <img
            src="../assets/img/logo.png"
            :class="{ 'title-img': !$root.mobile, 'title-img-m': $root.mobile }"
          />
        </div>
      </div>
      <div class="card-content">
        <div class="row" v-if="!isBiomedic">
          <DxChart
            id="chart"
            :data-source="data"
            :customize-point="customizePoint"
          >
            <DxCommonSeriesSettings
              :ignore-empty-points="true"
              argument-field="indice"
              value-field="valor"
              type="bar"
            />
            <DxSize v-if="!$root.mobile" :height="300" :width="700" />
            <DxSeriesTemplate name-field="nome" />
            <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
              <DxLabel>
                <DxFormat type="decimal" />
              </DxLabel>
            </DxArgumentAxis>
            <DxValueAxis :max="100" />
            <DxLegend :visible="false" />
            <DxTooltip :enabled="true" />
          </DxChart>
        </div>
        <div class="row" v-if="!isBiomedic">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Módulo</th>
                <th scope="col">Pontos</th>
                <th scope="col">Recomendação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in data" :key="i">
                <td scope="col">{{ i + 1 }} - {{ item.nome }}</td>
                <td scope="col" :style="{ color: item.cor }">
                  <!-- <span class="dot"> </span> -->
                  {{ item.valor }}
                </td>
                <td scope="col">
                  {{ item.recomendacao }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="isBiomedic">
          <div class="table-medidor-data">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" v-for="(item, idx) in data" :key="idx">
                    {{
                      item.jejum
                        ? `${item.nome.replace("@", "")} em jejum`
                        : item.nome.replace("@", "")
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <!-- <td
                    scope="col"
                    v-for="(item, idx) in data.filter((x) => x.cor !== null)"
                    :key="idx"
                  >
                    <i
                      class="fas fa-compass"
                      :style="{
                        color: item.cor,
                        'font-size': '20px',
                      }"
                      aria-hidden="true"
                    ></i>
                  </td> -->
                  <td scope="col" v-for="(item, i) in data" :key="i">
                    {{ item.valor }}
                  </td>
                </tr>
                <tr v-for="(item, id) in data" :key="id">
                  <td
                    :colspan="data.length + 1"
                    v-if="item.recomendacao !== null"
                  >
                    <table class="table">
                      <tbody>
                        <tr>
                          <td scope="col" colspan="3">
                            <div
                              class="pull-left"
                              v-show="item.cor === '#4caf50'"
                            >
                              <i
                                :id="Math.random()"
                                :class="'fas fa-compass ' + Math.random()"
                                :style="{
                                  color: '#4caf50',
                                  'font-size': '14px',
                                }"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              class="pull-left"
                              v-show="item.cor === '#e0c619'"
                            >
                              <i
                                :id="Math.random()"
                                :class="'fas fa-compass ' + Math.random()"
                                :style="{
                                  color: '#e0c619',
                                  'font-size': '14px',
                                }"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              class="pull-left"
                              v-if="item.cor === '#f44336'"
                            >
                              <i
                                :id="Math.random()"
                                :class="'fas fa-compass ' + Math.random()"
                                :style="{
                                  color: '#f44336',
                                  'font-size': '14px',
                                }"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <span style="margin-left: 7px">
                              {{ item.nome }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col" colspan="3">
                            {{ item.recomendacao }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          class="btn btn-secondary nextBtn pull-right"
          @click="$emit('close', true)"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxSeriesTemplate,
  DxSize,
  DxValueAxis,
} from "devextreme-vue/chart";

export default {
  name: "DadosMedicao",
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxSeriesTemplate,
    DxSize,
    DxValueAxis,
  },
  props: {
    data: {
      default: function () {
        return [];
      },
    },
    dataMedicao: {
      default: "",
    },
    indicador: {
      default: "",
    },
    person: {
      default: "",
    },
    isBiomedic: {
      default: null,
    },
  },
  // watch: {
  //   data: function (v) {
  //     this.color = v.filter((x) => x.cor !== null)[0].cor;
  //     this.recomendacao = v.filter(
  //       (x) => x.recomendacao !== null
  //     )[0].recomendacao;
  //   },
  // },
  data: function () {
    return {
      color: "",
      recomendacao: "",
    };
  },
  methods: {
    customizePoint: function (e) {
      return { color: e.data.cor };
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 95%;
}

.dot {
  width: 20px !important;
  height: 20px !important;
  display: table-cell !important;
}

.title-img {
  width: 200px;
  height: 73px;
}

.title-img-m {
  width: 175px;
  height: 73px;
}

.table-medidor-data {
  padding: 0 5%;
}

.card-detalhes {
  float: unset !important;
}
</style>