var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "formulario" } }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            return _vm.onFormSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-8" }),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c(
                    "DxButton",
                    {
                      staticClass: "btn btn-warning pull-right",
                      attrs: {
                        id: "save",
                        "use-submit-behavior": true,
                        text: "Salvar"
                      }
                    },
                    [_vm._v("Salvar")]
                  ),
                  _c(
                    "DxButton",
                    {
                      staticClass: "btn btn-warning pull-right",
                      attrs: { id: "back", text: "Voltar" },
                      on: { click: _vm.back }
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c("div", { staticClass: "clearfix" })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-content" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v("Dados")]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Razão Social*")
                    ]),
                    _c(
                      "DxTextBox",
                      {
                        model: {
                          value: _vm.formData.RazaoSocial,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "RazaoSocial", $$v)
                          },
                          expression: "formData.RazaoSocial"
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxRequiredRule", {
                              attrs: { message: "Campo Obrigatório" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Nome Fantasia (após inserir CNPJ)")
                    ]),
                    _c("DxTextBox", {
                      attrs: { disabled: _vm.formData.CNPJ.length <= 11 },
                      model: {
                        value: _vm.formData.NomeFantasia,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "NomeFantasia", $$v)
                        },
                        expression: "formData.NomeFantasia"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("CPF ou CNPJ* (somente os números)")
                    ]),
                    _c(
                      "DxTextBox",
                      {
                        attrs: { "max-length": 14 },
                        on: { "value-changed": _vm.hasNomeFantasia },
                        model: {
                          value: _vm.formData.CNPJ,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "CNPJ", $$v)
                          },
                          expression: "formData.CNPJ"
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxRequiredRule", {
                              attrs: { message: "Campo Obrigatório" }
                            }),
                            _c("DxPatternRule", {
                              attrs: {
                                pattern: _vm.cnpjPattern,
                                message: "CPF ou CNPJ no formato inválido"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Telefone (somente números)")
                    ]),
                    _c(
                      "DxTextBox",
                      {
                        model: {
                          value: _vm.formData.Telefone,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "Telefone", $$v)
                          },
                          expression: "formData.Telefone"
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxPatternRule", {
                              attrs: {
                                pattern: _vm.phonePattern,
                                message: "Telefone no formato inválido"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-1" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Ativo")
                    ]),
                    _c("DxSwitch", {
                      staticClass: "switch",
                      model: {
                        value: _vm.formData.Ativo,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "Ativo", $$v)
                        },
                        expression: "formData.Ativo"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Token de acesso de aplicações de terceiros")
                    ]),
                    _c("DxTextBox", {
                      attrs: { "read-only": true },
                      model: {
                        value: _vm.formData.TokenAcessoAplicacaoTerceira,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "TokenAcessoAplicacaoTerceira",
                            $$v
                          )
                        },
                        expression: "formData.TokenAcessoAplicacaoTerceira"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { id: "regenToken", text: "Regerar Token" },
                        on: { click: _vm.generateToken }
                      },
                      [_vm._v("Regerar Token")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("E-mail")
                    ]),
                    _c(
                      "DxTextBox",
                      {
                        model: {
                          value: _vm.formData.Email,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "Email", $$v)
                          },
                          expression: "formData.Email"
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxEmailRule", {
                              attrs: { message: "E-mail no formato inválido" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Site")
                    ]),
                    _c("DxTextBox", {
                      model: {
                        value: _vm.formData.Site,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "Site", $$v)
                        },
                        expression: "formData.Site"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Conselho")
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        "data-source": _vm.conselhosBoxData,
                        "display-expr": "Nome",
                        "value-expr": "Id",
                        searchEnabled: true
                      },
                      model: {
                        value: _vm.formData._Conselho,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "_Conselho", $$v)
                        },
                        expression: "formData._Conselho"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Uf Conselho")
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        "data-source": _vm.ufsBoxData,
                        "display-expr": "Sigla",
                        "value-expr": "Id",
                        searchEnabled: true
                      },
                      model: {
                        value: _vm.formData._UFConselho,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "_UFConselho", $$v)
                        },
                        expression: "formData._UFConselho"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Número do Registro")
                    ]),
                    _c("DxTextBox", {
                      model: {
                        value: _vm.formData.NumeroRegistro,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "NumeroRegistro", $$v)
                        },
                        expression: "formData.NumeroRegistro"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Tipo Faturamento")
                    ]),
                    _c(
                      "DxSelectBox",
                      {
                        attrs: {
                          "data-source": _vm.tiposFaturamento,
                          "display-expr": "nome",
                          "value-expr": "id"
                        },
                        on: {
                          "selection-changed": _vm.onTipoFaturamentoChanged
                        },
                        model: {
                          value: _vm.formData._TipoFaturamento,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "_TipoFaturamento", $$v)
                          },
                          expression: "formData._TipoFaturamento"
                        }
                      },
                      [
                        _c(
                          "DxValidator",
                          [
                            _c("DxRequiredRule", {
                              attrs: { message: "Campo Obrigatório" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Licensas Contratadas")
                    ]),
                    _c("DxNumberBox", {
                      attrs: { "read-only": !_vm.hasLicencas },
                      model: {
                        value: _vm.formData.LicencasContratadas,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "LicencasContratadas", $$v)
                        },
                        expression: "formData.LicencasContratadas"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Envio Automatico E-mail")
                    ]),
                    _c("DxSwitch", {
                      staticClass: "switch",
                      model: {
                        value: _vm.formData.EnvioAutomaticoEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "EnvioAutomaticoEmail", $$v)
                        },
                        expression: "formData.EnvioAutomaticoEmail"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Matriz")
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        "data-source": _vm.matrizes,
                        "display-expr": "RazaoSocial",
                        "value-expr": "Id",
                        searchEnabled: true,
                        "show-clear-button": true
                      },
                      model: {
                        value: _vm.formData._Matriz,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "_Matriz", $$v)
                        },
                        expression: "formData._Matriz"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("endereco", { attrs: { data: _vm.formData } }),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "card card-profile" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("Logo da empresa")
              ]),
              _c(
                "div",
                {
                  staticClass: "companie-image-box",
                  class: {
                    "companie-image-bkg-grey": !_vm.showPhoto,
                    "companie-image-bkg-white": _vm.showPhoto
                  },
                  attrs: { id: "imageBox" }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPhoto,
                        expression: "showPhoto"
                      }
                    ],
                    staticClass: "img",
                    staticStyle: { height: "60px" },
                    attrs: { id: "profilePhoto", src: _vm.photo }
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showPhoto,
                        expression: "!showPhoto"
                      }
                    ],
                    staticClass: "fas fa-image default-image-icon",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      "Extensões aceitas " +
                        _vm._s(_vm.imagesExtensions.join(","))
                    )
                  ]),
                  _c("DxFileUploader", {
                    ref: _vm.photoFileUploaderRefKey,
                    attrs: {
                      multiple: false,
                      "label-text": "",
                      accept: "image/*",
                      "upload-mode": "instantly",
                      "select-button-text": "Selecione um arquivo",
                      "invalid-file-extension-message":
                        "Extensão de arquivo não aceita",
                      "upload-url": _vm.urlUploadImport,
                      "allowed-file-extensions": _vm.imagesExtensions,
                      "upload-headers": _vm.uploadImportHeader
                    },
                    on: {
                      uploaded: _vm.onUploaded,
                      "value-changed": _vm.photoChanged
                    }
                  }),
                  _vm.showPhoto
                    ? _c(
                        "DxButton",
                        {
                          staticClass:
                            "btn btn-secondary pull-right btn-back separated",
                          attrs: {
                            id: "remove-photo-file",
                            text: "Remover arquivo"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeImage("Photos")
                            }
                          }
                        },
                        [_vm._v("Remover arquivo")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "card card-profile" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("Icone da logo da empresa")
              ]),
              _c(
                "div",
                {
                  staticClass: "companie-image-box img-icon-box",
                  class: {
                    "companie-image-bkg-grey": !_vm.showIcon,
                    "companie-image-bkg-white": _vm.showIcon
                  },
                  attrs: { id: "imageBoxIcon" }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showIcon,
                        expression: "showIcon"
                      }
                    ],
                    staticClass: "img",
                    staticStyle: { height: "60px" },
                    attrs: { id: "compPhotoIcon", src: _vm.icon }
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showIcon,
                        expression: "!showIcon"
                      }
                    ],
                    staticClass: "fas fa-image default-image-icon",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      "Extensões aceitas " +
                        _vm._s(_vm.imagesExtensions.join(","))
                    )
                  ]),
                  _c("DxFileUploader", {
                    ref: _vm.iconFileUploaderRefKey,
                    attrs: {
                      multiple: false,
                      "label-text": "",
                      accept: "image/*",
                      "upload-mode": "instantly",
                      "select-button-text": "Selecione um arquivo",
                      "invalid-file-extension-message":
                        "Extensão de arquivo não aceita",
                      "upload-url": _vm.urlUploadImport,
                      "allowed-file-extensions": _vm.imagesExtensions,
                      "upload-headers": _vm.uploadImportHeader
                    },
                    on: {
                      uploaded: _vm.onUploaded,
                      "value-changed": _vm.iconChanged
                    }
                  }),
                  _vm.showIcon
                    ? _c(
                        "DxButton",
                        {
                          staticClass:
                            "btn btn-secondary pull-right btn-back separated",
                          attrs: {
                            id: "remove-icon-file",
                            text: "Remover arquivo"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeImage("Icons")
                            }
                          }
                        },
                        [_vm._v("Remover arquivo")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-8" }),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c(
                    "DxButton",
                    {
                      staticClass: "btn btn-warning pull-right",
                      attrs: {
                        id: "save",
                        "use-submit-behavior": true,
                        text: "Salvar"
                      }
                    },
                    [_vm._v("Salvar")]
                  ),
                  _c(
                    "DxButton",
                    {
                      staticClass: "btn btn-warning pull-right",
                      attrs: { id: "back", text: "Voltar" },
                      on: { click: _vm.back }
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c("div", { staticClass: "clearfix" })
                ],
                1
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card-header card-header-icon",
        attrs: { "data-background-color": "orange" }
      },
      [
        _c("i", {
          staticClass: "fa fa-building",
          staticStyle: { "font-size": "20px" },
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }