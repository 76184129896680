<template>
  <div>
    <loading :loading="$root.loading" />
  </div>
</template>

<script>
import Login from "./login.vue";
import Loading from "@/components/loading.vue";

export default {
  name: "IndicadoresExterno",
  components: {
    Loading,
  },
  methods: {
    loginExterno(acesso, id) {
      Login.methods.LoginExterno(acesso, id);
    },
  },
};
</script>

<style>
</style>