var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Nome*")]),
              _c(
                "DxTextBox",
                {
                  model: {
                    value: _vm.formData.Nome,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "Nome", $$v)
                    },
                    expression: "formData.Nome"
                  }
                },
                [
                  _c(
                    "DxValidator",
                    { ref: "validator" },
                    [
                      _c("DxRequiredRule", {
                        attrs: { message: "Campo Obrigatório" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Ordem")]),
              _c("DxNumberBox", {
                attrs: { "read-only": true },
                model: {
                  value: _vm.formData.Ordem,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "Ordem", $$v)
                  },
                  expression: "formData.Ordem"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Status")]),
              _c("DxSwitch", {
                model: {
                  value: _vm.formData.Ativo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "Ativo", $$v)
                  },
                  expression: "formData.Ativo"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Mostra Gráfico")
              ]),
              _c("DxSwitch", {
                model: {
                  value: _vm.formData.MostrarGrafico,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "MostrarGrafico", $$v)
                  },
                  expression: "formData.MostrarGrafico"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Link")]),
              _c("DxTextBox", {
                attrs: { mode: "url" },
                model: {
                  value: _vm.formData.Link,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "Link", $$v)
                  },
                  expression: "formData.Link"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Peso")]),
              _c("DxNumberBox", {
                attrs: { min: 0, max: 99999999 },
                model: {
                  value: _vm.formData.Peso,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "Peso", $$v)
                  },
                  expression: "formData.Peso"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Casas Decimais")
              ]),
              _c("DxNumberBox", {
                attrs: { min: 0, max: 9999 },
                model: {
                  value: _vm.formData.CasasDecimais,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "CasasDecimais", $$v)
                  },
                  expression: "formData.CasasDecimais"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Valor Total Grupo")
              ]),
              _c("DxNumberBox", {
                attrs: { min: 0, max: 99999999 },
                model: {
                  value: _vm.formData.ValorTotalGrupo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "ValorTotalGrupo", $$v)
                  },
                  expression: "formData.ValorTotalGrupo"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [_vm._v("Help")]),
              _c("DxTextArea", {
                model: {
                  value: _vm.formData.help,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "help", $$v)
                  },
                  expression: "formData.help"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card card-profile" }, [
            _c(
              "div",
              {
                staticClass: "grupo-image-box",
                class: {
                  "companie-image-bkg-grey": !_vm.showPhoto,
                  "companie-image-bkg-white": _vm.showPhoto
                },
                attrs: { id: "imageBox" }
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPhoto,
                      expression: "showPhoto"
                    }
                  ],
                  staticClass: "img",
                  attrs: { id: "profilePhoto", src: _vm.photo }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showPhoto,
                        expression: "!showPhoto"
                      }
                    ]
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-image default-grupo-image-icon",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "card-content" },
              [
                _c("DxFileUploader", {
                  ref: "photoFileUploader",
                  attrs: {
                    multiple: false,
                    "label-text": "",
                    accept: "image/*",
                    "upload-mode": "instantly",
                    "select-button-text": "Selecione um arquivo",
                    "invalid-file-extension-message":
                      "Extensão de arquivo não aceita",
                    "upload-url": _vm.urlUploadImport,
                    "allowed-file-extensions": [".jpg", ".png"],
                    "upload-headers": _vm.uploadImportHeader
                  },
                  on: {
                    uploaded: _vm.onUploaded,
                    "value-changed": _vm.photoChanged
                  }
                }),
                _vm.showPhoto
                  ? _c(
                      "DxButton",
                      {
                        staticClass:
                          "btn btn-secondary pull-right btn-back separated",
                        attrs: {
                          id: "remove-icon-file",
                          text: "Remover arquivo"
                        },
                        on: {
                          click: function($event) {
                            return _vm.removeImage("Photos")
                          }
                        }
                      },
                      [_vm._v("Remover arquivo")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "DxButton",
              {
                staticClass: "btn btn-warning pull-right",
                attrs: { id: "save", text: "Salvar" },
                on: { click: _vm.Save }
              },
              [_vm._v("Salvar")]
            ),
            _c(
              "DxButton",
              {
                staticClass: "btn btn-warning pull-right",
                attrs: { id: "back", text: "Cancel" },
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancelar")]
            ),
            _c("div", { staticClass: "clearfix" })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }