<template>
  <div>
    <form>
      <DxValidationGroup :ref="'validationGroup'">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Nome*</label>
              <DxTextBox v-model="formData.Nome">
                <DxValidator name="ValidateNome" :maxLength="200">
                  <DxRequiredRule message="Campo Obrigatório" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Ativo</label>
              <br />
              <DxSwitch v-model="formData.Ativo" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Risco</label>
              <DxSelectBox
                :items="grauItems"
                display-expr="text"
                value-expr="value"
                v-model="formData.Grau"
                item-template="item"
                @value-changed="onGrauChanged"
              >
                <template #item="{ data }">
                  <div>
                    <img
                      v-if="data.color !== '#FFFFFF'"
                      :src="`${
                        $root.Controle.UrlAPI
                      }/Upload/GetPhoto?type=icon&image=${data.color.substring(
                        1
                      )}.png`"
                      height="15"
                      width="15"
                    />
                    <div style="float: right">{{ data.text }}</div>
                  </div>
                </template>
              </DxSelectBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Cor Exibição</label>
              <DxColorBox
                v-model="formData.CorExibicao"
                apply-value-mode="instantly"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Jejum</label>
              <br />
              <DxSwitch v-model="formData.Jejum" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Sexo</label>
              <DxSelectBox
                :items="sexoItems"
                display-expr="text"
                value-expr="value"
                v-model="formData.Sexo"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Valor Inicial</label>
              <DxNumberBox v-model="formData.ValorInicial" :max="99999999" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Valor Final</label>
              <DxNumberBox
                v-model="formData.ValorFinal"
                :max="99999999"
                :min="this.formData.ValorInicial"
              >
              </DxNumberBox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Idade Inicial</label>
              <DxNumberBox v-model="formData.IdadeInicial" :max="9999" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Idade Final</label>
              <DxNumberBox
                v-model="formData.IdadeFinal"
                :max="9999"
                :min="formData.IdadeInicial"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Unidade</label>
              <DxTextBox v-model="formData.Unidade" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Link</label>
              <DxTextBox
                v-model="formData.RecomendacaoLink"
                :mode="'url'"
                :maxLength="2400"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Recomendação</label>
              <DxTextBox v-model="formData.Recomendacao" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <DxButton
              id="save"
              text="Salvar"
              @click="save"
              class="btn btn-warning pull-right"
              >Salvar</DxButton
            >
            <DxButton
              id="back"
              text="Cancel"
              class="btn btn-warning pull-right"
              @click="cancel"
              >Cancelar</DxButton
            >
            <div class="clearfix"></div>
          </div>
        </div>
      </DxValidationGroup>
    </form>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import notify from "devextreme/ui/notify";
import DxColorBox from "devextreme-vue/color-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  name: "FaixasForm",
  components: {
    DxTextBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxSwitch,
    DxColorBox,
    DxSelectBox,
    DxValidationGroup,
  },
  props: {
    indicador: {
      default: null,
      required: true,
    },
    medidor: {
      default: null,
    },
    grupo: {
      default: null,
    },
    id: {
      default: null,
    },
    reloadData: {
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "Gps_IndicadorFaixaReferencia",
      formData: inst.defaultFormData(),
      showPhoto: false,
      sexoItems: [
        { text: "Todos", value: "X" },
        { text: "Masculino", value: "M" },
        { text: "Feminino", value: "F" },
      ],
      grauItems: [
        { text: "Nenhum", value: 0, color: "#FFFFFF" },
        { text: "Alto", value: 3, color: "#f44336" },
        { text: "Médio", value: 2, color: "#e0c619" },
        { text: "Baixo", value: 1, color: "#4caf50" },
      ],
    };
  },
  computed: {
    nameValidator: function () {
      return this.$refs["nameValidator"].instance;
    },
    valorValidator: function () {
      return this.$refs["valorValidator"].instance;
    },
    validationGroup: function () {
      return this.$refs["validationGroup"].instance;
    },
  },
  watch: {
    id: function (v) {
      this.loadData(v);
    },
    reloadData: function () {
      this.loadData(this.id);
    },
  },
  methods: {
    defaultFormData() {
      return {
        _Indicador: this.indicador,
        _IndicadorMedidor: this.medidor,
        _IndicadorGrupo: this.grupo,
        Nome: "",
        Jejum: false,
        Ativo: true,
        Grau: 0,
        CorExibicao: "",
        Sexo: "X",
        ValorInicial: 0,
        ValorFinal: 0,
        IdadeInicial: null,
        IdadeFinal: null,
        Recomendacao: "",
        RecomendacaoLink: "",
        Unidade: "",
      };
    },
    cancel() {
      this.formData = this.defaultFormData();
      this.$emit("close", true);
    },
    setFormData(v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    loadData(id) {
      if (id !== null) {
        this.$ConAPI
          .get(`/${this.action}(Id=${id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.setFormData(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.formData = this.defaultFormData();
      }
    },
    save() {
      const vg = this.validationGroup.validate();
      if (vg.isValid) {
        if (this.id === null) {
          this.$ConAPI
            .post(`/${this.action}`, this.formData, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Adicionado com sucesso", "success", 4000);
              this.formData = this.defaultFormData();
              this.$emit("close", true);
            })
            .catch(function (error) {
              console.log(error.code);
              // notify(error.response.value, "error", 4000);
            });
        } else {
          this.$ConAPI
            .patch(`/${this.action}(Id=${this.id})`, this.formData, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Alterado com sucesso", "success", 4000);
              this.formData = this.defaultFormData();
              this.$emit("close", true);
            })
            .catch(function (error) {
              notify(error.response.value, "error", 4000);
            });
        }
      }
    },
    onGrauChanged(e) {
      this.formData.CorExibicao = this.grauItems.filter(
        (v) => v.value == e.value
      )[0].color;
    },
  },
};
</script>

<style>
.card-header {
  text-align: left;
}
.default-grupo-image-icon {
  font-size: 50px;
  color: white;
  margin: 50% 0;
}
.grupo-image-box {
  height: 323px;
  width: 323px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}
.hide {
  display: none;
}
</style>