var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "wrapper wrapper-full-page login row" }, [
          _c("div", { staticClass: "col-md-6 login-forms" }, [
            _c("div", { staticClass: "form-login" }, [
              _vm._m(0),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "email" } }, [_vm._v("E-mail")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.login,
                      expression: "login"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "email", id: "email" },
                  domProps: { value: _vm.login },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.login = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "senha" } }, [_vm._v("Senha")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.senha,
                      expression: "senha"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", id: "senha", name: "loginPass" },
                  domProps: { value: _vm.senha },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.Logar($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.senha = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-block btn-login btn-full btn-wd btn-lg",
                    on: { click: _vm.Logar }
                  },
                  [_vm._v(" Entrar ")]
                ),
                _c(
                  "a",
                  { attrs: { href: "#" }, on: { click: _vm.EsqueciSenha } },
                  [_vm._v("Esqueci minha senha")]
                ),
                !_vm.$root.isProduction
                  ? _c("div", { staticClass: "google" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-login btn-google-login",
                          on: { click: _vm.LoginGoogle }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/img/google.svg"),
                              alt: "Google"
                            }
                          }),
                          _vm._v(" Login com Google ")
                        ]
                      )
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "text-center" }, [_c("pwa-install")], 1)
              ])
            ])
          ]),
          _vm._m(1)
        ])
      ]),
      this.$root.$data.Controle.prompt
        ? _c("div", { staticClass: "update-dialog" }, [
            _c("div", { staticClass: "update-dialog__content" }, [
              _vm._v(" Uma nova versão foi encontrada. Recarregar para abrir? ")
            ]),
            _c("div", { staticClass: "update-dialog__actions" }, [
              _c(
                "button",
                {
                  staticClass:
                    "update-dialog__button update-dialog__button--confirm",
                  on: { click: this.$root.update }
                },
                [_vm._v(" Atualizar ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "update-dialog__button update-dialog__button--cancel",
                  on: {
                    click: function($event) {
                      this.$root.$data.Controle.prompt = false
                    }
                  }
                },
                [_vm._v(" Cancelar ")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "login-header text-center",
        attrs: { "data-background-color": "orange" }
      },
      [
        _c("img", {
          staticStyle: { width: "80%" },
          attrs: { src: require("../assets/img/logo_white.png") }
        }),
        _c("h3", [_vm._v("Como está a minha saúde?")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-6 d-none d-md-block login-banner",
        attrs: { id: "login-bg" }
      },
      [
        _c("h3", { staticClass: "txt-login" }, [
          _vm._v(" A sua bússola "),
          _vm._v(" da saúde. ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }