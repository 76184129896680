<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Nova Senha*</label>
          <input
            type="password"
            id="password"
            ref="passwordRef"
            class="form-control"
            v-model="pass"
            :class="{ invalid: errorSenha, valid: validSenha }"
            autocomplete="new-password"
            @focus="onFocus"
            @blur="onBlur"
            @keyup="keymonitor"
          />
          <div
            id="password-strength"
            class="strength"
            :class="{ invisible: !inputFocused }"
          >
            <span ref="meterPass"></span>
          </div>
          <span class="msg error" :class="{ invisible: !errorSenha }">{{
            errorSenhaMsg
          }}</span>
          <span
            ref="msgStrength"
            :class="{ invisible: !showForca }"
            class="msg"
            >{{ forcaSenha }}</span
          >
          <!-- <span class="msg charlenght">{{ pass.length }}</span> -->
          <div class="validation">
            <span
              class="msg"
              :class="{
                error: !hasMoreThenEigthChars,
                'valid-rule': hasMoreThenEigthChars && pass.length > 0,
              }"
              >Caracteres: {{ numberOfChars }}</span
            >
            <span
              class="msg"
              :class="{
                error: !hasUpperCase,
                'valid-rule': hasUpperCase && pass.length > 0,
              }"
              >Maiúsculas</span
            >
            <span
              class="msg"
              :class="{
                error: !hasLowerCase,
                'valid-rule': hasLowerCase && pass.length > 0,
              }"
              >Minúsculas</span
            >
            <span
              class="msg"
              :class="{
                error: !hasNumbers,
                'valid-rule': hasNumbers && pass.length > 0,
              }"
              >Números</span
            >
            <span
              class="msg"
              :class="{
                error: !hasSymbols,
                'valid-rule': hasSymbols && pass.length > 0,
              }"
              >Caracteres Especiais</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Confirmar Senha*</label>
          <input
            type="password"
            v-model="confirmation"
            id="confirm"
            class="form-control"
            :class="{ invalid: errorConfirm, valid: validConfirm }"
            ref="confirmRef"
            autocomplete="new-password"
            @keyup="compareWithPass"
            @focus="compareWithPass"
            @blur="compareWithPass"
          />
          <span class="msg error" :class="{ invisible: !errorConfirm }">{{
            errorConfirmMsg
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const zxcvbn = require("zxcvbn");

export default {
  name: "SenhaComponent",
  props: {
    newPass: Boolean,
    validations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: function () {
    return {
      pass: "",
      meterVal: 0,
      forcaSenha: "",
      showForca: true,
      confirmation: "",
      inputFocused: false,
      errorSenha: false,
      validSenha: false,
      errorSenhaMsg: "",
      errorConfirm: false,
      validConfirm: false,
      errorConfirmMsg: "",
      errorsArray: [],
      minChars: 8,
    };
  },
  computed: {
    passwordStrength() {
      return zxcvbn(this.pass, this.validations);
    },
    hasMoreThenEigthChars() {
      return (
        this.pass.length === 0 || (this.pass.length > 0 && this.pass.length > 7)
      );
    },
    hasSymbols() {
      return this.errorsArray.indexOf("[^A-Za-z0-9]") === -1;
    },
    hasNumbers() {
      return this.errorsArray.indexOf("[0-9]") === -1;
    },
    hasUpperCase() {
      return this.errorsArray.indexOf("[A-Z]") === -1;
    },
    hasLowerCase() {
      return this.errorsArray.indexOf("[a-z]") === -1;
    },
    numberOfChars() {
      console.log(this.minChars);
      console.log(this.pass.length);
      return this.minChars - this.pass.length > 0
        ? this.minChars - this.pass.length
        : 0;
    },
  },
  watch: {
    newPass() {
      this.pass = "";
      this.confirmation = "";
      this.keymonitor();
      this.compareWithPass();
    },
  },
  methods: {
    compareWithPass: function () {
      this.$emit("confirm", this.confirmation);
      if (this.confirmation === this.pass) {
        this.errorConfirm = false;
        this.validConfirm = true;
        this.errorConfirmMsg = "";
        this.$emit("valid", this.validatePassword(this.pass) && true);
        return true;
      } else {
        this.errorConfirm = true;
        this.validConfirm = false;
        this.errorConfirmMsg = "Senha e Confirmação não são iguais";
        this.$emit("valid", this.validatePassword(this.pass) && false);
        return false;
      }
    },
    keymonitor: function () {
      this.$emit("senha", this.pass);
      if (!this.validatePassword(this.pass)) {
        this.errorSenha = true;
        this.validSenha = false;
        this.$emit("valid", false && this.compareWithPass());
      } else {
        this.errorSenha = false;
        this.validSenha = true;
        this.errorSenhaMsg = "";
        this.$emit("valid", true && this.compareWithPass());
      }

      this.medidorDeForca();
    },
    validatePassword(password) {
      this.errorsArray = [];
      if (password.length === 0) {
        this.errorSenhaMsg = "Campo obrigatório";
        this.inputFocused = false;
        this.showForca = false;
        return false;
      } else {
        this.showForca = true;
        this.errorSenhaMsg = "";
      }

      this.inputFocused = true;
      var matchedCase = new Array();
      matchedCase.push("[^A-Za-z0-9]");
      matchedCase.push("[A-Z]");
      matchedCase.push("[0-9]");
      matchedCase.push("[a-z]");

      var ctr = 0;
      for (var i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(password)) {
          ctr++;
        } else {
          this.errorsArray.push(matchedCase[i]);
        }
      }
      if (password.length <= 7) this.errorsArray.push("length");
      return password.length > 7 && ctr > 3;
    },
    medidorDeForca() {
      if (this.pass.length === 0) {
        this.$refs.meterPass.style.width = "0%";
        return;
      }
      switch (this.passwordStrength.score) {
        case 0:
          this.$refs.meterPass.style.width = "20%";
          this.$refs.meterPass.style.background = "#de1616";
          this.$refs.msgStrength.style.color = "#de1616";
          this.forcaSenha = "Fraca";
          break;
        case 1:
          this.$refs.meterPass.style.width = "40%";
          this.$refs.meterPass.style.background = "#de1616";
          this.$refs.msgStrength.style.color = "#de1616";
          this.forcaSenha = "Fraca";
          break;
        case 2:
          this.$refs.meterPass.style.width = "60%";
          this.$refs.meterPass.style.background = "#FFA200";
          this.$refs.msgStrength.style.color = "#FFA200";
          this.forcaSenha = "Mediana";
          break;
        case 3:
          this.$refs.meterPass.style.width = "80%";
          this.$refs.meterPass.style.background = "#FFA200";
          this.$refs.msgStrength.style.color = "#FFA200";
          this.forcaSenha = "Mediana";
          break;
        case 4:
          this.$refs.meterPass.style.width = "100%";
          this.$refs.meterPass.style.background = "#06bf06";
          this.$refs.msgStrength.style.color = "#06bf06";
          this.forcaSenha = "Forte";
          break;
        default:
          this.$refs.meterPass.style.width = "20%";
          this.$refs.meterPass.style.background = "#de1616";
          break;
      }
    },
    onFocus() {
      if (this.pass.length !== 0) {
        this.inputFocused = true;
      }
      if (this.validatePassword(this.pass) && this.pass.length > 0) {
        this.validSenha = true;
      } else {
        this.validSenha = false;
      }
    },
    onBlur() {
      if (!this.validatePassword(this.pass) || this.pass.length === 0) {
        this.errorSenha = true;
        this.validSenha = false;
      } else {
        this.errorSenha = false;
        this.errorSenhaMsg = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}

.error {
  color: #e91e63 !important;
}

.msg {
  font-weight: 400;
  font-size: 13px;
  font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial,
    sans-serif;
  line-height: 1.2857;
  background-color: transparent;
  padding: 4px 0 0;
  color: #c2c2c2;
}

.strength {
  height: 3px;
  width: 100%;
  background: #ccc;
  margin-top: -3px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  transition: height 0.3s;
}

.strength span {
  width: 0px;
  height: 7px;
  display: block;
  transition: width 0.3s;
}

input[type="password"] {
  border-radius: 5px !important;
  padding-left: 2px;
  background-image: linear-gradient(#d2d2d2, #d2d2d2),
    linear-gradient(#d2d2d2, #d2d2d2) !important;
}

input[type="password"]:focus {
  background-image: linear-gradient(#d2d2d2, #d2d2d2),
    linear-gradient(#d2d2d2, #d2d2d2) !important;
}

.invalid {
  border-left: 5px solid #e91e63 !important;
}
.valid {
  border-left: 5px solid #4cff4c !important;
}

.valid-rule {
  // color: #00e500;
  color: #aeaeae;
  font-weight: bold;
}

.invalid-rule {
  // color:#c2c2c2
  font-weight: initial;
}

.validation span {
  padding: 0 3px;
  border-right: 1px solid gray;
}

.validation span:last-child {
  padding: 0 7px;
  border-right: none;
}

.charlenght {
  position: absolute;
  right: 7px;
  bottom: 25%;
}
</style>