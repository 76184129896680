var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "page-indicador" } },
    [
      _c("ControlesIndicadores"),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "card card-product" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-image",
                      attrs: { "data-header-animation": "false" }
                    },
                    [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.img }
                        })
                      ]),
                      _c("div", { staticClass: "ripple-container" })
                    ]
                  ),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        !_vm.isBiomedic &&
                        _vm.$root.getAuthData().defaultSystem === "Cliente"
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/questionario-indicador/" + _vm.id
                                }
                              },
                              [
                                _c(
                                  "button",
                                  { staticClass: "btn btn-warning btn-block" },
                                  [
                                    _vm._v(" Iniciar novo questionário "),
                                    _c("div", {
                                      staticClass: "ripple-container"
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.isBiomedic &&
                        _vm.$root.getAuthData().defaultSystem === "Cliente"
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-warning",
                                on: {
                                  click: function($event) {
                                    _vm.popupVisible = !_vm.popupVisible
                                  }
                                }
                              },
                              [_vm._v(" Inserir nova medição ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" " + _vm._s(_vm.descricaoIndicador) + " ")
                  ])
                ])
              ]),
              false
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-warning",
                            staticStyle: { padding: "10px", width: "100%" },
                            attrs: {
                              id: "searched",
                              "use-submit-behavior": false,
                              text: "Recomendações",
                              to: "/recomendacoes/" + _vm.id
                            }
                          },
                          [_vm._v("Recomendações")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.$root.getAuthData().defaultSystem === "Admin"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [_c("CardGraficoRiscos")],
                      1
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "col-md-8" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("CardHistoricoMedicoes", {
                      attrs: {
                        id: _vm.id,
                        isBiomedic: _vm.isBiomedic,
                        reload: _vm.reload,
                        "mostra-jejum": _vm.hasJejum
                      },
                      on: {
                        "exclusao-concluida": function($event) {
                          _vm.reload = !_vm.reload
                        }
                      }
                    })
                  ],
                  1
                ),
                false
                  ? _c("div", [
                      _vm.hasGrupoChart &&
                      _vm.$root.getAuthData().defaultSystem === "Admin"
                        ? _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [_c("CardGraficoGrupo", { attrs: { id: _vm.id } })],
                            1
                          )
                        : _vm._e(),
                      _vm.hasEvolutivoChart &&
                      _vm.$root.getAuthData().defaultSystem === "Cliente" &&
                      1 != 1
                        ? _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("CardGraficoEvolutivoMedicao", {
                                attrs: { id: _vm.id }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasMedidorChart &&
                      _vm.$root.getAuthData().defaultSystem === "Cliente"
                        ? _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("CardGraficoEvolutivoMedidor", {
                                attrs: { id: _vm.id, reload: _vm.reload }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isBiomedic
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "card" }, [
                              _c(
                                "div",
                                { staticClass: "card-content" },
                                [
                                  _c("TabelaReferencias", {
                                    attrs: { data: _vm.referenciasData }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm.$root.getAuthData().defaultSystem === "Cliente"
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.indicador != null
                    ? _c("RelatorioConteudo", {
                        attrs: {
                          resource: _vm.indicador,
                          getModuleData: true,
                          reload: _vm.reload
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.popupVisible,
            width: 300,
            height: 500,
            "close-on-outside-click": true,
            showCloseButton: false,
            showTitle: false
          },
          on: {
            "update:visible": function($event) {
              _vm.popupVisible = $event
            },
            "content-ready": _vm.onContentReady
          }
        },
        [
          _c("CardFormIndicadorMedidor", {
            staticStyle: { "min-height": "500px" },
            attrs: {
              fields: _vm.medidoresFields,
              id: _vm.id,
              "mostra-jejum": _vm.hasJejum
            },
            on: {
              "medicao-salva": function($event) {
                _vm.reload = !_vm.reload
                _vm.popupVisible = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }