<template>
  <div
    @click="goToPermissions()"
    :class="{ 'menu-item': !$root.mobile, controle: $root.mobile }"
    class="text-orange"
    @mouseenter="PermissionVisible = !PermissionVisible"
    @mouseleave="PermissionVisible = !PermissionVisible"
  >
    <i id="permissions" class="fas fa-quote-right"></i>
    <span v-if="$root.mobile" class="titulo">Permissões</span>
    <span
      v-if="notificationCounter > 0 && !disabled"
      class="badge"
      :class="[$root.mobile ? 'badge-m' : 'badge-d']"
      >{{ notificationCounter }}</span
    >
    <DxTooltip
      v-if="!$root.mobile"
      :visible="PermissionVisible"
      :close-on-outside-click="false"
      target="#permissions"
      >Permissões</DxTooltip
    >
  </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  name: "Permissions",
  components: {
    DxTooltip,
  },
  data: function () {
    return {
      PermissionVisible: false,
    };
  },
  props: {
    notificationCounter: {
      default: 0,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    goToPermissions() {
      if (!this.disabled) {
        this.$emit("page-name", "Permissões");
        this.$router.push({
          path: `/permissions`,
        });
      }
    },
  },
};
</script>

<style scoped>
.badge {
  position: absolute;
  border-radius: 50%;
  background: red;
  color: white;
}

.badge-d {
  left: 31px;
  top: -4px;
  padding: 3px 7px;
}
.badge-m {
  left: 79px;
  top: -14px;
  padding: 5px 10px;
}

.controle {
  cursor: pointer;
  color: orange;
}
</style>
