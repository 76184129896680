var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "formulario" } },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              return _vm.onFormSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: {
                          id: "save",
                          "use-submit-behavior": true,
                          text: "Salvar"
                        }
                      },
                      [_vm._v("Salvar")]
                    ),
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { id: "back", text: "Voltar" },
                        on: { click: _vm.abrirPopupAlterarSenha }
                      },
                      [_vm._v("Alterar Senha")]
                    ),
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { id: "back", text: "Voltar" },
                        on: { click: _vm.back }
                      },
                      [_vm._v("Voltar")]
                    ),
                    _c("div", { staticClass: "clearfix" })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8", attrs: { id: "dados" } }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(0),
                _c("div", { staticClass: "card-content" }, [
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Dados")]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Nome*")
                          ]),
                          _c(
                            "DxTextBox",
                            {
                              model: {
                                value: _vm.formData.Nome,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "Nome", $$v)
                                },
                                expression: "formData.Nome"
                              }
                            },
                            [
                              _c(
                                "DxValidator",
                                [
                                  _c("DxRequiredRule", {
                                    attrs: { message: "Campo Obrigatório" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          attrs: { id: "dataNascimento" }
                        },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Data Nascimento")
                          ]),
                          _c("DxDateBox", {
                            attrs: {
                              type: "date",
                              "display-format": "dd/MM/yyyy"
                            },
                            model: {
                              value: _vm.formData.DataNascimentoNova,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "DataNascimentoNova",
                                  $$v
                                )
                              },
                              expression: "formData.DataNascimentoNova"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("DxRadioGroup", {
                            attrs: {
                              items: _vm.sexArr,
                              layout: "horizontal",
                              "display-expr": "text",
                              "value-expr": "value"
                            },
                            model: {
                              value: _vm.formData.Sexo,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Sexo", $$v)
                              },
                              expression: "formData.Sexo"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Identidade")
                          ]),
                          _c("DxTextBox", {
                            attrs: { maxLength: 20 },
                            model: {
                              value: _vm.formData.Identidade,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Identidade", $$v)
                              },
                              expression: "formData.Identidade"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("CPF*")
                          ]),
                          _c(
                            "DxTextBox",
                            {
                              attrs: { "max-length": 30 },
                              model: {
                                value: _vm.formData.CPF,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "CPF", $$v)
                                },
                                expression: "formData.CPF"
                              }
                            },
                            [
                              _c(
                                "DxValidator",
                                [
                                  _c("DxRequiredRule", {
                                    attrs: { message: "Campo Obrigatório" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("E-mail*")
                          ]),
                          _c(
                            "DxTextBox",
                            {
                              model: {
                                value: _vm.formData.Email,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "Email", $$v)
                                },
                                expression: "formData.Email"
                              }
                            },
                            [
                              _c(
                                "DxValidator",
                                [
                                  _c("DxRequiredRule", {
                                    attrs: { message: "Campo Obrigatório" }
                                  }),
                                  _c("DxEmailRule", {
                                    attrs: {
                                      message: "E-mail no formato inválido"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Situação")
                          ]),
                          _c("DxSwitch", {
                            staticClass: "switch",
                            model: {
                              value: _vm.formData.Ativo,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Ativo", $$v)
                              },
                              expression: "formData.Ativo"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  1 !== 1
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Operadora")
                              ]),
                              _c("DxTextBox", {
                                model: {
                                  value: _vm.formData.Operadora,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "Operadora", $$v)
                                  },
                                  expression: "formData.Operadora"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Plano")
                              ]),
                              _c("DxTextBox", {
                                model: {
                                  value: _vm.formData.Plano,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "Plano", $$v)
                                  },
                                  expression: "formData.Plano"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    1 !== 1
                      ? _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Etnia")
                              ]),
                              _c("DxTextBox", {
                                model: {
                                  value: _vm.formData.Etinia,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "Etinia", $$v)
                                  },
                                  expression: "formData.Etinia"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Escolaridade")
                          ]),
                          _c("DxSelectBox", {
                            attrs: { items: _vm.escolaridades },
                            model: {
                              value: _vm.formData.Escolaridade,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Escolaridade", $$v)
                              },
                              expression: "formData.Escolaridade"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: { type: "text", name: "fakeusernameremembered" }
                    }),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "password",
                        name: "fakepasswordremembered"
                      }
                    }),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Profissão")
                          ]),
                          _c("DxTextBox", {
                            model: {
                              value: _vm.formData.Ocupacao,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Ocupacao", $$v)
                              },
                              expression: "formData.Ocupacao"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  1 !== 1
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { staticClass: "control-label restrict-data" },
                                [
                                  _vm._v(
                                    "Restringir alterações por terceiros (Profissionais de Saúde , GPS MED ou parceiros)"
                                  )
                                ]
                              ),
                              _c("DxCheckBox", {
                                model: {
                                  value: _vm.formData.RestringirDadosBasicos,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "RestringirDadosBasicos",
                                      $$v
                                    )
                                  },
                                  expression: "formData.RestringirDadosBasicos"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "col-md-4 update" }, [
              _c("div", { staticClass: "card card-profile" }, [
                _c("div", { staticClass: "card-avatar" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { id: "profilePhoto", src: _vm.photo }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [
                    _c("DxFileUploader", {
                      ref: "photoFileUploader",
                      attrs: {
                        multiple: false,
                        "label-text": "",
                        accept: "image/*",
                        "upload-mode": "instantly",
                        "select-button-text": "Selecione um arquivo",
                        "invalid-file-extension-message":
                          "Extensão de arquivo não aceita",
                        "upload-url": _vm.urlUploadImport,
                        "allowed-file-extensions": [".jpg", ".png"],
                        "upload-headers": _vm.uploadImportHeader
                      },
                      on: {
                        uploaded: _vm.onUploaded,
                        "value-changed": _vm.photoChanged
                      }
                    }),
                    _vm.showPhoto
                      ? _c(
                          "DxButton",
                          {
                            staticClass:
                              "btn btn-secondary pull-right btn-back separated",
                            attrs: {
                              id: "remove-photo-file",
                              text: "Remover arquivo"
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeImage()
                              }
                            }
                          },
                          [_vm._v("Remover arquivo")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]),
          _c(
            "div",
            { attrs: { id: "accordionExample" } },
            [
              _c("dados-contato", { attrs: { data: _vm.formData } }),
              _c("endereco", { attrs: { data: _vm.formData } })
            ],
            1
          ),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-content" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Empresas vinculadas")
              ]),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("DxDataGrid", {
                    attrs: {
                      "data-source": _vm.dataCompanies,
                      columns: _vm.columns,
                      "show-borders": true
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: {
                          id: "save",
                          "use-submit-behavior": true,
                          text: "Salvar"
                        }
                      },
                      [_vm._v("Salvar")]
                    ),
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { id: "back", text: "Voltar" },
                        on: {
                          click: function($event) {
                            _vm.openAlterPass = true
                          }
                        }
                      },
                      [_vm._v("Alterar Senha")]
                    ),
                    _c(
                      "DxButton",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { id: "back", text: "Voltar" },
                        on: { click: _vm.back }
                      },
                      [_vm._v("Voltar")]
                    ),
                    _c("div", { staticClass: "clearfix" })
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openAlterPass,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": true,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "90vh",
            height: "60vh",
            title: "Alterar Senha"
          },
          on: {
            "update:visible": function($event) {
              _vm.openAlterPass = $event
            }
          }
        },
        [
          _c("senha", {
            attrs: { "new-pass": _vm.openAlterPass, validations: _vm.userData },
            on: {
              valid: function($event) {
                _vm.isPasswordValid = $event
              },
              senha: function($event) {
                _vm.password = $event
              },
              confirm: function($event) {
                _vm.confirm = $event
              }
            }
          }),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-center" },
                    [
                      _c(
                        "DxButton",
                        {
                          staticClass:
                            "btn btn-warning btn-block btn-full btn-wd btn-lg",
                          attrs: { text: "Salvar" },
                          on: {
                            click: function($event) {
                              _vm.openAlterPass = false
                            }
                          }
                        },
                        [_vm._v("Fechar")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-center" },
                    [
                      _c(
                        "DxButton",
                        {
                          staticClass:
                            "btn btn-warning btn-block btn-full btn-wd btn-lg",
                          attrs: { text: "Alterar Senha" },
                          on: { click: _vm.alterarSenha }
                        },
                        [_vm._v("Alterar Senha")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card-header card-header-icon",
        attrs: { "data-background-color": "orange" }
      },
      [
        _c("i", {
          staticClass: "fa fa-user",
          staticStyle: { "font-size": "20px" },
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }