<template>
  <div class="card" :class="{ 'card-dados': !$root.mobile }">
    <div class="card-content">
      <span class="stats explanation"
        >Clique nos itens para ver no gráfico</span
      >
      <DxDataGrid
        :show-column-headers="false"
        :data-source="data"
        :selection="{ mode: type === 'Grupos' ? 'single' : 'multiple' }"
        :show-borders="false"
        :hover-state-enabled="true"
        :class="{ 'tabela-grupos': type === 'Grupos' }"
        key-expr="Id"
        @selection-changed="onSelectionChanged"
        width="100%"
        :height="$root.mobile ? 'inherit' : '250px'"
        :selected-row-keys="selectedKeys"
      >
        <DxScrolling showScrollbar="always" />
        <DxColumn data-field="Nome" caption="Title" />
        <DxColumn
          data-field="UltimaMedicao"
          caption="Valor"
          v-if="type !== 'Grupos'"
        />
        <DxColumn
          :width="40"
          data-field="CorExibicao"
          cell-template="cellTemplate"
        />
        <template #cellTemplate="{ data }">
          <img
            class="icon-compass"
            v-if="data.value !== null"
            :src="`${
              $root.Controle.UrlAPI
            }/Upload/GetPhoto?type=icon&image=${data.value.substring(1)}.png`"
          />
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling } from "devextreme-vue/data-grid";

export default {
  name: "CardTableData",
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
  },
  props: {
    data: {
      default: function () {
        return [];
      },
      required: true,
    },
    type: {
      default: "",
      required: true,
    },
    uniqueBlock: {
      default: false,
    },
    selectedKeys: {
      default: function () {
        return [];
      },
    },
  },
  data: function () {
    return {
      selected: [],
    };
  },
  methods: {
    sortBy: function (a, key, asc = true) {
      return a.slice().sort((a, b) => {
        if (asc) {
          if (a[key] < b[key]) {
            return -1;
          }
          if (b[key] < a[key]) {
            return 1;
          }
        } else {
          if (a[key] > b[key]) {
            return -1;
          }
          if (b[key] > a[key]) {
            return 1;
          }
        }
        return 0;
      });
    },
    onSelectionChanged(e) {
      if (!this.uniqueBlock) this.$emit("selection-change", e.selectedRowKeys);
      else e.component.selectAll();
    },
  },
};
</script>

<style>
.icon-compass {
  width: 15px !important;
}
.card-dados {
  height: 300px;
}

.tabela-grupos
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td,
.tabela-grupos
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td,
.tabela-grupos
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td,
.tabela-grupos
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  /*background-color: rgba(255, 152, 0, 0.4);*/
  border-top: 2px solid #ff9800;
  background-color: white;
}
.tabela-grupos .dx-datagrid-rowsview tr.dx-selection {
  border: 2px solid #ff9800;
}
</style>

<style scoped>
.card .card-header {
  padding: 15px 15px;
  z-index: 3;
  float: unset;
}
</style>
