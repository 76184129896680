<template>
  <div id="page-permissions" class="content">
    <loading :loading="$root.loading" />
    <div v-show="!$root.loading">
      <div>
        <Tabs
          :tabs="[
            {
              nome: 'Permissões',
              name: 'Permissões',
              ref: 'permissions',
              active: true,
              icon: 'fa-user-shield',
              visible: true,
            },
            {
              nome: 'Solicitações como profissional de saúde',
              name: 'Solicitações',
              ref: 'requestedPermissions',
              active: false,
              icon: 'fa-user-md',
              visible: $root.getAuthData().isHealthWorker,
            },
          ]"
        >
          <template v-slot:permissions>
            <DxDataGrid
              id="gridContainer"
              :data-source="data"
              :column-auto-width="true"
              :show-borders="true"
              :column-hiding-enabled="true"
            >
              <DxSearchPanel
                :visible="true"
                :width="240"
                placeholder="Procurar..."
              />
              <DxColumn
                :width="100"
                :allow-filtering="false"
                :allow-sorting="false"
                caption="Solicitante"
                data-field="photoSolicitante"
                cell-template="photo-template"
                :visibleIndex="!$root.mobile ? 0 : 2"
              />
              <DxColumn
                :width="!$root.mobile ? 250 : 130"
                caption="Nome"
                data-field="solicitante"
                :customizeText="customizeTextNome"
                :visibleIndex="!$root.mobile ? 1 : 0"
              />
              <DxColumn
                caption="Profissão"
                data-field="profissao"
                :visibleIndex="!$root.mobile ? 2 : 3"
              />
              <DxColumn
                caption="Tipo"
                data-field="tipo"
                :visibleIndex="!$root.mobile ? 3 : 4"
              />
              <DxColumn
                caption="Situação"
                data-field="statusTxt"
                :visibleIndex="!$root.mobile ? 4 : 1"
              />
              <DxColumn
                caption="Inicio"
                data-field="dataInicioPermission"
                data-type="datetime"
              />
              <DxColumn
                caption="Termino"
                data-field="dataFimPermission"
                data-type="datetime"
              />
              <DxColumn caption="Contratante" data-field="empresa" />
              <DxColumn caption="E-mail" data-field="emailSolicitante" />
              <DxColumn
                caption="CPF"
                data-field="documentoSolicitante"
                cell-template="cpf-template"
              />
              <DxColumn caption="Telefones" data-field="telefoneSolicitante" />
              <DxColumn caption="Conselho" data-field="conselho" />
              <DxColumn caption="UF do Conselho" data-field="ufConselho" />
              <DxColumn
                caption="Número de Registro"
                data-field="numeroConselho"
              />
              <DxColumn caption="Especialidades" data-field="especialidade" />
              <DxColumn
                caption="Cadastro"
                data-field="dataSolicitacao"
                data-type="datetime"
              />

              <DxColumn caption="Aprovar" type="buttons" class="aprovar-negar">
                <DxButton
                  name="aprovar"
                  :text="$root.mobile ? 'Sim' : ''"
                  :icon="!$root.mobile ? 'check' : ''"
                  css-class="btn-aprove"
                  :on-click="aprovarClick"
                  :visible="canAprove"
                  :hint="'Aprovar'"
                />
                <DxButton
                  name="negar"
                  css-class="btn-deny"
                  :text="$root.mobile ? 'Não' : ''"
                  :icon="!$root.mobile ? 'clear' : ''"
                  :on-click="negarClick"
                  :visible="canDeny"
                  :hint="'Negar'"
                />
              </DxColumn>
              <template #photo-template="{ data }">
                <img
                  class=""
                  :src="`${defaultPath}${
                    data.data.photoSolicitante !== null &&
                    data.data.photoSolicitante !== '' &&
                    data.data.photoSolicitante !== undefined
                      ? data.data.photoSolicitante
                      : defaultImage
                  }`"
                />
              </template>
              <template #cpf-template="{ data }">
                <div>
                  {{ customizeTextCPF(data.data.documentoSolicitante) }}
                </div>
              </template>
            </DxDataGrid>
          </template>
          <template v-slot:requestedPermissions>
            <DxDataGrid
              id="gridContainerRequested"
              :data-source="dataRequested"
              :column-auto-width="false"
              :show-borders="true"
              :column-hiding-enabled="$root.mobile"
              :column-resizing-mode="'nextColumn'"
            >
              <DxSearchPanel
                :visible="true"
                :width="240"
                placeholder="Procurar..."
              />
              <DxColumn
                :width="100"
                :allow-resizing="false"
                :allow-filtering="false"
                :allow-sorting="false"
                caption="Solicitado"
                data-field="photoSolicitado"
                cell-template="photo-template"
                :visibleIndex="!$root.mobile ? 0 : 1"
              />
              <DxColumn
                :allow-resizing="true"
                :width="!$root.mobile ? 250 : 130"
                caption="Nome"
                data-field="solicitado"
                :visibleIndex="!$root.mobile ? 1 : 0"
              />
              <DxColumn
                :allow-resizing="true"
                :width="100"
                caption="Contratante"
                data-field="empresa"
              />
              <DxColumn
                :allow-resizing="true"
                :width="100"
                caption="E-mail"
                data-field="emailSolicitado"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Tipo"
                data-field="tipo"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Situação"
                data-field="statusTxt"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Telefones"
                data-field="telefoneSolicitado"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Cadastro"
                data-field="dataSolicitacao"
                data-type="datetime"
                :sort-index="1"
                sort-order="desc"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Inicio"
                data-field="dataInicioPermission"
                data-type="datetime"
              />
              <DxColumn
                :allow-resizing="true"
                caption="Termino"
                data-field="dataFimPermission"
                data-type="datetime"
                :sort-index="0"
                sort-order="desc"
              />
              <DxColumn
                caption="Reenviar"
                type="buttons"
                :allow-resizing="true"
                :width="70"
              >
                <DxButton
                  name="resend"
                  :icon="'refresh'"
                  :on-click="resendNotification"
                  :hint="'Reenviar Solicitação'"
                />
              </DxColumn>
              <template #photo-template="{ data }">
                <img
                  class=""
                  :src="`${defaultPath}${
                    data.data.photoSolicitado !== null &&
                    data.data.photoSolicitado !== '' &&
                    data.data.photoSolicitado !== undefined
                      ? data.data.photoSolicitado
                      : defaultImage
                  }`"
                />
              </template>
              <template #cpf-template="{ data }">
                <div>
                  {{ customizeTextCPF(data.data.documentoSolicitado) }}
                </div>
              </template>
            </DxDataGrid>
          </template>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/loading.vue";
import Tabs from "@/components/tabs.vue";
import notify from "devextreme/ui/notify";
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import { confirm } from "devextreme/ui/dialog";

export default {
  name: "Permissions",
  components: {
    Loading,
    DxDataGrid,
    DxColumn,
    Tabs,
    DxButton,
    DxSearchPanel,
  },
  data: function () {
    const inst = this;
    return {
      data: [],
      dataRequested: [],
      hasNoData: false,
      defaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=`,
      defaultImage: "default-non-user-no-photo-1.jpg",
      columns: [
        { dataField: "nome", caption: "Contratante" },
        { dataField: "dataCadastro", caption: "Data Cadastro" },
        { dataField: "telefone", caption: "Telefone" },
      ],
      customizeTextNome: function (cellInfo) {
        let a = cellInfo.value.trim().split(" ");
        return `${a[0]} ${a[a.length - 1]}`;
      },
    };
  },
  created: function () {
    this.getPermissionsData()
      .then((result) => JSON.parse(result.request.response))
      .then((resposta) => this.setData(resposta))
      .catch((error) => notify(error.data.message, "error", 4000));
    this.getRequestedPermissionsData()
      .then((result) => JSON.parse(result.request.response))
      .then((resposta) => {
        this.dataRequested = resposta;
      })
      .catch((error) => notify(error.data.message, "error", 4000));
  },
  methods: {
    getCompaniesWithMyData: function () {
      return this.$ConAPI.get(
        `/Permission/GetCompaniesWithData/${this.$root.getAuthData().usuario}`,
        {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        }
      );
    },
    getRequestedPermissionsData: function () {
      var x = new Date();
      return this.$ConAPI.get(
        `/Permission/GetRequestedPermissions/${x.getTimezoneOffset()}`,
        {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        }
      );
    },
    getPermissionsData: function () {
      var x = new Date();
      return this.$ConAPI.get(
        `/Permission/GetUserPermissions/${x.getTimezoneOffset()}`,
        {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        }
      );
    },
    setData: function (d) {
      this.data = d;
      this.hasNoData = d.length === 0;
    },
    setPermission: function (event, type) {
      const data = { ...event, type };
      this.$ConAPI
        .post(`/Permission/UpdatePermission/`, data, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          this.$emit("permission-altered", true);
          notify("Permissão alterada com sucesso", "success", 4000);
          this.getPermissionsData()
            .then((result) => {
              var resposta = JSON.parse(result.request.response);
              this.setData(resposta);
            })
            .catch(function (error) {
              notify(error.data.message, "error", 4000);
            });
        })
        .catch(function (error) {
          notify(error.data.message, "error", 4000);
        });
    },
    customizeTextCPF: function (txt) {
      return txt.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (regex, argumento1, argumento2, argumento3, argumento4) {
          return (
            argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
          );
        }
      );
    },
    onCellPrepared: function (e) {
      if (e.rowType === "data") {
        if (e.column.caption === "CPF") {
          return this.customizeTextCPF(e.value);
        }
      }
    },
    negarClick: function (e) {
      console.log(e);
      let result = confirm(
        "<i>Deseja realmente negar a permissão solicitada pelo médico?</i>",
        "Negar pedido de permissão"
      );
      result.then((d) => {
        if (d) {
          this.setPermission({ id: e.row.data.id }, "N");
        }
      });
    },
    aprovarClick: function (e) {
      this.setPermission({ id: e.row.data.id }, "A");
    },
    canAprove: function (e) {
      return e.row.data.status === "P";
    },
    canDeny: function (e) {
      return e.row.data.status !== "N";
    },
    resendNotification: function (e) {
      return this.$ConAPI
        .get(`/Permission/ResendNotification/${e.row.data.id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          notify("Reenvio feito com sucesso", "success", 4000);
        })
        .catch(() => {
          notify("Ocorreu um erro no reenvio da solicitação", "error", 4000);
        });
    },
  },
};
</script>

<style >
.btn-deny {
  color: #dc3545 !important;
}
.btn-aprove {
  color: #28a745 !important;
}
.aprovar-negar {
  font-size: 14px;
}
</style>

<style scoped lang="scss">
#page-permissions {
  padding-bottom: 50px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}

.buttons {
  font-size: 20px;
}

.buttons * {
  margin: 0 5px;
  cursor: pointer;
}

img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

#gridContainer tr.main-row td:not(:first-child) {
  height: 21px;
}

#gridContainer tr.notes-row {
  white-space: normal;
  vertical-align: top;
  text-align: left;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#gridContainer tbody.employee:hover {
  background-color: #ebebeb;
}

.dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

.dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #ccc;
}

#gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}
</style>
