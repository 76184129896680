var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _c(
            "DxChart",
            {
              staticClass: "chart",
              attrs: {
                id: "chart1",
                "data-source": _vm.dataSource,
                palette: "Violet"
              },
              on: { initialized: _vm.saveChartInstance }
            },
            [
              _c("DxCommonSeriesSettings", {
                attrs: { type: "spline", "argument-field": "Data" }
              }),
              _c(
                "DxCommonAxisSettings",
                [_c("DxGrid", { attrs: { visible: true } })],
                1
              ),
              _vm._l(_vm.data, function(tipos, index2) {
                return _c("DxSeries", {
                  key: index2,
                  attrs: {
                    "value-field":
                      tipos.Nome !== null && tipos.Nome !== undefined
                        ? tipos.Nome.replace("@", "")
                        : "",
                    name:
                      tipos.Nome !== null && tipos.Nome !== undefined
                        ? tipos.Nome.replace("@", "")
                        : ""
                  }
                })
              }),
              _c("DxMargin", { attrs: { bottom: 20 } }),
              _c(
                "DxArgumentAxis",
                {
                  attrs: {
                    "allow-decimals": false,
                    "axis-division-factor": 60,
                    name: "medidorEixo"
                  }
                },
                [
                  _c(
                    "DxLabel",
                    { attrs: { "customize-text": _vm.customizeLabel } },
                    [_c("DxFormat", { attrs: { type: "decimal" } })],
                    1
                  )
                ],
                1
              ),
              _c("DxLegend", {
                attrs: {
                  "vertical-alignment": "top",
                  "horizontal-alignment": "center",
                  visible: true
                }
              }),
              _c("DxTooltip", { attrs: { enabled: true } })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", [_vm._v("Gráfico evolutivo")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }