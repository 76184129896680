var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "photo" }, [
    _vm.$root.getAuthData().acessoGPS &&
    _vm.$root.getAuthData().defaultSystem === "Admin"
      ? _c("div", { staticClass: "gestor" }, [
          _c("i", { staticClass: "fas fa-suitcase" })
        ])
      : _vm._e(),
    _vm.$root.getAuthData().userName !== _vm.$root.getAuthData().userLoggedName
      ? _c("div", { staticClass: "gestor" }, [
          _c("i", { staticClass: "fas fa-quote-right" })
        ])
      : _vm._e(),
    _c("img", {
      staticStyle: { height: "100%", "object-fit": "cover" },
      attrs: { src: _vm.img }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }