var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            return _vm.onFormSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Nome*")
                ]),
                _c(
                  "DxTextBox",
                  {
                    attrs: { "max-length": 60 },
                    model: {
                      value: _vm.formData.Nome,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Nome", $$v)
                      },
                      expression: "formData.Nome"
                    }
                  },
                  [
                    _c(
                      "DxValidator",
                      [
                        _c("DxRequiredRule", {
                          attrs: { message: "Campo Obrigatório" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Descrição*")
                ]),
                _c(
                  "DxTextBox",
                  {
                    attrs: { "max-length": 100 },
                    model: {
                      value: _vm.formData.Sigla,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Sigla", $$v)
                      },
                      expression: "formData.Sigla"
                    }
                  },
                  [
                    _c(
                      "DxValidator",
                      [
                        _c("DxRequiredRule", {
                          attrs: { message: "Campo Obrigatório" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [_vm._v("Logo")]),
                _c("DxTextBox", {
                  attrs: { "max-length": 1200 },
                  model: {
                    value: _vm.formData.logo,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "logo", $$v)
                    },
                    expression: "formData.logo"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-10" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Ordem")
                ]),
                _c("DxNumberBox", {
                  attrs: { "read-only": true },
                  model: {
                    value: _vm.formData.Ordem,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "Ordem", $$v)
                    },
                    expression: "formData.Ordem"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Status")
                ]),
                _c("DxSwitch", {
                  model: {
                    value: _vm.formData.Ativo,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "Ativo", $$v)
                    },
                    expression: "formData.Ativo"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "DxButton",
                {
                  staticClass: "btn btn-warning pull-right",
                  attrs: {
                    id: "save",
                    "use-submit-behavior": true,
                    text: "Salvar"
                  }
                },
                [_vm._v("Salvar")]
              ),
              _c(
                "DxButton",
                {
                  staticClass: "btn btn-warning pull-right",
                  attrs: { id: "back", text: "Cancel" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancelar")]
              ),
              _c("div", { staticClass: "clearfix" })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }