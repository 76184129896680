<template>
  <div id="formulario">
    <form @submit="onFormSubmit($event)">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content">
              <div class="row">
                <div class="col-md-7">
                  <DxAutocomplete
                    v-if="id === null"
                    v-model="cpfSearchVal"
                    :data-source.sync="dataSource"
                    :search-expr="defaultSearchField"
                    value-expr="nome"
                    placeholder="Buscar por CPF"
                    search-mode="startswith"
                    item-template="cpfTemplate"
                    @selection-changed="searchByCPF"
                    @value-changed="showForm = false"
                  >
                    <template #cpfTemplate="{ data }">
                      <span>{{ data.nome }} - {{ data.cpf }}</span>
                    </template>
                  </DxAutocomplete>
                </div>
                <div class="col-md-3" v-if="id === null && 1 === 2">
                  <DxCheckBox
                    style="float: left; margin: 3px 7px"
                    @value-changed="
                      defaultSearchField === 'CPF'
                        ? (defaultSearchField = 'Nome')
                        : (defaultSearchField = 'CPF')
                    "
                  />
                  <label>Buscar por nome</label>
                </div>
                <div class="col-md-5">
                  <DxButton
                    style="height: 35px"
                    v-if="id === null"
                    v-show="!showForm"
                    id="searched"
                    :use-submit-behavior="false"
                    :text="showFormBtnText"
                    class="btn btn-warning pull-right"
                    @click="useFounded"
                    >{{ showFormBtnText }}</DxButton
                  >
                  <DxButton
                    style="height: 35px"
                    v-if="showForm"
                    id="save"
                    :use-submit-behavior="true"
                    text="Salvar"
                    class="btn btn-warning pull-right"
                    >Salvar</DxButton
                  >
                  <DxButton
                    style="height: 35px"
                    v-if="id === null"
                    v-show="showForm"
                    :use-submit-behavior="false"
                    text="Cancelar"
                    class="btn btn-warning pull-right"
                    @click="cancelFounded"
                    >Cancelar</DxButton
                  >
                  <DxButton
                    style="height: 35px"
                    id="back"
                    text="Voltar"
                    class="btn btn-warning pull-right"
                    @click="back"
                    >Voltar</DxButton
                  >
                </div>
              </div>
              <div class="row" v-if="1 === 2"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showForm">
        <div class="row">
          <div class="col-12">
            <ul
              class="nav nav-pills nav-pills-warning nav-pills-icons"
              role="tablist"
              style="width: 100%; margin: 15px"
            >
              <li class="nav-item active">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#dadosTab"
                  role="tablist"
                >
                  <i class="fa fa-user icons-wizard"></i>
                  <span style="margin-left: 5px">Dados Básicos</span>
                  <div
                    style="float: left"
                    class="error-icon"
                    v-show="hasDataInvalidField"
                  >
                    <i
                      class="fas fa-exclamation-circle"
                      style="font-size: 14px"
                    ></i>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#enderecoTab"
                  role="tablist"
                >
                  <i class="fas fa-address-book icons-wizard"></i>
                  <span style="margin-left: 5px">Contato e Endereço</span>

                  <div
                    style="float: left"
                    class="error-icon"
                    v-show="hasContactInvalidField"
                  >
                    <i
                      class="fas fa-exclamation-circle"
                      style="font-size: 14px"
                    ></i>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#historicoTab"
                  role="tablist"
                >
                  <i class="fas fa-history icons-wizard"></i>
                  <span style="margin-left: 5px">Histórico</span>

                  <div
                    style="float: left"
                    class="error-icon"
                    v-show="hasHistoryInvalidField"
                  >
                    <i
                      class="fas fa-exclamation-circle"
                      style="font-size: 14px"
                    ></i>
                  </div>
                </a>
              </li>
              <li class="nav-item" v-show="showFuncionarioTab">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#funcionarioTab"
                  role="tablist"
                >
                  <i class="fas fa-user-tie icons-wizard"></i>
                  <span style="margin-left: 5px">Relação com Empresa</span>
                  <div
                    style="float: left"
                    class="error-icon"
                    v-show="hasRelationInvalidField"
                  >
                    <i
                      class="fas fa-exclamation-circle"
                      style="font-size: 14px"
                    ></i>
                  </div>
                </a>
              </li>
              <li class="nav-item" v-show="showRegistrosTab">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#profissionalSaudeTab"
                  role="tablist"
                >
                  <i class="fas fa-user-nurse icons-wizard"></i>
                  <span style="margin-left: 5px">Profissional da Saúde</span>
                  <div style="float: left" v-show="hasRegisterInvalidField">
                    <i
                      class="fas fa-exclamation-circle"
                      style="color: red; font-size: 14px"
                    ></i>
                  </div>
                </a>
              </li>
              <li class="nav-item" v-show="showPacienteTab">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#pacienteTab"
                  role="tablist"
                >
                  <i class="fas fa-hospital-user icons-wizard"></i>
                  <span style="margin-left: 5px">Dados Paciente</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="page-categories">
              <div class="tab-content tab-subcategories">
                <div class="tab-pane active" id="dadosTab">
                  <div class="card">
                    <div class="card-content">
                      <h4 class="card-title">Dados</h4>
                      <div class="row">
                        <div class="col-md-12">
                          <label style="float: left">Tipo de Usuário: </label>
                          <div style="float: left">
                            <DxCheckBox
                              id="checkFuncionario"
                              style="float: left; margin: 3px 7px"
                              v-model="formData.Funcionario"
                              @value-changed="userTypeValueChange"
                            />
                            <label>Funcionário</label>
                          </div>
                          <div style="float: left">
                            <DxCheckBox
                              id="checkRegistro"
                              style="float: left; margin: 3px 7px"
                              v-model="formData.PrestadorSaude"
                              @value-changed="userTypeValueChange"
                            />
                            <label>Profissional da saúde</label>
                          </div>
                          <div style="float: left">
                            <DxCheckBox
                              id="checkPaciente"
                              style="float: left; margin: 3px 7px"
                              v-model="formData.Paciente"
                              @value-changed="userTypeValueChange"
                            />
                            <label>Paciente</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Nome*</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Nome"
                              :disabled="restrictData"
                            >
                              <DxValidator>
                                <DxRequiredRule message="Campo Obrigatório" />
                              </DxValidator>
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div id="dataNascimento" class="form-group">
                            <label class="control-label"
                              >Data Nascimento*</label
                            >
                            <DxDateBox
                              v-model="formData.DataNascimentoNova"
                              type="date"
                              display-format="dd/MM/yyyy"
                              :use-mask-behavior="true"
                              :disabled="restrictData"
                              :max="maxDate"
                            >
                              <DxValidator>
                                <DxRequiredRule message="Campo Obrigatório" />
                              </DxValidator>
                            </DxDateBox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <DxRadioGroup
                              :items="sexArr"
                              layout="horizontal"
                              v-model="formData.Sexo"
                              display-expr="text"
                              value-expr="value"
                              :disabled="restrictData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">E-mail*</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Email"
                              :disabled="restrictData"
                            >
                              <DxValidator>
                                <DxRequiredRule message="Campo Obrigatório" />
                                <DxEmailRule
                                  message="E-mail no formato inválido"
                                />
                              </DxValidator>
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">CPF*</label>
                            <DxTextBox v-model="formData.CPF" :max-length="30">
                              <DxValidator>
                                <DxRequiredRule message="Campo Obrigatório" />
                                <!-- <DxPatternRule
                                  :pattern="cpfPattern"
                                  message="Cpf no formato inválido"
                                /> -->
                              </DxValidator>
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">Identidade</label>
                            <DxTextBox
                              v-model="formData.Identidade"
                              :maxLength="20"
                            >
                              <!-- <DxValidator>
                                <DxPatternRule
                                  :pattern="identidadePattern"
                                  message="Identidade no formato inválido"
                                />
                              :use-masked-value="false"
                              mask="00.000.000-0"
                              mask-invalid-message="Identidade no formato inválido"
                              :disabled="restrictData"
                              </DxValidator> -->
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-2" v-if="1 !== 1">
                          <div class="form-group">
                            <label class="control-label">Etnia</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Etinia"
                              :disabled="restrictData"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">Situação</label>
                            <DxSwitch class="switch" v-model="formData.Ativo" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Profissão</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Ocupacao"
                              :disabled="restrictData"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Escolaridade</label>
                            <DxSelectBox
                              :disabled="restrictData"
                              :items="escolaridades"
                              v-model="formData.Escolaridade"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Contratante*</label>
                            <DxSelectBox
                              display-expr="NomeFantasia"
                              value-expr="Id"
                              v-model="formData.IdPessoaJuridica"
                              :searchEnabled="true"
                              :data-source="$root.empresasDataSource"
                              :show-clear-button="false"
                              :disabled="empresas.length < 2"
                              @value-changed="onEmpresaValueChanged"
                            >
                              <DxValidator>
                                <DxRequiredRule message="Campo Obrigatório" />
                              </DxValidator>
                            </DxSelectBox>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="1 !== 1">
                          <div class="form-group">
                            <label class="control-label">Categoria</label>
                            <DxSelectBox
                              display-expr="nome"
                              value-expr="id"
                              v-model="formData.Categoria"
                              :searchEnabled="true"
                              :data-source="categorias"
                              :show-clear-button="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="enderecoTab">
                  <dados-contato
                    :data="formData"
                    :restrictData="restrictData"
                    @validation="validateContatosData"
                  />
                  <EnderecoComponent
                    :data="formData"
                    :restrictData="restrictData"
                  />
                </div>
                <div class="tab-pane" id="historicoTab">
                  <div class="card">
                    <div href="#">
                      <div class="card-header">
                        <h4 class="card-title">Histórico</h4>
                      </div>
                    </div>
                    <div id="collapseContato" class="card-content">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <DxHtmlEditor
                              height="200px"
                              v-model="formData.Historico"
                            >
                              <DxMediaResizing :enabled="true" />
                              <DxToolbar :multiline="isMultiline">
                                <DxItem format-name="undo" />
                                <DxItem format-name="redo" />
                                <DxItem format-name="separator" />
                                <DxItem
                                  :format-values="sizeValues"
                                  format-name="size"
                                />
                                <DxItem
                                  :format-values="fontValues"
                                  format-name="font"
                                />
                                <DxItem format-name="separator" />
                                <DxItem format-name="bold" />
                                <DxItem format-name="italic" />
                                <DxItem format-name="strike" />
                                <DxItem format-name="underline" />
                                <DxItem format-name="separator" />
                                <DxItem format-name="alignLeft" />
                                <DxItem format-name="alignCenter" />
                                <DxItem format-name="alignRight" />
                                <DxItem format-name="alignJustify" />
                                <DxItem format-name="separator" />
                                <DxItem format-name="orderedList" />
                                <DxItem format-name="bulletList" />
                                <DxItem format-name="separator" />
                                <DxItem
                                  :format-values="headerValues"
                                  format-name="header"
                                />
                              </DxToolbar>
                            </DxHtmlEditor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="funcionarioTab">
                  <div id="empresaCard" class="card">
                    <div href="#">
                      <div class="card-header">
                        <h4 class="card-title">Dados do Funcionário</h4>
                      </div>
                    </div>
                    <div id="collapseRelacao" class="card-content">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">Cargo*</label>
                            <DxTextBox
                              :max-length="240"
                              v-model="formData.Cargo"
                            >
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="control-label">Departamento*</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Departamento"
                            >
                            </DxTextBox>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="control-label">Data Admissão*</label>
                            <DxDateBox
                              v-model="formData.DataAdmissao"
                              type="date"
                              display-format="dd/MM/yyyy"
                              :use-mask-behavior="true"
                            >
                            </DxDateBox>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="form-group">
                            <label class="control-label">Ativo</label>
                            <DxSwitch
                              class="switch"
                              v-model="formData.AtivoEmpresa"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="profissionalSaudeTab">
                  <div id="empresaCard" class="card">
                    <div href="#">
                      <div class="card-header">
                        <h4 class="card-title">Dados Profissional da Saúde</h4>
                      </div>
                    </div>
                    <registro-profissional
                      :data="formData"
                      :restrictData="restrictData"
                    />
                  </div>
                </div>
                <div class="tab-pane" id="pacienteTab">
                  <div id="empresaCard" class="card">
                    <div href="#">
                      <div class="card-header">
                        <h4 class="card-title">Dados Paciente</h4>
                      </div>
                    </div>
                    <div id="collapseRelacao" class="card-content">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="control-label">Operadora</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Operadora"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="control-label">Plano</label>
                            <DxTextBox
                              :max-length="200"
                              v-model="formData.Plano"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import {
  DxValidator,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/validator";
import { DxSwitch } from "devextreme-vue/switch";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxAutocomplete } from "devextreme-vue/autocomplete";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { DxCheckBox } from "devextreme-vue/check-box";
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem,
} from "devextreme-vue/html-editor";
import EnderecoComponent from "../../components/endereco.vue";
import DadosContato from "../../components/dados-contato.vue";
import RegistroProfissional from "../../components/registro-profissional.vue";

export default {
  name: "ClienteForm",
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxEmailRule,
    DxSwitch,
    DxRadioGroup,
    DxDateBox,
    DxSelectBox,
    DxAutocomplete,
    DxCheckBox,
    DxHtmlEditor,
    DxToolbar,
    DxMediaResizing,
    DxItem,
    EnderecoComponent,
    DadosContato,
    RegistroProfissional,
  },
  props: {
    id: {
      default: null,
    },
    company: {
      default: null,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "/GPS_PessoaFisica",
      formData: inst.defaultFormData(),
      cpfPattern: /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{11})$/,
      identidadePattern: /^(\d{2}\.\d{3}\.\d{3}-\d{1})|(\d{9})$/,
      phonePattern: /^(\([0-9]{2}\)[0-9]{5}-[0-9]{4})|(\d{11})$/,
      phonePattern2: /^(\([0-9]{2}\)[0-9]{4}-[0-9]{4})|(\d{10})$/,
      cepPattern: /^\d{5}-\d{3}|(\d{8})$/,
      mailPattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      sexArr: [
        { text: "Masculino", value: "M" },
        { text: "Feminino", value: "F" },
      ],
      empresas: [],
      categorias: [],
      dataSource: null,
      userSearched: null,
      showForm: inst.id !== null,
      showFormBtnText: "Cadastrar",
      cpfSearchVal: "",
      escolaridades: [
        "Infantil",
        "Fundamental",
        "Médio",
        "Superior (Graduação)",
        "Pós-graduação",
        "Mestrado",
        "Doutorado",
        "Pós-Doutorado",
      ],
      defaultSearchField: "CPF",
      showFuncionarioTab: false,
      showRegistrosTab: false,
      showPacienteTab: false,
      wizardBarSize: 38,
      hasRelationInvalidField: false,
      hasRegisterInvalidField: false,
      hasContactInvalidField: false,
      hasHistoryInvalidField: false,
      hasDataInvalidField: false,
      idUniqueCompany: null,
      restrictData: false,
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      maxDate: new Date(),
    };
  },
  mounted: function () {
    this.restrictData =
      this.id !== null
        ? this.id !== this.$root.getAuthData().userLogged
        : false;
    this.getEmpresas();
    this.loadCliente(this.id, this.company);
    this.getUsersFormSearch();
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    onFormSubmit(e) {
      e.preventDefault();

      if (!this.validaUserType()) return;
      if (this.formData.Funcionario && !this.validateFuncionarioData()) return;
      if (this.formData.PrestadorSaude && !this.validateProfissionalSaudeData())
        return;

      this.userSearched =
        this.userSearched !== null ? this.userSearched : this.id;

      if (this.userSearched === null) {
        this.$ConAPI
          .post(`${this.action}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.value, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.userSearched})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Alterado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.value, "error", 4000);
          });
      }
    },
    getCategorias: function (id) {
      this.$ConAPI
        .get(`TipoCategoria/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => {
          this.categorias = res.data;
        })
        .catch(function () {});
    },
    getEmpresas: function () {
      this.$ConAPI
        .get(
          `GPS_PessoaJuridica?%24orderby=RazaoSocial&%24select=Id%2CNomeFantasia%2CAtivo&%24filter=((Ativo%20eq%20true))`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.value !== null) {
            if (res.data.value.length === 1) {
              this.idUniqueCompany = res.data.value[0].Id;
              this.formData.IdPessoaJuridica = res.data.value[0].Id;
            }
          }
          this.empresas = res.data.value;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setFormData: function (v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined && v[value] !== undefined) {
          if (value === "RestringirDadosBasicos") {
            inst.formData[value] = v["RestringirDadosBasicos"] ? true : false;
          } else if (value === "_UF") {
            inst.formData[value] =
              v["UF"] !== "00000000-0000-0000-0000-000000000000"
                ? v["UF"]
                : null;
          } else if (value === "TelefoneCelular") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else if (value === "TelefoneResidencial") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else if (value === "TelefoneTrabalho") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else inst.formData[value] = v[value];
        }
      });
    },
    loadCliente: function (id, company) {
      if (id !== null) {
        this.$ConAPI
          .get(
            `/GPS_PessoaFisica/GetUsuarioEmpresa(Id=${id},Empresa=${company})`,
            {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
              },
            }
          )
          .then((res) => JSON.parse(res.request.response))
          .then((res) => JSON.parse(res.value))
          .then((odata) => {
            this.setFormData(odata);
            if (odata["DataNascimento"] === "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = null;
            } else if (odata["DataNascimento"] !== "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = odata["DataNascimento"];
            }

            this.restrictData =
              this.restrictData && this.formData.RestringirDadosBasicos;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadSearched: function (id) {
      if (id !== null) {
        this.$ConAPI
          .get(`/GPS_PessoaFisica/GetUsuarioPesquisado(id=${id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => JSON.parse(res.request.response))
          .then((res) => JSON.parse(res.value))
          .then((odata) => {
            this.setFormData(odata);
            if (odata["DataNascimento"] === "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = null;
            } else if (odata["DataNascimento"] !== "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = odata["DataNascimento"];
            }
            if (
              this.idUniqueCompany !== null &&
              this.formData.IdPessoaJuridica === null
            ) {
              this.formData.IdPessoaJuridica = this.idUniqueCompany;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    searchByCPF: function (e) {
      if (e.selectedItem !== null && e.selectedItem !== undefined) {
        this.showFormBtnText = "Usar Usuário encontrado";
        if (e.selectedItem.isSameCompany)
          this.loadCliente(
            e.selectedItem.id,
            this.$root.getAuthData().userCompany
          );
        else this.loadSearched(e.selectedItem.id);

        this.userSearched = e.selectedItem.id;
      } else {
        this.showFormBtnText = "Cadastrar";
        this.userSearched = null;
        this.formData = this.defaultFormData();
      }
    },
    defaultFormData: function () {
      return {
        Nome: "",
        CPF: "",
        Sexo: "M",
        Email: "",
        Ativo: true,
        Operadora: "",
        Plano: "",
        TelefoneResidencial: "",
        TelefoneCelular: "",
        TelefoneTrabalho: "",
        Endereco: "",
        Complemento: "",
        Bairro: "",
        Cidade: "",
        CEP: "",
        IdPessoaJuridica: this.$root.getAuthData().userCompany,
        Departamento: "",
        DataAdmissao: "",
        AtivoEmpresa: true,
        Foto: "",
        NovaSenha: "",
        Ocupacao: "",
        Identidade: "",
        Cargo: "",
        Etinia: "",
        Escolaridade: "",
        Funcionario: false,
        PrestadorSaude: false,
        Paciente: false,
        RegistrosMedico: [],
        _EspecialidadeMedico: [],
        RestringirDadosBasicos: false,
        Historico: "",
        DataNascimentoNova: null,
        Categoria: null,
        _UF: null,
        Pais: null,
      };
    },
    useFounded: function () {
      this.showForm = true;
    },
    cancelFounded: function () {
      this.showFormBtnText = "Cadastrar";
      this.cpfSearchVal = "";
      this.showForm = false;
      this.defaultFormData();
    },
    getUsersFormSearch: function () {
      const inst = this;
      let defaultSearch = {
        Nome: "",
        CPF: "",
        Empresa: this.$root.getAuthData().userCompany,
      };
      this.dataSource = new CustomStore({
        key: "Id",
        load: function (loadOptions) {
          if (loadOptions.searchValue.length > 3) {
            defaultSearch[loadOptions.searchExpr] = loadOptions.searchValue;
            return inst.$ConAPI
              .get(
                `/GPS_PessoaFisica/PesquisaUsuarios(Nome='${defaultSearch.Nome}', CPF='${defaultSearch.CPF}', Empresa=${defaultSearch.Empresa})`,
                {
                  headers: {
                    Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  },
                }
              )
              .then((res) => JSON.parse(res.request.response))
              .then((data) => {
                return {
                  data,
                };
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        },
      });
    },
    userTypeValueChange: function (e) {
      switch (e.element.id) {
        case "checkFuncionario":
          this.showFuncionarioTab = e.value;
          break;
        case "checkRegistro":
          this.showRegistrosTab = e.value;
          break;
        case "checkPaciente":
          this.showPacienteTab = e.value;
          break;
        default:
          break;
      }
      this.wizardBarSize = e.value
        ? this.wizardBarSize + 19
        : this.wizardBarSize - 19;
    },
    validateProfissionalSaudeData: function () {
      let registrosValid = true;
      this.hasRegisterInvalidField = false;
      if (
        this.formData.RegistrosMedico === null ||
        this.formData.RegistrosMedico === undefined ||
        this.formData.RegistrosMedico.length === 0
      ) {
        notify(
          `Os dados de registro profissional são obrigatórios`,
          "error",
          4000
        );
        this.hasRegisterInvalidField = true;
        return false;
      }

      for (let i = 0; i < this.formData.RegistrosMedico.length; i++) {
        const k = Object.keys(this.formData.RegistrosMedico[i]);
        for (let i2 = 0; i2 < k.length; i2++) {
          if (k[i2] !== "HistoricoProfissional") {
            if (
              this.formData.RegistrosMedico[i][k[i2]] === null ||
              this.formData.RegistrosMedico[i][k[i2]] === "" ||
              this.formData.RegistrosMedico[i][k[i2]] === undefined
            ) {
              notify(
                `O campo ${
                  k[i2] === "_UF" ? "UF Conselho" : k[i2]
                } é obrigatório`,
                "error",
                4000
              );
              this.hasRegisterInvalidField = true;
              registrosValid = false;
              break;
            }
          }
        }
      }

      return registrosValid;
    },
    validaUserType: function () {
      if (
        !this.showPacienteTab &&
        !this.showRegistrosTab &&
        !this.showFuncionarioTab
      ) {
        notify(
          "É necessário escolher o tipo de usuário para adicioná-lo",
          "error",
          4000
        );
        return false;
      }

      return true;
    },
    validateFuncionarioData: function () {
      this.hasRelationInvalidField = false;
      if (
        this.formData.Cargo === null ||
        this.formData.Cargo === undefined ||
        this.formData.Cargo === ""
      ) {
        notify(`O campo Cargo é obrigatório`, "error", 4000);
        this.hasRelationInvalidField = true;
        return false;
      }

      if (
        this.formData.DataAdmissao === null ||
        this.formData.DataAdmissao === undefined ||
        this.formData.DataAdmissao === ""
      ) {
        notify(`O campo Data Admissão é obrigatório`, "error", 4000);
        this.hasRelationInvalidField = true;
        return false;
      }

      if (
        this.formData.Departamento === null ||
        this.formData.Departamento === undefined ||
        this.formData.Departamento === ""
      ) {
        notify(`O campo Departamento é obrigatório`, "error", 4000);
        this.hasRelationInvalidField = true;
        return false;
      }

      return true;
    },
    validateDadosData: function () {
      if (
        this.formData.Nome === "" ||
        this.formData.Nome === undefined ||
        this.formData.Nome === null
      ) {
        this.hasDataInvalidField = true;
        notify(`O campo Nome é obrigatório`, "error", 4000);
        return false;
      } else {
        this.hasDataInvalidField = false;
      }

      if (
        this.formData.DataNascimentoNova === "" ||
        this.formData.DataNascimentoNova === null ||
        this.formData.DataNascimentoNova === undefined
      ) {
        this.hasDataInvalidField = true;
        notify(`O campo Data Nascimento é obrigatório`, "error", 4000);
        return false;
      } else {
        this.hasDataInvalidField = false;
      }

      if (
        this.formData.IdPessoaJuridica ===
          "00000000-0000-0000-0000-000000000000" ||
        this.formData.IdPessoaJuridica === undefined ||
        this.formData.IdPessoaJuridica === null
      ) {
        this.hasDataInvalidField = true;
        notify(`O campo E-mail é obrigatório`, "error", 4000);
        return false;
      } else {
        this.hasDataInvalidField = false;
      }

      if (
        this.formData.CPF === "" ||
        this.formData.CPF === undefined ||
        this.formData.CPF === null
      ) {
        this.hasDataInvalidField = true;
        notify(`O campo CPF é obrigatório`, "error", 4000);
        return false;
      } else if (this.formData.CPF.match(this.cpfPattern) === null) {
        notify(`O campo CPF está no formato errado`, "error", 4000);
        this.hasDataInvalidField = true;
        return false;
      } else {
        this.hasDataInvalidField = false;
      }

      if (
        this.formData.Email === "" ||
        this.formData.Email === undefined ||
        this.formData.CPF === null
      ) {
        this.hasDataInvalidField = true;
        notify(`O campo E-mail é obrigatório`, "error", 4000);
        return false;
      } else if (this.formData.Email.match(this.mailPattern) === null) {
        notify(`O campo E-mail está no formato errado`, "error", 4000);
        this.hasDataInvalidField = true;
        return false;
      } else {
        this.hasDataInvalidField = false;
      }
    },
    validateContatosData: function () {
      this.hasContactInvalidField = false;
      if (this.formData.TelefoneCelular !== null) {
        if (
          this.formData.TelefoneCelular !== "" &&
          this.formData.TelefoneCelular.match(this.phonePattern) === null
        ) {
          notify(`O campo Celular está no formato errado`, "error", 4000);
          this.hasContactInvalidField = true;
          return false;
        }
      }
      if (this.formData.TelefoneTrabalho !== null) {
        if (
          this.formData.TelefoneTrabalho !== "" &&
          this.formData.TelefoneTrabalho.match(this.phonePattern) === null
        ) {
          notify(
            `O campo Telefone Trabalho está no formato errado`,
            "error",
            4000
          );
          this.hasContactInvalidField = true;
          return false;
        }
      }
      if (this.formData.TelefoneResidencial !== null) {
        if (
          this.formData.TelefoneResidencial !== "" &&
          this.formData.TelefoneResidencial.match(this.phonePattern) === null
        ) {
          notify(
            `O campo Telefone Residencial está no formato errado`,
            "error",
            4000
          );
          this.hasContactInvalidField = true;
          return false;
        }
      }
      if (this.formData.CEP !== null) {
        if (
          this.formData.CEP !== "" &&
          this.formData.CEP.match(this.cepPattern) === null
        ) {
          notify(`O campo CEP está no formato errado`, "error", 4000);
          this.hasContactInvalidField = true;
          return false;
        }
      }
    },
    onValidated: function () {
      this.validateContatosData();
    },
    onValidatedFuncionario: function () {
      if (this.formData.Funcionario) {
        this.validateFuncionarioData();
      }
    },
    onValidatedProfSaude: function () {
      if (this.formData.PrestadorSaude) {
        this.validateProfissionalSaudeData();
      }
    },
    onEmpresaValueChanged: function (e) {
      this.getCategorias(e.value);
    },
  },
};
</script>

<style scoped>
.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.picedit_box {
  width: 100% !important;
}

.ico-picedit-camera {
  display: none !important;
}

.profile-data {
  display: none;
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.nav-pills {
  margin: 15px;
  display: flex;
  justify-content: center;
}

.icons-wizard {
  font-size: 20px;
}

.error-icon {
  color: red;
}

.card .card-header {
  float: unset !important;
}

@media (max-width: 480px) {
  .nav-pills {
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
  }

  .card .card-header {
    float: unset !important;
  }
}
</style>