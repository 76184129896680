<template>
  <div id="formulario">
    <form @submit="onFormSubmit($event)">
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div
          class="card-header card-header-icon"
          data-background-color="orange"
        >
          <i
            class="fa fa-building"
            aria-hidden="true"
            style="font-size: 20px"
          ></i>
        </div>
        <div class="card-content">
          <h4 class="card-title">Dados</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Razão Social*</label>
                <DxTextBox v-model="formData.RazaoSocial">
                  <DxValidator>
                    <DxRequiredRule message="Campo Obrigatório" />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label"
                  >Nome Fantasia (após inserir CNPJ)</label
                >
                <DxTextBox
                  v-model="formData.NomeFantasia"
                  :disabled="formData.CNPJ.length <= 11"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label class="control-label"
                  >CPF ou CNPJ* (somente os números)</label
                >
                <DxTextBox
                  v-model="formData.CNPJ"
                  @value-changed="hasNomeFantasia"
                  :max-length="14"
                >
                  <DxValidator>
                    <DxRequiredRule message="Campo Obrigatório" />
                    <!-- <DxStringLengthRule
                      :min="11"
                      message="CPF ou CNPJ precisa ter pelo menos 11 caracteres"
                    /> -->
                    <DxPatternRule
                      :pattern="cnpjPattern"
                      message="CPF ou CNPJ no formato inválido"
                    />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="control-label">Telefone (somente números)</label>
                <DxTextBox v-model="formData.Telefone">
                  <DxValidator>
                    <DxPatternRule
                      :pattern="phonePattern"
                      message="Telefone no formato inválido"
                    />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="col-md-1">
              <div class="form-group">
                <label class="control-label">Ativo</label>
                <DxSwitch class="switch" v-model="formData.Ativo" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="control-label"
                  >Token de acesso de aplicações de terceiros</label
                >
                <DxTextBox
                  v-model="formData.TokenAcessoAplicacaoTerceira"
                  :read-only="true"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <DxButton
                  id="regenToken"
                  text="Regerar Token"
                  class="btn btn-warning pull-right"
                  @click="generateToken"
                  >Regerar Token</DxButton
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">E-mail</label>
                <DxTextBox v-model="formData.Email">
                  <DxValidator>
                    <!-- <DxRequiredRule message="Campo Obrigatório" /> -->
                    <DxEmailRule message="E-mail no formato inválido" />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Site</label>
                <DxTextBox v-model="formData.Site" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Conselho</label>
                <DxSelectBox
                  :data-source="conselhosBoxData"
                  display-expr="Nome"
                  value-expr="Id"
                  v-model="formData._Conselho"
                  :searchEnabled="true"
                >
                </DxSelectBox>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Uf Conselho</label>
                <DxSelectBox
                  :data-source="ufsBoxData"
                  display-expr="Sigla"
                  value-expr="Id"
                  v-model="formData._UFConselho"
                  :searchEnabled="true"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Número do Registro</label>
                <DxTextBox v-model="formData.NumeroRegistro"> </DxTextBox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label class="control-label">Tipo Faturamento</label>
                <DxSelectBox
                  :data-source="tiposFaturamento"
                  display-expr="nome"
                  value-expr="id"
                  v-model="formData._TipoFaturamento"
                  @selection-changed="onTipoFaturamentoChanged"
                >
                  <DxValidator>
                    <DxRequiredRule message="Campo Obrigatório" />
                  </DxValidator>
                </DxSelectBox>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label class="control-label">Licensas Contratadas</label>
                <DxNumberBox
                  v-model="formData.LicencasContratadas"
                  :read-only="!hasLicencas"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="control-label">Envio Automatico E-mail</label>
                <DxSwitch
                  class="switch"
                  v-model="formData.EnvioAutomaticoEmail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">Matriz</label>
                <DxSelectBox
                  :data-source="matrizes"
                  display-expr="RazaoSocial"
                  value-expr="Id"
                  v-model="formData._Matriz"
                  :searchEnabled="true"
                  :show-clear-button="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <endereco :data="formData" />
      <div class="row">
        <div class="col-md-6">
          <div class="card card-profile">
            <div class="card-header">Logo da empresa</div>
            <div
              id="imageBox"
              class="companie-image-box"
              :class="{
                'companie-image-bkg-grey': !showPhoto,
                'companie-image-bkg-white': showPhoto,
              }"
            >
              <img
                id="profilePhoto"
                v-show="showPhoto"
                style="height: 60px"
                class="img"
                :src="photo"
              />
              <i
                class="fas fa-image default-image-icon"
                v-show="!showPhoto"
                aria-hidden="true"
              ></i>
            </div>
            <div class="card-content">
              <label class="control-label"
                >Extensões aceitas {{ imagesExtensions.join(",") }}</label
              >
              <DxFileUploader
                :multiple="false"
                label-text
                accept="image/*"
                upload-mode="instantly"
                select-button-text="Selecione um arquivo"
                invalid-file-extension-message="Extensão de arquivo não aceita"
                :upload-url="urlUploadImport"
                :allowed-file-extensions="imagesExtensions"
                :upload-headers="uploadImportHeader"
                :ref="photoFileUploaderRefKey"
                @uploaded="onUploaded"
                @value-changed="photoChanged"
              />
              <DxButton
                v-if="showPhoto"
                id="remove-photo-file"
                text="Remover arquivo"
                class="btn btn-secondary pull-right btn-back separated"
                @click="removeImage('Photos')"
                >Remover arquivo</DxButton
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-profile">
            <div class="card-header">Icone da logo da empresa</div>
            <div
              id="imageBoxIcon"
              class="companie-image-box img-icon-box"
              :class="{
                'companie-image-bkg-grey': !showIcon,
                'companie-image-bkg-white': showIcon,
              }"
            >
              <img
                id="compPhotoIcon"
                v-show="showIcon"
                style="height: 60px"
                class="img"
                :src="icon"
              />
              <i
                class="fas fa-image default-image-icon"
                v-show="!showIcon"
                aria-hidden="true"
              ></i>
            </div>
            <div class="card-content">
              <label class="control-label"
                >Extensões aceitas {{ imagesExtensions.join(",") }}</label
              >
              <DxFileUploader
                :multiple="false"
                label-text
                accept="image/*"
                upload-mode="instantly"
                select-button-text="Selecione um arquivo"
                invalid-file-extension-message="Extensão de arquivo não aceita"
                :upload-url="urlUploadImport"
                :allowed-file-extensions="imagesExtensions"
                :upload-headers="uploadImportHeader"
                :ref="iconFileUploaderRefKey"
                @uploaded="onUploaded"
                @value-changed="iconChanged"
              />
              <DxButton
                v-if="showIcon"
                id="remove-icon-file"
                text="Remover arquivo"
                class="btn btn-secondary pull-right btn-back separated"
                @click="removeImage('Icons')"
                >Remover arquivo</DxButton
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import {
  DxValidator,
  DxRequiredRule,
  DxPatternRule,
  DxEmailRule,
} from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import DxSelectBox from "devextreme-vue/select-box";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import notify from "devextreme/ui/notify";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import Guid from "devextreme/core/guid";
import { DxNumberBox } from "devextreme-vue/number-box";
import Endereco from "../../components/endereco.vue";

//mask="00.000.000/0000-00" mascara do campo cnpj

export default {
  name: "EmpresaForm",
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxPatternRule,
    DxEmailRule,
    DxButton,
    DxSwitch,
    DxSelectBox,
    DxFileUploader,
    DxNumberBox,
    Endereco,
  },
  props: {
    id: {
      default: null,
    },
  },
  data: function () {
    const inst = this;
    const matrizesBoxData = new DataSource({
      store: new ODataStore({
        url: `${inst.$UrlAPI}/GPS_PessoaJuridica`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.$root.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "RazaoSocial", "Ativo"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "RazaoSocial", desc: false },
      filter: [["Ativo", "=", true], "&", ["Id", "<>", this.id]],
    });
    const conselhosBoxData = new DataSource({
      store: new ODataStore({
        url: `${inst.$UrlAPI}/GPS_Conselho`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.$root.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "Nome"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Nome", desc: false },
    });

    return {
      action: "/GPS_PessoaJuridica",
      formData: {
        RazaoSocial: "",
        NomeFantasia: "",
        CNPJ: "",
        Telefone: "",
        Ativo: true,
        _Matriz: null,
        Endereco: "",
        Complemento: "",
        Bairro: "",
        Cidade: "",
        _UF: null,
        CEP: "",
        Foto: "",
        Icone: "",
        Email: "",
        Site: "",
        _UFConselho: null,
        _Conselho: null,
        NumeroRegistro: "",
        TokenAcessoAplicacaoTerceira: new Guid()._value,
        _TipoFaturamento: null,
        LicencasContratadas: 0,
        EnvioAutomaticoEmail: false,
        Pais: null,
      },
      matrizes: matrizesBoxData,
      phonePattern: /^(\([0-9]{2}\) [0-9]{4}-[0-9]{4})|(\d{11})|(\d{10})$/,
      cnpjPattern:
        /^([0-9]{2}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[\\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[-]?[0-9]{2})$/,
      cepPattern: /^\d{5}-\d{3}|(\d{8})$/,
      icon: "",
      photo: "",
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/UploadPhoto`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
      },
      showIcon: false,
      showPhoto: false,
      isIcon: false,
      conselhosBoxData,
      ufsBoxData: inst.$root.ufDataSource,
      photoFileUploaderRefKey: "photoFileUploader",
      iconFileUploaderRefKey: "iconFileUploader",
      cnpjLength: 0,
      mask: "000.000.000-00",
      rules: {
        // a single character
        S: "$",

        // a regular expression
        H: /[0-9A-F]/,

        // an array of characters
        N: ["$", "%", "&", "@"],

        // a function
        F: function (char) {
          return char == char.toUpperCase();
        },
      },
      imagesExtensions: [".jpg", ".png", ".jpeg"],
      hasLicencas: false,
      tiposFaturamento: [],
    };
  },
  mounted: function () {
    this.getTiposFaturamento();
    this.loadCompany();
  },
  computed: {
    photoFileUploader: function () {
      return this.$refs[this.photoFileUploaderRefKey].instance;
    },
    inconFileUploader: function () {
      return this.$refs[this.iconFileUploaderRefKey].instance;
    },
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    setFormData: function (v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    onFormSubmit(e) {
      if (this.hasLicencas && this.formData.LicencasContratadas < 1) {
        notify(
          "Para empresas cadastradas com tipo de volume é necessário cadastrar pelo menos 1 licença",
          "error",
          4000
        );
        return;
      }

      if (this.id === null) {
        this.$ConAPI
          .post(`${this.action}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.back();
          })
          .catch((err) => {
            const e = JSON.parse(err.request.response);
            notify(e.error.details.message, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            const a = this.$root.getAuthData();
            if (this.id === a.userCompany) {
              if (this.formData.Icone !== "") {
                a.companyIconPhoto = this.formData.Icone;
              }
              if (this.formData.Foto !== "") {
                a.companyPhoto = this.formData.Foto;
              }
              this.$root.setAuthData(a);
              this.$emit("updatePhotos", true);
            }
            notify("Alterado com sucesso", "success", 4000);
            this.back();
          })
          .catch((err) => {
            const e = JSON.parse(err.request.response);
            notify(e.error.details.message, "error", 4000);
          });
      }

      e.preventDefault();
    },
    loadCompany: function () {
      if (this.id !== null) {
        this.$ConAPI
          .get(`/GPS_PessoaJuridica(Id=${this.id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.setFormData(res.data);
            if (
              this.formData.TokenAcessoAplicacaoTerceira ===
              "00000000-0000-0000-0000-000000000000"
            ) {
              this.formData.TokenAcessoAplicacaoTerceira = new Guid()._value;
            }
            if (
              this.formData.Icone !== "" &&
              this.formData.Icone !== null &&
              this.formData.Icone !== undefined &&
              this.formData.Icone !== "null"
            ) {
              this.icon = `${this.$root.Controle.UrlAPI}/Upload/GetPhoto?type=icon&image=${this.formData.Icone}`;
              this.showIcon = true;
            }
            if (
              this.formData.Foto !== "" &&
              this.formData.Foto !== null &&
              this.formData.Foto !== undefined &&
              this.formData.Foto !== "null"
            ) {
              this.photo = `${this.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=${this.formData.Foto}`;
              this.showPhoto = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    iconChanged: function (e) {
      if (e.value.length > 0) {
        const inst = this;
        if (e.value.length) {
          var reader = new FileReader();
          reader.onload = function (e) {
            inst.icon = e.target.result;
          };
          reader.readAsDataURL(e.value[0]);
        }
        this.isIcon = true;
        this.showIcon = true;
      } else {
        this.showIcon = false;
      }
    },
    photoChanged: function (e) {
      if (e.value.length > 0) {
        const inst = this;
        if (e.value.length) {
          var reader = new FileReader();
          reader.onload = function (e) {
            inst.photo = e.target.result;
          };
          reader.readAsDataURL(e.value[0]);
        }
        this.isIcon = false;
        this.showPhoto = true;
      } else {
        this.showPhoto = false;
      }
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.status == 200) {
          notify(response.msg, "success", 4000);
          if (this.isIcon) this.formData.Icone = response.path;
          else this.formData.Foto = response.path;
        } else {
          notify(response.msg, "error", 4000);
        }
      }
    },
    removeImage: function (type) {
      this.$ConAPI
        .get(
          `/Upload/RemoveImage?image=${
            type === "Photos" ? this.formData.Foto : this.formData.Icone
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((res) => JSON.parse(res.request.response))
        .then((result) => {
          if (result.found) {
            notify(result.message, "success", 4000);
          }
          if (type === "Photos") {
            this.photoFileUploader.reset();
            this.formData.Foto = "";
          } else {
            this.inconFileUploader.reset();
            this.formData.Icone = "";
          }
        })
        .catch((err) => {
          const e = JSON.parse(err.request.response);
          notify(e.error.details.message, "error", 4000);
        });
    },
    hasNomeFantasia() {
      // this.cnpjLength = this.formData.CNPJ.length;
      if (this.formData.CNPJ.length <= 11) {
        this.formData.NomeFantasia = "";
      }
      this.$forceUpdate();
    },
    generateToken() {
      this.formData.TokenAcessoAplicacaoTerceira = new Guid()._value;
    },
    onTipoFaturamentoChanged(e) {
      if (e.selectedItem.porVolume !== "1")
        this.formData.LicencasContratadas = 0;

      this.hasLicencas = e.selectedItem.porVolume === "1";
    },
    getTiposFaturamento() {
      this.$ConAPI
        .get(`/TipoFaturamento`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => {
          this.tiposFaturamento = JSON.parse(res.request.response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.companie-image-box {
  height: 67px;
  width: 230px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}

.companie-image-bkg-grey {
  background-color: lightgrey;
}
.companie-image-bkg-white {
  background-color: white;
}

.img-icon-box {
  width: 80px;
  height: 80px;
  padding: 14px;
  border-radius: 40px;
  margin: 1px auto;
}

.default-image-icon {
  font-size: 50px;
  color: white;
}
</style>