<template>
  <div>
    <loading :loading="$root.loading" />
    <NoData
      :message.sync="mensagem"
      :use-logo="false"
      :icon="icon"
      :iconColor="iconColor"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading.vue";
import NoData from "@/components/no-data.vue";

export default {
  name: "UserValidateSolicitation",
  components: {
    Loading,
    NoData,
  },
  data: function () {
    return {
      mensagem: "",
      icon: "",
      iconColor: "",
    };
  },
  created: function () {
    this.approveReprove();
  },
  methods: {
    approveReprove: function () {
      const data = {
        id: this.$route.params.id,
        type: this.$route.params.approve,
      };
      const instance = this;

      this.$ConAPI
        .get(`/Permission/ValidatePermissionOwner/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((resposta) => {
          if (resposta) {
            instance.$ConAPI
              .post(`/Permission/UpdatePermission/`, data, {
                headers: {
                  Authorization: `Bearer ${this.$root.getAuthData().token}`,
                },
              })
              .then(() => {
                instance.icon = "fa-check-circle";
                instance.iconColor = "green";
                instance.mensagem =
                  instance.$route.params.approve === "A"
                    ? "autorizado com sucesso"
                    : "negado com sucesso";
              })
              .catch(function (error) {
                instance.icon = "fa-exclamation-circle";
                instance.iconColor = "red";
                instance.mensagem = error.response.data;
              });
          } else {
            window.localStorage.removeItem(
              instance.$root.$data.Controle.keyNav
            );
            instance.$router.push({
              path: "/login",
              query: { nextUrl: instance.$route.path },
            });
          }
        });
    },
  },
};
</script>

<style>
</style>