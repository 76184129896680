var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        {
          staticClass: "wrapper wrapper-full-page row",
          attrs: { id: "reset" }
        },
        [
          _c("div", { staticClass: "login-forms" }, [
            _c(
              "div",
              { staticClass: "form-login" },
              [
                _vm._m(0),
                _c("h4", [_vm._v("Redefina sua senha abaixo:")]),
                _c("senha", {
                  staticClass: "senha",
                  attrs: { validations: _vm.userData },
                  on: {
                    valid: function($event) {
                      _vm.isValid = $event
                    },
                    senha: function($event) {
                      _vm.password = $event
                    },
                    confirm: function($event) {
                      _vm.confirm = $event
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm.hasLogout
                      ? _c(
                          "DxButton",
                          {
                            staticClass:
                              "btn btn-warning btn-block btn-full btn-wd btn-lg",
                            attrs: { text: "Sair" },
                            on: { click: _vm.$root.logout }
                          },
                          [_vm._v("Sair")]
                        )
                      : _vm._e(),
                    _c(
                      "DxButton",
                      {
                        staticClass:
                          "btn btn-warning btn-block btn-full btn-wd btn-lg",
                        attrs: { text: "Salvar" },
                        on: { click: _vm.alterarSenha }
                      },
                      [_vm._v("Alterar senha")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("img", {
        staticStyle: { width: "80%" },
        attrs: { src: require("../assets/img/logo.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }