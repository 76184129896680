<template>
  <Grid
    :columns="columns"
    :action="action"
    class="grid-resize"
    @add-item="addItem"
    @edit-item="editItem"
  />
</template>

<script>
import Grid from "@/components/Grid.vue";

export default {
  name: "Pacote",
  components: {
    Grid,
  },
  data() {
    return {
      columns: [
        { name: "Id", notVisible: true },
        { name: "Nome", showColumn: true, defaultSort: true },
        { name: "Ativo", showColumn: true },
      ],
      action: "Gps_Pacote",
    };
  },
  methods: {
    addItem: function () {
      this.$router.push({ path: "/pacote/form" });
    },
    editItem: function (e) {
      this.$router.push({ path: `/pacote/form/${e.key.Id._value}` });
    },
  },
};
</script>

<style>
</style>