var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row vertical-center", staticStyle: { height: "100vh" } },
    [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticStyle: { "text-align": "center" } }, [
          _vm.useLogo
            ? _c("img", {
                staticStyle: { "max-width": "50%" },
                attrs: { src: require("../assets/img/logo.png") }
              })
            : _vm._e(),
          !_vm.useLogo
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.iconHtml) } })
            : _vm._e(),
          _vm.message !== ""
            ? _c("h4", [_vm._v(_vm._s(_vm.message))])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }