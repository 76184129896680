<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <DxTabPanel
          :data-source="tabs"
          :selected-index.sync="selectedIndex"
          :animation-enabled="true"
          :swipe-enabled="true"
        >
          <template #title="{ data: company }">
            <span>{{ company.name }}</span>
          </template>
          <template #item="{ data: company }">
            <div>
              <DadosIndicador
                v-if="company.name === 'Dados'"
                :id="id"
                :type="type"
                @indicador-adicionado="hasIndicadorCadastrado"
              />
              <FaixasIndicador
                v-if="company.name === 'Faixas Indicador'"
                :indicador="id"
              />
              <GrupoIndicador
                v-if="company.name === 'Módulos'"
                :indicador="id"
              />
              <DependenciasIndicador
                v-if="company.name === 'Dependências'"
                :indicador="id"
              />
            </div>
          </template>
        </DxTabPanel>
      </div>
    </div>
  </div>
</template>

<script>
import DxTabPanel from "devextreme-vue/tab-panel";
import DadosIndicador from "@/components/cadastro-indicadores/dados.vue";
import FaixasIndicador from "@/components/cadastro-indicadores/faixas.vue";
import GrupoIndicador from "@/components/cadastro-indicadores/grupos.vue";
import DependenciasIndicador from "@/components/cadastro-indicadores/dependencias.vue";

export default {
  name: "IndicadorForm",
  components: {
    DxTabPanel,
    DadosIndicador,
    FaixasIndicador,
    GrupoIndicador,
    DependenciasIndicador,
  },
  props: {
    id: {
      default: null,
    },
    type: {
      default: null,
      required: true,
    },
  },
  data: function () {
    return {
      selectedIndex: 0,
      tabs: [
        { name: "Dados", disabled: false },
        { name: "Módulos", disabled: true },
        { name: "Faixas Indicador", disabled: true },
        { name: "Dependências", disabled: true },
      ],
    };
  },
  created: function () {
    if (this.id !== null) {
      this.hasIndicadorCadastrado();
    }
  },
  methods: {
    hasIndicadorCadastrado() {
      this.tabs = [
        { name: "Dados", disabled: false },
        { name: "Módulos", disabled: false },
        { name: "Faixas Indicador", disabled: false },
        { name: "Dependências", disabled: false },
      ];
    },
  },
};
</script>

<style>
.card-header {
  text-align: left;
}
.default-indicador-image-icon {
  font-size: 50px;
  color: white;
  margin: 50% 0;
}
.indicador-image-box {
  height: 323px;
  width: 323px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}
.hide {
  display: none;
}
</style>

<style scoped>
.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.picedit_box {
  width: 100% !important;
}

.ico-picedit-camera {
  display: none !important;
}

.profile-data {
  display: none;
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.nav-pills {
  margin: 15px;
  display: flex;
  justify-content: center;
}

.icons-wizard {
  font-size: 20px;
}

.error-icon {
  color: red;
}

.btns-container {
  margin: 15px 0;
}

.add-remove-fields-btns {
  margin: 0 5px;
  padding: 3px;
  font-size: 11px;
}

.card .card-header {
  float: unset !important;
}

@media (max-width: 480px) {
  .nav-pills {
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
  }

  .card .card-header {
    float: unset !important;
  }
}
</style>