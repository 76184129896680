<template>
  <div style="display: flex; justify-content: space-between; width: 100%">
    <div class="logo-mobile">
      <img
          class="visible-xs"
          src="../../assets/img/logo.png"
          v-on:click="toPainel()"
      />
    </div>
    <company-photo
      :photo="$root.getAuthData().companyPhoto"
      :icon="$root.getAuthData().companyIconPhoto"
      class="visible-xs logo-mobile"
      style="margin-left: auto"
    />
  </div>
</template>

<script>
import CompanyPhoto from "@/components/logos/companyPhoto.vue";

export default {
  name: "TopoMobile",
  components: { CompanyPhoto },
  methods: {
    toPainel: function () {
      this.$emit("page-name", "Indicadores");
      this.$router.push({
        path: `/indicador-resumo`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.logo-mobile {
  width: 50%;
  img {
    max-width: 100%;
    height: auto;
  }
}

</style>
