<template>
  <div id="formulario">
    <form @submit="onFormSubmit($event)">
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="abrirPopupAlterarSenha"
                >Alterar Senha</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8" id="dados">
          <div class="card">
            <div
              class="card-header card-header-icon"
              data-background-color="orange"
            >
              <i
                class="fa fa-user"
                style="font-size: 20px"
                aria-hidden="true"
              ></i>
            </div>
            <div class="card-content">
              <h4 class="card-title">Dados</h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Nome*</label>
                    <DxTextBox v-model="formData.Nome">
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div id="dataNascimento" class="form-group">
                    <label class="control-label">Data Nascimento</label>
                    <DxDateBox
                      v-model="formData.DataNascimentoNova"
                      type="date"
                      display-format="dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <DxRadioGroup
                      :items="sexArr"
                      layout="horizontal"
                      v-model="formData.Sexo"
                      display-expr="text"
                      value-expr="value"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="control-label">Identidade</label>
                    <DxTextBox v-model="formData.Identidade" :maxLength="20">
                      <!-- <DxValidator>
                        <DxPatternRule
                          :pattern="identidadePattern"
                          message="Identidade no formato inválido"
                        />
                      :use-masked-value="false"
                      mask="00.000.000-0"
                      mask-invalid-message="Identidade no formato inválido"
                      </DxValidator> -->
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="control-label">CPF*</label>
                    <DxTextBox v-model="formData.CPF" :max-length="30">
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                        <!-- <DxPatternRule
                          :pattern="cpfPattern"
                          message="Cpf no formato inválido"
                        /> -->
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">E-mail*</label>
                    <DxTextBox v-model="formData.Email">
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                        <DxEmailRule message="E-mail no formato inválido" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">Situação</label>
                    <DxSwitch class="switch" v-model="formData.Ativo" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="1 !== 1">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Operadora</label>
                    <DxTextBox v-model="formData.Operadora" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Plano</label>
                    <DxTextBox v-model="formData.Plano" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" v-if="1 !== 1">
                  <div class="form-group">
                    <label class="control-label">Etnia</label>
                    <DxTextBox v-model="formData.Etinia" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Escolaridade</label>
                    <DxSelectBox
                      :items="escolaridades"
                      v-model="formData.Escolaridade"
                    />
                  </div>
                </div>
                <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
                <input
                  style="display: none"
                  type="text"
                  name="fakeusernameremembered"
                />
                <input
                  style="display: none"
                  type="password"
                  name="fakepasswordremembered"
                />
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Profissão</label>
                    <DxTextBox v-model="formData.Ocupacao" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="1 !== 1">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label restrict-data"
                      >Restringir alterações por terceiros (Profissionais de
                      Saúde , GPS MED ou parceiros)</label
                    >
                    <DxCheckBox v-model="formData.RestringirDadosBasicos" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 update">
          <div class="card card-profile">
            <div class="card-avatar">
              <img id="profilePhoto" class="img" :src="photo" />
            </div>
            <div class="card-content">
              <DxFileUploader
                ref="photoFileUploader"
                :multiple="false"
                label-text
                accept="image/*"
                upload-mode="instantly"
                select-button-text="Selecione um arquivo"
                invalid-file-extension-message="Extensão de arquivo não aceita"
                :upload-url="urlUploadImport"
                :allowed-file-extensions="['.jpg', '.png']"
                :upload-headers="uploadImportHeader"
                @uploaded="onUploaded"
                @value-changed="photoChanged"
              />
              <DxButton
                v-if="showPhoto"
                id="remove-photo-file"
                text="Remover arquivo"
                class="btn btn-secondary pull-right btn-back separated"
                @click="removeImage()"
                >Remover arquivo</DxButton
              >
            </div>
          </div>
        </div>
      </div>
      <div id="accordionExample">
        <dados-contato :data="formData" />
        <endereco :data="formData" />
      </div>
      <div class="card">
        <div class="card-content">
          <h4 class="card-title">Empresas vinculadas</h4>
          <div class="row">
            <DxDataGrid
              :data-source="dataCompanies"
              :columns="columns"
              :show-borders="true"
            />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="openAlterPass = true"
                >Alterar Senha</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <DxPopup
      :visible.sync="openAlterPass"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'90vh'"
      :height="'60vh'"
      title="Alterar Senha"
    >
      <senha
        :new-pass="openAlterPass"
        :validations="userData"
        @valid="isPasswordValid = $event"
        @senha="password = $event"
        @confirm="confirm = $event"
      />
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-6 text-center">
                <DxButton
                  text="Salvar"
                  class="btn btn-warning btn-block btn-full btn-wd btn-lg"
                  @click="openAlterPass = false"
                  >Fechar</DxButton
                >
              </div>
              <div class="col-md-6 text-center">
                <DxButton
                  text="Alterar Senha"
                  class="btn btn-warning btn-block btn-full btn-wd btn-lg"
                  @click="alterarSenha"
                  >Alterar Senha</DxButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import Guid from "devextreme/core/guid";
import DxButton from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import {
  DxValidator,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/validator";
import { DxSwitch } from "devextreme-vue/switch";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import notify from "devextreme/ui/notify";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxDataGrid } from "devextreme-vue/data-grid";
import Endereco from "../../components/endereco.vue";
import DadosContato from "../../components/dados-contato.vue";
import { DxPopup } from "devextreme-vue/popup";
import Senha from "../../components/senha.vue";

export default {
  name: "ProfileForm",
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxEmailRule,
    DxSwitch,
    DxRadioGroup,
    DxDateBox,
    DxSelectBox,
    DxFileUploader,
    DxCheckBox,
    DxDataGrid,
    Endereco,
    DadosContato,
    DxPopup,
    Senha,
  },
  props: {
    id: {
      default: null,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "/GPS_PessoaFisica",
      formData: inst.defaultFormData(),
      cpfPattern: /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{11})$/,
      identidadePattern: /^(\d{2}\.\d{3}\.\d{3}-\d{1})|(\d{9})$/,
      sexArr: [
        { text: "Masculino", value: "M" },
        { text: "Feminino", value: "F" },
      ],
      ufs: inst.$root.ufDataSource,
      paises: inst.$root.paisDataSource,
      pais: null,
      escolaridades: [
        "Infantil",
        "Fundamental",
        "Médio",
        "Superior (Graduação)",
        "Pós-graduação",
        "Mestrado",
        "Doutorado",
        "Pós-Doutorado",
      ],
      photoDefaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=`,
      photo: "",
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/UploadPhoto`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
      },
      showPhoto: false,
      columns: [
        { dataField: "nome", caption: "Contratante" },
        {
          dataField: "dataCadastro",
          caption: "Data Cadastro",
          dataType: "date",
          format: "dd/MM/yyyy",
        },
        { dataField: "telefone", caption: "Telefone" },
      ],
      dataCompanies: [],
      passName: new Guid()._value,
      openAlterPass: false,
      password: "",
      confirm: "",
      isPasswordValid: false,
      userData: [],
    };
  },
  mounted: function () {
    this.loadCliente();
    this.photo = `${this.photoDefaultPath}default-non-user-no-photo-1.jpg`;
    this.getCompaniesWithMyData()
      .then((result) => JSON.parse(result.request.response))
      .then((resposta) => {
        this.dataCompanies = resposta;
      })
      .catch((error) => notify(error.data.message, "error", 4000));
  },
  computed: {
    photoFileUploader: function () {
      return this.$refs["photoFileUploader"].instance;
    },
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    onFormSubmit(e) {
      console.log(this.id);
      if (this.id !== null) {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            const a = this.$root.getAuthData();
            a.profilePhoto = this.formData.Foto;
            a.loggedProfilePhoto = this.formData.Foto;
            a.userName = this.formData.Nome;
            a.userLoggedName = this.formData.Nome;
            this.$root.setAuthData(a);
            this.$emit("updatePhotos", true);
            notify("Alterado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.value, "error", 4000);
          });
      }
      e.preventDefault();
    },
    setFormData: function (v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);

        if (i !== null && i !== undefined && v[value] !== undefined) {
          if (value === "RestringirDadosBasicos") {
            inst.formData[value] = v["RestringirDadosBasicos"] ? true : false;
          } else if (value === "_UF") {
            inst.formData[value] =
              v["UF"] !== "00000000-0000-0000-0000-000000000000"
                ? v["UF"]
                : null;
          } else if (value === "TelefoneCelular") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else if (value === "TelefoneResidencial") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else if (value === "TelefoneTrabalho") {
            if (v[value] !== null)
              inst.formData[value] = v[value].replace(/[^A-Z0-9]/gi, "");
          } else {
            inst.formData[value] = v[value];
          }
        }
      });
    },
    loadCliente: function () {
      if (this.id !== null) {
        this.$ConAPI
          .get(`/GPS_PessoaFisica(Id=${this.id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => JSON.parse(res.request.response))
          .then((odata) => {
            this.setFormData(JSON.parse(odata.value));
            if (odata["DataNascimento"] === "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = null;
            } else if (odata["DataNascimento"] !== "0001-01-01T00:00:00") {
              this.formData["DataNascimentoNova"] = JSON.parse(odata.value)[
                "DataNascimento"
              ];
            }
            if (
              this.formData.Foto !== "" &&
              this.formData.Foto !== null &&
              this.formData.Foto !== undefined
            ) {
              this.showPhoto = true;
              this.photo = `${this.photoDefaultPath}${this.formData.Foto}`;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    defaultFormData: function () {
      return {
        Nome: "",
        CPF: "",
        Sexo: "M",
        Email: "",
        DataNascimentoNova: null,
        Ativo: true,
        Operadora: "",
        Plano: "",
        TelefoneResidencial: "",
        TelefoneCelular: "",
        TelefoneTrabalho: "",
        Endereco: "",
        Complemento: "",
        Bairro: "",
        Cidade: "",
        _UF: null,
        CEP: "",
        IdPessoaJuridica: null,
        Departamento: "",
        DataAdmissao: "",
        AtivoEmpresa: true,
        Foto: "",
        NovaSenha: "",
        Ocupacao: "",
        Identidade: "",
        Cargo: "",
        Etinia: "",
        Escolaridade: "",
        Funcionario: false,
        PrestadorSaude: false,
        Paciente: false,
        RegistrosMedico: [],
        _EspecialidadeMedico: [],
        Categoria: null,
        RestringirDadosBasicos: false,
        Historico: "",
        Pais: null,
      };
    },
    photoChanged: function (e) {
      if (e.value.length > 0) {
        const inst = this;
        if (e.value.length) {
          var reader = new FileReader();
          reader.onload = function (e) {
            inst.photo = e.target.result;
          };
          reader.readAsDataURL(e.value[0]);
        }
        this.showPhoto = true;
      } else {
        this.showPhoto = false;
        this.photo = `${this.photoDefaultPath}default-non-user-no-photo-1.jpg`;
      }
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.status == 200) {
          notify(response.msg, "success", 4000);
          this.formData.Foto = response.path;
        } else {
          notify(response.msg, "error", 4000);
        }
      }
    },
    compareWithPass: function () {
      return this.formData.NovaSenha;
    },
    removeImage: function () {
      this.$ConAPI
        .get(`/Upload/RemoveImage?image=${this.formData.Foto}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((result) => {
          if (result.found) {
            notify(result.message, "success", 4000);
          }
          this.photoFileUploader.reset();
          this.formData.Foto = "";
        })
        .catch((err) => {
          const e = JSON.parse(err.request.response);
          notify(e.error.details.message, "error", 4000);
        });
    },
    getCompaniesWithMyData: function () {
      return this.$ConAPI.get(
        `/Permission/GetCompaniesWithData/${this.$root.getAuthData().usuario}`,
        {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        }
      );
    },
    countryChanged(e) {
      this.$root.updateUfDatasourceBy(e.value._value);
    },
    abrirPopupAlterarSenha() {
      this.openAlterPass = true;
      this.$ConAPI
        .get(
          `/Gps_PessoaFisica/UserData(token='${
            this.$root.getAuthData().refresh
          }',id=${this.id})`
        )
        .then((r) => {
          this.userData = r.data.value;
        });
    },
    alterarSenha() {
      if (this.isPasswordValid) {
        this.$ConAPI
          .post(`/Gps_PessoaFisica/AlterarSenha`, {
            Id: this.id,
            Senha: this.password,
            Confirmacao: this.confirm,
          })
          .then(() => {
            this.openAlterPass = false;
            notify("Senha alterada com sucesso", "success", 4000);
          })
          .catch((value) => {
            notify(value.response.data.error.message, "error", 4000);
          });
      }
    },
  },
};
</script>

<style lang="scss">
#formulario {
  padding-bottom: 50px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}
.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.picedit_box {
  width: 100% !important;
}

.ico-picedit-camera {
  display: none !important;
}

.profile-data {
  display: none;
}

.restrict-data {
  margin-right: 7px !important;
}
</style>
