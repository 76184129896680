var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid", attrs: { id: "page-recomendacoes" } },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("IndicadoresFilters", {
        attrs: {
          "risco-default": this.id !== undefined && this.id !== null ? 0 : 3,
          "indicador-default": this.id !== null ? this.id : "0"
        },
        on: {
          "filter-values": function($event) {
            return _vm.getRecomendacoes($event)
          }
        }
      }),
      _vm._l(_vm.dados, function(tipoIndicador, i1) {
        return _c("div", { key: i1 }, [
          tipoIndicador.recomendacoes.length > 0
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selecaoIndicadores === "0",
                          expression: "selecaoIndicadores === '0'"
                        }
                      ],
                      staticClass: "col-sm-12 recomendacoes-sub"
                    },
                    [
                      _c("h4", [
                        _vm._v(
                          " " +
                            _vm._s(tipoIndicador.tipo) +
                            " " +
                            _vm._s(
                              tipoIndicador.exibeFaixaUnicoBloco
                                ? ""
                                : " - " + tipoIndicador.indicador
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _vm._l(
                    _vm.getSlicesFromRecomendations(
                      tipoIndicador.recomendacoes
                    ),
                    function(item, i2) {
                      return _c(
                        "div",
                        { key: i2, staticClass: "row" },
                        _vm._l(
                          tipoIndicador.exibeFaixaUnicoBloco
                            ? _vm.getItemWithHigherGrau(item)
                            : item,
                          function(grupoMedidor, i3) {
                            return _c("div", { key: i3 }, [
                              grupoMedidor.recomendacao !== null
                                ? _c("div", { staticClass: "col-sm-4" }, [
                                    _c("div", { staticClass: "card" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header card-header-icon card-header-recomendacao",
                                          style: {
                                            "background-color":
                                              grupoMedidor.cor,
                                            color: "white",
                                            "font-size": "18px"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas",
                                            class: {
                                              "fa-file-alt":
                                                grupoMedidor.grau < 3,
                                              "fa-file-text-o":
                                                grupoMedidor.grau >= 3
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "card-content" },
                                        [
                                          _c(
                                            "h4",
                                            {
                                              staticClass: "card-title",
                                              staticStyle: {
                                                "padding-left": "60px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    tipoIndicador.exibeFaixaUnicoBloco
                                                      ? tipoIndicador.indicador
                                                      : grupoMedidor.nome
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  grupoMedidor.recomendacao
                                                ) +
                                                " "
                                            )
                                          ]),
                                          grupoMedidor.link
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary btn-sm",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.verIndicacaoRecomendacao(
                                                        grupoMedidor.link
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" VER ")]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          }
                        ),
                        0
                      )
                    }
                  )
                ],
                2
              )
            : _vm._e()
        ])
      }),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openLinkPopup,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "200vh",
            height: "85vh",
            title: "Referência"
          },
          on: {
            "update:visible": function($event) {
              _vm.openLinkPopup = $event
            }
          }
        },
        [
          _c("iframe", {
            staticStyle: { height: "100%", width: "100%" },
            attrs: { src: _vm.frameSrc }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }