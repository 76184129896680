import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import Login from "./views/login.vue";
import IndicadoresExterno from "./views/indicadores-externo.vue";
import ptMessages from "devextreme/localization/messages/pt.json";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { locale, loadMessages } from "devextreme/localization";
import GAuth from 'vue-google-oauth2';
import Guid from "devextreme/core/guid";
import IdleVue from 'idle-vue'
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 15000
})
// import Vidle from 'v-idle';

// Vue.use(Vidle)

if (process.env.VUE_APP_IS_PROD !== 'true') {
  const gauthOption = {
    clientId: process.env.VUE_APP_GOOGLE_ID_CLIENT,
    scope: 'profile email',
    prompt: 'select_account'
  }

  Vue.use(GAuth, gauthOption)
}

Vue.config.productionTip = false;
Vue.prototype.$ConAPI = axios.create();
Vue.prototype.$NotLoadingCon = axios.create();
Vue.prototype.$UrlAPI = process.env.VUE_APP_APIURL;
Vue.prototype.$UrlApl = process.env.VUE_APP_APLURL;
Vue.prototype.$keyNav = process.env.VUE_APP_STORAGE_KEY;

Vue.prototype.$ExternalData = {};

window.addEventListener("message", messageHandler, false);
function messageHandler(event) {
  // if (window.VUE.$UrlApl.indexOf(event.origin) > -1) return;
  // if (event.data == "Deslogar") {
  //   window.localStorage.removeItem(window.VUE.$keyNav);
  //   return;
  // }
  // if (window.localStorage.getItem(window.VUE.$keyNav) == null)
  if (JSON.stringify(event.data).indexOf('Usuario') > -1) {
    window.VUE.LoginExterno(JSON.parse(event.data));
  }
}

var GPSUI = new Vue({
  router,
  Login,
  data: {
    versao: 2.0,
    Controle: {
      UrlAPI: Vue.prototype.$UrlAPI,
      Logado: false,
      TokenAcesso: "SEM ACESSO",
      RefreshToken: "Sem Acesso",
      NomeUsuario: "",
      Foto: "",
      keyNav: Vue.prototype.$keyNav,
      UserCompany: "",
      CompanyPhoto: "",
      CompanyIconPhoto: "",
    },
    loading: false,
    mobile: window.innerWidth <= 769,
    ufDataSource: null,
    paisDataSource: null,
    tipoIndicadoresDataSource: null,
    empresasDataSource: null,
    tipoFaturamentoDataSource: null,
    emailCategoriasDataSource: null,
    indicadores: [],
    indicadoresAdmin: [],
    isProduction: process.env.VUE_APP_IS_PROD === 'true',
    timerPermissionNotification: '',
    timerRefreshToken: '',
    timer: '',
    refreshTokenTime: 30000,
    isIdle: 0,
  },
  created() {
    this.setConnectionsDefaults();
    this.setConnectionInterceptions();
    this.setNotificationsInterceptions();

    addEventListener('resize', () => {
      this.mobile = innerWidth <= 768
    });

    loadMessages(ptMessages);
    locale(navigator.language);

    const inst = this;
    this.ufDataSource = new DataSource({
      store: new ODataStore({
        url: `${this.Controle.UrlAPI}/GPS_UF`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "Sigla"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Sigla", desc: false },

    });

    this.paisDataSource = new DataSource({
      store: new ODataStore({
        url: `${this.Controle.UrlAPI}/GPS_Pais`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "Nome", "Sigla"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Sigla", desc: false },
    });

    this.tipoFaturamentoDataSource = new DataSource({
      store: new CustomStore({
        key: "Id",
        load: function () {
          return inst.$ConAPI
            .get(`/TipoFaturamento`, {
              headers: {
                Authorization: `Bearer ${inst.$root.getAuthData().token}`,
              },
            })
            .then((res) => JSON.parse(res.request.response))
            .then((data) => {
              return {
                data,
              };
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        byKey: (key) => {
          return inst.$ConAPI
            .get(`/TipoFaturamento/${key}`, {
              headers: {
                Authorization: `Bearer ${inst.$root.getAuthData().token}`,
              },
            })
            .then((res) => JSON.parse(res.request.response))
            .then((data) => {
              return {
                data,
              };
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }),
      select: ["Id", "Nome", 'Faturado', 'PorVolume'],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Nome", desc: false },
    });

    this.tipoIndicadoresDataSource = new DataSource({
      store: new ODataStore({
        url: `${this.Controle.UrlAPI}/Gps_IndicadorTipo`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "Sigla"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Sigla", desc: false },
    });

    this.empresasDataSource = new DataSource({
      store: new ODataStore({
        url: `${this.Controle.UrlAPI}/GPS_PessoaJuridica`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "NomeFantasia", "RazaoSocial", "Ativo"],
      paginate: true,
      pageSize: 10,
      sort: { selector: "RazaoSocial", desc: false },
      filter: [["Ativo", "=", true]],
    });

    this.emailCategoriasDataSource = new DataSource({
      store: new ODataStore({
        url: `${this.Controle.UrlAPI}/Gps_EmailCategoria`,
        version: 4,
        key: "Id",
        keyType: {
          Id: "Guid",
        },
        beforeSend: function (e) {
          e.headers = {
            Authorization: `Bearer ${inst.getAuthData().token}`,
          };
        },
      }),
      select: ["Id", "Nome", 'ParametroTipo'],
      paginate: true,
      pageSize: 10,
      sort: { selector: "Nome", desc: false },
    });
  },
  onIdle() {
    this.isIdle = 1;
  },
  onActive() {
    this.isIdle = 0;
  },
  methods: {
    LoginExterno: function (acesso) {
      IndicadoresExterno.methods.loginExterno(acesso, this.$route.params.id);
    },
    getAuthData: function () {
      return JSON.parse(window.localStorage.getItem(this.$keyNav));
    },
    setAuthData: function (data) {
      window.localStorage.setItem(this.$keyNav, JSON.stringify(data));
    },
    sortBy: function (a, key, asc = true) {
      return a.slice().sort((a, b) => {
        if (asc) {
          if (a[key] < b[key]) {
            return -1;
          }
          if (b[key] < a[key]) {
            return 1;
          }
        } else {
          if (a[key] > b[key]) {
            return -1;
          }
          if (b[key] > a[key]) {
            return 1;
          }
        }
        return 0;
      });
    },
    isJson: function (txt) {
      if (
        /^[\],:{}\s]*$/.test(
          txt
            .replace(/\\["\\/bfnrtu]/g, "@")
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
              "]"
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    logout: function (invalidToken) {
      const auth = this.getAuthData();
      if (auth !== undefined && auth !== null && invalidToken !== undefined && invalidToken !== null) {
        this.$NotLoadingCon
          .get(`/acesso/logout`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }).then(() => {
            if (typeof (this.$gAuth) != 'undefined')
              this.$gAuth.signOut();
            window.localStorage.removeItem(this.Controle.keyNav);
            this.Controle.TokenAcesso = "SEM ACESSO";
            clearInterval(this.timer);
            if (!this.mobile) this.$router.push({ path: "/" });
            else window.location = this.$UrlApl + "/";
          });
      } else {
        if (typeof (this.$gAuth) != 'undefined')
          this.$gAuth.signOut();
        window.localStorage.removeItem(this.Controle.keyNav);
        this.Controle.TokenAcesso = "SEM ACESSO";
        clearInterval(this.timer);
        if (!this.mobile) this.$router.push({ path: "/" });
        else window.location = this.$UrlApl + "/";
      }
    },
    setConnectionsDefaults() {
      this.$ConAPI.defaults.baseURL = this.Controle.UrlAPI;
      this.$ConAPI.defaults.headers.common["Authorization"] =
        "Bearer " + this.Controle.TokenAcesso;
      this.$ConAPI.defaults.headers.post["Content-Type"] = "application/json";
      this.$ConAPI.defaults.method = "post";

      this.$NotLoadingCon.defaults.baseURL = this.Controle.UrlAPI;
      this.$NotLoadingCon.defaults.method = "get";
    },
    setConnectionInterceptions() {
      this.$ConAPI.interceptors.request.use(
        (config) => {
          this.addRefreshTokenToHeaderConnection(config);
          this.loading = true;
          return config;
        },
        (error) => {
          this.loading = false;
          return Promise.reject(error);
        }
      );
      this.$ConAPI.interceptors.response.use(
        (response) => {
          this.loading = false;
          return response;
        },
        (error) => {
          this.loading = false;
          this.doLogoutWhenUnauthorized(error);
          return Promise.reject(error);
        }
      );
    },
    setNotificationsInterceptions() {
      this.$NotLoadingCon.interceptors.request.use(
        (config) => {
          this.addRefreshTokenToHeaderConnection(config);
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      this.$NotLoadingCon.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          this.doLogoutWhenUnauthorized(error);
          return Promise.reject(error);
        }
      );
    },
    resetLoggedUser() {
      const a = this.$root.getAuthData();
      a.usuario = a.userLogged;
      a.userName = a.userLoggedName;
      a.profilePhoto = a.loggedProfilePhoto;
      this.setAuthData(a);
    },
    updateUfDatasourceBy(country) {
      this.$root.ufDataSource.filter(["Pais.Id", "=", new Guid(country)]);
      this.$root.ufDataSource.load();
    },
    addRefreshTokenToHeaderConnection(config) {
      if (this.getAuthData())
        config.headers.common["Token"] = this.getAuthData().refresh;

      config.headers.common["idle"] = this.isIdle;
    },
    doLogoutWhenUnauthorized(error) {
      if (error.response.status === 401) {
        if (error.response.data.error)
          notify(error.response.data.error.message, "error", 4000)
        this.logout(error.response.config.url.indexOf('logout') > -1)
        return;
      }

      if (error.response.status === 404) {
        if (!this.mobile) this.$router.push({ path: "/" });
        else window.location = this.$UrlApl + "/";
        return;
      }
    },
    refreshToken() {
      const inst = this;
      this.timer = setInterval(function () {
        const a = inst.getAuthData();
        if (a !== undefined && a !== null) {
          inst.$NotLoadingCon
            .get(`/acesso/refresh-token`, {
              headers: {
                Authorization: `Bearer ${a.token}`,
              },
            })
            .then((res) => JSON.parse(res.request.response))
            .then((resposta) => {
              const a = inst.getAuthData();
              if (a !== undefined && a !== null) {
                a.refresh = resposta.refresh;
                a.token = resposta.token;
                if (a.aceiteTermo) inst.getPendingPermissions();
                inst.setAuthData(a);
              }
            });
        }
      }, this.refreshTokenTime);
    },
    getPendingPermissions() {
      const a = this.$root.getAuthData();
      this.$NotLoadingCon
        .get(`/Permission/CountPendingPermissions/${a.usuario}`, {
          headers: {
            Authorization: `Bearer ${a.token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((resposta) => {
          if (
            this.firstAttempt &&
            resposta.count > 0 &&
            this.$root.getAuthData().usuario ===
            this.$root.getAuthData().userLogged
          ) {
            this.firstAttempt = false;
            let result = confirm(
              `<i>Existe${resposta.count > 1 ? "m" : ""} ${resposta.count
              } permiss${resposta.count > 1 ? "ões" : "ão"
              } pendentes de avaliação. Deseja avaliá-las agora?</i>`,
              "Permissões para avaliar"
            );
            result.then((dialogResult) => {
              if (dialogResult) {
                this.$router.push({ path: "/permissions" });
              }
            });
          }
          this.pendingPermissions = resposta.data;
          this.countPendingPermissions = resposta.count;
        });
    },
  },
  render: (h) => h(App),
});

GPSUI.$mount("#app");
window.VUE = GPSUI;
