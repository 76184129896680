var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          columns: _vm.columns,
          action: _vm.action,
          reload: _vm.reloadGrid,
          "other-buttons": _vm.otherButtons,
          "parent-id-field": _vm.parentIdField,
          "filter-value": _vm.filterValue,
          "filter-field": _vm.filterField,
          hasDetail: true,
          "can-reorder-by-drag": true,
          "can-sort": false
        },
        on: { "add-item": _vm.addItem, "edit-item": _vm.editItem },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var master = ref.master
              return [
                _c("Grid", {
                  attrs: {
                    slot: "relations-tab",
                    columns: _vm.detailColumns,
                    "other-buttons": _vm.medidorOtherButtons,
                    action: "Gps_IndicadorMedidor",
                    "parent-id-field": "_IndicadorGrupo",
                    "filter-value": master.data.Id,
                    "filter-field": "IndicadorGrupo.Id",
                    "edit-mode": "popup",
                    "init-inserting-row": _vm.initInsertingRowMedidor,
                    "can-reorder-by-drag": true,
                    "can-sort": false,
                    "customize-columns": _vm.customizeColumns
                  },
                  slot: "relations-tab"
                })
              ]
            }
          }
        ])
      }),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openForm,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "85vh",
            height: "70vh",
            title: _vm.grupoPopupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openForm = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("GrupoIndicadorForm", {
                attrs: {
                  indicador: _vm.indicador,
                  id: _vm.grupoId,
                  "reload-data": _vm.grupoReload
                },
                on: { close: _vm.fecharPopupTipo }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openFaixaMedidorForm,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "faixas-grupo",
            width: 900,
            height: 500,
            title: _vm.faixaMedidorPopupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openFaixaMedidorForm = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("FaixasIndicador", {
                attrs: { indicador: _vm.indicador, medidor: _vm.medidorFaixaId }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openFaixaGrupoForm,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "faixas-grupo",
            width: 900,
            height: 500,
            title: _vm.faixaGrupoPopupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openFaixaGrupoForm = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("FaixasIndicador", {
                attrs: { indicador: _vm.indicador, grupo: _vm.grupoFaixaId }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openOpcaoMedidor,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "faixas-grupo",
            width: 900,
            height: 500,
            title: _vm.opcaoMedidorPopupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openOpcaoMedidor = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("Grid", {
                staticClass: "grid-resize",
                attrs: {
                  columns: _vm.opcaoColumns,
                  action: "GPS_Opcao",
                  reload: _vm.reloadGridOpcao,
                  "parent-id-field": "_IndicadorMedidor",
                  "filter-value": _vm.medidorId,
                  "filter-field": "IndicadorMedidor.Id",
                  "edit-mode": "batch",
                  "init-inserting-row": _vm.initInsertingRowOpcao
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }