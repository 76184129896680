<template>
  <div class="card">
    <div class="card-header">
      <h3>Resultado mais recente</h3>
    </div>
    <div class="card-content">
      <div class="row">
        <DxChart
          id="chart"
          :data-source="chartData"
          :customize-point="customizePoint"
        >
          <DxCommonSeriesSettings
            :ignore-empty-points="true"
            argument-field="name"
            value-field="value"
            type="bar"
            :barWidth="20"
          />
          <DxSeriesTemplate name-field="name" />
          <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
            <DxLabel>
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxValueAxis :max="100" />
          <DxLegend :visible="false" />
          <DxTooltip :enabled="true" />
          <DxSize :height="200" />
          <!-- <DxAdaptiveLayout :height="200" :width="768" /> -->
        </DxChart>
      </div>
      <div class="row">
        <table class="table">
          <tbody>
            <tr v-for="(item, idx) in data" :key="idx">
              <td scope="col">
                {{ idx + 1 }}
              </td>
              <td scope="col">
                {{ item.name }}
              </td>
              <td scope="col">
                <i
                  class="fas fa-compass"
                  :style="{
                    color: item.cor ? item.cor : 'gray',
                    'font-size': '20px',
                  }"
                  aria-hidden="true"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxSeriesTemplate,
  DxSize,
  DxValueAxis,
} from "devextreme-vue/chart";
import notify from "devextreme/ui/notify";

export default {
  name: "CardGraficoGrupo",
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxLabel,
    DxSeriesTemplate,
    DxFormat,
    DxSize,
    DxValueAxis,
  },
  props: {
    id: {
      default: null,
      required: true,
    },
  },
  data: function () {
    return {
      data: [],
      chartData: [],
      loaded: false,
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      const id =
        this.$root.getAuthData().defaultSystem === "Cliente"
          ? this.$root.getAuthData().usuario
          : this.$root.getAuthData().userCompany;
      const type = this.$root.getAuthData().defaultSystem === "Cliente" ? 3 : 8;
      this.$ConAPI
        .get(`/DadosIndicadores/GetIndicadorData/${type}/${id}/${this.id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.data =
            resposta.Grupos !== null
              ? resposta.Grupos.filter((x) => x.visible)
              : [];
          this.chartData = this.data.reduce((p, c, i) => {
            p.push({ name: i + 1, value: c.value, cor: c.cor });
            return p;
          }, []);
          this.loaded = true;
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    customizePoint: function (e) {
      return { color: e.data.cor };
    },
  },
};
</script>

<style scoped>
#chart {
  height: 75%;
  width: 100%;
}
</style>