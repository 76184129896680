<template>
  <div class="card">
    <div class="card-header">
      <h3>Resultado Evolutivo</h3>
    </div>
    <div class="card-content">
      <DxChart id="chart3" class="chart" :data-source="data" palette="Violet">
        <DxCommonAxisSettings>
          <DxGrid :visible="true" />
        </DxCommonAxisSettings>
        <DxSeries argument-field="Data" value-field="Value" type="line" />
        <DxMargin :bottom="20" />
        <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
          <DxLabel :customize-text="customizeLabel">
            <DxFormat type="decimal" />
          </DxLabel>
        </DxArgumentAxis>
        <DxLegend :visible="false" />
        <DxTooltip :enabled="true" />
      </DxChart>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonAxisSettings,
  DxGrid,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from "devextreme-vue/chart";
import notify from "devextreme/ui/notify";

export default {
  name: "CardGraficoEvolutivoMedicao",
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonAxisSettings,
    DxGrid,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
  },
  props: {
    id: {
      default: null,
      required: true,
    },
  },
  data: function () {
    return {
      data: [],
      loaded: false,
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/5/${
            this.$root.getAuthData().usuario
          }/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.data = resposta.Medicoes[0].Dados;
          this.loaded = true;
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    customizeLabel: function (e) {
      const d = new Date(e.value);
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
};
</script>

<style>
</style>