<template>
  <Grid
    class="grid-resize"
    :columns="columns"
    :action="action"
    :filter-value="getFilterValue()"
    :filter-field="'PessoaJuridica.Id'"
    :editMode="'batch'"
    :init-inserting-row="initInsertingRow"
  />
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
// import ODataStore from "devextreme/data/odata/store";

export default {
  name: "Categorias",
  components: {
    Grid,
  },
  data() {
    const inst = this;
    return {
      columns: [
        { name: "Id", notVisible: true },
        { name: "Nome", showColumn: true, defaultSort: true },
        // {
        //   name: "_IdPessoaJuridica",
        //   caption: "Empresa",
        //   showColumn: true,
        //   lookup: {
        //     allowClearing: true,
        //     dataSource: {
        //       store: new ODataStore({
        //         url: `${inst.$UrlAPI}/GPS_PessoaJuridica`,
        //         version: 4,
        //         key: ["Id"],
        //         keyType: {
        //           Id: "Guid",
        //         },
        //         beforeSend: function (e) {
        //           e.headers = {
        //             Authorization: `Bearer ${inst.$root.getAuthData().token}`,
        //           };
        //         },
        //       }),
        //       select: ["Id", "RazaoSocial", "Ativo"],
        //       filter: [
        //         ["Ativo", "=", true],
        //         "&",
        //         ["Id", "=", new Guid(inst.$root.getAuthData().userCompany)],
        //       ],
        //       sort: [{ selector: "RazaoSocial", desc: false }],
        //     },
        //     displayExpr: "RazaoSocial",
        //     valueExpr: "Id",
        //   },
        // },
        { name: "Ativo", showColumn: true, type: "boolean" },
        {
          name: "Travado",
          notVisible: !inst.$root.getAuthData().acessoGPS,
          type: "boolean",
        },
      ],
      action: "GPS_PessoaFisicaTipoCategoria",
      initInsertingRow: function (e) {
        e.data = {
          Ativo: true,
          Travado: false,
        };
      },
    };
  },
  methods: {
    getFilterValue: function () {
      return new Guid(this.$root.getAuthData().userCompany);
    },
  },
};
</script>

<style>
</style>