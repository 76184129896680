var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          columns: _vm.columns,
          action: _vm.action,
          "other-buttons": _vm.otherButtons,
          "filter-field": _vm.filterField,
          "filter-value": _vm.filterValue,
          reload: _vm.reloadGrid
        },
        on: { "add-item": _vm.addItem, "edit-item": _vm.editItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }