var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$root.getAuthData().defaultSystem === "Admin"
      ? _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group label-floating" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Riscos")
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        items: _vm.riscoCombo,
                        "display-expr": "Name",
                        "value-expr": "id"
                      },
                      on: { "value-changed": _vm.onValueChange },
                      model: {
                        value: _vm.historicoDataFilters.grau,
                        callback: function($$v) {
                          _vm.$set(_vm.historicoDataFilters, "grau", $$v)
                        },
                        expression: "historicoDataFilters.grau"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group label-floating" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Modulos")
                    ]),
                    _c("DxSelectBox", {
                      attrs: {
                        items: _vm.medidores,
                        "display-expr": "nome",
                        "value-expr": "id"
                      },
                      on: { "value-changed": _vm.onValueChange },
                      model: {
                        value: _vm.historicoDataFilters.medidor,
                        callback: function($$v) {
                          _vm.$set(_vm.historicoDataFilters, "medidor", $$v)
                        },
                        expression: "historicoDataFilters.medidor"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded"
            }
          ],
          staticClass: "card-content"
        },
        [
          _c(
            "DxDataGrid",
            {
              attrs: {
                "data-source": _vm.medicoes,
                "show-borders": false,
                "column-hiding-enabled": true,
                height: "450",
                width: "95%",
                loadPanel: { enabled: true },
                columnAutoWidth: true
              },
              on: {
                initialized: _vm.saveGridInstance,
                "row-removed": _vm.onRowRemoved,
                "toolbar-preparing": function($event) {
                  return _vm.onToolbarPreparing($event)
                },
                "row-prepared": _vm.onRowPrepared
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function(ref) {
                    return [
                      _c("div", { staticClass: "informer" }, [
                        _c("h4", [_vm._v(" " + _vm._s("Histórico") + " ")])
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "DxEditing",
                {
                  attrs: {
                    refreshMode: "full",
                    mode: "row",
                    useIcons: true,
                    "allow-deleting": _vm.canRemove
                  }
                },
                [
                  _c("DxTexts", {
                    attrs: {
                      "confirm-delete-message":
                        "Deseja realmente excluir a medição?"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.colunasMedicoes, function(item, index) {
                return _c("DxColumn", {
                  key: index,
                  attrs: {
                    "data-field": item.dataField,
                    "data-type": item.dataType,
                    format: item.format,
                    sortIndex: item.sortIndex,
                    sortOrder: item.sortOrder,
                    customizeText: item.customizeText
                  }
                })
              }),
              _vm.$root.getAuthData().defaultSystem !== "Admin"
                ? _c(
                    "DxColumn",
                    { attrs: { type: "buttons" } },
                    [
                      _c("DxButton", {
                        attrs: {
                          "item-template": "default",
                          visible: _vm.isFilledByOther,
                          icon: "user",
                          "on-click": _vm.verPopupUser,
                          hint: "Preenchido por outro usuário"
                        }
                      }),
                      _c("DxButton", {
                        attrs: {
                          "item-template": "default",
                          visible:
                            _vm.$root.getAuthData().defaultSystem === "Admin" &&
                            1 != 1,
                          text: "Ver",
                          "on-click": this.isBiomedic
                            ? this.verPopupMedidor
                            : this.verPopupGrupo,
                          hint: "Preenchido por outro usuário"
                        }
                      }),
                      _c("DxButton", { attrs: { name: "delete" } })
                    ],
                    1
                  )
                : _vm._e(),
              _c("DxPager", {
                attrs: { "show-page-size-selector": false, "show-info": true }
              }),
              _c("DxPaging", { attrs: { "page-size": 5 } }),
              _c("DxSearchPanel", {
                attrs: {
                  visible: _vm.$root.getAuthData().defaultSystem === "Admin",
                  width: 240,
                  placeholder: "Procurar..."
                }
              })
            ],
            2
          ),
          _c(
            "DxPopup",
            {
              attrs: {
                visible: _vm.popupUserVisible,
                "drag-enabled": false,
                "close-on-outside-click": true,
                showTitle: false,
                showCloseButton: false,
                width: 400,
                height: 200,
                fullScreen: _vm.$root.mobile,
                id: "tutorial-popup"
              },
              on: {
                "update:visible": function($event) {
                  _vm.popupUserVisible = $event
                },
                "content-ready": _vm.onContentReady
              }
            },
            [
              _c("div", { staticClass: "row user-control" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "" +
                        _vm.defaultPath +
                        (_vm.userControlData.Foto !== null &&
                        _vm.userControlData.Foto !== undefined
                          ? _vm.userControlData.Foto
                          : _vm.defaultImage)
                    }
                  })
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", [_vm._v("Nome:")]),
                  _c("h4", [_vm._v(_vm._s(_vm.userControlData.Nome))])
                ])
              ])
            ]
          ),
          _c(
            "DxPopup",
            {
              attrs: {
                visible: _vm.popupVisible,
                "drag-enabled": false,
                "close-on-outside-click": true,
                showTitle: false,
                showCloseButton: false,
                width: 900,
                height: 450,
                fullScreen: _vm.$root.mobile,
                id: "tutorial-popup"
              },
              on: {
                "update:visible": function($event) {
                  _vm.popupVisible = $event
                },
                "content-ready": _vm.onContentReady
              }
            },
            [
              _c(
                "DxScrollView",
                {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    showScrollbar: !_vm.isBiomedic ? "always" : "onScroll",
                    "scroll-by-content": !_vm.$root.mobile,
                    "scroll-by-thumb": _vm.$root.mobile
                  }
                },
                [
                  _c("DadosMedicao", {
                    attrs: {
                      data: _vm.dadosMedicaoData,
                      "data-medicao": _vm.dataMedicao,
                      indicador: _vm.indicador,
                      "is-biomedic": _vm.isBiomedic,
                      person: _vm.pessoaMedicao
                    },
                    on: {
                      close: function($event) {
                        _vm.popupVisible = !_vm.popupVisible
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }