<template>
  <div class="photo">
    <div
      class="gestor"
      v-if="
        $root.getAuthData().acessoGPS &&
          $root.getAuthData().defaultSystem === 'Admin'
      "
    >
      <i class="fas fa-suitcase"></i>
    </div>
    <div
      class="gestor"
      v-if="$root.getAuthData().userName !== $root.getAuthData().userLoggedName"
    >
      <i class="fas fa-quote-right"></i>
    </div>

    <img
      :src="img"
      style="
    height: 100%;
    object-fit: cover;"
    />
  </div>
</template>

<script>
export default {
  name: "ProfilePhoto",
  props: {
    foto: {
      default: "",
    },
    reload: {
      default: true,
    },
  },
  data: function() {
    const inst = this;
    return {
      img: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=default-non-user-no-photo-1.jpg`,
      defaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=`,
      defaultImage: "default-non-user-no-photo-1.jpg",
    };
  },
  watch: {
    foto: function(v) {
      this.setPhoto(v);
    },
  },
  mounted: function() {
    this.setPhoto(this.foto);
  },
  methods: {
    setPhoto: function(photo) {
      if (photo !== "" && photo !== undefined && photo !== null) {
        this.img = `${this.defaultPath}${photo}`;
      } else {
        this.img = `${this.defaultPath}${this.defaultImage}`;
      }
    },
  },
};
</script>

<style scoped>
.badge {
  position: absolute;
  left: 150px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}
</style>
