<template>
  <div class="container visible-xs" id="menu-dimensoes" v-if="isHome">
    <div class="">
      <p class="px-4">Clique nos ícones abaixo para seguir sua jornada<br>
        rumo a uma vida mais saudável, produtiva e feliz:</p>
      <div class="row menuMobile">
        <div class="col-xs-4 text-center">
          <a href="#mente" class="controle">
            <span class="fas fa-star"></span>
            <span class="titulo">Mente</span>
          </a>
        </div>
        <div class="col-xs-4 text-center">
          <a href="#estilo-de-vida" class="controle">
            <span class="fas fa-hand-peace"></span>
            <span class="titulo">Estilo de vida</span>
          </a>
        </div>
        <div class="col-xs-4 text-center">
          <a href="#corpo" class="controle">
            <span class="fas fa-heartbeat"></span>
            <span class="titulo">Corpo</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "menu-dimensoes",
  props: ["isHome"],
};
</script>

<style lang="scss" scoped>
#menu-dimensoes {
  background: orange;
  padding: 20px 0 0;
  color: #fff !important;
  //border-bottom: 1px solid #999999;
  margin: 5px 15px 20px;
  margin-right: 20px;
  border-radius: 10px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  p {
    padding: 0 20px;
    font-size: 11px;
  }
  a {
    color: #fff !important;
  }
}

.menuMobile {
  @media (max-width: 680px) {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }
  margin: 0;
  margin-top: 20px;

  > div {
    display: block;
  }

  .controle {
    color: orange;
    display: inline-block;
    text-align: center;
    font-size: 4rem;
    margin-bottom: 20px;
    cursor: pointer;

    &.desabilitado {
      color: gray !important;
      pointer-events: none;
      cursor: none;
    }

    span.fas {
      font-size: 4rem !important;
      display: block !important;
      margin: auto;
    }

    span.titulo {
      font-size: 1.2rem;
      display: block;
    }
  }
}
</style>
