<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="row relatorio-card-header">
          <div class="col-md-4 col-sm-12">
            <h4 v-if="!getModuleData">{{ indicador.Indicador }}</h4>
          </div>
          <div class="col-md-8 col-sm-12">
            <div class="infos-indicador">
              <div class="ultima-medicao">
                <h6>última medição:</h6>
                <span>{{ indicador.ultimaMedicaoData }}</span>
              </div>
            </div>

            <div class="indicador-btns">
              <router-link
                v-if="showRecommendationButton"
                class="btn btn-warning"
                :to="`/recomendacoes/${indicador.idIndicador}`"
                ><i class="fas fa-lightbulb"></i>
                <span>Recomendações</span>
              </router-link>
              <div
                v-show="
                  indicador.usaMedidor &&
                  indicador.Referencias[0].Medidores.find(
                    (x) => x.ValoresReferencia.length > 0
                  ) !== undefined
                "
                class="btn btn-warning"
                @click="popupVisible = !popupVisible"
              >
                <i class="fas fa-sliders-h"></i>
                <span>Valores de referência</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="row" style="margin: unset">
              <CardTableData
                v-if="loadedData"
                :data="tableData"
                :type="tipoTabela"
                :selected-keys="firstSelected"
                @selection-change="selectionChange"
                :key="indicador.Id"
                :unique-block="indicador.exibeFaixaUnicoBloco"
              />
            </div>
          </div>
          <div :class="'col-md-8'">
            <GraficoMedicoes
              v-if="loadedData"
              :dados="chartData"
              :type-data="tipoTabela"
              :receive-data="true"
              :key="indicador.Id"
              :indicador="tipoTabela !== 'Grupos' ? indicador.Indicador : ''"
              :cor-ultima-medicao="indicador.corUltimaMedicao"
            />
          </div>
        </div>
      </div>
    </div>
    <DxPopup
      :full-screen="$root.mobile"
      :visible.sync="popupVisible"
      :show-close-button="true"
      title="Valores de Referência"
      height="450px"
    >
      <DxScrollView
        width="100%"
        :height="!$root.mobile ? '380px' : 'inherit'"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <TabelaReferencias
          :show-title="false"
          :data="indicador.Referencias[0]"
          :exibe-faixa-unico-bloco="indicador.exibeFaixaUnicoBloco"
        />
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import CardTableData from "@/components/relatorio-indicadores/tabela-dados-card.vue";
import GraficoMedicoes from "@/components/relatorio-indicadores/grafico-medicoes.vue";
import TabelaReferencias from "@/components/relatorio-indicadores/tabela-referencias.vue";
import { DxPopup } from "devextreme-vue/popup";
import notify from "devextreme/ui/notify";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "RelatorioConteudo",
  components: {
    CardTableData,
    GraficoMedicoes,
    TabelaReferencias,
    DxPopup,
    DxScrollView,
  },
  props: {
    resource: {
      default: function () {
        return [];
      },
      requeired: true,
    },
    getModuleData: {
      default: false,
    },
    reload: {
      default: false,
    },
    showRecommendationButton: {
      default: true,
    },
  },
  data: function () {
    return {
      indicador: null,
      medidores: [],
      tipoTabela: "",
      chartWidth: 400,
      chartHeight: 300,
      data: [],
      selectedKeys: [],
      firstSelected: [],
      chartData: [],
      ultimaMedicao: "",
      popupVisible: false,
      loadedData: false,
    };
  },
  computed: {
    tableData: function () {
      if (
        this.indicador[this.tipoTabela] !== null &&
        this.indicador[this.tipoTabela] !== undefined
      )
        return this.indicador[this.tipoTabela].filter((x) => x.visible);
      else return [];
    },
  },
  watch: {
    resource: function (v) {
      this.setData(v);
    },
    reload: function () {
      this.setData(this.resource);
    },
  },
  created: function () {
    this.setData(this.resource);
  },
  methods: {
    getTipoTabela: function (a) {
      this.tipoTabela = a ? "Grupos" : "Medidores";
      return this.tipoTabela;
    },
    selectionChange: function (e) {
      this.selectedKeys = e;
      this.chartData =
        this.indicador[this.tipoTabela] !== null &&
        this.indicador[this.tipoTabela] !== undefined
          ? this.indicador[this.tipoTabela].filter(
              (x) => e.find((y) => y === x.Id) !== undefined
            )
          : [];
    },
    setData: function (indicador) {
      this.indicador = indicador;
      this.getTipoTabela(this.indicador.usaGrupo);
      if (this.getModuleData) {
        this.getData(indicador);
      } else {
        this.loadedData = true;
        if (this.indicador.exibeFaixaUnicoBloco) {
          this.firstSelected.concat(
            this.indicador[this.tipoTabela]
              .filter((x) => x.visible)
              .map((value) => value.Id)
          );
          this.chartData = this.indicador[this.tipoTabela];
        } else {
          this.firstSelected.push(
            this.indicador[this.tipoTabela].filter((x) => x.visible)[0].Id
          );
          this.chartData = this.indicador[this.tipoTabela].filter(
            (x) => x.Id === this.firstSelected[0]
          );
        }
      }
    },
    getData: function (indicador) {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/9/${
            this.$root.getAuthData().usuario
          }/${indicador.idIndicador}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => JSON.parse(result.request.response))
        .then((resposta) => {
          if (resposta.length > 0) {
            this.indicador = { ...indicador, ...resposta[0] };
            if (this.indicador.exibeFaixaUnicoBloco) {
              this.indicador[this.tipoTabela]
                .filter((x) => x.visible)
                .map((value) => {
                  this.firstSelected.push(value.Id);
                });
              this.chartData = this.indicador[this.tipoTabela];
            } else {
              if (
                this.indicador[this.tipoTabela].filter((x) => x.visible)
                  .length > 0
              ) {
                this.firstSelected.push(
                  this.indicador[this.tipoTabela].filter((x) => x.visible)[0].Id
                );
                this.chartData = this.indicador[this.tipoTabela].filter(
                  (x) => x.Id === this.firstSelected[0]
                );
              }
            }
            this.loadedData = true;
          } else {
            this.loadedData = false;
          }
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    setDefaultSelected: function () {
      if (this.indicador.exibeFaixaUnicoBloco) {
        this.firstSelected.concat(
          this.indicador[this.tipoTabela]
            .filter((x) => x.visible)
            .map((value) => value.Id)
        );
      } else {
        this.firstSelected.push(
          this.indicador[this.tipoTabela].filter((x) => x.visible)[0].Id
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  height: 150px;
  width: 100%;
}
#chart1 {
  height: 300px;
  width: 100%;
}
.relatorio-card-header {
  h4 {
    color: orange;
    font-weight: 700;
    font-size: 2.15rem;
    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.btn,
.navbar .navbar-nav > li > a.btn {
  text-transform: initial;
}
</style>
