var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collapse navbar-collapse" }, [
    _c(
      "div",
      {
        staticClass: "nav navbar-nav navbar-right",
        class: [_vm.hasCombos ? "nav-w-combo" : "nav-s-combo"]
      },
      [
        _c("alter-user-combo", {
          key: "alter-user-combo" + _vm.alteredSystem,
          staticClass: "nav-combo",
          on: { "change-user": _vm.changeUser }
        }),
        _c("alter-company-combo", {
          key: "alter-company-combo" + _vm.alteredSystem,
          staticClass: "nav-combo",
          on: {
            "empresa-selecionada": function($event) {
              return _vm.$emit("empresa-selecionada", $event)
            }
          }
        }),
        _c("alter-system-action", {
          key: "alter-system" + _vm.alteredUser,
          on: { "altered-system": _vm.chengeSystem }
        }),
        _c("profile", {
          class: _vm.isAcessedByHealthWorker() ? "desabilitado" : ""
        }),
        _c("permissions", {
          key: "permissions-action" + _vm.alteredUser,
          class: _vm.isAcessedByHealthWorker() ? "desabilitado" : "",
          attrs: {
            disabled: _vm.isAcessedByHealthWorker(),
            "notification-counter": _vm.notificationCounter
          }
        }),
        _c("contacts-action"),
        _c("logout-action")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }