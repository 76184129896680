<template>
  <div>
    <div
      :class="{ 'menu-item': !$root.mobile, controle: $root.mobile }"
      @click="$root.logout"
      @mouseenter="logoutVisible = !logoutVisible"
      @mouseleave="logoutVisible = !logoutVisible"
      class="text-orange"
    >
      <i id="logout" class="fas fa-sign-out-alt orange" aria-hidden="true"></i>
      <span class="titulo" v-if="$root.mobile">Sair</span>
      <DxTooltip
        v-if="!$root.mobile"
        :visible="logoutVisible"
        :close-on-outside-click="false"
        target="#logout"
        >Sair</DxTooltip
      >
    </div>
  </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  name: "LogoutAction",
  components: { DxTooltip },
  props: {
    columnClass: {
      default: "col-xs-4",
    },
  },
  data: function () {
    return {
      logoutVisible: false,
    };
  },
};
</script>

<style scoped>
.menu-item {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.menu-item-m {
  color: inherit;
  font-weight: 500;
  font-size: 15px;
  /* text-transform: uppercase; */
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 35px;
  cursor: pointer;
}

.text-orange {
  color: #ff9000;
}
</style>