<template>
  <div class="container-fluid" id="page-recomendacoes">
    <loading :loading="$root.loading" />
    <IndicadoresFilters
      :risco-default="this.id !== undefined && this.id !== null ? 0 : 3"
      :indicador-default="this.id !== null ? this.id : '0'"
      @filter-values="getRecomendacoes($event)"
    />
    <div v-for="(tipoIndicador, i1) in dados" :key="i1">
      <div class="row" v-if="tipoIndicador.recomendacoes.length > 0">
        <div
          class="col-sm-12 recomendacoes-sub"
          v-show="selecaoIndicadores === '0'"
        >
          <h4>
            {{ tipoIndicador.tipo }}
            {{
              tipoIndicador.exibeFaixaUnicoBloco
                ? ""
                : " - " + tipoIndicador.indicador
            }}
          </h4>
        </div>
        <div
          class="row"
          v-for="(item, i2) in getSlicesFromRecomendations(
            tipoIndicador.recomendacoes
          )"
          :key="i2"
        >
          <div
            v-for="(grupoMedidor, i3) in tipoIndicador.exibeFaixaUnicoBloco
              ? getItemWithHigherGrau(item)
              : item"
            :key="i3"
          >
            <div class="col-sm-4" v-if="grupoMedidor.recomendacao !== null">
              <div class="card">
                <div
                  class="card-header card-header-icon card-header-recomendacao"
                  :style="{
                    'background-color': grupoMedidor.cor,
                    color: 'white',
                    'font-size': '18px',
                  }"
                >
                  <i
                    class="fas"
                    :class="{
                      'fa-file-alt': grupoMedidor.grau < 3,
                      'fa-file-text-o': grupoMedidor.grau >= 3,
                    }"
                  ></i>
                </div>
                <div class="card-content">
                  <h4 class="card-title" style="padding-left: 60px">
                    {{
                      tipoIndicador.exibeFaixaUnicoBloco
                        ? tipoIndicador.indicador
                        : grupoMedidor.nome
                    }}
                  </h4>
                  <p>
                    {{ grupoMedidor.recomendacao }}
                  </p>
                  <button
                    class="btn btn-primary btn-sm"
                    v-if="grupoMedidor.link"
                    @click="verIndicacaoRecomendacao(grupoMedidor.link)"
                  >
                    VER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DxPopup
      :visible.sync="openLinkPopup"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'200vh'"
      :height="'85vh'"
      :title="'Referência'"
    >
      <iframe :src="frameSrc" style="height: 100%; width: 100%"></iframe>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from "devextreme-vue/popup";
import notify from "devextreme/ui/notify";
import IndicadoresFilters from "@/components/indicadores-filters.vue";
import Loading from "../components/loading.vue";

export default {
  name: "RecomendacoesCliente",
  components: {
    DxPopup,
    IndicadoresFilters,
    Loading,
  },
  props: {
    id: {
      default: null,
    },
  },
  data: function () {
    return {
      selecaoIndicadores: "0",
      selecaoRisco: 3,
      indicadoresCombo: [
        {
          id: "0",
          Name: "Todos os Indicadores",
        },
      ],
      riscoCombo: [
        { id: 0, Name: "Todos níveis de risco" },
        { id: 3, Name: "Alto risco" },
        { id: 2, Name: "Médio risco" },
        { id: 1, Name: "Baixo risco" },
      ],
      dados: [],
      firstAttempt: false,
      teste: false,
      openLinkPopup: false,
      frameSrc: "",
    };
  },
  created: function () {
    this.firstAttempt = this.id === null;
    this.selecaoRisco = this.id !== undefined && this.id !== null ? 0 : 3;
  },
  mounted: function () {
    this.getRecomendacoes({
      risco: this.id !== undefined && this.id !== null ? 0 : 3,
      indicador: this.id !== null ? this.id : "0",
    });
  },
  methods: {
    getIndicadoresCombo: function (items) {
      items.map((value1) => {
        value1.indicadores.map((value2) => {
          this.indicadoresCombo.push({
            id: value2.id,
            Name: `${value1.sigla} - ${value2.nome}`,
          });
        });
      });
    },
    getRecomendacoes: function (ev) {
      this.$ConAPI
        .post(
          `/Recomendacoes/GetRecomendacoes/`,
          {
            user: this.$root.getAuthData().usuario,
            risco: ev.risco,
            indicador: ev.indicador,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          if (
            result.request.response !== null &&
            result.request.response !== undefined &&
            result.request.response !== ""
          )
            return JSON.parse(result.request.response);
          else {
            notify(
              "Não existem dados para o indicador selecionado",
              "error",
              4000
            );
            this.selecaoIndicadores = "0";
          }
        })
        .then((resposta) => {
          if (resposta !== null && resposta !== undefined) {
            if (this.id !== null && this.id === ev.indicador) {
              this.$emit("breadcrumb-page-name", [
                { name: resposta[0].tipo },
                {
                  name: resposta[0].indicador,
                  link: `/indicador/${this.$route.params.id}`,
                },
              ]);
            }
            this.dados = resposta;
          } else {
            notify(
              "Não existem dados para o indicador selecionado",
              "error",
              4000
            );
          }
        })
        .catch(function () {
          notify("Não foi possível carregar as recomendações.", "error", 4000);
        });
    },
    getSlicesFromRecomendations: function (r) {
      let b = [];
      const indexes = Math.ceil(r.length / 3);
      for (let index = 0; index < indexes; index++) {
        b.push(r.slice(index * 3, (index + 1) * 3));
      }
      return b;
    },
    verIndicacaoRecomendacao: function (link) {
      this.openLinkPopup = true;
      this.frameSrc = link;
      // window.open(link, "_blank");
    },
    // onIndicadoresChanged: function (e) {
    //   if (e.event !== undefined)
    //     this.getRecomendacoes(this.selecaoRisco, e.value);
    // },
    // onRiscoChanged: function (e) {
    //   if (e.event !== undefined)
    //     this.getRecomendacoes(e.value, this.selecaoIndicadores);
    // },
    getComboIndicadores: function () {
      this.$ConAPI
        .get(`/Menu/GetMenu/U/${this.$root.getAuthData().usuario}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.getIndicadoresCombo(resposta);

          this.getRecomendacoes(
            this.selecaoRisco,
            this.id !== null ? this.id : "0"
          );
          this.selecaoIndicadores = this.id !== null ? this.id : "0";
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getItemWithHigherGrau: function (arr) {
      const a = arr.reduce((p, c) => {
        if (p.grau > c.grau) return p;
        if (p.grau < c.grau) return c;
        if (p.grau == c.grau) return c;
      });
      return [a];
    },
  },
};
</script>

<style lang="scss">
#page-recomendacoes {
  padding-bottom: 50px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}
.card-header-recomendacao {
  float: left;
}
</style>
