<template>
  <div>
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Nome*</label>
            <DxTextBox v-model="formData.Nome">
              <DxValidator :ref="'validator'">
                <DxRequiredRule message="Campo Obrigatório" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Ordem</label>
            <DxNumberBox v-model="formData.Ordem" :read-only="true" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Status</label>
            <DxSwitch v-model="formData.Ativo" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Mostra Gráfico</label>
            <DxSwitch v-model="formData.MostrarGrafico" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Link</label>
            <DxTextBox v-model="formData.Link" :mode="'url'" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Peso</label>
            <DxNumberBox v-model="formData.Peso" :min="0" :max="99999999" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Casas Decimais</label>
            <DxNumberBox
              v-model="formData.CasasDecimais"
              :min="0"
              :max="9999"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Valor Total Grupo</label>
            <DxNumberBox
              v-model="formData.ValorTotalGrupo"
              :min="0"
              :max="99999999"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Help</label>
            <DxTextArea v-model="formData.help" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-profile">
            <div
              id="imageBox"
              class="grupo-image-box"
              :class="{
                'companie-image-bkg-grey': !showPhoto,
                'companie-image-bkg-white': showPhoto,
              }"
            >
              <img
                id="profilePhoto"
                v-show="showPhoto"
                class="img"
                :src="photo"
              />
              <span v-show="!showPhoto">
                <i
                  class="fas fa-image default-grupo-image-icon"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <div class="card-content">
              <DxFileUploader
                ref="photoFileUploader"
                :multiple="false"
                label-text
                accept="image/*"
                upload-mode="instantly"
                select-button-text="Selecione um arquivo"
                invalid-file-extension-message="Extensão de arquivo não aceita"
                :upload-url="urlUploadImport"
                :allowed-file-extensions="['.jpg', '.png']"
                :upload-headers="uploadImportHeader"
                @uploaded="onUploaded"
                @value-changed="photoChanged"
              />
              <DxButton
                v-if="showPhoto"
                id="remove-icon-file"
                text="Remover arquivo"
                class="btn btn-secondary pull-right btn-back separated"
                @click="removeImage('Photos')"
                >Remover arquivo</DxButton
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <DxButton
            id="save"
            text="Salvar"
            class="btn btn-warning pull-right"
            @click="Save"
            >Salvar</DxButton
          >
          <DxButton
            id="back"
            text="Cancel"
            class="btn btn-warning pull-right"
            @click="cancel"
            >Cancelar</DxButton
          >
          <div class="clearfix"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import DxFileUploader from "devextreme-vue/file-uploader";
import DxTextArea from "devextreme-vue/text-area";
import notify from "devextreme/ui/notify";

export default {
  name: "GrupoIndicadorForm",
  components: {
    DxTextBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxSwitch,
    DxFileUploader,
    DxTextArea,
  },
  props: {
    indicador: {
      default: null,
      required: true,
    },
    id: {
      default: null,
    },
    reloadData: {
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "Gps_IndicadorGrupo",
      formData: inst.defaultFormData(),
      photoDefaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=grupos&image=`,
      photo: "",
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/UploadPhoto`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
      },
      showPhoto: false,
    };
  },
  computed: {
    validator: function () {
      return this.$refs["validator"].instance;
    },
    photoFileUploader: function () {
      return this.$refs["photoFileUploader"].instance;
    },
  },
  watch: {
    id: function (v) {
      this.loadData(v);
    },
    reloadData: function () {
      this.loadData(this.id);
    },
  },
  methods: {
    defaultFormData() {
      return {
        _Indicador: this.indicador,
        Ordem: 0,
        Nome: "",
        help: "",
        Peso: 0,
        Imagem: "",
        Link: "",
        MostrarGrafico: false,
        Ativo: true,
        CasasDecimais: 0,
        ValorTotalGrupo: 0,
      };
    },
    cancel() {
      this.formData = this.defaultFormData();
      this.$emit("close", true);
    },
    setFormData(v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    loadData(id) {
      if (id !== null) {
        this.$ConAPI
          .get(`/${this.action}(Id=${id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.photoFileUploader.reset();
            this.setFormData(res.data);
            if (
              this.formData.Imagem !== "" &&
              this.formData.Imagem !== null &&
              this.formData.Imagem !== undefined &&
              this.formData.Imagem !== "null"
            ) {
              this.photo = `${this.photoDefaultPath}${this.formData.Imagem}`;
              this.showPhoto = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.formData = this.defaultFormData();
        this.photoFileUploader.reset();
      }
    },
    Save: function () {
      const v = this.validator.validate();
      if (v.isValid) {
        if (this.id === null) {
          this.$ConAPI
            .post(`/${this.action}`, this.formData, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Adicionado com sucesso", "success", 4000);
              this.formData = this.defaultFormData();
              this.$emit("close", true);
            })
            .catch(function (error) {
              notify(error.response.value, "error", 4000);
            });
        } else {
          this.$ConAPI
            .patch(`/${this.action}(Id=${this.id})`, this.formData, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Alterado com sucesso", "success", 4000);
              this.formData = this.defaultFormData();
              this.$emit("close", true);
            })
            .catch(function (error) {
              notify(error.response.value, "error", 4000);
            });
        }
      }
    },
    photoChanged: function (e) {
      if (e.value.length > 0) {
        const inst = this;
        if (e.value.length) {
          var reader = new FileReader();
          reader.onload = function (e) {
            inst.photo = e.target.result;
          };
          reader.readAsDataURL(e.value[0]);
        }
        this.showPhoto = true;
      } else {
        this.showPhoto = false;
      }
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.status == 200) {
          notify(response.msg, "success", 4000);
          this.formData.Imagem = response.path;
        } else {
          notify(response.msg, "error", 4000);
        }
      }
    },
    removeImage: function (type) {
      this.$ConAPI
        .get(
          `/Upload/RemoveImage?image=${
            type === "Photos" ? this.formData.Imagem : this.formData.Icone
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((res) => JSON.parse(res.request.response))
        .then((result) => {
          if (result.found) {
            notify(result.message, "success", 4000);
          }
          if (type === "Photos") {
            this.photoFileUploader.reset();
            this.formData.Imagem = "";
          } else {
            this.inconFileUploader.reset();
            this.formData.Icone = "";
          }
        })
        .catch((err) => {
          const e = JSON.parse(err.request.response);
          notify(e.error.details.message, "error", 4000);
        });
    },
  },
};
</script>

<style>
.card-header {
  text-align: left;
}
.default-grupo-image-icon {
  font-size: 50px;
  color: white;
  margin: 50% 0;
}
.grupo-image-box {
  height: 323px;
  width: 323px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}
.hide {
  display: none;
}
</style>