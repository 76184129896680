var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isHome
    ? _c(
        "div",
        {
          staticClass: "container visible-xs",
          attrs: { id: "menu-dimensoes" }
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("p", { staticClass: "px-4" }, [
        _vm._v("Clique nos ícones abaixo para seguir sua jornada"),
        _c("br"),
        _vm._v(" rumo a uma vida mais saudável, produtiva e feliz:")
      ]),
      _c("div", { staticClass: "row menuMobile" }, [
        _c("div", { staticClass: "col-xs-4 text-center" }, [
          _c("a", { staticClass: "controle", attrs: { href: "#mente" } }, [
            _c("span", { staticClass: "fas fa-star" }),
            _c("span", { staticClass: "titulo" }, [_vm._v("Mente")])
          ])
        ]),
        _c("div", { staticClass: "col-xs-4 text-center" }, [
          _c(
            "a",
            { staticClass: "controle", attrs: { href: "#estilo-de-vida" } },
            [
              _c("span", { staticClass: "fas fa-hand-peace" }),
              _c("span", { staticClass: "titulo" }, [_vm._v("Estilo de vida")])
            ]
          )
        ]),
        _c("div", { staticClass: "col-xs-4 text-center" }, [
          _c("a", { staticClass: "controle", attrs: { href: "#corpo" } }, [
            _c("span", { staticClass: "fas fa-heartbeat" }),
            _c("span", { staticClass: "titulo" }, [_vm._v("Corpo")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }