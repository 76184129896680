<template>
  <div>
    <div
      :class="{ 'menu-item': !$root.mobile, controle: $root.mobile }"
      @click="contactPage"
      @mouseenter="contactHintVisible = !contactHintVisible"
      @mouseleave="contactHintVisible = !contactHintVisible"
      class="text-orange"
    >
      <i
        v-if="!$root.mobile"
        class="fas fa-question-circle orange"
        id="suporte"
      ></i>
      <span v-if="$root.mobile" class="fas fa-question-circle"></span>
      <span class="titulo" v-if="$root.mobile">{{ text }}</span>
      <DxTooltip
        v-if="!$root.mobile"
        :visible="contactHintVisible"
        :close-on-outside-click="false"
        target="#suporte"
        >{{ text }}</DxTooltip
      >
    </div>
  </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  name: "ContactsACtion",
  components: {
    DxTooltip,
  },
  props: {
    columnClass: {
      default: "col-xs-4",
    },
  },
  data: function () {
    return {
      contactHintVisible: false,
      text: "Ajuda",
    };
  },
  methods: {
    contactPage: function () {
      window.open("https://ajuda.gpsmed.com.br", "_blank");
    },
  },
};
</script>

<style scoped>
.menu-item {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 3px;
  position: relative;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.menu-item-m {
  color: inherit;
  font-weight: 500;
  font-size: 15px;
  /* text-transform: uppercase; */
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 35px;
  cursor: pointer;
}

.text-orange {
  color: #ff9000;
}
</style>
