<template>
    <div
        @click="goToProfile()"
        :class="{ 'menu-item': !$root.mobile, 'controle': $root.mobile }"
        class="text-orange"
        @mouseenter="ProfileVisible = !ProfileVisible"
        @mouseleave="ProfileVisible = !ProfileVisible"
    >
      <i id="profile" v-if="!$root.mobile" class="fas fa-user"></i>
      <i v-if="$root.mobile" class="fas fa-user"></i>
      <span v-if="$root.mobile" class="titulo">Perfil</span>
      <DxTooltip
          v-if="!$root.mobile"
          :visible="ProfileVisible"
          :close-on-outside-click="false"
          target="#profile"
      >Visualizar Perfil</DxTooltip>
    </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {

  name: "Profile",
  components: {
    DxTooltip,
  },
  data: function () {
    return {
      ProfileVisible: false,
    }
  },
  methods: {
    goToProfile() {
      if (
          this.$root.getAuthData().usuario === this.$root.getAuthData().userLogged
      ) {
        this.$emit("page-name", "Perfil");
        this.$router.push({
          name: `ProfileForm`,
          params: {id: this.$root.getAuthData().usuario},
        });
      }
    }
  },
}
</script>

<style scoped>
.text-orange {
  color: orange;
}
.controle {
  cursor: pointer;
  color: orange;
}
</style>
