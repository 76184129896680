<template>
  <div class="content" id="page-indicador">
    <ControlesIndicadores></ControlesIndicadores>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="card card-product">
                <div class="card-image" data-header-animation="false">
                  <a href="#">
                    <img class="img" :src="img" />
                  </a>
                  <div class="ripple-container"></div>
                </div>
                <div class="card-content">
                  <div style="width: 100%">
                    <router-link
                      :to="'/questionario-indicador/' + id"
                      v-if="
                        !isBiomedic &&
                        $root.getAuthData().defaultSystem === 'Cliente'
                      "
                    >
                      <button class="btn btn-warning btn-block">
                        Iniciar novo questionário
                        <div class="ripple-container"></div>
                      </button>
                    </router-link>
                    <div
                      class="btn btn-warning"
                      v-if="
                        isBiomedic &&
                        $root.getAuthData().defaultSystem === 'Cliente'
                      "
                      @click="popupVisible = !popupVisible"
                    >
                      Inserir nova medição
                    </div>
                  </div>
                  {{ descricaoIndicador }}
                </div>
              </div>
            </div>
            <div class="row" v-if="false">
              <div class="col-md-12">
                <router-link
                  style="padding: 10px; width: 100%"
                  id="searched"
                  :use-submit-behavior="false"
                  :text="'Recomendações'"
                  class="btn btn-warning"
                  :to="`/recomendacoes/${id}`"
                  >Recomendações</router-link
                >
              </div>
            </div>
            <div
              class="row"
              v-if="$root.getAuthData().defaultSystem === 'Admin'"
            >
              <div class="col-md-12">
                <CardGraficoRiscos />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <CardHistoricoMedicoes
                  :id="id"
                  :isBiomedic="isBiomedic"
                  :reload="reload"
                  :mostra-jejum="hasJejum"
                  @exclusao-concluida="reload = !reload"
                />
              </div>
              <div v-if="false">
                <div
                  class="col-md-12"
                  v-if="
                    hasGrupoChart &&
                    $root.getAuthData().defaultSystem === 'Admin'
                  "
                >
                  <CardGraficoGrupo :id="id" />
                </div>
                <div
                  class="col-md-12"
                  v-if="
                    hasEvolutivoChart &&
                    $root.getAuthData().defaultSystem === 'Cliente' &&
                    1 != 1
                  "
                >
                  <CardGraficoEvolutivoMedicao :id="id" />
                </div>
                <div
                  class="col-md-12"
                  v-if="
                    hasMedidorChart &&
                    $root.getAuthData().defaultSystem === 'Cliente'
                  "
                >
                  <CardGraficoEvolutivoMedidor :id="id" :reload="reload" />
                </div>
                <div class="col-md-12" v-if="isBiomedic">
                  <div class="card">
                    <div class="card-content">
                      <TabelaReferencias :data="referenciasData" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="$root.getAuthData().defaultSystem === 'Cliente'">
          <RelatorioConteudo
            v-if="indicador != null"
            :resource="indicador"
            :getModuleData="true"
            :reload="reload"
          />
        </div>
      </div>
    </div>
    <DxPopup
      :visible.sync="popupVisible"
      :width="300"
      :height="500"
      :close-on-outside-click="true"
      :showCloseButton="false"
      :showTitle="false"
      @content-ready="onContentReady"
    >
      <CardFormIndicadorMedidor
        style="min-height: 500px"
        :fields="medidoresFields"
        :id="id"
        :mostra-jejum="hasJejum"
        @medicao-salva="
          reload = !reload;
          popupVisible = false;
        "
      />
    </DxPopup>
  </div>
</template>

<script>
import CardHistoricoMedicoes from "@/components/card-historico-medicoes.vue";
import CardGraficoGrupo from "@/components/card-grafico-grupo.vue";
import CardGraficoEvolutivoMedicao from "@/components/card-grafico-evolutivo-medicao.vue";
import CardGraficoEvolutivoMedidor from "@/components/card-grafico-evolutivo-medidor.vue";
import CardFormIndicadorMedidor from "@/components/card-form-indicador-medidor.vue";
import CardGraficoRiscos from "@/components/card-grafico-riscos.vue";
import TabelaReferencias from "@/components/relatorio-indicadores/tabela-referencias.vue";
import { DxPopup } from "devextreme-vue/popup";
import RelatorioConteudo from "@/components/relatorio-indicadores/relatorio-conteudo.vue";
import ControlesIndicadores from "@/components/mobile-menu/controles-indicadores.vue";

export default {
  name: "ClienteIndicador",
  components: {
    CardHistoricoMedicoes,
    CardGraficoGrupo,
    CardGraficoEvolutivoMedicao,
    CardGraficoEvolutivoMedidor,
    CardFormIndicadorMedidor,
    CardGraficoRiscos,
    TabelaReferencias,
    DxPopup,
    RelatorioConteudo,
    ControlesIndicadores,
  },
  created() {
    this.getIndicadorData();
  },
  watch: {
    $route: "getIndicadorData",
  },
  data: function () {
    const inst = this;
    return {
      id: null,
      medicoes: [],
      colunasMedicoes: [],
      gruposGrafico: [],
      evolutivoDados: [],
      evolutivoDadosMedidor: [],
      descricaoIndicador: "",
      hasGrupoChart: false,
      hasEvolutivoChart: false,
      hasMedidorChart: false,
      isBiomedic: null,
      nome: "",
      defaultImagePath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=indicadores&image=`,
      img: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=indicadores&image=no-image.png`,
      medidoresFields: [],
      reload: false,
      loading: false,
      hasJejum: false,
      referenciasData: [],
      popupVisible: false,
      indicador: null,
    };
  },
  methods: {
    getIndicadorData: function () {
      this.id = this.$route.params.id;
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/1/${
            this.$root.getAuthData().usuario
          }/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.indicador = resposta;
          this.$emit("breadcrumb-page-name", [
            { name: resposta.Tipo },
            {
              name: resposta.Indicador,
            },
          ]);
          this.referenciasData = resposta.Referencias[0];
          this.descricaoIndicador = resposta.Descricao;
          this.hasGrupoChart = resposta.usaGrupo;
          this.hasEvolutivoChart = resposta.usaMedicao;
          this.hasMedidorChart = resposta.usaMedidor;
          this.isBiomedic = !resposta.usaQuestionario;
          this.hasJejum = resposta.jejum;

          if (this.isBiomedic)
            this.medidoresFields = resposta.MedidoresForm[0].Medidores;

          if (resposta.Imagem !== null && resposta.Imagem !== undefined)
            this.img = `${this.defaultImagePath}${resposta.Imagem}`;
        })
        .catch(function () {
          // console.log(error);
        });
    },
    groupDataToLastValueChart: function (arr) {
      const i = this;
      return arr.reduce((r, a) => {
        let h = {};
        h["name"] = a.Nome;
        h["value"] = i.$root.sortBy(a.Dados, "Data", false)[0].Value;
        r.push(h);
        return r;
      }, []);
    },
    onContentReady: function (e) {
      var html = e.component.content();
      html.setAttribute("style", "padding: 0!important;");
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 20px;
}
#page-indicador {
  padding-bottom: 50px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}
</style>
