<template>
  <div class="row vertical-center">
    <div class="col-md-12">
      <div style="text-align: center">
        <img v-if="!useQr" src="../assets/img/logo.png" style="width: 20%" />
        <qrcode-vue
          v-if="useQr && permissionUrl !== ''"
          :value="permissionUrl"
          :size="150"
          level="H"
        ></qrcode-vue>
        <h4
          v-if="message !== ''"
          v-html="message"
          class="validation-text"
          :class="{
            'validation-text-center': askPermission,
            'validation-text-justify': !askPermission,
          }"
        ></h4>
        <div v-if="askPermission">
          <button
            type="button"
            class="btn btn-warning startBtn"
            @click="$emit('ask-permission', true)"
          >
            Solicitar Permissão
          </button>
        </div>
        <div v-if="!askPermission">
          <button
            v-if="validatePermission"
            type="button"
            class="btn btn-warning startBtn"
            @click="$emit('validate-permission', true)"
          >
            Entrar
          </button>
          <input type="hidden" id="permission-url" :value="permissionUrl" />
          <button
            v-if="permissionUrl !== ''"
            type="button"
            class="btn btn-warning startBtn"
            @click="copyPermissionUrl"
          >
            Copiar o link da Solicitação
          </button>
          <button
            type="button"
            class="btn btn-warning startBtn"
            @click="$emit('resend-permission', true)"
          >
            Reenviar e-mail com Solicitação
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import notify from "devextreme/ui/notify";

export default {
  name: "PermissionValidation",
  components: {
    QrcodeVue,
  },
  props: {
    askPermission: {
      default: false,
    },
    validatePermission: {
      default: false,
    },
    message: {
      default: "",
    },
    useQr: {
      default: false,
    },
    permissionUrl: {
      default: "",
    },
  },
  methods: {
    copyPermissionUrl() {
      let urlToCopy = document.querySelector("#permission-url");
      urlToCopy.setAttribute("type", "text");
      urlToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful)
          notify("Url de permissão copiada com sucesso", "success", 4000);
        else notify("Url de permissão copiada com erro", "success", 4000);
      } catch (err) {
        notify(
          "Infelizmente não foi possível completar sua requisição",
          "success",
          4000
        );
      }

      urlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.vertical-center {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 95%;
}

.validation-text {
  width: 80%;
  margin: 0 auto;
}

.validation-text-justify {
  text-align: justify;
}
.validation-text-center {
  text-align: center;
}
</style>