<template>
  <div class="card card-grafico-medicoes">
    <div class="card-header">
      <span v-if="typeData === 'Grupos'">{{ seriesData[0].Nome }}</span>
      <span v-if="typeData !== 'Grupos'">{{ indicador }}</span>

      <!-- <div class="filters">
        <DxDateBox
          :value="dataInicial"
          type="date"
          style="float: left; width: 40%"
          @value-changed="onValueChanged"
        />
        &nbsp;&nbsp;&nbsp;
        <DxDateBox
          :value="dataFinal"
          type="date"
          style="float: right; width: 40%"
          @value-changed="onValueChanged"
        />
      </div> -->
    </div>
    <div class="card-content">
      <DxChart
        id="chart1"
        class="chart"
        :data-source="dataSource"
        palette="Violet"
        :customize-point="customizePoint"
        @initialized="saveChartInstance"
      >
        <DxCommonSeriesSettings type="spline" argument-field="Data" />
        <DxCommonAxisSettings>
          <DxGrid :visible="true" />
        </DxCommonAxisSettings>
        <DxSeries
          v-for="(tipos, index2) in seriesData"
          :key="index2"
          :value-field="
            tipos.Nome !== null && tipos.Nome !== undefined
              ? tipos.Nome.replace('@', '')
              : ''
          "
          :name="
            tipos.Nome !== null && tipos.Nome !== undefined
              ? tipos.Nome.replace('@', '')
              : ''
          "
          axis="percent"
        />
        <DxMargin :bottom="20" />
        <DxValueAxis
          name="percent"
          :adjustOnZoom="false"
          v-if="typeData === 'Grupos'"
          :visual-range="{ startValue: 0, endValue: 100, lenght: 0 }"
          :value-type="'numeric'"
          :valueMarginsEnabled="false"
        />
        <DxArgumentAxis
          :allow-decimals="false"
          :axis-division-factor="60"
          name="medidorEixo"
        >
          <DxLabel :customize-text="customizeLabel">
            <DxFormat type="decimal" />
          </DxLabel>
        </DxArgumentAxis>
        <DxLegend
          vertical-alignment="top"
          horizontal-alignment="center"
          :visible="typeData !== 'Grupos'"
        />
        <DxExport :enabled="false" />
        <DxTooltip :enabled="true" />
      </DxChart>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxValueAxis,
} from "devextreme-vue/chart";
// import notify from "devextreme/ui/notify";
// import DxDateBox from "devextreme-vue/date-box";

export default {
  name: "GraficoMedicoes",
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxValueAxis,
    // DxDateBox,
  },
  props: {
    dados: {
      default: function () {
        return [];
      },
    },
    receiveData: {
      default: false,
    },
    idModulo: {
      default: null,
    },
    typeData: {
      default: "",
    },
    indicador: {
      default: "",
    },
    corUltimaMedicao: {
      default: "",
    },
  },
  data: function () {
    const date = new Date();
    return {
      dataSource: [],
      seriesData: [],
      chartInstance: null,
      dataInicial: new Date(date.getFullYear(), date.getMonth(), 1),
      dataFinal: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      max: 0,
      min: 0,
    };
  },
  watch: {
    reload: function () {
      this.getData(this.dados);
    },
    dados: function (v) {
      if (this.receiveData) {
        if (v.length > 0) {
          this.setData(v);
        }
      } else {
        this.getData();
      }
    },
  },
  created: function () {
    if (this.receiveData) {
      if (this.dados.length > 0) {
        this.setData(this.dados);
      }
    } else {
      this.getData();
    }
  },
  methods: {
    saveChartInstance: function (e) {
      this.chartInstance = e.component;
    },
    getData: function () {
      // this.$ConAPI
      //   .get(
      //     `/DadosIndicadores/GetIndicadorData/4/${this.$root.getAuthData().usuario}/${this.id}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${this.$root.getAuthData().token}`,
      //       },
      //     }
      //   )
      //   .then((result) => {
      //     var resposta = JSON.parse(result.request.response);
      //     this.dataSource = this.groupDataToChart(resposta.Medidores, "Nome");
      //     this.seriesData = resposta.Medidores;
      //     this.chartInstance.refresh();
      //   })
      //   .catch(function (error) {
      //     notify(error, "error", 4000);
      //   });
    },
    groupDataToChart: function (arr, key) {
      const i = arr.reduce((r, a) => {
        if (a.visible) {
          a.Dados.map((value) => {
            let h = {};
            h[a[key]] = value.Value;
            h["value"] = value.Value;
            h["Data"] = value.Data;
            h["Cor"] = value.Cor;
            r.push(h);
          });
        }
        return r;
      }, []);
      return i;
    },
    customizePoint: function (e) {
      if (e.data.Cor !== null) {
        return {
          image: {
            url: `${
              this.$root.Controle.UrlAPI
            }/Upload/GetPhoto?type=icon&image=${e.data.Cor.substring(1)}.png`,
            width: 15,
            height: 15,
          },
          visible: true,
        };
      }
    },
    customizeLabel: function (e) {
      const d = new Date(e.value);
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    setData: function (arr) {
      this.dataSource = this.groupDataToChart(arr, "Nome");
      // this.dataSource = this.groupDataToChart(arr, "Nome").filter(
      //   (x) =>
      //     new Date(x.Data).getTime() > this.dataInicial.getTime() &&
      //     new Date(x.Data).getTime() < this.dataFinal.getTime()
      // );
      const mx = this.arrayMax(this.dataSource);
      const mn = this.arrayMin(this.dataSource);
      this.min = this.minValueMargin(mn, mx);
      this.max = this.maxValueMargin(mn, mx);
      this.seriesData = arr;
      if (this.chartInstance !== null) {
        if (this.typeData === "Grupos") {
          this.chartInstance.option("valueAxis", {
            min: 0,
            max: 100,
            name: "percent",
            valueMarginsEnabled: false,
            adjustOnZoom: false,
          });
        }
        this.chartInstance.refresh();
      }
    },
    onValueChanged: function () {
      this.dataSource = this.groupDataToChart(this.dados, "Nome").filter(
        (x) =>
          new Date(x.Data).getTime() > this.dataInicial.getTime() &&
          new Date(x.Data).getTime() < this.dataFinal.getTime()
      );
    },
    arrayMin: function (arr) {
      const i = arr.reduce(function (p, v) {
        return p.value < v.value ? p : v;
      });
      return i.value;
    },
    arrayMax: function (arr) {
      return arr.reduce(function (p, v) {
        return p > v.value ? p : v.value;
      }, 0);
    },
    minValueMargin: function (min, max) {
      return min != 0 ? min - 0 / (max - min) : 0;
    },
    maxValueMargin: function (min, max) {
      return max != 0 ? -(max - 100) / (max - min) : 0;
    },
  },
};
</script>

<style>
.icon-last-measurement {
  width: 20px !important;
}
.chart {
  height: 230px;
  width: 100%;
}
.card-grafico-medicoes {
  height: 300px;
}
.filters {
  width: 60%;
  float: right;
}
</style>