<template>
  <div class="content" id="page-relatorios">
    <loading :loading="$root.loading || loading" />
    <PermissionValidation
      v-if="hasNoPermission"
      :use-qr="hasPendingPermission"
      :permission-url="urlValidatePermission"
      :message.sync="mensagemSemPermissao"
      :ask-permission="hasPermitionButton"
      :validate-permission="hasPendingPermission"
      @validate-permission="validateUserPermission"
      @ask-permission="askPermission"
      @resend-permission="resendNotification"
      style="z-index: 100"
    />
    <IndicadoresFilters
      v-if="showFilters"
      :multiple-indicador-filter="true"
      @filter-values="getTipos({ ...$event, idPessoaFisica, tipo: '' })"
    />
    <NoData v-if="hasNoData" :message="'Nenhum dado encontrado.'" />
    <DxScrollView
      :id="isExternal ? 'content_view' : ''"
      width="100%"
      :show-scrollbar="'always'"
      :scroll-by-content="!$root.mobile"
      :scroll-by-thumb="$root.mobile"
    >
      <div class="container-fluid" v-if="!hasNoData">
        <div class="row" style="margin-bottom: 10px">
          <div class="col-md-12">
            <ul
              class="nav nav-pills nav-pills-warning nav-pills-icons"
              role="tablist"
              style="width: 100%; margin: 0 auto"
            >
              <li
                class="nav-item"
                :class="{ active: currentActive === item.sigla }"
                v-for="(item, index) in tiposIndicadores"
                :key="index"
              >
                <a
                  class="nav-link"
                  data-toggle="tab"
                  :href="
                    '#' + item.sigla.replace(' ', '').replace(' ', '') + 'tab'
                  "
                  role="tablist"
                  @click="
                    loading = true;
                    setCurrent(item.sigla);
                  "
                >
                  <i
                    class="fa logo-size"
                    :class="item.logo"
                    aria-hidden="true"
                  ></i>
                  <span style="">{{ item.sigla }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="page-categories">
              <div class="tab-content tab-subcategories">
                <div
                  class="tab-pane"
                  v-for="(item, index) in tiposIndicadores"
                  :key="index"
                  :class="{ active: currentActive === item.sigla }"
                  :id="
                    '' + item.sigla.replace(' ', '').replace(' ', '') + 'tab'
                  "
                >
                  <div v-if="currentActive === item.sigla">
                    <RelatorioConteudo
                      :key="idx"
                      v-show="!$root.loading"
                      :resource="item2"
                      :show-recommendation-button="!isExternal && isSameUser"
                      v-for="(item2, idx) in getDataArray(item.sigla)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DxScrollView>
  </div>
</template>

<script>
import RelatorioConteudo from "@/components/relatorio-indicadores/relatorio-conteudo.vue";
import Loading from "@/components/loading.vue";
import NoData from "@/components/no-data.vue";
import PermissionValidation from "@/components/permission-validation.vue";
import notify from "devextreme/ui/notify";
import { DxScrollView } from "devextreme-vue/scroll-view";
import IndicadoresFilters from "@/components/indicadores-filters.vue";

export default {
  name: "RelatorioIndicadores",
  components: {
    RelatorioConteudo,
    Loading,
    NoData,
    PermissionValidation,
    DxScrollView,
    IndicadoresFilters,
  },
  data: function () {
    return {
      currentTabComponent: "",
      currentActive: "",
      dadosComponent: [],
      loading: true,
      tiposIndicadores: [],
      dados: [],
      hasNoData: false,
      showFilters: true,
      hasNoPermission: false,
      mensagemSemPermissao: "",
      hasPermitionButton: false,
      hasPendingPermission: false,
      isExternal: false,
      isSameUser: false,
      idPessoaFisica: null,
      urlValidatePermission: `${this.$root.$UrlApl}/validate-solicitation/A/`,
      pendingPermissionId: "",
    };
  },
  created: function () {
    this.isSameUser =
      this.$root.getAuthData().usuario.toLowerCase() ==
      this.$route.params.id.toLowerCase();
    if (this.isSameUser) {
      this.idPessoaFisica = this.$route.params.id;
      this.getTipos({
        idPessoaFisica: this.idPessoaFisica,
        indicadores: [],
        risco: 0,
        tipo: "",
      });
    } else {
      if (
        this.$route.params.id.toLowerCase() ==
        this.$root.getAuthData().userLogged.toLowerCase()
      ) {
        this.idPessoaFisica = this.$root.getAuthData().usuario;
        this.getTipos({
          idPessoaFisica: this.idPessoaFisica,
          indicadores: [],
          risco: 0,
          tipo: "",
        });
      } else {
        this.validateUserPermission();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.meta.type === "external") vm.isExternal = true;
      else vm.isExternal = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.meta.type === "external") this.isExternal = true;
    else this.isExternal = false;
    next();
  },
  updated: function () {
    this.loading = false;
  },
  methods: {
    getAllData: function (e) {
      return this.$ConAPI.post(`/DadosIndicadores/GetDados/`, e, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    getDataBy: function (type, id) {
      return this.$ConAPI.get(`/DadosIndicadores/GetDados/${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    getTipos: function (e) {
      this.$ConAPI
        .post(`/DadosIndicadores/GetTiposIndicadores`, e, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          if (resposta.length > 0) {
            this.tiposIndicadores = resposta;
            this.setData(resposta[0].sigla, e);
            this.hasNoData = false;
          } else {
            this.hasNoData = true;
            this.showFilters = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setData: function (tipo, e) {
      this.loading = true;
      this.getAllData(e)
        .then((result) => {
          var resposta2 = JSON.parse(result.request.response);
          this.dados = resposta2;
          this.setCurrent(tipo);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setCurrent: function (page) {
      this.currentTabComponent =
        page === "Estilo de vida" ? "EstiloVida" : page;
      this.currentActive = page;
    },
    getDataArray: function (tipo) {
      if (this.dados.length > 0)
        return this.dados.filter((value) => value.nome === tipo)[0].indicadores;
      else return [];
    },
    validateuser: function (id) {
      return this.$ConAPI.get(`/Permission/ValidateViewPermission/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    askPermission: function () {
      this.$ConAPI
        .post(
          `/Permission/AskPermission`,
          { id: this.$route.params.id, visualizar: true, editar: false },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then(() => {
          notify("Pedido feito com sucesso", "success", 4000);
          this.validateUserPermission();
        })
        .catch(function (error) {
          notify(error.data.message, "error", 4000);
        });
    },
    validateUserPermission: function () {
      this.validateuser(this.$route.params.id)
        .then((res) => JSON.parse(res.request.response))
        .then((resp) => {
          this.hasNoPermission = false;
          this.pendingPermissionId = "";
          if (resp.valid) {
            this.getTipos({
              idPessoaFisica: this.$route.params.id,
              indicadores: [],
              risco: 0,
              tipo: "",
            });
            this.salvaLogAcessoMedico(this.$route.params.id);
          } else {
            this.showFilters = false;
            this.setValidationData(resp);
          }
        });
    },
    setValidationData(resp) {
      this.hasNoPermission = true;
      this.pendingPermissionId =
        resp.permission !== null ? resp.permission : "";
      this.hasPendingPermission = !resp.askPermission;
      this.urlValidatePermission += resp.permission;
      this.hasPermitionButton = resp.askPermission;
      this.mensagemSemPermissao = `${
        !resp.isDenied && resp.askPermission
          ? "Bem-vindo à Plataforma GPS MED.<br /> Aqui você poderá monitorar indicadores de saúde de seu paciente ao longo do tempo.<br />Para solicitar o acesso aos dados de seu paciente, clique no botão abaixo."
          : "Foi enviado para este paciente um e-mail com a sua solicitação de acesso para visualização da Plataforma GPS MED."
      }
        ${
          resp.isDenied
            ? "<br />O paciente negou a sua última solicitação. Caso deseje solicitar novamente, clicar no botão abaixo:"
            : ""
        }
        ${
          !resp.askPermission
            ? "<br/>Sua Solicitação está pendente e você poderá utilizar as seguintes ações:" +
              "<ol><li>Se o paciente estiver presente, ele poderá autorizar o seu acesso agora, lendo o código QR.</li>" +
              "<li>Caso queira copiar o link da solicitação, clique no botão correspondente.</li>" +
              "<li>Caso queira reenviar o link da solicitação por e-mail, clique no botão correspondente.</li>" +
              "<li>Após o paciente autorizar, clique no botão ENTRAR.</li></ol>"
            : ""
        }
      `;
    },
    resendNotification: function () {
      if (this.pendingPermissionId !== "") {
        const a = this.$root.getAuthData();
        this.$ConAPI
          .get(`/Permission/ResendNotification/${this.pendingPermissionId}`, {
            headers: {
              Authorization: `Bearer ${a.token}`,
            },
          })
          .then((r) => notify(r.data.message, "success", 4000))
          .catch(() =>
            notify("Ocorreu um erro no reenvio da solicitação", "error", 4000)
          );
      }
    },
    salvaLogAcessoMedico(id) {
      this.$ConAPI
        .get(`/Permission/log-acesso/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .catch(function (error) {
          this.selectedUser = null;
          notify(error.data.message, "error", 4000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
#page-relatorios {
  padding-top: 0 !important;
  padding-bottom: 50px;
  @media screen and (min-width: 780px) {
    padding-bottom: 20px !important;
  }
}
.content {
  text-align: left;
}

.logo-size {
  /* font-size: 18px;
  display: block; */
  margin: 5px;
  font-size: 20px;
  margin-right: 7px;
}

.nav-item {
  width: 33%;
  height: 45px;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 11px;
}

#content_view {
  height: 95vh;
}
</style>
