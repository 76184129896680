var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "11px" } },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "col-lg-4 col-md-6 col-sm-6" },
          [
            _c(
              "div",
              {
                staticClass: "card card-stats card-home-top",
                attrs: { id: item.tipo.toLowerCase().replace(/ /g, "-") }
              },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("i", {
                    staticClass: "fa",
                    class: item.logo,
                    staticStyle: { "font-size": "45px" },
                    attrs: { "aria-hidden": "true" }
                  }),
                  _c("h3", { staticClass: "card-title title" }, [
                    _c("span", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                    _c("div", { staticClass: "stats explanation" }, [
                      _vm._v(" Clique nos itens para ver o detalhamento. ")
                    ])
                  ])
                ]),
                _c(
                  "DxScrollView",
                  {
                    attrs: {
                      width: "100%",
                      height: "65vh",
                      showScrollbar: "always",
                      "scroll-by-content": !_vm.$root.mobile,
                      "scroll-by-thumb": _vm.$root.mobile
                    }
                  },
                  [
                    _c("div", { staticClass: "card-content" }, [
                      _c(
                        "table",
                        { staticClass: "table table-hover table-margin" },
                        [
                          _c(
                            "tbody",
                            _vm._l(item.indicadores, function(item2, index2) {
                              return _c(
                                "tr",
                                {
                                  key: index2,
                                  staticStyle: {
                                    "font-size": "large",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goTo(
                                        "/indicador-admin/" + item2.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("td", { attrs: { scope: "col" } }, [
                                    _vm._v(" " + _vm._s(item2.nome) + " ")
                                  ]),
                                  _c("td", { staticClass: "col-md-1" }, [
                                    _vm._v(" " + _vm._s(item2.valor) + " ")
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }