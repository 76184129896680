<template>
  <div>
    <loading :loading="$root.loading" />
    <div :class="{ 'transiton-blur': $root.loading }" class="container-fluid">
      <div class="wrapper wrapper-full-page login row">
        <div class="col-md-6 login-forms">
          <div class="form-login aceite">
            <div
              class="login-header text-center"
              data-background-color="orange"
            >
              <img src="../assets/img/logo_white.png" style="width: 80%" />
              <h3 class="mb-4">
                Você é responsável pela veracidade dos seus dados e nós pela
                privacidade.
              </h3>
              <p class="text-left text-white">
                Para responder questionários, registrar marcações e acessar seus
                indicadores de saúde, concorde com os termos do GPS Med abaixo:
              </p>
            </div>
            <div class="form-group">
              <label class="checkbox-aceite">
                <input
                  type="checkbox"
                  name="termo"
                  id="termo"
                  class=""
                  v-model="aceiteTermo"
                />
                <span class="checkmark"></span>
              </label>
              <label class="label-text"
                >Li e concordo com o
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#modalTermo"
                  class="underline"
                  >Termos de Uso</a
                ></label
              >
            </div>
            <div class="form-group">
              <label class="checkbox-aceite">
                <input
                  type="checkbox"
                  name="politica"
                  id="politica"
                  class=""
                  v-model="aceitePolitica"
                />
                <span class="checkmark"></span>
              </label>
              <label class="label-text"
                >Li e concordo com a
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#modalPolitica"
                  class="underline"
                  >Política de Privacidade</a
                ></label
              >
            </div>
            <div class="text-center">
              <button
                :disabled="!aceite"
                @click="Continuar"
                class="
                  btn btn-block
                  w-50
                  btn-login btn-full btn-wd btn-lg
                  mx-auto
                "
              >
                Continuar
              </button>
              <a href="#" @click="$root.logout">Sair</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 d-none d-md-block login-banner" id="aceite-bg">
          <h3>
            A nossa missão é ajudar você a melhorar a sua saúde e qualidade de
            vida.
          </h3>
        </div>
      </div>
    </div>
    <ModaisTermos
      :termo="termoUso"
      :politica="politicaPrivacidade"
    ></ModaisTermos>
  </div>
</template>

<script>
// import notify from "devextreme/ui/notify";

import ModaisTermos from "@/components/ModaisTermos.vue";
import Loading from "../components/loading.vue";
// import Vue from "vue";

export default {
  name: "AceiteTermos",
  components: {
    ModaisTermos,
    Loading,
  },
  data: function () {
    return {
      termoLido: false,
      aceiteTermo: false,
      aceitePolitica: false,
      termoUso: "",
      politicaPrivacidade: "",
    };
  },
  computed: {
    aceite: function () {
      return this.aceitePolitica && this.aceiteTermo;
    },
  },
  created: function () {
    this.$ConAPI
      .get(`/acesso/termo-uso/${this.$root.getAuthData().termoUso}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      })
      .then((res) => JSON.parse(res.request.response))
      .then((resposta) => {
        this.termoUso = resposta.termo;
        this.politicaPrivacidade = resposta.politicaPrivacidade;
      });
  },
  methods: {
    onReachBottom: function (options) {
      this.termoLido = true;
      options.component.release(true);
    },
    Continuar: function () {
      if (this.aceite) {
        this.$ConAPI
          .get(`/Acesso/AceitoTermo/${this.$root.getAuthData().termoUso}`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then(() => {
            const a = this.$root.getAuthData();
            a.aceiteTermo = true;
            this.abreTermo = false;
            this.$root.setAuthData(a);
            if (this.$route.query.nextUrl !== undefined)
              this.$router.push({ path: this.$route.query.nextUrl });
            else this.$router.push({ path: "/" });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$root.logout();
      }
    },
  },
};
</script>

<style lang="scss">
.aceite {
  .underline {
    text-decoration: underline;
  }

  h3 {
    font-size: 2rem !important;
  }

  p {
    font-size: 1.3rem;
  }

  /* The container */
  .checkbox-aceite {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-aceite input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border-radius: 2px;
    border: 1px solid white;
  }

  /* On mouse-over, add a grey background color */
  //.checkbox-aceite:hover input ~ .checkmark {
  //  background-color: #ccc;
  //}

  /* When the checkbox is checked, add a blue background */
  .checkbox-aceite input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-aceite input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-aceite .checkmark:after {
    left: 4px;
    top: 0px;
    width: 9px;
    height: 12px;
    border: solid #fd9504;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label-text {
    margin-left: 30px;
  }
}
</style>
