var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("div", { staticClass: "card-content" }, [
        _c("h4", { staticClass: "card-title" }, [
          _vm._v("Incluir nova medição")
        ]),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                return _vm.onFormSubmit($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "DxDateBox",
                  {
                    attrs: {
                      type: "datetime",
                      placeholder: "Data/hora da medição",
                      "display-format": "dd/MM/yyyy HH:mm",
                      "picker-type": "roller",
                      "use-mask-behavior": true,
                      max: new Date()
                    },
                    on: { initialized: _vm.saveDateGridInstance },
                    model: {
                      value: _vm.formData.DataInicial,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "DataInicial", $$v)
                      },
                      expression: "formData.DataInicial"
                    }
                  },
                  [
                    _c(
                      "DxValidator",
                      [
                        _c("DxRequiredRule", {
                          attrs: { message: "Campo Obrigatório" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.fields, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(_vm._s(item.nome.replace("@", "")))
                  ]),
                  _c(
                    "DxNumberBox",
                    {
                      staticClass: "form-control form-number-field",
                      attrs: {
                        "show-clear-button": true,
                        placeholder: item.exemplo,
                        max: 99999999
                      },
                      on: { initialized: _vm.saveNumbersGridInstance },
                      model: {
                        value: _vm.medidoresFields[item.id],
                        callback: function($$v) {
                          _vm.$set(_vm.medidoresFields, item.id, $$v)
                        },
                        expression: "medidoresFields[item.id]"
                      }
                    },
                    [
                      _c(
                        "DxValidator",
                        [
                          _c("DxRequiredRule", {
                            attrs: { message: "Campo Obrigatório" }
                          }),
                          _c("DxPatternRule", {
                            attrs: {
                              pattern: /^\d+(\.\d{1,2})?/,
                              message: "Formato inválido de valor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _vm.mostraJejum
              ? _c(
                  "div",
                  { staticClass: "form-group label-floating" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Jejum")
                    ]),
                    _c("DxSwitch", {
                      staticClass: "switch",
                      model: {
                        value: _vm.formData.Jejum,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "Jejum", $$v)
                        },
                        expression: "formData.Jejum"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "DxButton",
              {
                staticClass: "btn btn-fill btn-warning",
                attrs: {
                  id: "save",
                  "use-submit-behavior": true,
                  text: "Incluir"
                }
              },
              [_vm._v("Incluir")]
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }