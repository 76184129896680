<template>
  <div class="content">
    <loading :loading="$root.loading" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ul
            class="nav nav-pills nav-pills-warning nav-pills-icons"
            role="tablist"
            style="width: 100%; margin: 0 auto"
          >
            <li
              class="nav-item"
              :class="{ active: currentActive === item.sigla }"
              v-for="(item, index) in tiposIndicadores"
              :key="index"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                :href="'#' + item.sigla + 'tab'"
                role="tablist"
                style="text-align: center"
                @click="
                  loading = true;
                  setCurrent(item.sigla);
                "
              >
                <i
                  class="fa logo-size"
                  :class="item.logo"
                  aria-hidden="true"
                ></i>
                {{ item.sigla }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="page-categories">
            <div class="tab-content tab-subcategories">
              <div class="tab-pane active" id="mentetab">
                <div class="card" style="padding: 0 15px">
                  <div class="card-content">
                    <div
                      class="row"
                      v-for="(indicador, idx1) in dadosComponent"
                      :key="idx1"
                    >
                      <div
                        style="
                          border-bottom: 1px solid gray;
                          margin-bottom: 7px;
                        "
                      >
                        <h4 style="display: inline">
                          {{ indicador.indicador }}
                        </h4>
                        <span
                          class="pull-right"
                          style="
                            cursor: pointer;
                            display: inline;
                            color: purple;
                          "
                          @click="
                            $router.push({
                              path: `indicador-admin/${indicador.id}`,
                            })
                          "
                          >Detalhes</span
                        >
                      </div>
                      <div
                        class="row"
                        style="margin-bottom: 10px"
                        v-for="(item, idx2) in indicador.dados"
                        :key="idx2"
                      >
                        <div class="col-lg-3" style="line-height: 1.5">
                          {{
                            indicador.indicador !== item.nome ? item.nome : ""
                          }}
                        </div>
                        <div class="col-lg-9">
                          <div
                            class="progress progress-line-danger"
                            style="height: 23px; font-weight: 500"
                          >
                            <div
                              class="progress-bar"
                              style="padding: 1px"
                              :style="{
                                width: `${(item.bom / item.total) * 100}%`,
                                display:
                                  Math.round((item.bom / item.total) * 100) > 0
                                    ? 'block'
                                    : 'none',
                                'background-color': coresRisco.filter(
                                  (x) => x.grau === 'bom'
                                )[0].cor,
                              }"
                            >
                              {{
                                `${Math.round(
                                  (item.bom / item.total) * 100
                                )}%(${item.bom})`
                              }}
                            </div>
                            <div
                              class="progress-bar"
                              style="padding: 1px"
                              :style="{
                                width: `${(item.medio / item.total) * 100}%`,
                                display:
                                  Math.round((item.medio / item.total) * 100) >
                                  0
                                    ? 'block'
                                    : 'none',
                                'background-color': coresRisco.filter(
                                  (x) => x.grau === 'medio'
                                )[0].cor,
                              }"
                            >
                              {{
                                `${Math.round(
                                  (item.medio / item.total) * 100
                                )}%(${item.medio})`
                              }}
                            </div>
                            <div
                              class="progress-bar"
                              style="padding: 1px"
                              :style="{
                                width: `${(item.ruim / item.total) * 100}%`,
                                display:
                                  Math.round((item.ruim / item.total) * 100) > 0
                                    ? 'block'
                                    : 'none',
                                'background-color': coresRisco.filter(
                                  (x) => x.grau === 'ruim'
                                )[0].cor,
                              }"
                            >
                              {{
                                `${Math.round(
                                  (item.ruim / item.total) * 100
                                )}%(${item.ruim})`
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "../components/loading.vue";
export default {
  components: { loading },
  name: "RelatorioIndicadoresAdmin",
  data: function () {
    return {
      currentTabComponent: "",
      currentActive: "",
      tiposIndicadores: [],
      data: [],
      dadosComponent: [],
      coresRisco: [],
    };
  },
  created: function () {
    this.getAllData(this.$root.getAuthData().userCompany)
      .then((result) => {
        var resposta = JSON.parse(result.request.response);
        this.setData(resposta);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    getAllData: function (id) {
      return this.$ConAPI.get(`/DadosIndicadores/RelatorioRiscosAdmin/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    setData: function (d) {
      this.data = d;
      this.tiposIndicadores = d.dados.reduce((p, c) => {
        p.push({ sigla: c.tipo, logo: c.logo });
        return p;
      }, []);
      this.setCurrent(this.tiposIndicadores[0].sigla);
    },
    setCurrent: function (page) {
      this.currentActive = page;
      this.dadosComponent = this.data.dados.filter(
        (value) => value.tipo === this.currentActive
      )[0].indicadores;
      this.coresRisco = this.data.riscos;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  text-align: left;
}

.logo-size {
  font-size: 20px;
  margin-right: 7px;
}

.nav-item {
  width: 33%;
  height: 45px;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 11px;
}
</style>
