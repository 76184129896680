<template>
  <div class="navbar-minimize">
    <button
      id="minimizeSidebar"
      class="btn btn-round btn-white btn-fill btn-just-icon"
      @click="$emit('close-menu', true)"
    >
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      <i class="fa fa-bars visible-on-sidebar-mini" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "MinimizeMenu",
};
</script>

<style scoped>
#minimizeSidebar {
  width: 45px;
  height: 45px;
}
</style>