<template>
  <div>
    <loading :loading="loading" />
    <Grid
      :has-header-filter="true"
      :columns="columns"
      :action="action"
      :hasDetail="$root.getAuthData().acessoGPS"
      :other-buttons="otherButtons"
      :grid-actions="gridActions"
      :is-user="true"
      class="grid-resize"
      v-on:add-item="addItem"
      v-on:edit-item="editItem"
      :reload="reloadGridMaster"
      :selection="{
        mode: 'multiple',
        showCheckBoxesMode: 'always',
      }"
      @selected-rows-ids="selectedRows = $event"
      :delete-message="'Deseja realmetne excluir este usuário?'"
    >
      <template slot-scope="{ master }">
        <DxTabPanel>
          <DxItem title="Relação com contratantes" template="relations-tab" />
          <Grid
            slot="relations-tab"
            :columns="detailColumns"
            :action="detailAction"
            :parent-id-field="'_PessoaFisica'"
            :filter-value="master.data.Id"
            :filter-field="filterDetailBy"
            :edit-mode="detailMode"
          />
        </DxTabPanel>
      </template>
    </Grid>
    <DxPopup
      :visible.sync="popupGridVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="900"
      :height="400"
      :title="popupTitle"
      :fullScreen="$root.mobile"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <Grid
          :reload="reloadGrid"
          :columns="gridPopupColumns"
          :action="gridPopupAction"
          :filter-value="filterByClienteId"
          :filter-field="filterDetailBy"
          :edit-mode="detailMode"
          :parent-id-field="parentIdField"
          :init-inserting-row="initInsertingPopupRow"
          :can-sort="false"
          :can-search="false"
          :edit-config="editConfigPopup"
        />
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :fullScreen="$root.mobile"
      :visible.sync="popupImportVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="1000"
      :height="500"
      title="Importação"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <div class="row">
          <div class="col-md-12">
            <a
              id="back"
              text="Voltar"
              class="btn btn-primary"
              href="../../assets/files/template.xlsx"
              >Download modelo para importação</a
            >
          </div>
          <div class="col-md-12">
            <DxFileUploader
              accept=".xlsx, .xls"
              :ref="fileUploaderkey"
              upload-mode="instantly"
              select-button-text="Selecione um arquivo"
              invalid-file-extension-message="Extensão de arquivo não aceita"
              :upload-url="urlUploadImport"
              :multiple="false"
              :allowed-file-extensions="['.xlsx', '.xls']"
              :upload-headers="uploadImportHeader"
              @uploaded="onUploaded"
              @upload-error="onUploadError"
            />
            <Grid
              v-show="hasImportError"
              :reload="importErrorReload"
              :data="importErrorData"
              :columns="[
                { name: 'Nome', showColumn: true },
                { name: 'Email', showColumn: true },
                { name: 'CPF', showColumn: true },
                { name: 'CNPJ', showColumn: true },
                { name: 'DescricaoErro', showColumn: true },
              ]"
              :edit-mode="''"
            />
          </div>
        </div>
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :visible.sync="openPermissionValidation"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'80vh'"
      :height="'70vh'"
    >
      <PermissionValidation
        :use-qr="true"
        :permission-url="validateUrl"
        :message="'Peça ao usuário para ler o código acima para fornecer permissão<br/>Ou clique no botão para copiar o link de validação:'"
        :ask-permission="false"
        :validate-permission="false"
      />
    </DxPopup>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import ODataStore from "devextreme/data/odata/store";
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import Guid from "devextreme/core/guid";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import notify from "devextreme/ui/notify";
import { custom, alert } from "devextreme/ui/dialog";
import Loading from "@/components/loading.vue";
import PermissionValidation from "@/components/permission-validation.vue";

export default {
  name: "Cliente",
  components: {
    Grid,
    DxTabPanel,
    DxItem,
    DxPopup,
    DxScrollView,
    DxFileUploader,
    Loading,
    PermissionValidation,
  },
  data() {
    const inst = this;

    return {
      columns: [
        { name: "Id", notVisible: true },
        { name: "IdPessoaJuridica", notVisible: true },
        { name: "Nome", showColumn: true, defaultSort: true, size: 200 },
        { name: "Idade", showColumn: false, size: "5%" },
        {
          name: "Email",
          showColumn: true,
          class: "WrappedColumnClass",
          size: 200,
        },
        {
          name: "CPF",
          showColumn: false,
          customizeText: function (e) {
            if (e.value !== undefined && e.value != null)
              return e.value.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                function (
                  regex,
                  argumento1,
                  argumento2,
                  argumento3,
                  argumento4
                ) {
                  return (
                    argumento1 +
                    "." +
                    argumento2 +
                    "." +
                    argumento3 +
                    "-" +
                    argumento4
                  );
                }
              );
            else return e.value;
          },
        },
        {
          name: "Sexo",
          showColumn: false,
          customizeText: function (e) {
            switch (e.value.toLowerCase()) {
              case "m":
                return "Masculino";
              case "f":
                return "Feminino";
              default:
                return "";
            }
          },
        },
        { name: "Identidade", showColumn: false },
        {
          name: "NomeFantasia",
          showColumn: true,
          caption: "Contratante",
          size: 100,
        },
        // {
        //   name: "RazaoSocial",
        //   showColumn: true,
        //   caption: "Contratante",
        //   size: 100,
        // },
        {
          name: "Funcionario",
          caption: "Funcionário",
          showColumn: true,
          size: 100,
        },
        { name: "Paciente", caption: "Paciente", showColumn: true, size: 100 },
        {
          name: "PrestadorSaude",
          caption: "Prof Saúde",
          showColumn: true,
          size: 100,
        },
        { name: "Ocupacao", caption: "Ocupação", showColumn: true },
        {
          name: "TelefoneCelular",
          showColumn: true,
          caption: "Celular",
          customizeText: function (e) {
            if (e.value !== undefined && e.value != null)
              return e.value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
            else return e.value;
          },
        },
        { name: "Categorias", caption: "Categorias", showColumn: true },
        {
          name: "TelefoneResidencial",
          showColumn: false,
          caption: "Telefone Residencial",
          customizeText: function (e) {
            if (e.value !== undefined && e.value != null)
              return e.value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
            else return e.value;
          },
        },
        {
          name: "TelefoneTrabalho",
          showColumn: false,
          caption: "Telefone Trabalho",
          customizeText: function (e) {
            if (e.value !== undefined && e.value != null)
              return e.value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
            else return e.value;
          },
        },
        { name: "Operadora", showColumn: false },
        { name: "Plano", showColumn: false },
        { name: "Ativo", showColumn: false, caption: "Situação" },
        { name: "Endereco", showColumn: false },
        { name: "Bairro", showColumn: false },
        { name: "Cidade", showColumn: false },
        { name: "CEP", showColumn: false },
        { name: "Complemento", showColumn: false },
        { name: "P_UF", showColumn: true, caption: "UF" },
        { name: "Departamento", showColumn: true },
      ],
      action: "GPS_PessoaFisica",
      detailAction: "GPS_Pessoa_FisicaJuridica",
      filterDetailBy: "PessoaFisica.Id",
      filterByClienteId: null,
      detailMode: "batch",
      detailColumns: [
        { name: "Id", notVisible: true },
        {
          name: "_PessoaJuridica",
          showColumn: true,
          caption: "Empresa",
          required: true,
          lookup: {
            allowClearing: true,
            dataSource: {
              store: new ODataStore({
                url: `${inst.$UrlAPI}/GPS_PessoaJuridica`,
                version: 4,
                key: ["Id"],
                keyType: {
                  Id: "Guid",
                },
                beforeSend: function (e) {
                  e.headers = {
                    Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  };
                },
              }),
              select: ["Id", "NomeFantasia", "RazaoSocial", "Ativo"],
              filter: [["Ativo", "=", true]],
              sort: [{ selector: "RazaoSocial", desc: false }],
            },
            displayExpr: "NomeFantasia",
            valueExpr: "Id",
          },
        },
        { name: "Departamento", showColumn: true },
        { name: "DataAdmissao", showColumn: true, caption: "Data Admissão" },
        { name: "Ativo", showColumn: true },
      ],
      otherButtons: [
        {
          text: "Desbloqueio usuário",
          icon: "todo",
          hint: "Desbloqueio usuário",
          key: new Guid().toString(),
          visible: true,
          command: function (e) {
            inst.$ConAPI
              .get(`/${inst.action}/Unblock(Id=${e.row.key.Id._value})`, {
                headers: {
                  Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  "Content-type": "application/json;odata=verbose",
                },
              })
              .then(() => {
                notify("Usuário desbloqueado com sucesso", "success", 4000);
              })
              .catch((value) => {
                console.log(value);
              });
          },
        },
        {
          text: "Solicitar Permissão",
          icon: "user",
          hint: "Solicitar Permissão",
          key: new Guid().toString(),
          visible: function (e) {
            return (
              inst.usersWithPermission.filter(
                (v) =>
                  v.idPessoa === e.row.data.Id._value &&
                  v.status === "A" &&
                  v.visualizar
              ).length === 0 &&
              inst.usersWithPermission.filter(
                (v) => v.idPessoa === e.row.data.Id._value && v.status === "P"
              ).length === 0 &&
              inst.$root.getAuthData().usuario !== e.row.data.Id._value &&
              inst.$root.getAuthData().isHealthWorker
              // (inst.$root.getAuthData().isEmployee ||
            );
          },
          command: function (e) {
            let myDialog = custom({
              title: "Solicitar permissão de acesso",
              messageHtml:
                "<i>Gostaria de solicitar qual tipo de permissão de acesso?</i>",
              buttons: [
                {
                  text: "Todas as permissões",
                  onClick: () => {
                    inst.askIndividualPermission(e.row.key.Id, {
                      visualizar: true,
                      editar: true,
                    });
                  },
                },
                {
                  text: "Permissão de visualização",
                  onClick: () => {
                    inst.askIndividualPermission(e.row.key.Id, {
                      visualizar: true,
                    });
                  },
                },
                {
                  text: "Permissão de edição",
                  onClick: () => {
                    inst.askIndividualPermission(e.row.key.Id, {
                      editar: true,
                    });
                  },
                },
                {
                  text: "Cancelar",
                  onClick: () => {},
                },
              ],
            });
            myDialog.show();
          },
        },
        {
          text: "Validar Permissão",
          icon: "refresh",
          hint: "Validar Permissão",
          key: new Guid().toString(),
          visible: function (e) {
            return (
              inst.usersWithPermission.filter(
                (v) => v.idPessoa === e.row.data.Id._value && v.status === "P"
              ).length > 0 &&
              inst.$root.getAuthData().usuario !== e.row.data.Id._value &&
              (inst.$root.getAuthData().isEmployee ||
                inst.$root.getAuthData().isHealthWorker)
            );
          },
          command: function (e) {
            let myDialog = custom({
              title: "Validar permissão",
              messageHtml: `<i>Você possui uma permissão pendente para ${e.row.data.Nome}.</i>`,
              buttons: [
                {
                  text: "Verificar permissão",
                  onClick: () => {
                    inst
                      .validateUser(
                        inst.usersWithPermission.filter(
                          (v) =>
                            v.idPessoa === e.row.data.Id._value &&
                            v.status === "P"
                        )[0].permission
                      )
                      .then((r) => {
                        if (r.data.valid) {
                          inst
                            .getUsersWithPermisssion()
                            .then((res) => inst.setUsersWithPermission(res))
                            .then(() => {
                              inst.reloadGridMaster = !inst.reloadGridMaster;
                            });
                        } else {
                          notify(
                            "Solicitação permanece pendente",
                            "warning",
                            4000
                          );
                        }
                      });
                  },
                },
                {
                  text: "Ler QRCode",
                  onClick: () => {
                    inst
                      .validateUser(
                        inst.usersWithPermission.filter(
                          (v) =>
                            v.idPessoa === e.row.data.Id._value &&
                            v.status === "P"
                        )[0].permission
                      )
                      .then((r) => {
                        if (!r.data.valid) {
                          inst.validateUrl =
                            inst.urlPermission + r.data.permission;
                          inst.openPermissionValidation = true;
                        } else {
                          inst
                            .getUsersWithPermisssion()
                            .then((res) => inst.setUsersWithPermission(res))
                            .then(() => {
                              inst.reloadGridMaster = !inst.reloadGridMaster;
                            });
                        }
                      });
                  },
                },
                {
                  text: "Reenviar Solicitação",
                  onClick: () => {
                    inst.validateUser(e.row.key.Id).then((r) => {
                      if (!r.data.valid) {
                        inst
                          .resendNotification(r.data.permission)
                          .then((r) => notify(r.data.message, "success", 4000));
                      }
                    });
                  },
                },
                {
                  text: "Cancelar",
                  onClick: () => {},
                },
              ],
            });
            myDialog.show();
          },
        },
        {
          text: "Dados Usuário",
          icon: "chart",
          hint: "Relatório Usuário",
          key: new Guid().toString(),
          visible: function (e) {
            return (
              inst.usersWithPermission.filter(
                (v) =>
                  v.idPessoa === e.row.data.Id._value &&
                  v.status === "A" &&
                  v.visualizar
              ).length > 0 &&
              inst.$root.getAuthData().usuario !== e.row.data.Id._value &&
              (inst.$root.getAuthData().isEmployee ||
                inst.$root.getAuthData().isHealthWorker)
            );
          },
          command: function (e) {
            inst.$router.push({
              path: `/relatorio-indicadores/${e.row.key.Id._value}`,
            });
          },
        },
        {
          text: "chave",
          icon: "key",
          hint: "Regerar senha",
          key: new Guid().toString(),
          command: function (e) {
            inst.$ConAPI
              .get(`/Acesso/RegerarSenha/${e.row.key.Id._value}`, {
                headers: {
                  Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  "Content-type": "application/json;odata=verbose",
                },
              })
              .then((e) => {
                if (e.data.status === 400)
                  notify(e.data.message, "error", 4000);
                else notify(e.data.message, "success", 4000);
              });
          },
          visible: function (o) {
            return inst.$root.getAuthData().usuario !== o.row.data.Id._value;
          },
        },
        {
          text: "Acesso",
          icon: "group",
          hint: "Acesso",
          visible: inst.$root.getAuthData().acessoGPS,
          key: new Guid().toString(),
          command: function (e) {
            inst.filterByClienteId = e.row.key.Id;
            inst.editConfigPopup = {
              adding: true,
              updating: false,
              deleting: true,
            };
            inst.gridPopupColumns = [
              { name: "Id", notVisible: true },
              { name: "_PessoaFisica", notVisible: true },
              { name: "ObrigarMetodoSalvar", notVisible: true },
              {
                name: "_PessoaJuridica",
                required: true,
                showColumn: true,
                caption: "Empresa",
                lookup: {
                  allowClearing: true,
                  dataSource: {
                    store: new ODataStore({
                      url: `${inst.$UrlAPI}/GPS_PessoaJuridica/GetCompaniesLinkedTo(PessoaFisica=${e.row.key.Id})`,
                      version: 4,
                      key: ["Id"],
                      keyType: {
                        Id: "Guid",
                      },
                      beforeSend: function (e) {
                        e.headers = {
                          Authorization: `Bearer ${
                            inst.$root.getAuthData().token
                          }`,
                        };
                      },
                    }),
                    select: ["Id", "NomeFantasia", "Ativo"],
                    filter: [["Ativo", "=", true]],
                    sort: [{ selector: "NomeFantasia", desc: false }],
                  },
                  displayExpr: "NomeFantasia",
                  valueExpr: "Id",
                },
              },
              {
                name: "ResponsavelContrato",
                caption: "Administrador",
                showColumn: true,
                type: "boolean",
              },
              {
                name: "ResponsavelMedico",
                caption: "Gestor de Saúde",
                showColumn: true,
                type: "boolean",
              },
            ];
            inst.gridPopupAction = "GPS_PessoaJuridicaAcesso";
            inst.reloadGrid = !inst.reloadGrid;
            inst.parentIdField = "_PessoaFisica";
            inst.initInsertingPopupRow = function (e) {
              e.data = {
                ResponsavelContrato: false,
                ResponsavelMedico: false,
                ObrigarMetodoSalvar: false,
              };
            };
            inst.popupImport = false;
            inst.showPopUp("Acesso");
          },
        },
        {
          text: "Pacote",
          icon: "columnfield",
          hint: "Vínculo Pacote",
          visible: inst.$root.getAuthData().acessoGPS,
          key: new Guid().toString(),
          command: function (e) {
            inst.filterByClienteId = e.row.key.Id;
            inst.editConfigPopup = {
              adding: true,
              updating: true,
              deleting: true,
            };
            inst.gridPopupColumns = [
              { name: "Id", notVisible: true },
              { name: "_PessoaFisica", notVisible: true },
              {
                name: "_Pacote",
                required: true,
                showColumn: true,
                caption: "Pacote",
                lookup: {
                  allowClearing: true,
                  dataSource: {
                    store: new ODataStore({
                      url: `${inst.$UrlAPI}/Gps_Pacote`,
                      version: 4,
                      key: ["Id"],
                      keyType: {
                        Id: "Guid",
                      },
                      beforeSend: function (e) {
                        e.headers = {
                          Authorization: `Bearer ${
                            inst.$root.getAuthData().token
                          }`,
                        };
                      },
                    }),
                    select: ["Id", "Nome"],
                    filter: [["Ativo", "=", true]],
                    sort: [{ selector: "Nome", desc: false }],
                    paginate: true,
                  },
                  displayExpr: "Nome",
                  valueExpr: "Id",
                },
              },
            ];
            inst.gridPopupAction = "Gps_PacotePessoaFisica";
            inst.parentIdField = "_PessoaFisica";
            inst.reloadGrid = !inst.reloadGrid;
            inst.popupImport = false;
            inst.showPopUp("Vínculo Pacote");
          },
        },
      ],
      gridActions: [
        {
          widget: "dxButton",
          options: {
            icon: "upload",
            hint: "Importar Usuários",
            onClick: function () {
              inst.$refs[inst.fileUploaderkey].instance.reset();
              inst.hasImportError = false;
              inst.popupImportVisible = true;
            },
          },
          location: "after",
        },
        {
          widget: "dxButton",
          options: {
            icon: "user",
            hint: "Solicitar Permissão",
            onClick: function () {
              if (inst.selectedRows.length > 0) {
                let myDialog = custom({
                  title: "Solicitar permissão",
                  messageHtml:
                    "<i>Solicitar permissões a todos os usuários.</i>",
                  buttons: [
                    {
                      text: "Todas as permissões",
                      onClick: () => {
                        inst.askMultiplePermissions(
                          inst.selectedRows.map((v) => v.Id._value),
                          {
                            visualizar: true,
                            editar: true,
                          }
                        );
                      },
                    },
                    {
                      text: "Permissão de visualização",
                      onClick: () => {
                        inst.askMultiplePermissions(
                          inst.selectedRows.map((v) => v.Id._value),
                          {
                            visualizar: true,
                          }
                        );
                      },
                    },
                    {
                      text: "Permissão de edição",
                      onClick: () => {
                        inst.askMultiplePermissions(
                          inst.selectedRows.map((v) => v.Id._value),
                          {
                            visualizar: true,
                          }
                        );
                      },
                    },
                    {
                      text: "Cancelar",
                      onClick: () => {},
                    },
                  ],
                });
                myDialog.show();
              } else {
                alert(
                  "É preciso haver pelo menos 1 usuário selecionado",
                  "Atenção"
                );
              }
            },
          },
          location: "after",
        },
      ],
      popupGridVisible: false,
      popupTitle: "",
      reloadGrid: false,
      reloadGridMaster: false,
      gridAccessAction: "GPS_PessoaJuridica",
      gridPopupColumns: [],
      gridPopupAction: "",
      parentIdField: "",
      initInsertingPopupRow: null,
      popupImportVisible: false,
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/ProcessarLista`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
        Token: inst.$root.getAuthData().refresh,
      },
      importErrorData: [],
      usersWithPermission: [],
      importErrorReload: false,
      hasImportError: false,
      loading: true,
      fileUploaderkey: "fileUploaderkey",
      openPermissionValidation: false,
      urlPermission: `${inst.$root.$UrlApl}validate-solicitation/A/`,
      validateUrl: "",
      selectedRows: [],
      editConfigPopup: {
        adding: true,
        updating: true,
        deleting: true,
      },
    };
  },
  created: function () {
    this.getUsersWithPermisssion().then((r) => this.setUsersWithPermission(r));
  },
  mounted: function () {},
  methods: {
    formatTelefone: function (tel, tipo = "") {
      if (tel !== undefined && tel != null) {
        switch (tipo) {
          case "celular":
            return tel.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
          case "Residencial":
          case "Trabalho":
          default:
            return tel.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        }
      }

      return "";
    },
    addItem: function () {
      this.$router.push({ path: "/usuario/form" });
    },
    editItem: function (e) {
      console.log(e.data);
      this.$router.push({
        path: `/usuario/form/${e.key.Id._value}/${e.data.IdPessoaJuridica._value}`,
      });
    },
    showPopUp: function (name) {
      this.popupTitle = name;
      this.popupGridVisible = true;
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.length > 0) {
          this.importErrorData = response;
          this.importErrorReload = !this.importErrorReload;
          this.hasImportError = true;
        }
      } else if (e.request.response == "") {
        notify("Importação concluída", "success", 4000);
      } else {
        notify(e.request.response, "error", 4000);
      }
    },
    onUploadError: function (e) {
      notify(e.error.response, "error", 4000);
    },
    setUsersWithPermission: function (data) {
      this.usersWithPermission = data.data;
      this.loading = false;
    },
    getUsersWithPermisssion: function () {
      return this.$ConAPI.get(`/Permission/GetUserWithPermission/`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    askIndividualPermission: function (id, permissions) {
      this.$ConAPI
        .post(
          `/Permission/AskPermission`,
          { id, ...permissions, type: "P" },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then(() => {
          notify("Pedido feito com sucesso", "success", 4000);
          this.getUsersWithPermisssion()
            .then((res) => this.setUsersWithPermission(res))
            .then(() => {
              this.reloadGridMaster = !this.reloadGridMaster;
            });
        })
        .catch(function (error) {
          notify(error.data.message, "error", 4000);
        });
    },
    askMultiplePermissions: function (ids, permissions) {
      this.$ConAPI
        .post(
          `/Permission/AskMultiPermission`,
          { ids, ...permissions, type: "P" },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then(() => {
          notify("Pedido feito com sucesso", "success", 4000);
          this.getUsersWithPermisssion()
            .then((res) => this.setUsersWithPermission(res))
            .then(() => {
              this.reloadGridMaster = !this.reloadGridMaster;
            });
        })
        .catch(function (error) {
          notify(error.data.message, "error", 4000);
        });
    },
    validateUser: function (id) {
      return this.$ConAPI.get(`/Permission/ValidatePermission/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
    resendNotification: function (id) {
      return this.$ConAPI.get(`/Permission/ResendNotification/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$root.getAuthData().token}`,
        },
      });
    },
  },
};
</script>

<style>
.grid-resize {
  height: 80vh;
}

.WrappedColumnClass {
  white-space: normal;
  word-wrap: break-word !important;
}
</style>