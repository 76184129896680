<template>
  <div class="card" v-show="!loading && data.length > 0">
    <loading :loading="loading" />
    <div class="card-content">
      <div class="row">
        <DxSelectBox
          :items="selectorData"
          v-model="selectorFirstValue"
          @value-changed="onValueChanged"
        />
      </div>
      <div class="row">
        <div class="col-md-12">
          <DxPieChart
            class="pie-chart-riscos"
            type="doughnut"
            size-group="piesGroup"
            :data-source="chartData"
            :customize-point="customizePoint"
          >
            <DxSeries argument-field="grau" value-field="value">
              <DxLabel :visible="true" format="percent" position="inside">
                <DxFont :size="20" />
              </DxLabel>
            </DxSeries>
            <DxLegend :visible="false" />
            <DxTooltip :enabled="false" />
          </DxPieChart>
        </div>
      </div>
      <div class="row">
        <table class="table">
          <tbody>
            <tr v-for="(item, idx) in legendData" :key="idx">
              <td scope="col"></td>
              <td scope="col">
                {{ idx + 1 }} -
                {{ item.grau.toUpperCase() }}
              </td>
              <td scope="col">
                {{ item.value }}
              </td>
              <td scope="col">
                <div v-show="item.cor === '#4caf50'">
                  <i
                    class="fas fa-compass"
                    :style="{
                      color: '#4caf50',
                      'font-size': '20px',
                    }"
                    aria-hidden="true"
                  ></i>
                </div>
                <div v-show="item.cor === '#e0c619'">
                  <i
                    class="fas fa-compass"
                    :style="{
                      color: '#e0c619',
                      'font-size': '20px',
                    }"
                    aria-hidden="true"
                  ></i>
                </div>
                <div v-show="item.cor === '#f44336'">
                  <i
                    class="fas fa-compass"
                    :style="{
                      color: '#f44336',
                      'font-size': '20px',
                    }"
                    aria-hidden="true"
                  ></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DxPieChart, {
  DxSeries,
  DxLabel,
  DxLegend,
  DxTooltip,
  DxFont,
} from "devextreme-vue/pie-chart";
import DxSelectBox from "devextreme-vue/select-box";
import Loading from "@/components/loading.vue";

export default {
  name: "CardGraficoRiscos",
  components: {
    DxPieChart,
    DxSeries,
    DxLabel,
    DxLegend,
    DxSelectBox,
    DxTooltip,
    DxFont,
    Loading,
  },
  data: function () {
    return {
      data: [],
      chartData: [],
      selectorData: [],
      selectorFirstValue: "",
      legendData: [],
      loading: true,
    };
  },
  created: function () {
    this.getData().then((value) => {
      if (value.request.response !== "") {
        var resposta = JSON.parse(value.request.response);
        if (Object.keys(resposta.Indicadores[0]).length > 1) {
          this.setDataOnJejum(resposta);
        } else {
          this.setDataCommon(resposta);
        }
      } else {
        this.loading = false;
      }
    });
  },
  updated: function () {
    this.loading = false;
  },
  methods: {
    getData: function () {
      return this.$ConAPI.get(
        `/DadosIndicadores/GetDadosIndicadoresAdmPizza/${
          this.$route.params.id
        }/${this.$root.getAuthData().userCompany}/${
          this.$root.getAuthData().usuario
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        }
      );
    },
    setData: function (d) {
      if (d.length > 0) {
        this.data = d;
        this.selectorData = d.map((value) => value.nome);
        this.selectorFirstValue = d[0].nome;
      } else {
        this.loading = false;
      }
    },
    getChartData: function (nome) {
      const r = this.data.filter((x) => x.nome === nome)[0].riscos;
      const a = r.reduce((p, c) => {
        if (p.find((v) => v.cor === c.cor) === undefined) {
          const vg = this.somaNumerosArray(
            r.filter((v) => v.cor === c.cor).map((x) => x.value)
          );
          p.push({
            grau: c.grau,
            cor: c.cor,
            value: vg / this.data.filter((x) => x.nome === nome)[0].dados.total,
            total: this.data.filter((x) => x.nome === nome)[0].dados.total,
          });
        }
        return p;
      }, []);
      return a;
    },
    customizePoint: function (e) {
      return { color: e.data.cor };
    },
    onValueChanged: function (e) {
      this.legendData = this.data.filter((x) => x.nome === e.value)[0].riscos;
      this.selectorFirstValue = e.value;
      this.chartData = this.getChartData(e.value);
    },
    somaNumerosArray: function (arr) {
      return arr.reduce((a, b) => a + b, 0);
    },
    setDataOnJejum: function (resposta) {
      const a = Object.values(resposta.Indicadores[0]).reduce((p, c) => {
        p.push(c[0]);
        return p;
      }, []);

      const b = a.reduce((p, c) => {
        const o = { nome: c.Nome };

        let f = [];
        let gf = [];
        let dto = {};
        if (o.nome.indexOf("Sem") !== -1) {
          f = Object.keys(c).filter(
            (x) => x.includes("sem") || x.includes("Autorizado")
          );
          c.Faixa.Faixas.filter(
            (x) => x.Nome.includes("sem") || x.Nome.includes("Autorizado")
          ).map((v) => {
            gf.push({ cor: v.CorExibicao, grau: v.Nome, value: 0 });
          });
          gf.map((v) => {
            v.value =
              c[f.filter((x) => x.startsWith(v.grau.split(" (")[0]))[0]];
          });
          f.map((v) => {
            dto[v] = c[v];
          });
          dto.total = f.reduce((prev, cur) => {
            prev += c[cur];
            return prev;
          }, 0);
        } else {
          f = Object.keys(c).filter(
            (x) =>
              x.indexOf("sem") === -1 &&
              (x.includes("jejum") || x.includes("Autorizado"))
          );
          c.Faixa.Faixas.filter(
            (x) =>
              x.Nome.indexOf("sem") === -1 &&
              (x.Nome.includes("jejum") || x.Nome.includes("Autorizado"))
          ).map((v) => {
            gf.push({ cor: v.CorExibicao, grau: v.Nome, value: 0 });
          });
          gf.map((v) => {
            v.value =
              c[f.filter((x) => x.startsWith(v.grau.split(" (")[0]))[0]];
          });
          f.map((v) => {
            dto[v] = c[v];
          });
          dto.total = f.reduce((prev, cur) => {
            prev += c[cur];
            return prev;
          }, 0);
        }
        o.riscos = gf;
        o.dados = dto;
        p.push(o);
        return p;
      }, []);

      this.setData(b);
    },
    setDataCommon: function (resposta) {
      const a = resposta.Indicadores[0].Normal;
      let b = [];
      if (a !== undefined) {
        b = a.reduce((p, c) => {
          const o = { nome: c.Nome };
          let f = [];
          let gf = [];
          let dto = {};

          f = Object.keys(c);
          c.Faixa.Faixas.map((v) => {
            gf.push({ cor: v.CorExibicao, grau: v.Nome, value: 0 });
          });
          gf.map((v) => {
            v.value =
              c[f.filter((x) => x.startsWith(v.grau.split(" (")[0]))[0]];
            dto[v.grau] = v.value;
          });
          dto.total = Object.keys(dto)
            .filter((x) => x !== "total")
            .reduce((prev, cur) => {
              prev += dto[cur];
              return prev;
            }, 0);

          o.riscos = gf;
          o.dados = dto;
          p.push(o);
          return p;
        }, []);
      }

      this.setData(b);
    },
  },
};
</script>

<style>
.pie-chart-riscos {
  width: 283px;
}
</style>