<template>
  <div style="margin-top: 11px; margin-bottom: 20px; padding-bottom: 20px;">
    <loading :loading="$root.loading" />
    <div
      class="col-lg-4 col-md-6 col-sm-6"
      v-for="(item, index) in data"
      :key="index"
      style="padding-bottom: 20px;"
    >
      <div class="card card-stats card-home-top">
        <a :id="(item.tipo).toLowerCase().replace(/ /g, '-')" class="anchor-card"></a>
        <div class="card-header">
          <i
            class="fa"
            :class="item.logo"
            aria-hidden="true"
            style="font-size: 45px"
          ></i>
          <h3 class="card-title title">
            <span>
              {{ item.tipo }}
            </span>
            <div class="stats explanation">
              Clique nos itens abaixo.
            </div>
          </h3>
        </div>
        <DxScrollView
          width="100%"
          height="65vh"
          :showScrollbar="'always'"
          :scroll-by-content="!$root.mobile"
          :scroll-by-thumb="$root.mobile"
        >
          <div class="card-content">
            <table class="table table-hover table-margin">
              <tbody>
                <tr
                  v-for="(item2, index2) in item.indicadores"
                  :key="index2"
                  style="font-size: large; cursor: pointer"
                  @click="goTo(`/indicador/${item2.id}`)"
                >
                  <td scope="col">
                    {{ item2.nome }}
                  </td>
                  <!-- <td class="col-md-1">
                    {{ item2.valor }}
                  </td> -->
                  <td scope="col">
                    <i
                      class="fas fa-compass"
                      :class="{
                        'fa-flip-vertical': item2.cor === '#f44336',
                        'rotate-45': item2.cor === '#e0c619',
                      }"
                      :style="{
                        color: item2.cor ? item2.cor : 'gray',
                        transform: item2.cor ? '' : 'rotate(-45deg)',
                      }"
                      aria-hidden="true"
                    ></i>
                    <!-- <i
                      class="fa fa-bar-chart"
                      :style="{ color: item2.cor }"
                      aria-hidden="true"
                    ></i> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </DxScrollView>
        <div class="card-footer visible-xs text-right">
          <a href="#mobile-topo">Voltar ao topo <i class="fa fa-chevron-circle-up"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";
import Loading from "@/components/loading.vue";

export default {
  name: "IndicadoresResumo",
  components: {
    DxScrollView,
    Loading,
  },
  data: function () {
    return {
      data: [],
    };
  },
  created: function () {
    this.getResumoData();
  },
  methods: {
    getResumoData: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetUltimoValorIndicadores/${
            this.$root.getAuthData().usuario
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.data = resposta;
          this.$emit("finished-loading-data", true);
        })
        .catch(function () {
          //console.log(error);
        });
    },
    goTo: function (url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.table-margin {
  margin: 10px 0;
}
a {
  color: rgba(0, 0, 0, 0.87);
}

.content-size {
  height: 65vh;
  overflow-y: auto;
  margin-bottom: 40px;
}

@media (max-width: 480px) {
  .content-size {
    min-height: 40vh;
    height: auto;
    overflow-y: unset;
  }
}
.card .card-header {
  padding: 15px 15px;
  z-index: 3;
  float: unset;
}

.rotate-45 {
  transform: rotate(45deg);
}

.explanation {
  color: dimgray;
  font-size: 12px;
  font-style: italic;
}
a.anchor-card {
  position: relative;
  top: -16px;
}
</style>
