var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c(
        "div",
        {
          staticClass: "container-fluid",
          class: { "transiton-blur": _vm.$root.loading }
        },
        [
          _c("div", { staticClass: "wrapper wrapper-full-page login row" }, [
            _c("div", { staticClass: "col-md-6 login-forms" }, [
              _c("div", { staticClass: "form-login aceite" }, [
                _vm._m(0),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "checkbox-aceite" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aceiteTermo,
                          expression: "aceiteTermo"
                        }
                      ],
                      attrs: { type: "checkbox", name: "termo", id: "termo" },
                      domProps: {
                        checked: Array.isArray(_vm.aceiteTermo)
                          ? _vm._i(_vm.aceiteTermo, null) > -1
                          : _vm.aceiteTermo
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.aceiteTermo,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.aceiteTermo = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.aceiteTermo = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.aceiteTermo = $$c
                          }
                        }
                      }
                    }),
                    _c("span", { staticClass: "checkmark" })
                  ]),
                  _vm._m(1)
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "checkbox-aceite" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aceitePolitica,
                          expression: "aceitePolitica"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        name: "politica",
                        id: "politica"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.aceitePolitica)
                          ? _vm._i(_vm.aceitePolitica, null) > -1
                          : _vm.aceitePolitica
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.aceitePolitica,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.aceitePolitica = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.aceitePolitica = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.aceitePolitica = $$c
                          }
                        }
                      }
                    }),
                    _c("span", { staticClass: "checkmark" })
                  ]),
                  _vm._m(2)
                ]),
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "\n                btn btn-block\n                w-50\n                btn-login btn-full btn-wd btn-lg\n                mx-auto\n              ",
                      attrs: { disabled: !_vm.aceite },
                      on: { click: _vm.Continuar }
                    },
                    [_vm._v(" Continuar ")]
                  ),
                  _c(
                    "a",
                    { attrs: { href: "#" }, on: { click: _vm.$root.logout } },
                    [_vm._v("Sair")]
                  )
                ])
              ])
            ]),
            _vm._m(3)
          ])
        ]
      ),
      _c("ModaisTermos", {
        attrs: { termo: _vm.termoUso, politica: _vm.politicaPrivacidade }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "login-header text-center",
        attrs: { "data-background-color": "orange" }
      },
      [
        _c("img", {
          staticStyle: { width: "80%" },
          attrs: { src: require("../assets/img/logo_white.png") }
        }),
        _c("h3", { staticClass: "mb-4" }, [
          _vm._v(
            " Você é responsável pela veracidade dos seus dados e nós pela privacidade. "
          )
        ]),
        _c("p", { staticClass: "text-left text-white" }, [
          _vm._v(
            " Para responder questionários, registrar marcações e acessar seus indicadores de saúde, concorde com os termos do GPS Med abaixo: "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label-text" }, [
      _vm._v("Li e concordo com o "),
      _c(
        "a",
        {
          staticClass: "underline",
          attrs: {
            href: "#",
            "data-toggle": "modal",
            "data-target": "#modalTermo"
          }
        },
        [_vm._v("Termos de Uso")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label-text" }, [
      _vm._v("Li e concordo com a "),
      _c(
        "a",
        {
          staticClass: "underline",
          attrs: {
            href: "#",
            "data-toggle": "modal",
            "data-target": "#modalPolitica"
          }
        },
        [_vm._v("Política de Privacidade")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-6 d-none d-md-block login-banner",
        attrs: { id: "aceite-bg" }
      },
      [
        _c("h3", [
          _vm._v(
            " A nossa missão é ajudar você a melhorar a sua saúde e qualidade de vida. "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }