<template>
  <div>
    <loading :loading="$root.loading" />
    <Grid
      :columns="columns"
      :action="action"
      :other-buttons="otherButtons"
      :filter-field="filterField"
      :filter-value="filterValue"
      :reload="reloadGrid"
      class="grid-resize"
      v-on:add-item="addItem"
      v-on:edit-item="editItem"
    />
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
import notify from "devextreme/ui/notify";
import Loading from "../components/loading.vue";

export default {
  name: "ModeloEmail",
  components: {
    Grid,
    Loading,
  },
  data() {
    const inst = this;

    return {
      columns: [
        { name: "Id", notVisible: true },
        { name: "Modelo", notVisible: true },
        { name: "Titulo", defaultSort: true },
        { name: "Categoria.Nome", caption: "Categoria" },
        { name: "Descricao" },
        { name: "Ativo" },
      ],
      action: "Gps_EmailModelo",
      filterField: "",
      filterValue: null,
      otherButtons: [
        {
          text: "Copiar Modelo",
          icon: "copy",
          hint: "Copiar Modelo",
          key: new Guid().toString(),
          visible: true,
          command: function (e) {
            inst.$ConAPI
              .get(`/${inst.action}/Duplicate(Id=${e.row.key.Id._value})`, {
                headers: {
                  Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                },
              })
              .then(() => {
                notify("Modelo Duplicado com sucesso", "success", 4000);
                inst.reloadGrid = !inst.reloadGrid;
              })
              .catch((value) => {
                console.log(value);
              });
          },
        },
      ],
      reloadGrid: false,
    };
  },
  created: function () {
    if (!this.$root.getAuthData().acessoGPS) {
      this.filterField = "PessoaJuridica.Id";
      this.filterValue = new Guid(this.$root.getAuthData().userCompany);
    }
  },
  methods: {
    addItem: function () {
      this.$router.push({ path: "/modelos/form" });
    },
    editItem: function (e) {
      this.$router.push({ path: `/modelos/form/${e.key.Id._value}` });
    },
  },
};
</script>

<style>
</style>