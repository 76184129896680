<template>
  <div class="row vertical-center" style="height: 100vh">
    <div class="col-md-12">
      <div style="text-align: center;">
        <img
          v-if="useLogo"
          src="../assets/img/logo.png"
          style="max-width: 50%"
        />
        <div v-if="!useLogo" v-html="iconHtml"></div>
        <h4 v-if="message !== ''">{{ message }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    message: {
      default: "",
    },
    useLogo: {
      default: true,
    },
    icon: {
      default: "",
    },
    iconColor: {
      default: "black",
    },
  },
  data: function () {
    return {
      iconHtml: "",
    };
  },
  updated: function () {
    this.iconHtml = `<i class='fas ${this.icon}' style='color:${this.iconColor}; font-size:50px' ></i>`;
  },
};
</script>

<style scoped>
.vertical-center {
  margin: 0 auto;
  position: fixed;
  top: 45%;
  width: 95%;
}
</style>
