var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.$root.getAuthData().acessoGPS &&
    _vm.$root.getAuthData().usuario === _vm.$root.getAuthData().userLogged) ||
    (_vm.$root.getAuthData().hasAdminAccess &&
      _vm.$root.getAuthData().usuario === _vm.$root.getAuthData().userLogged)
    ? _c("div", [
        _c(
          "div",
          {
            class: {
              "menu-item": !_vm.$root.mobile,
              controle: _vm.$root.mobile
            },
            on: {
              click: _vm.alterSystem,
              mouseenter: function($event) {
                _vm.altSystemVisible = !_vm.altSystemVisible
              },
              mouseleave: function($event) {
                _vm.altSystemVisible = !_vm.altSystemVisible
              }
            }
          },
          [
            !_vm.$root.mobile
              ? _c("i", {
                  staticClass: "fas fa-suitcase text-orange",
                  attrs: { id: "altSystem" }
                })
              : _vm._e(),
            _vm.$root.mobile
              ? _c("span", { staticClass: "fas fa-suitcase" })
              : _vm._e(),
            _vm.$root.mobile
              ? _c("span", { staticClass: "titulo" }, [
                  _vm._v(_vm._s(_vm.mobileTxt))
                ])
              : _vm._e(),
            !_vm.$root.mobile
              ? _c(
                  "DxTooltip",
                  {
                    key: _vm.tooltipChangeSystem,
                    attrs: {
                      visible: _vm.altSystemVisible,
                      "close-on-outside-click": false,
                      target: "#altSystem"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tooltipChangeSystem))]
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }