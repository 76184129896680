var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "row", staticStyle: { "margin-bottom": "16px" } },
      [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "ul",
            {
              staticClass:
                "nav nav-pills nav-pills-warning nav-pills-icons menu-tabs",
              attrs: { role: "tablist" }
            },
            _vm._l(
              _vm.tabs.filter(function(x) {
                return x.visible
              }),
              function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "nav-item",
                    class: { active: _vm.currentActive === item.ref }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#" + item.ref,
                          role: "tablist"
                        },
                        on: {
                          click: function($event) {
                            _vm.currentActive = item.ref
                          }
                        }
                      },
                      [
                        item.icon
                          ? _c("i", {
                              staticClass: "fa logo-size",
                              class: item.icon,
                              attrs: { "aria-hidden": "true" }
                            })
                          : _vm._e(),
                        !_vm.$root.mobile
                          ? _c("span", {}, [_vm._v(_vm._s(item.nome))])
                          : _vm._e(),
                        _vm.$root.mobile
                          ? _c("span", [_vm._v(_vm._s(item.name))])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }
            ),
            0
          )
        ])
      ]
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12" }, [
        _c("div", { staticClass: "page-categories" }, [
          _c(
            "div",
            { staticClass: "tab-content tab-subcategories" },
            _vm._l(_vm.tabs, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "tab-pane",
                  class: { active: _vm.currentActive === item.ref },
                  attrs: { id: item.ref }
                },
                [_vm._t(item.ref)],
                2
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }