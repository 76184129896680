<template>
  <div class="card">
    <loading :loading="$root.loading" />
    <div class="card-content">
      <h4 class="card-title">Incluir nova medição</h4>
      <form @submit="onFormSubmit($event)">
        <div class="form-group">
          <DxDateBox
            v-model="formData.DataInicial"
            type="datetime"
            placeholder="Data/hora da medição"
            display-format="dd/MM/yyyy HH:mm"
            :picker-type="'roller'"
            :use-mask-behavior="true"
            @initialized="saveDateGridInstance"
            :max="new Date()"
          >
            <DxValidator>
              <DxRequiredRule message="Campo Obrigatório" />
            </DxValidator>
          </DxDateBox>
        </div>
        <div class="form-group" v-for="(item, index) in fields" :key="index">
          <label class="control-label">{{ item.nome.replace("@", "") }}</label>
          <DxNumberBox
            class="form-control form-number-field"
            v-model="medidoresFields[item.id]"
            :show-clear-button="true"
            :placeholder="item.exemplo"
            @initialized="saveNumbersGridInstance"
            :max="99999999"
          >
            <DxValidator>
              <DxRequiredRule message="Campo Obrigatório" />
              <DxPatternRule
                :pattern="/^\d+(\.\d{1,2})?/"
                message="Formato inválido de valor"
              />
            </DxValidator>
          </DxNumberBox>
        </div>
        <div class="form-group label-floating" v-if="mostraJejum">
          <label class="control-label">Jejum</label>
          <DxSwitch class="switch" v-model="formData.Jejum" />
        </div>
        <DxButton
          id="save"
          :use-submit-behavior="true"
          text="Incluir"
          class="btn btn-fill btn-warning"
          >Incluir</DxButton
        >
      </form>
    </div>
  </div>
</template>

<script>
import { DxNumberBox } from "devextreme-vue/number-box";
import DxDateBox from "devextreme-vue/date-box";
import {
  DxValidator,
  DxRequiredRule,
  DxPatternRule,
} from "devextreme-vue/validator";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import Loading from "./loading.vue";

export default {
  name: "CardFormIndicadorMedidor",
  components: {
    DxDateBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxPatternRule,
    DxButton,
    DxSwitch,
    Loading,
  },
  props: {
    fields: {
      default: function () {
        return [];
      },
      required: true,
    },
    id: {
      default: null,
      required: true,
    },
    mostraJejum: {
      default: false,
    },
  },
  watch: {
    fields: function (v) {
      this.setFormDataDefaultItems(v);
    },
  },
  data: function () {
    const inst = this;
    return {
      formData: {
        DataInicial: new Date(),
        Medidores: [],
        DataFinal: null,
        Indicador: this.id,
        Jejum: false,
        PessoaFisica: inst.$root.getAuthData().usuario,
      },
      medidoresFields: {},
      loading: false,
      dateInstance: null,
      numberInstances: [],
    };
  },
  mounted: function () {
    this.setFormDataDefaultItems(this.fields);
  },
  methods: {
    setFormDataDefaultItems: function (f) {
      f.map((value) => {
        this.formData.Medidores.push({ Medidor: value.id, Valor: null });
        this.medidoresFields[value.id] = null;
      });
    },
    onFormSubmit(e) {
      this.loading = true;
      e.preventDefault();
      Object.keys(this.medidoresFields).map((value) => {
        this.formData.Medidores.filter((x) => x.Medidor === value)[0].Valor =
          this.medidoresFields[value];
      });
      this.formData.DataFinal = this.getStringDate(this.formData.DataInicial);
      this.formData.DataFinal = this.getStringDate(new Date());
      this.$ConAPI
        .post(`/Medicao/SaveMedicao/`, this.formData, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          notify("Medição salva com sucesso", "success", 4000);
          this.loading = false;
          this.formData.DataInicial = new Date();
          this.numberInstances.map((value) => {
            value.reset();
          });
          this.$emit("medicao-salva", true);
        })
        .catch(function () {
          // console.log(error);
        });
    },
    saveDateGridInstance: function (e) {
      this.dateInstance = e.component;
    },
    saveNumbersGridInstance: function (e) {
      this.numberInstances.push(e.component);
    },
    getStringDate: function (date_ob) {
      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style scoped>
.f-size {
  font-size: 18px;
}
.form-number-field {
  padding: 0px 0 6px !important;
}
</style>