<template>
  <div id="formulario">
    <loading :loading="loading" />
    <form @submit="onFormSubmit($event)">
      <div class="row">
        <div class="col-md-12" id="dados">
          <div class="card">
            <div
              class="card-header card-header-icon"
              data-background-color="orange"
            >
              <i
                class="fa fa-envelope"
                aria-hidden="true"
                style="font-size: 20px"
              ></i>
            </div>
            <div class="card-content">
              <h4 class="card-title">Dados</h4>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="control-label">Título*</label>
                    <DxTextBox v-model="formData.Titulo">
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Categoria*</label>
                    <DxSelectBox
                      :data-source="$root.emailCategoriasDataSource"
                      display-expr="Nome"
                      value-expr="Id"
                      v-model="formData._Categoria"
                      @selection-changed="categoriaChange"
                      @value-changed="categoriaValueChange"
                    >
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                      </DxValidator>
                    </DxSelectBox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Descrição</label>
                    <DxTextBox v-model="formData.Descricao" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Empresa</label>
                    <DxSelectBox
                      :data-source="$root.empresasDataSource"
                      display-expr="NomeFantasia"
                      value-expr="Id"
                      v-model="formData._IdPessoaJuridica"
                      :searchEnabled="true"
                      :show-clear-button="true"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">Ativo</label>
                    <DxSwitch class="switch" v-model="formData.Ativo" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="showParametros">
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="control-label">Parametro Validador</label>
                    <DxDateBox
                      v-if="parametroType === 'DateTime'"
                      v-model="formData.VlParametroValidador"
                      type="date"
                      display-format="dd/MM/yyyy"
                      :use-mask-behavior="true"
                    >
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                      </DxValidator>
                    </DxDateBox>

                    <DxTextBox
                      v-if="parametroType === 'Int'"
                      v-model="formData.VlParametroValidador"
                    >
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                        <DxPatternRule
                          :pattern="/^[0-9]*$/"
                          message="Valor precisa ser numérico"
                        />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Modelo</h4>
            </div>
            <div id="collapseSenha" class="card-content">
              <div class="container modelo">
                <div class="row">
                  <div class="col-md-12">
                    <div class="buttons">
                      <button
                        type="button"
                        title="Html Básico"
                        @click="setHtmlModelo"
                      >
                        <i class="fas fa-file-code"></i>
                      </button>
                      <div class="separator"></div>
                      <button type="button" title="Negrito" @click="bold">
                        <b><i class="fas fa-bold"></i></b>
                      </button>
                      <button type="button" title="Itálico" @click="italic">
                        <i><i class="fas fa-italic"></i></i>
                      </button>
                      <div class="separator"></div>
                      <button
                        type="button"
                        title="Reutilizar imagem"
                        @click="showImageInclusion('url')"
                      >
                        <b><i class="far fa-image"></i></b>
                      </button>
                      <button
                        title="Upload imagem"
                        type="button"
                        @click="showImageInclusion('upload')"
                      >
                        <i class="fas fa-upload"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <textarea
                      ref="modelo"
                      name="modelo"
                      id="modelo"
                      cols="60"
                      rows="20"
                      v-model="formData.Modelo"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Preview</h4>
            </div>
            <div class="card-content">
              <div class="container modelo">
                <div class="row">
                  <div class="col-md-12">
                    <button type="button" @click="sendEmailTest">
                      Enviar e-mail Teste
                    </button>
                    <div v-html="formData.Modelo"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <DxButton
                id="save"
                :use-submit-behavior="true"
                text="Salvar"
                class="btn btn-warning pull-right"
                >Salvar</DxButton
              >
              <DxButton
                id="back"
                text="Voltar"
                class="btn btn-warning pull-right"
                @click="back"
                >Voltar</DxButton
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <DxPopup
      :fullScreen="$root.mobile"
      :visible.sync="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="500"
      :height="200"
      title="Upload Imagem"
    >
      <div class="row" ref="urlImage">
        <div class="col-md-12">
          <label class="control-label">Nome Imagem</label>
          <input ref="imageName" class="form-control" />
          <button
            type="button"
            style="margin-top: 7px"
            @click="setImageUploaded"
          >
            Inserir imagem
          </button>
        </div>
      </div>
      <div class="row" ref="uploadImage">
        <div class="col-md-12">
          <DxFileUploader
            :multiple="false"
            label-text
            accept="image/*"
            upload-mode="instantly"
            select-button-text="Selecione um arquivo"
            invalid-file-extension-message="Extensão de arquivo não aceita"
            :upload-url="urlUploadImport"
            :allowed-file-extensions="imagesExtensions"
            :upload-headers="uploadImportHeader"
            @uploaded="onUploaded"
          />
        </div>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import Guid from "devextreme/core/guid";
import DxTextBox from "devextreme-vue/text-box";
import {
  DxValidator,
  DxRequiredRule,
  DxPatternRule,
} from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import DxSelectBox from "devextreme-vue/select-box";
import { DxPopup } from "devextreme-vue/popup";
import notify from "devextreme/ui/notify";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import Loading from "@/components/loading.vue";
import DxDateBox from "devextreme-vue/date-box";

String.prototype.replaceAt = function (start, end, replacement) {
  return (
    this.substring(0, start) + replacement + this.substring(end, this.length)
  );
};

export default {
  name: "ModeloEmailForm",
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxPatternRule,
    DxButton,
    DxSwitch,
    DxSelectBox,
    DxPopup,
    DxFileUploader,
    Loading,
    DxDateBox,
  },
  props: {
    id: {
      default: null,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "/Gps_EmailModelo",
      formData: {
        Titulo: "",
        Descricao: "",
        Modelo: "",
        _Categoria: null,
        _IdPessoaJuridica: null,
        Ativo: true,
        VlParametroValidador: "",
      },
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      popupVisible: false,
      previewOptions: null,
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/UploadEmailPhoto`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
      },
      imagesExtensions: [".jpg", ".png", ".jpeg"],
      urlGetPhoto: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=email&image=`,
      modeloId: new Guid(),
      loading: false,
      showParametros: false,
      parametroType: "",
    };
  },
  mounted: function () {
    const inst = this;

    this.previewOptions = {
      icon: "fa fa-eye",
      hint: "Preview Html Model Template",
      onClick: function () {
        inst.showPreview();
      },
    };

    if (this.id !== null) {
      this.$ConAPI
        .get(`/Gps_EmailModelo(Id=${this.id})`, {
          headers: {
            Authorization: `Bearer ${inst.$root.getAuthData().token}`,
          },
        })
        .then((res) => {
          this.setFormData(res.data);
          if (this.parametroType === "Int")
            this.formData.VlParametroValidador = parseInt(
              this.formData.VlParametroValidador
            );
          this.urlUploadImport = `${inst.$root.Controle.UrlAPI}/Upload/UploadEmailPhoto?modelo=${this.id}`;
          this.urlGetPhoto = `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=email&modelo=${this.id}&image=`;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.formData = { Id: this.modeloId, ...this.formData };
      this.urlUploadImport = `${inst.$root.Controle.UrlAPI}/Upload/UploadEmailPhoto?modelo=${inst.modeloId._value}`;
      this.urlGetPhoto = `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=email&modelo=${inst.modeloId._value}&image=`;
    }
  },
  methods: {
    back: function () {
      this.$router.go(-1);
    },
    showImageInclusion: function (type) {
      this.$refs.urlImage.style.display = type == "url" ? "block" : "none";
      this.$refs.uploadImage.style.display =
        type == "upload" ? "block" : "none";
      this.popupVisible = true;
    },
    setFormData: function (v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    onFormSubmit(e) {
      if (this.parametroType === "Int")
        this.formData.VlParametroValidador =
          this.formData.VlParametroValidador.toString();
      if (this.id === null) {
        this.$ConAPI
          .post(`${this.action}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.message, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Alterado com sucesso", "success", 4000);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.data.message, "error", 4000);
          });
      }

      e.preventDefault();
    },
    bold() {
      const start = this.$refs.modelo.selectionStart;
      const end = this.$refs.modelo.selectionEnd;
      const text = this.$refs.modelo.value.substring(start, end);
      this.setTextOnModelo(`<b>${text}</b>`);
    },
    italic() {
      const start = this.$refs.modelo.selectionStart;
      const end = this.$refs.modelo.selectionEnd;
      const text = this.$refs.modelo.value.substring(start, end);
      this.setTextOnModelo(`<i>${text}</i>`);
    },
    setImageUploaded() {
      console.log(this.$refs.imageName);
      if (this.$refs.imageName.value != "")
        this.setTextOnModelo(
          `<img src="${this.urlGetPhoto + this.$refs.imageName.value}" >`
        );

      this.$refs.imageName.value = "";
      this.popupVisible = false;
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.status == 200) {
          notify(response.msg, "success", 4000);
          this.setTextOnModelo(
            `<img src="${this.urlGetPhoto + response.path}" >`
          );
          this.popupVisible = false;
        } else {
          notify(response.msg, "error", 4000);
        }
      }
    },
    setTextOnModelo: function (tagged) {
      const start = this.$refs.modelo.selectionStart;
      const end = this.$refs.modelo.selectionEnd;
      this.formData.Modelo = this.$refs.modelo.value.replaceAt(
        start,
        end,
        tagged
      );
    },
    sendEmailTest() {
      this.loading = true;
      this.$ConAPI
        .post(
          `Email`,
          { Modelo: this.formData.Modelo },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          }
        )
        .then(() => {
          this.loading = false;
          notify("Teste enviado com sucesso", "success", 4000);
        })
        .catch(function (error) {
          notify(error.response.data.message, "error", 4000);
        });
    },
    categoriaChange(e) {
      if (e.selectedItem.ParametroTipo !== null) {
        this.showParametros = true;
        this.parametroType = e.selectedItem.ParametroTipo;
      } else {
        this.showParametros = false;
        this.parametroType = "";
      }
    },
    categoriaValueChange(e) {
      if (e.previousValue !== null) {
        if (this.parametroType === "Int")
          this.formData.VlParametroValidador = 0;
        else this.formData.VlParametroValidador = "";
      }
    },
    setHtmlModelo() {
      const html =
        "<!doctype html>" +
        '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"' +
        'xmlns:o="urn:schemas-microsoft-com:office:office">' +
        "<head>" +
        "<title>" +
        "" +
        "</title>" +
        "<!--[if !mso]><!-->" +
        '<meta http-equiv="X-UA-Compatible" content="IE=edge">' +
        "<!--<![endif]-->" +
        '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">' +
        '<meta name="viewport" content="width=device-width, initial-scale=1">' +
        "</head>" +
        "<body >" +
        "Html Básico" +
        "</body>" +
        "</html>";
      this.setTextOnModelo(html);
    },
  },
};
</script>

<style>
#formulario {
  text-align: left;
}

.switch {
  display: block !important;
}

.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.picedit_box {
  width: 100% !important;
}

.ico-picedit-camera {
  display: none !important;
}

.modelo {
  height: 500px;
  overflow-y: scroll;
  width: 100%;
}

.buttons button {
  margin: 7px 5px;
  border: none;
  border-radius: 3px;
  position: relative;
  padding: 3px 15px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.buttons .separator {
  border: 1px solid;
  display: inline;
  width: 1px;
  height: 27px;
  margin: 0 2px;
}

#modelo {
  resize: none;
}
</style>