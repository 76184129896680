var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.$root.loading } }),
      _c("NoData", {
        attrs: {
          message: _vm.mensagem,
          "use-logo": false,
          icon: _vm.icon,
          iconColor: _vm.iconColor
        },
        on: {
          "update:message": function($event) {
            _vm.mensagem = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }