<template>
  <div>
    <div class="container-fluid">
      <div id="reset" class="wrapper wrapper-full-page row">
        <div class="login-forms">
          <div class="form-login">
            <div class="text-center">
              <img src="../assets/img/logo.png" style="width: 80%" />
            </div>
            <h4>Redefina sua senha abaixo:</h4>
            <senha
              class="senha"
              :validations="userData"
              @valid="isValid = $event"
              @senha="password = $event"
              @confirm="confirm = $event"
            />
            <div class="text-center">
              <DxButton
                v-if="hasLogout"
                text="Sair"
                class="btn btn-warning btn-block btn-full btn-wd btn-lg"
                @click="$root.logout"
                >Sair</DxButton
              >
              <DxButton
                text="Salvar"
                class="btn btn-warning btn-block btn-full btn-wd btn-lg"
                @click="alterarSenha"
                >Alterar senha</DxButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Senha from "../components/senha.vue";
import DxButton from "devextreme-vue/button";
import Guid from "devextreme/core/guid";
import notify from "devextreme/ui/notify";

export default {
  name: "ResetSenha",
  components: {
    Senha,
    DxButton,
  },
  data: function () {
    return {
      formData: {
        NovaSenha: "",
      },
      userData: [],
      isValid: false,
      hasLogout: false,
      id: "",
      token: "",
      password: "",
      confirm: "",
    };
  },
  computed: {
    header() {
      if (
        this.$root.getAuthData() !== undefined &&
        this.$root.getAuthData() !== null
      )
        return {};

      return {
        Token: this.token,
      };
    },
  },
  created: function () {
    const auth = this.$root.getAuthData();
    if (auth !== undefined && auth !== null) {
      this.hasLogout = true;
      this.token = auth.refresh;
      this.id = new Guid(auth.usuario);
    }
    if (
      (this.$route.params.token !== undefined &&
        this.$route.params.token !== null) ||
      (this.$route.params.id !== undefined && this.$route.params.id !== null)
    ) {
      this.token = this.$route.params.token;
      this.id = new Guid(this.$route.params.id);
    }
    this.getUserData().then((r) => {
      this.userData = r.data.value;
    });
  },
  methods: {
    alterarSenha() {
      if (this.isValid) {
        this.$ConAPI
          .post(
            `/Gps_PessoaFisica/AlterarSenha`,
            {
              Id: this.id,
              Senha: this.password,
              Confirmacao: this.confirm,
            },
            {
              headers: this.header,
            }
          )
          .then(() => {
            this.$router.push({ path: "/login" });
          })
          .catch((value) => {
            notify(value.response.data.error.message, "error", 4000);
          });
      }
    },
    getUserData() {
      return this.$ConAPI.get(
        `/Gps_PessoaFisica/UserData(token='${this.token}',id=${this.id})`
      );
    },
  },
};
</script>

<style scoped>
#reset {
  background-color: orange !important;
}

.card {
  border-radius: 10px;
}

#reset .login-forms {
  background-color: orange !important;
  height: 100% !important;
  min-height: 100vh !important;
  display: flex;
  align-items: center;
}
#reset .form-login {
  background-color: white;
  min-width: 470px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  width: 32%;
}

@media (max-width: 420px) {
  #reset .form-login {
    min-width: 410px;
  }
}

@media (max-width: 379px) {
  #reset .form-login {
    min-width: 374px;
  }
}

@media (max-width: 300px) {
  #reset .form-login {
    min-width: 279px;
    word-break: break-all;
  }
}

@media (max-width: 480px) {
  #reset .form-login {
    height: 100%;
    min-height: 100vh;
    margin-top: 0;
    border-radius: 0;
  }
}

#reset .form-login .form-control {
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  height: 40px;
  background: none;
  color: black !important;
  font-size: 16px !important;
}
</style>