<template>
  <div>
    <div v-if="!$root.mobile && !$root.getAuthData().acessoGPS">
      <div
        v-if="photo !== ''"
        class="logo-cliente hidden-xs"
        style="margin: 10px 25px"
      >
        <img :src="companyPhoto" style="max-height: 90px; max-width: 80%" />
      </div>
      <div v-if="icon !== ''" class="logo-cliente-icon">
        <img :src="companyIcon" class="company-icon" />
      </div>
    </div>
    <div
      v-if="photo !== '' && $root.mobile && !$root.getAuthData().acessoGPS"
      style="margin: 0 auto; max-width: 25%"
    >
      <img
        :src="companyPhoto"
        class="hidden-md"
        style="height: 45px; max-width: 100%; height: auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "companyPhoto",
  data: function () {
    const inst = this;
    return {
      companyPhoto: "",
      companyIcon: "",
      defaultPhotoPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=photos&image=`,
      defaulIcontPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=icon&image=`,
    };
  },
  props: {
    photo: {
      default: "",
    },
    icon: {
      default: "",
    },
  },
  watch: {
    photo: function (v) {
      this.setPhoto(v);
    },
    icon: function (v) {
      this.setIcon(v);
    },
  },
  mounted: function () {
    this.setPhoto(this.photo);
    this.setIcon(this.icon);
  },
  methods: {
    setPhoto: function (photo) {
      if (photo !== "" && photo !== undefined && photo !== null) {
        this.companyPhoto = `${this.defaultPhotoPath}${photo}`;
      }
    },
    setIcon: function (icon) {
      if (icon !== "" && icon !== undefined && icon !== null) {
        this.companyIcon = `${this.defaulIcontPath}${icon}`;
      }
    },
  },
};
</script>

<style scoped>
.company-icon {
  height: 66px;
  width: 50px;
  padding: 7px;
}
</style>
