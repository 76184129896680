<template>
  <div
    :style="[
      !closedMenu
        ? {
            overflow: 'hidden',
            height:
              $root.getAuthData().companyPhoto !== '' &&
              $root.getAuthData().companyPhoto !== undefined &&
              $root.getAuthData().companyPhoto !== null &&
              !$root.getAuthData().acessoGPS
                ? '76%'
                : '75%',
          }
        : { overflow: 'initial', height: '100%' },
      $root.mobile ? { overflow: 'initial', height: 'initial' } : {},
    ]"
  >
    <DxScrollView
      width="100%"
      height="100%"
      v-if="!closedMenu"
      :showScrollbar="'always'"
      :scroll-by-content="!$root.mobile"
      :scroll-by-thumb="$root.mobile"
    >
      <ul class="nav">
        <li
          class="main-menu"
          v-for="(item, index) in menu"
          :key="index"
          :class="{
            active: item.key.name === page,
          }"
        >
          <a
            v-if="item.items.length > 0"
            :data-toggle="item.items.length > 0 ? 'collapse' : ''"
            :href="'#Menu' + item.key.name.replace(' ', '').replace(' ', '')"
            :class="{
              closedMenu: closedMenu,
              collapsed: item.items.length > 0,
              menuToggle: item.items.length > 0,
            }"
            aria-expanded="false"
          >
            <span v-if="item.items.length > 0">
              <i class="fa" :class="item.key.icon" aria-hidden="true"></i>
              {{ item.key.name }}
              <b class="caret"></b>
            </span>
          </a>
          <div
            v-if="item.items.length > 0"
            class="collapse"
            :id="'Menu' + item.key.name.replace(' ', '').replace(' ', '')"
            aria-expanded="false"
            :style="[
              closedMenu ? { height: 'initial!important' } : { height: '0' },
            ]"
          >
            <ul class="nav">
              <li
                v-for="(item2, index2) in item.items"
                :key="index2"
                :class="{
                  active: item2.name === subPage,
                }"
                @click="makeActive(item2.name, item.key.name)"
              >
                <i
                  v-show="false"
                  class="fa"
                  :class="item2.meta.menu.icon"
                  aria-hidden="true"
                ></i>
                <router-link
                  :to="
                    '/' + item2.path.replace(':id', $root.getAuthData().usuario)
                  "
                  >{{ item2.name }}</router-link
                >
              </li>
            </ul>
          </div>
          <router-link
            v-if="item.items.length === 0"
            :to="'/' + item.key.path"
            @click.native="makeActive(item.key.name, '')"
          >
            <i class="fa" :class="item.key.icon" aria-hidden="true"></i>
            {{ item.key.name }}
          </router-link>
        </li>
      </ul>
    </DxScrollView>
    <div v-if="closedMenu">
      <ul class="nav">
        <li
          class="main-menu"
          v-for="(item, index) in menu"
          :key="index"
          :class="{
            active: item.key.name === page,
          }"
        >
          <a
            v-if="item.items.length > 0"
            data-toggle="collapse"
            :href="'#Menu' + item.key.name"
            class="collapsed menuToggle"
            :class="{ closedMenu: closedMenu }"
            aria-expanded="false"
          >
            <i class="fa" :class="item.key.icon" aria-hidden="true"></i>
            <!-- <p>
              {{ item.key.name }}
              <b class="caret"></b>
            </p> -->
          </a>
          <div
            v-if="item.items.length > 0"
            class="collapse"
            :id="'Menu' + item.key.name"
            aria-expanded="false"
            :class="{ in: !closedMenu }"
            :style="[
              closedMenu ? { height: 'initial!important' } : { height: '0' },
            ]"
          >
            <DxScrollView
              width="100%"
              height="40vh"
              :scroll-by-content="!$root.mobile"
              :scroll-by-thumb="$root.mobile"
            >
              <ul class="nav">
                <li
                  v-for="(item2, index2) in item.items"
                  :key="index2"
                  :class="{
                    active: item2.name === subPage,
                  }"
                  @click="makeActive(item2.name, item.key.name)"
                >
                  <i
                    v-show="false"
                    class="fa"
                    :class="item2.meta.menu.icon"
                    aria-hidden="true"
                  ></i>
                  <router-link
                    :to="
                      '/' +
                      item2.path.replace(':id', $root.getAuthData().usuario)
                    "
                    >{{ item2.name }}</router-link
                  >
                </li>
              </ul>
            </DxScrollView>
          </div>
          <router-link
            :id="item.key.name.replace(' ', '')"
            :to="'/' + item.key.path"
            v-if="item.items.length === 0"
            @click.native="makeActive(item.key.name, '')"
          >
            <i class="fa" :class="item.key.icon" aria-hidden="true"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "Menu",
  components: {
    DxScrollView,
  },
  props: {
    pageActive: {
      default: "cliente",
    },
    closedMenu: {
      default: false,
    },
    menu: {
      default: function () {
        return [];
      },
    },
  },
  data: function () {
    return {
      page: this.pageActive,
      subPage: this.pageActive,
      uncollapseMenu: false,
      routes: null,
      routesKeys: null,
    };
  },
  watch: {
    menu: function (v) {
      this.loadSelectedMenu(v, this.pageActive);
    },
    pageActive: function (v) {
      this.loadSelectedMenu(this.menu, v);
    },
  },
  created: function () {
    this.loadSelectedMenu(this.menu, this.pageActive);
  },
  methods: {
    makeActive: function (item, root) {
      if (root !== "") {
        this.page = root;
        this.subPage = item;
      } else {
        this.page = item;
        this.subPage = "";
      }
      this.$emit("breadcrumb-page-name", [
        { name: root },
        {
          name: item,
        },
      ]);
    },
    makeSubActive: function (root, item) {
      this.page = root;
      this.subPage = item;
    },
    getRouteMenuKey: function (arr) {
      return arr.reduce((p, c) => {
        const a = c.meta.menu.base;
        if (p.find((v) => v.name === a.name) === undefined) {
          p.push(a);
        }
        return p;
      }, []);
    },
    loadSelectedMenu: function (v, pa) {
      if (!this.$route.name.includes("Recomendacoes Redirect")) {
        let a = "";
        let b = "";
        v.map((value) => {
          if (value.key.name === pa) b = value.key.name;
          if (value.items.filter((v) => v.name === pa)[0]) {
            a = value.key.name;
            b = value.items.filter((v) => v.name === pa)[0].name;
          }
        });
        this.makeActive(b, a);
      } else {
        this.makeActive("Recomendações", "");
      }
    },
  },
};
</script>

<style scoped>
.menuToggle {
  width: 235px;
}

.main-menu {
  width: 100%;
}

.closedMenu {
  pointer-events: none;
  width: 80px;
}

.nav {
  text-align: left;
}

.nav li {
  float: left;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.root-menu-item {
  color: #3c4858 !important;
}

.active a .root-menu-item {
  color: white !important;
}

@media (min-width: 992px) {
  .sidebar-mini .sidebar .collapse .nav {
    width: 235px;
    height: auto;
    position: relative;
    margin: 5px 0;
  }
  .sidebar-mini .sidebar .collapse .nav a {
    float: right;
  }
}
</style>
