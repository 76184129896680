var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          return _vm.onFormSubmit($event)
        }
      }
    },
    [
      _c("div", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-8" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-8" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Nome*")
                        ]),
                        _c(
                          "DxTextBox",
                          {
                            attrs: { maxLength: 240 },
                            model: {
                              value: _vm.formData.Nome,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "Nome", $$v)
                              },
                              expression: "formData.Nome"
                            }
                          },
                          [
                            _c(
                              "DxValidator",
                              [
                                _c("DxRequiredRule", {
                                  attrs: { message: "Campo Obrigatório" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.Principal,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "Principal", $$v)
                          },
                          expression: "formData.Principal"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Exibir como principal")
                      ])
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Tipo Indicador")
                        ]),
                        _c("DxSelectBox", {
                          attrs: {
                            "data-source": _vm.tiposDataSource,
                            "display-expr": "Sigla",
                            "value-expr": "Id"
                          },
                          model: {
                            value: _vm.formData._Tipo,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "_Tipo", $$v)
                            },
                            expression: "formData._Tipo"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Sigla")
                        ]),
                        _c("DxTextBox", {
                          attrs: { maxLength: 200 },
                          model: {
                            value: _vm.formData.Sigla,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Sigla", $$v)
                            },
                            expression: "formData.Sigla"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Ordem")
                        ]),
                        _c("DxNumberBox", {
                          attrs: { "read-only": true },
                          model: {
                            value: _vm.formData.Ordem,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Ordem", $$v)
                            },
                            expression: "formData.Ordem"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Direcionamento")
                        ]),
                        _c("DxSelectBox", {
                          attrs: {
                            items: _vm.direcionamentoItems,
                            "display-expr": "text",
                            "value-expr": "value"
                          },
                          model: {
                            value: _vm.formData.Direcionamento,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Direcionamento", $$v)
                            },
                            expression: "formData.Direcionamento"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Preenchimento:")
                        ]),
                        _c("DxRadioGroup", {
                          attrs: {
                            items: _vm.preenchimentoItens,
                            layout: "horizontal",
                            "display-expr": "text",
                            "value-expr": "value"
                          },
                          model: {
                            value: _vm.formData.Calculado,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Calculado", $$v)
                            },
                            expression: "formData.Calculado"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Ativo")
                        ]),
                        _c("DxSwitch", {
                          staticClass: "switch",
                          model: {
                            value: _vm.formData.Ativo,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Ativo", $$v)
                            },
                            expression: "formData.Ativo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _vm._m(1),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Mostrar para os seguintes sexos")
                        ]),
                        _c("DxSelectBox", {
                          attrs: {
                            items: _vm.sexoItems,
                            "display-expr": "text",
                            "value-expr": "value"
                          },
                          model: {
                            value: _vm.formData.Sexo,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Sexo", $$v)
                            },
                            expression: "formData.Sexo"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Casas Decimais")
                        ]),
                        _c("DxNumberBox", {
                          attrs: { max: 9999 },
                          model: {
                            value: _vm.formData.CasasDecimais,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "CasasDecimais", $$v)
                            },
                            expression: "formData.CasasDecimais"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.Mostra_Jejum,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "Mostra_Jejum", $$v)
                          },
                          expression: "formData.Mostra_Jejum"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Mostrar Campo Jejum na medição")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.MostrarFaixaIndicador,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "MostrarFaixaIndicador", $$v)
                          },
                          expression: "formData.MostrarFaixaIndicador"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Mostrar Faixa Indicador")
                      ])
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.MostrarFaixaGrupo,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "MostrarFaixaGrupo", $$v)
                          },
                          expression: "formData.MostrarFaixaGrupo"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Mostrar Faixa Sub-Indicador")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.MostrarFaixaMedidor,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "MostrarFaixaMedidor", $$v)
                          },
                          expression: "formData.MostrarFaixaMedidor"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Mostrar Faixa Medidor")
                      ])
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.ExibirFaixaUnicoBloco,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "ExibirFaixaUnicoBloco", $$v)
                          },
                          expression: "formData.ExibirFaixaUnicoBloco"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Exibir Medidores Agrupados")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6",
                      staticStyle: { margin: "15px 0" }
                    },
                    [
                      _c("DxCheckBox", {
                        staticStyle: { float: "left", margin: "3px 7px" },
                        attrs: { id: "checkExibiPrincipal" },
                        model: {
                          value: _vm.formData.UtilizaQuestionario,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "UtilizaQuestionario", $$v)
                          },
                          expression: "formData.UtilizaQuestionario"
                        }
                      }),
                      _c("label", { staticStyle: { float: "left" } }, [
                        _vm._v("Exibir Medição como Questionário")
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "DxButton",
                        {
                          staticClass: "btn btn-warning pull-right",
                          attrs: {
                            id: "save",
                            "use-submit-behavior": true,
                            text: "Salvar"
                          }
                        },
                        [_vm._v("Salvar")]
                      ),
                      _c(
                        "DxButton",
                        {
                          staticClass: "btn btn-warning pull-right",
                          attrs: { id: "back", text: "Voltar" },
                          on: { click: _vm.back }
                        },
                        [_vm._v("Voltar")]
                      ),
                      _c("div", { staticClass: "clearfix" })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "card card-profile" }, [
              _c(
                "div",
                {
                  staticClass: "indicador-image-box",
                  class: {
                    "companie-image-bkg-grey": !_vm.showPhoto,
                    "companie-image-bkg-white": _vm.showPhoto
                  },
                  attrs: { id: "imageBox" }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPhoto,
                        expression: "showPhoto"
                      }
                    ],
                    staticClass: "img",
                    attrs: { id: "profilePhoto", src: _vm.photo }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showPhoto,
                          expression: "!showPhoto"
                        }
                      ]
                    },
                    [
                      _c("i", {
                        staticClass:
                          "fas fa-image default-indicador-image-icon",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("DxFileUploader", {
                    ref: "photoFileUploader",
                    attrs: {
                      multiple: false,
                      "label-text": "",
                      accept: "image/*",
                      "upload-mode": "instantly",
                      "select-button-text": "Selecione um arquivo",
                      "invalid-file-extension-message":
                        "Extensão de arquivo não aceita",
                      "upload-url": _vm.urlUploadImport,
                      "allowed-file-extensions": [".jpg", ".png"],
                      "upload-headers": _vm.uploadImportHeader
                    },
                    on: {
                      uploaded: _vm.onUploaded,
                      "value-changed": _vm.photoChanged
                    }
                  }),
                  _vm.showPhoto
                    ? _c(
                        "DxButton",
                        {
                          staticClass:
                            "btn btn-secondary pull-right btn-back separated",
                          attrs: {
                            id: "remove-photo-file",
                            text: "Remover arquivo"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeImage("Photos")
                            }
                          }
                        },
                        [_vm._v("Remover arquivo")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(2),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Descrição")
                        ]),
                        _c("DxTextArea", {
                          attrs: { autoResizeEnabled: true },
                          model: {
                            value: _vm.formData.Descricao,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Descricao", $$v)
                            },
                            expression: "formData.Descricao"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group label-floating" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Formula de Cálculo")
                        ]),
                        _c("DxTextArea", {
                          attrs: { autoResizeEnabled: true },
                          model: {
                            value: _vm.formData.Formula,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "Formula", $$v)
                            },
                            expression: "formData.Formula"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header", staticStyle: { "text-align": "left" } },
      [_c("h4", { staticClass: "card-title" }, [_vm._v("Dados")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Comportamentos")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header", staticStyle: { "text-align": "left" } },
      [_c("h4", { staticClass: "card-title" }, [_vm._v("Descrição e Cálculo")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }