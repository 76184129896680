var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "text-orange",
        class: { "menu-item": !_vm.$root.mobile, controle: _vm.$root.mobile },
        on: {
          click: _vm.contactPage,
          mouseenter: function($event) {
            _vm.contactHintVisible = !_vm.contactHintVisible
          },
          mouseleave: function($event) {
            _vm.contactHintVisible = !_vm.contactHintVisible
          }
        }
      },
      [
        !_vm.$root.mobile
          ? _c("i", {
              staticClass: "fas fa-question-circle orange",
              attrs: { id: "suporte" }
            })
          : _vm._e(),
        _vm.$root.mobile
          ? _c("span", { staticClass: "fas fa-question-circle" })
          : _vm._e(),
        _vm.$root.mobile
          ? _c("span", { staticClass: "titulo" }, [_vm._v(_vm._s(_vm.text))])
          : _vm._e(),
        !_vm.$root.mobile
          ? _c(
              "DxTooltip",
              {
                attrs: {
                  visible: _vm.contactHintVisible,
                  "close-on-outside-click": false,
                  target: "#suporte"
                }
              },
              [_vm._v(_vm._s(_vm.text))]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }