var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between",
        width: "100%"
      }
    },
    [
      _c("div", { staticClass: "logo-mobile" }, [
        _c("img", {
          staticClass: "visible-xs",
          attrs: { src: require("../../assets/img/logo.png") },
          on: {
            click: function($event) {
              return _vm.toPainel()
            }
          }
        })
      ]),
      _c("company-photo", {
        staticClass: "visible-xs logo-mobile",
        staticStyle: { "margin-left": "auto" },
        attrs: {
          photo: _vm.$root.getAuthData().companyPhoto,
          icon: _vm.$root.getAuthData().companyIconPhoto
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }