<template>
  <div>
    <form @submit="onFormSubmit($event)">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Nome*</label>
            <DxTextBox v-model="formData.Nome" :max-length="60">
              <DxValidator>
                <DxRequiredRule message="Campo Obrigatório" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Descrição*</label>
            <DxTextBox v-model="formData.Sigla" :max-length="100">
              <DxValidator>
                <DxRequiredRule message="Campo Obrigatório" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Logo</label>
            <DxTextBox v-model="formData.logo" :max-length="1200"> </DxTextBox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <div class="form-group">
            <label class="control-label">Ordem</label>
            <DxNumberBox v-model="formData.Ordem" :read-only="true" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Status</label>
            <DxSwitch v-model="formData.Ativo" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <DxButton
            id="save"
            :use-submit-behavior="true"
            text="Salvar"
            class="btn btn-warning pull-right"
            >Salvar</DxButton
          >
          <DxButton
            id="back"
            text="Cancel"
            class="btn btn-warning pull-right"
            @click="cancel"
            >Cancelar</DxButton
          >
          <div class="clearfix"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import notify from "devextreme/ui/notify";
import { DxNumberBox } from "devextreme-vue/number-box";

export default {
  name: "TipoIndicador",
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxSwitch,
    DxNumberBox,
  },
  props: {
    id: {
      default: null,
    },
    reloadData: {
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "Gps_IndicadorTipo",
      formData: inst.defaultFormData(),
    };
  },
  watch: {
    id: function (v) {
      this.loadData(v);
    },
    reloadData: function () {
      this.loadData(this.id);
    },
  },
  methods: {
    defaultFormData() {
      return {
        Nome: "",
        Sigla: "",
        logo: "",
        Ativo: true,
        Ordem: 0,
      };
    },
    cancel() {
      this.formData = this.defaultFormData();
      this.$emit("close", true);
    },
    setFormData(v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    loadData(id) {
      if (id !== null) {
        this.$ConAPI
          .get(`/${this.action}(Id=${id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.setFormData(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.formData = this.defaultFormData();
      }
    },
    onFormSubmit(e) {
      if (this.id === null) {
        this.$ConAPI
          .post(`/${this.action}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.formData = this.defaultFormData();
            this.$emit("close", true);
          })
          .catch(function (error) {
            notify(error.response.value, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`/${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Alterado com sucesso", "success", 4000);
            this.formData = this.defaultFormData();
            this.$emit("close", true);
          })
          .catch(function (error) {
            notify(error.response.value, "error", 4000);
          });
      }

      e.preventDefault();
    },
  },
};
</script>

<style scoped>
.info-icon {
  cursor: pointer;
}
</style>