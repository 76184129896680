<template>
  <div
    id="alter-company-comp"
    v-if="$root.getAuthData().defaultSystem === 'Admin'"
  >
    <DxSelectBox
      :key="$root.getAuthData().defaultSystem === 'Admin'"
      :searchEnabled="true"
      display-expr="nome"
      value-expr="id"
      v-model="selectedCompany"
      :disabled="companies.length === 1"
      :data-source="companies"
      @value-changed="onValueChanged"
    >
    </DxSelectBox>
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";

export default {
  name: "AlterCompanyCombo",
  components: { DxSelectBox },
  data: function () {
    const inst = this;
    return {
      selectedCompany:
        inst.$root.getAuthData().userCompany !== ""
          ? inst.$root.getAuthData().userCompany
          : null,
      companies: [],
    };
  },
  created: function () {
    this.getCompanies();
  },
  methods: {
    getCompanies: function () {
      const a = this.$root.getAuthData();
      this.$ConAPI
        .get(`/Menu/GetAdminCompanies/${a.usuario}`, {
          headers: {
            Authorization: `Bearer ${a.token}`,
          },
        })
        .then((result) => {
          this.companies = JSON.parse(result.request.response);
          this.selectedCompany =
            this.$root.getAuthData().userCompany !== ""
              ? this.$root.getAuthData().userCompany
              : null;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onValueChanged: function (e) {
      if (e.event !== undefined) this.$emit("empresa-selecionada", e.value);
    },
  },
};
</script>

<style lang="scss">
div#alter-company-comp {
  @media screen and (max-width: 768px){
    padding: 20px 0 10px;
  }
}
div#alter-company-comp .dx-texteditor.dx-editor-underlined:after {
  border-bottom: unset;
}
div#alter-company-comp .dx-texteditor.dx-editor-underlined {
  border: 1px solid #ff9000;
  border-radius: 20px;
  padding-left: 0.5em;
}

</style>
