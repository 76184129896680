import RelatorioIndicadoresAdmin from "@/views/relatorio-indicadores-admin.vue";
import ModeloEmailForm from "@/views/forms/modelo-email.vue";
import EmpresaForm from "@/views/forms/empresa.vue";
import ClienteForm from "@/views/forms/cliente.vue";
import ProfileForm from "@/views/forms/profile.vue";
import PacoteForm from "@/views/forms/pacote.vue";
import Pacote from "@/views/pacote.vue";
import Cliente from "@/views/cliente.vue";
import Empresa from "@/views/empresa.vue";
import ModeloEmail from "@/views/modelo-email.vue";
import IndicadoresResumoAdmin from "@/views/indicadores-resumo-admin.vue";
import Categorias from "@/views/categorias.vue";
import ClienteIndicador from "@/views/cliente-indicador.vue";
import GerenciarIndicadores from "@/views/gerenciar-indicadores.vue";
import IndicadorForm from "@/views/forms/indicador.vue";


const adminRoutes = [
    {
        path: 'usuario',
        name: 'Usuários',
        component: Cliente,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Usuários'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-user',
                visibility: 'always',
            },
            type: 'Admin',
        }
    },
    {
        path: 'usuario/form',
        name: 'FormCliente',
        component: ClienteForm,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Usuários', link: '/usuario'
            }, {
                name: 'Cadastrar Usuários'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'usuario/form/:id/:company',
        name: 'FormClienteEdit',
        component: ClienteForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Usuários', link: '/usuario'
            }, {
                name: 'Editar Usuários'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'usuario/profile/:id',
        name: 'ProfileForm',
        component: ProfileForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Editar Perfil'
            }],
            type: '',
        }
    },
    {
        path: 'empresa',
        name: 'Empresas',
        component: Empresa,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Empresas'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-building',
                visibility: 'adminGPS',
            },
            type: 'Admin',
        }
    },
    {
        path: 'empresa/form',
        name: 'FormEmpresa',
        component: EmpresaForm,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Empresas', link: '/empresa'
            }, {
                name: 'Cadastrar Empresa'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'empresa/form/:id',
        name: 'FormEmpresaEdit',
        component: EmpresaForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Empresas', link: '/empresa'
            }, {
                name: 'Editar Empresa'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'modelos',
        name: 'Modelo E-mail',
        component: ModeloEmail,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Modelo E-mail'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-envelope',
                visibility: 'adminGPS',
            },
            type: 'Admin',
        }
    },
    {
        path: 'modelos/form',
        name: 'FormModeloEmail',
        component: ModeloEmailForm,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Modelo E-mail', link: '/modelos'
            }, {
                name: 'Cadastrar Modelo de E-mail'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'modelos/form/:id',
        name: 'FormModeloEmailEdit',
        component: ModeloEmailForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Modelo E-mail', link: '/modelos'
            }, {
                name: 'Editar Modelo de E-mail'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'pacote',
        name: 'Pacotes',
        component: Pacote,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Pacotes'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-th-large',
                visibility: 'adminGPS',
            },
            type: 'Admin',
        }
    },
    {
        path: 'pacote/form',
        name: 'FormPacote',
        component: PacoteForm,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Pacotes', link: '/pacote'
            }, {
                name: 'Cadastro Pacote'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'pacote/form/:id',
        name: 'FormPacoteEdit',
        component: PacoteForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Pacotes', link: '/pacote'
            }, {
                name: 'Editar Pacote'
            }],
            type: 'Admin',
        }
    },
    {
        path: 'indicador-admin/:id',
        name: 'Indicador Admin',
        component: ClienteIndicador,
        // props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/'
            }],
            menu: {
                type: 'Admin',
            },
            type: 'Admin'
        }
    },
    {
        path: 'resumo-admin',
        name: 'Indicadores Admin',
        component: IndicadoresResumoAdmin,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores'
            }],
            menu: {
                type: 'Admin',
                order: 1,
                base: {
                    name: 'Indicadores',
                    icon: 'fa-tachometer-alt',
                    path: 'resumo-admin',
                    class: 'active'
                },
                visibility: 'always',
            },
            type: 'Admin',
        }
    },
    {
        path: 'relatorio-admin',
        name: 'Relatório Admin',
        component: RelatorioIndicadoresAdmin,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Indicadores', link: '/resumo-admin'
            }, {
                name: 'Relatórios'
            }],
            menu: {
                type: 'Admin',
                order: 2,
                base: { name: 'Relatórios', icon: 'fa-chart-line', path: 'relatorio-admin' },
                visibility: 'always',
            },
            type: 'Admin',
        }
    },
    {
        path: 'categorias',
        name: 'Categorias',
        component: Categorias,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Categorias'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-user',
                visibility: 'none',
            },
            type: 'Admin',
        }
    },
    {
        path: 'gerenciar-indicadores',
        name: 'Gestão de Indicadores',
        component: GerenciarIndicadores,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Gestão de Indicadores'
            }],
            menu: {
                type: 'Admin',
                order: 3,
                base: { name: 'Administração', icon: 'fa-th-large' },
                icon: 'fa-user',
                visibility: 'adminGPS',
            },
            type: 'Admin',
        }
    },
    {
        path: 'gerenciar-indicadores/form/:type/:id?',
        name: 'Form Indicador',
        component: IndicadorForm,
        props: true,
        meta: {
            breadcrumb: [{
                name: 'Administração'
            }, {
                name: 'Gestão de Indicadores',
                link: '/gerenciar-indicadores'
            }, {
                name: 'Cadastro Indicadores'
            }],
            type: 'Admin',
        },
        beforeEnter(to, from, next) {
            if (to.params.id !== null && to.params.id !== undefined) {
                to.meta.breadcrumb[to.meta.breadcrumb.length - 1] = {
                    name: 'Editar Indicador'
                }
            }
            next();
        },
    },
];


export default adminRoutes;
