var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          "has-header-filter": true,
          columns: _vm.columns,
          action: _vm.action,
          hasDetail: _vm.$root.getAuthData().acessoGPS,
          "other-buttons": _vm.otherButtons,
          "grid-actions": _vm.gridActions,
          "is-user": true,
          reload: _vm.reloadGridMaster,
          selection: {
            mode: "multiple",
            showCheckBoxesMode: "always"
          },
          "delete-message": "Deseja realmetne excluir este usuário?"
        },
        on: {
          "add-item": _vm.addItem,
          "edit-item": _vm.editItem,
          "selected-rows-ids": function($event) {
            _vm.selectedRows = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var master = ref.master
              return [
                _c(
                  "DxTabPanel",
                  [
                    _c("DxItem", {
                      attrs: {
                        title: "Relação com contratantes",
                        template: "relations-tab"
                      }
                    }),
                    _c("Grid", {
                      attrs: {
                        slot: "relations-tab",
                        columns: _vm.detailColumns,
                        action: _vm.detailAction,
                        "parent-id-field": "_PessoaFisica",
                        "filter-value": master.data.Id,
                        "filter-field": _vm.filterDetailBy,
                        "edit-mode": _vm.detailMode
                      },
                      slot: "relations-tab"
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.popupGridVisible,
            "drag-enabled": false,
            "close-on-outside-click": true,
            "show-title": true,
            width: 900,
            height: 400,
            title: _vm.popupTitle,
            fullScreen: _vm.$root.mobile
          },
          on: {
            "update:visible": function($event) {
              _vm.popupGridVisible = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("Grid", {
                attrs: {
                  reload: _vm.reloadGrid,
                  columns: _vm.gridPopupColumns,
                  action: _vm.gridPopupAction,
                  "filter-value": _vm.filterByClienteId,
                  "filter-field": _vm.filterDetailBy,
                  "edit-mode": _vm.detailMode,
                  "parent-id-field": _vm.parentIdField,
                  "init-inserting-row": _vm.initInsertingPopupRow,
                  "can-sort": false,
                  "can-search": false,
                  "edit-config": _vm.editConfigPopup
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            fullScreen: _vm.$root.mobile,
            visible: _vm.popupImportVisible,
            "drag-enabled": false,
            "close-on-outside-click": true,
            "show-title": true,
            width: 1000,
            height: 500,
            title: "Importação"
          },
          on: {
            "update:visible": function($event) {
              _vm.popupImportVisible = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        id: "back",
                        text: "Voltar",
                        href: "../../assets/files/template.xlsx"
                      }
                    },
                    [_vm._v("Download modelo para importação")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("DxFileUploader", {
                      ref: _vm.fileUploaderkey,
                      attrs: {
                        accept: ".xlsx, .xls",
                        "upload-mode": "instantly",
                        "select-button-text": "Selecione um arquivo",
                        "invalid-file-extension-message":
                          "Extensão de arquivo não aceita",
                        "upload-url": _vm.urlUploadImport,
                        multiple: false,
                        "allowed-file-extensions": [".xlsx", ".xls"],
                        "upload-headers": _vm.uploadImportHeader
                      },
                      on: {
                        uploaded: _vm.onUploaded,
                        "upload-error": _vm.onUploadError
                      }
                    }),
                    _c("Grid", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasImportError,
                          expression: "hasImportError"
                        }
                      ],
                      attrs: {
                        reload: _vm.importErrorReload,
                        data: _vm.importErrorData,
                        columns: [
                          { name: "Nome", showColumn: true },
                          { name: "Email", showColumn: true },
                          { name: "CPF", showColumn: true },
                          { name: "CNPJ", showColumn: true },
                          { name: "DescricaoErro", showColumn: true }
                        ],
                        "edit-mode": ""
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openPermissionValidation,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": true,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "80vh",
            height: "70vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.openPermissionValidation = $event
            }
          }
        },
        [
          _c("PermissionValidation", {
            attrs: {
              "use-qr": true,
              "permission-url": _vm.validateUrl,
              message:
                "Peça ao usuário para ler o código acima para fornecer permissão<br/>Ou clique no botão para copiar o link de validação:",
              "ask-permission": false,
              "validate-permission": false
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }