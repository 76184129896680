var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: [
        !_vm.closedMenu
          ? {
              overflow: "hidden",
              height:
                _vm.$root.getAuthData().companyPhoto !== "" &&
                _vm.$root.getAuthData().companyPhoto !== undefined &&
                _vm.$root.getAuthData().companyPhoto !== null &&
                !_vm.$root.getAuthData().acessoGPS
                  ? "76%"
                  : "75%"
            }
          : { overflow: "initial", height: "100%" },
        _vm.$root.mobile ? { overflow: "initial", height: "initial" } : {}
      ]
    },
    [
      !_vm.closedMenu
        ? _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c(
                "ul",
                { staticClass: "nav" },
                _vm._l(_vm.menu, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "main-menu",
                      class: {
                        active: item.key.name === _vm.page
                      }
                    },
                    [
                      item.items.length > 0
                        ? _c(
                            "a",
                            {
                              class: {
                                closedMenu: _vm.closedMenu,
                                collapsed: item.items.length > 0,
                                menuToggle: item.items.length > 0
                              },
                              attrs: {
                                "data-toggle":
                                  item.items.length > 0 ? "collapse" : "",
                                href:
                                  "#Menu" +
                                  item.key.name
                                    .replace(" ", "")
                                    .replace(" ", ""),
                                "aria-expanded": "false"
                              }
                            },
                            [
                              item.items.length > 0
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa",
                                      class: item.key.icon,
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(" " + _vm._s(item.key.name) + " "),
                                    _c("b", { staticClass: "caret" })
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      item.items.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "collapse",
                              style: [
                                _vm.closedMenu
                                  ? { height: "initial!important" }
                                  : { height: "0" }
                              ],
                              attrs: {
                                id:
                                  "Menu" +
                                  item.key.name
                                    .replace(" ", "")
                                    .replace(" ", ""),
                                "aria-expanded": "false"
                              }
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "nav" },
                                _vm._l(item.items, function(item2, index2) {
                                  return _c(
                                    "li",
                                    {
                                      key: index2,
                                      class: {
                                        active: item2.name === _vm.subPage
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.makeActive(
                                            item2.name,
                                            item.key.name
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false"
                                          }
                                        ],
                                        staticClass: "fa",
                                        class: item2.meta.menu.icon,
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/" +
                                              item2.path.replace(
                                                ":id",
                                                _vm.$root.getAuthData().usuario
                                              )
                                          }
                                        },
                                        [_vm._v(_vm._s(item2.name))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      item.items.length === 0
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: "/" + item.key.path },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.makeActive(item.key.name, "")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa",
                                class: item.key.icon,
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" " + _vm._s(item.key.name) + " ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm.closedMenu
        ? _c("div", [
            _c(
              "ul",
              { staticClass: "nav" },
              _vm._l(_vm.menu, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "main-menu",
                    class: {
                      active: item.key.name === _vm.page
                    }
                  },
                  [
                    item.items.length > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "collapsed menuToggle",
                            class: { closedMenu: _vm.closedMenu },
                            attrs: {
                              "data-toggle": "collapse",
                              href: "#Menu" + item.key.name,
                              "aria-expanded": "false"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa",
                              class: item.key.icon,
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      : _vm._e(),
                    item.items.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "collapse",
                            class: { in: !_vm.closedMenu },
                            style: [
                              _vm.closedMenu
                                ? { height: "initial!important" }
                                : { height: "0" }
                            ],
                            attrs: {
                              id: "Menu" + item.key.name,
                              "aria-expanded": "false"
                            }
                          },
                          [
                            _c(
                              "DxScrollView",
                              {
                                attrs: {
                                  width: "100%",
                                  height: "40vh",
                                  "scroll-by-content": !_vm.$root.mobile,
                                  "scroll-by-thumb": _vm.$root.mobile
                                }
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "nav" },
                                  _vm._l(item.items, function(item2, index2) {
                                    return _c(
                                      "li",
                                      {
                                        key: index2,
                                        class: {
                                          active: item2.name === _vm.subPage
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.makeActive(
                                              item2.name,
                                              item.key.name
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: false,
                                              expression: "false"
                                            }
                                          ],
                                          staticClass: "fa",
                                          class: item2.meta.menu.icon,
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                item2.path.replace(
                                                  ":id",
                                                  _vm.$root.getAuthData()
                                                    .usuario
                                                )
                                            }
                                          },
                                          [_vm._v(_vm._s(item2.name))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    item.items.length === 0
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              id: item.key.name.replace(" ", ""),
                              to: "/" + item.key.path
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.makeActive(item.key.name, "")
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa",
                              class: item.key.icon,
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }