var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-minimize" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-round btn-white btn-fill btn-just-icon",
        attrs: { id: "minimizeSidebar" },
        on: {
          click: function($event) {
            return _vm.$emit("close-menu", true)
          }
        }
      },
      [
        _c("i", {
          staticClass: "fa fa-ellipsis-v",
          attrs: { "aria-hidden": "true" }
        }),
        _c("i", {
          staticClass: "fa fa-bars visible-on-sidebar-mini",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }