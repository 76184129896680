var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      [
        _c("DxValidationGroup", { ref: "validationGroup" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Nome*")
                  ]),
                  _c(
                    "DxTextBox",
                    {
                      model: {
                        value: _vm.formData.Nome,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "Nome", $$v)
                        },
                        expression: "formData.Nome"
                      }
                    },
                    [
                      _c(
                        "DxValidator",
                        { attrs: { name: "ValidateNome", maxLength: 200 } },
                        [
                          _c("DxRequiredRule", {
                            attrs: { message: "Campo Obrigatório" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Ativo")
                  ]),
                  _c("br"),
                  _c("DxSwitch", {
                    model: {
                      value: _vm.formData.Ativo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Ativo", $$v)
                      },
                      expression: "formData.Ativo"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Risco")
                  ]),
                  _c("DxSelectBox", {
                    attrs: {
                      items: _vm.grauItems,
                      "display-expr": "text",
                      "value-expr": "value",
                      "item-template": "item"
                    },
                    on: { "value-changed": _vm.onGrauChanged },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(ref) {
                          var data = ref.data
                          return [
                            _c("div", [
                              data.color !== "#FFFFFF"
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$root.Controle.UrlAPI +
                                        "/Upload/GetPhoto?type=icon&image=" +
                                        data.color.substring(1) +
                                        ".png",
                                      height: "15",
                                      width: "15"
                                    }
                                  })
                                : _vm._e(),
                              _c("div", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(data.text))
                              ])
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.formData.Grau,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Grau", $$v)
                      },
                      expression: "formData.Grau"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Cor Exibição")
                  ]),
                  _c("DxColorBox", {
                    attrs: { "apply-value-mode": "instantly" },
                    model: {
                      value: _vm.formData.CorExibicao,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "CorExibicao", $$v)
                      },
                      expression: "formData.CorExibicao"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Jejum")
                  ]),
                  _c("br"),
                  _c("DxSwitch", {
                    model: {
                      value: _vm.formData.Jejum,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Jejum", $$v)
                      },
                      expression: "formData.Jejum"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Sexo")
                  ]),
                  _c("DxSelectBox", {
                    attrs: {
                      items: _vm.sexoItems,
                      "display-expr": "text",
                      "value-expr": "value"
                    },
                    model: {
                      value: _vm.formData.Sexo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Sexo", $$v)
                      },
                      expression: "formData.Sexo"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Valor Inicial")
                  ]),
                  _c("DxNumberBox", {
                    attrs: { max: 99999999 },
                    model: {
                      value: _vm.formData.ValorInicial,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "ValorInicial", $$v)
                      },
                      expression: "formData.ValorInicial"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Valor Final")
                  ]),
                  _c("DxNumberBox", {
                    attrs: { max: 99999999, min: this.formData.ValorInicial },
                    model: {
                      value: _vm.formData.ValorFinal,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "ValorFinal", $$v)
                      },
                      expression: "formData.ValorFinal"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Idade Inicial")
                  ]),
                  _c("DxNumberBox", {
                    attrs: { max: 9999 },
                    model: {
                      value: _vm.formData.IdadeInicial,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "IdadeInicial", $$v)
                      },
                      expression: "formData.IdadeInicial"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Idade Final")
                  ]),
                  _c("DxNumberBox", {
                    attrs: { max: 9999, min: _vm.formData.IdadeInicial },
                    model: {
                      value: _vm.formData.IdadeFinal,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "IdadeFinal", $$v)
                      },
                      expression: "formData.IdadeFinal"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Unidade")
                  ]),
                  _c("DxTextBox", {
                    model: {
                      value: _vm.formData.Unidade,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Unidade", $$v)
                      },
                      expression: "formData.Unidade"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Link")
                  ]),
                  _c("DxTextBox", {
                    attrs: { mode: "url", maxLength: 2400 },
                    model: {
                      value: _vm.formData.RecomendacaoLink,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "RecomendacaoLink", $$v)
                      },
                      expression: "formData.RecomendacaoLink"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Recomendação")
                  ]),
                  _c("DxTextBox", {
                    model: {
                      value: _vm.formData.Recomendacao,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "Recomendacao", $$v)
                      },
                      expression: "formData.Recomendacao"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "DxButton",
                  {
                    staticClass: "btn btn-warning pull-right",
                    attrs: { id: "save", text: "Salvar" },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Salvar")]
                ),
                _c(
                  "DxButton",
                  {
                    staticClass: "btn btn-warning pull-right",
                    attrs: { id: "back", text: "Cancel" },
                    on: { click: _vm.cancel }
                  },
                  [_vm._v("Cancelar")]
                ),
                _c("div", { staticClass: "clearfix" })
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }