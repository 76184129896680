var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          columns: _vm.columns,
          action: _vm.action,
          "other-buttons": _vm.otherButtons,
          reload: _vm.reloadEmpresaGrid,
          "delete-message": "Deseja desativar esta empresa?"
        },
        on: { "add-item": _vm.addItem, "edit-item": _vm.editItem }
      }),
      _c(
        "DxPopup",
        {
          attrs: {
            fullScreen: _vm.$root.mobile,
            visible: _vm.popupVisible,
            "drag-enabled": false,
            "close-on-outside-click": true,
            "show-title": true,
            width: 900,
            height: 400,
            title: _vm.popupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.popupVisible = $event
            }
          }
        },
        [
          _c("Grid", {
            attrs: {
              reload: _vm.reloadGrid,
              columns: _vm.gridPopupColumns,
              action: _vm.gridPopupAction,
              "filter-value": _vm.filterByClienteId,
              "filter-field": _vm.filterDetailBy,
              "edit-mode": _vm.detailMode,
              "parent-id-field": _vm.parentIdField,
              "init-inserting-row": _vm.initInsertingPopupRow,
              "can-search": false,
              "can-sort": false,
              "edit-config": _vm.editConfigPopup
            }
          })
        ],
        1
      ),
      _c(
        "DxPopup",
        {
          attrs: {
            fullScreen: _vm.$root.mobile,
            visible: _vm.popupIndicadoresVisible,
            "drag-enabled": false,
            "close-on-outside-click": true,
            "show-title": true,
            width: 900,
            height: 500,
            title: _vm.popupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.popupIndicadoresVisible = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("Grid", {
                attrs: {
                  reload: _vm.reloadIndicadoresGrid,
                  data: _vm.indicadoresDataSource,
                  "grid-actions": _vm.gridIndicadoresActions,
                  columns: [
                    { name: "id", notVisible: true },
                    { name: "tipo", showColumn: true },
                    { name: "indicadorNome", showColumn: true }
                  ],
                  "edit-mode": "",
                  selection: {
                    mode: "multiple",
                    showCheckBoxesMode: "always"
                  },
                  "selected-rows": _vm.selectedRows
                },
                on: { "selected-rows-ids": _vm.selectedRowsIds }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }