var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "row relatorio-card-header" }, [
            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
              !_vm.getModuleData
                ? _c("h4", [_vm._v(_vm._s(_vm.indicador.Indicador))])
                : _vm._e()
            ]),
            _c("div", { staticClass: "col-md-8 col-sm-12" }, [
              _c("div", { staticClass: "infos-indicador" }, [
                _c("div", { staticClass: "ultima-medicao" }, [
                  _c("h6", [_vm._v("última medição:")]),
                  _c("span", [_vm._v(_vm._s(_vm.indicador.ultimaMedicaoData))])
                ])
              ]),
              _c(
                "div",
                { staticClass: "indicador-btns" },
                [
                  _vm.showRecommendationButton
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-warning",
                          attrs: {
                            to: "/recomendacoes/" + _vm.indicador.idIndicador
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-lightbulb" }),
                          _c("span", [_vm._v("Recomendações")])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.indicador.usaMedidor &&
                            _vm.indicador.Referencias[0].Medidores.find(
                              function(x) {
                                return x.ValoresReferencia.length > 0
                              }
                            ) !== undefined,
                          expression:
                            "\n                indicador.usaMedidor &&\n                indicador.Referencias[0].Medidores.find(\n                  (x) => x.ValoresReferencia.length > 0\n                ) !== undefined\n              "
                        }
                      ],
                      staticClass: "btn btn-warning",
                      on: {
                        click: function($event) {
                          _vm.popupVisible = !_vm.popupVisible
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-sliders-h" }),
                      _c("span", [_vm._v("Valores de referência")])
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
              _c(
                "div",
                { staticClass: "row", staticStyle: { margin: "unset" } },
                [
                  _vm.loadedData
                    ? _c("CardTableData", {
                        key: _vm.indicador.Id,
                        attrs: {
                          data: _vm.tableData,
                          type: _vm.tipoTabela,
                          "selected-keys": _vm.firstSelected,
                          "unique-block": _vm.indicador.exibeFaixaUnicoBloco
                        },
                        on: { "selection-change": _vm.selectionChange }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              { class: "col-md-8" },
              [
                _vm.loadedData
                  ? _c("GraficoMedicoes", {
                      key: _vm.indicador.Id,
                      attrs: {
                        dados: _vm.chartData,
                        "type-data": _vm.tipoTabela,
                        "receive-data": true,
                        indicador:
                          _vm.tipoTabela !== "Grupos"
                            ? _vm.indicador.Indicador
                            : "",
                        "cor-ultima-medicao": _vm.indicador.corUltimaMedicao
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "DxPopup",
        {
          attrs: {
            "full-screen": _vm.$root.mobile,
            visible: _vm.popupVisible,
            "show-close-button": true,
            title: "Valores de Referência",
            height: "450px"
          },
          on: {
            "update:visible": function($event) {
              _vm.popupVisible = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: !_vm.$root.mobile ? "380px" : "inherit",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("TabelaReferencias", {
                attrs: {
                  "show-title": false,
                  data: _vm.indicador.Referencias[0],
                  "exibe-faixa-unico-bloco": _vm.indicador.exibeFaixaUnicoBloco
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }