<template>
  <div class="container visible-xs">
    <div v-if="isHome" class="row menuMobile">
      <div class="">
        <profile class="col-xs-4 text-center"
                 :disabled="isAcessedByHealthWorker"
                 :notification-counter="notificationCounter"
                 :class="isAcessedByHealthWorker ? 'desabilitado' : ''"
        />
        <div class="col-xs-4 text-center">
          <a href="#" @click="goToResults()" class="controle">
            <span class="fas fa-chart-line"></span>
            <span class="titulo">{{
              $root.getAuthData().defaultSystem === "Cliente"
                ? "Resultados"
                : "Relatório"
            }}</span>
          </a>
        </div>
        <div
          v-if="$root.getAuthData().defaultSystem === 'Cliente'"
          class="col-xs-4 text-center"
        >
          <div @click="goToRecomendations()" class="controle">
            <span class="fas fa-lightbulb"></span>
            <span class="titulo">Recomendações</span>
          </div>
        </div>
        <permissions-action
          class="col-xs-4 text-center"
          :disabled="isAcessedByHealthWorker"
          :notification-counter="notificationCounter"
          :class="isAcessedByHealthWorker ? 'desabilitado' : ''"
          @page-name="$emit('page-name', $event)"
        />
        <AlterSystemAction
          :class="['col-xs-4', 'text-center']"
          @altered-system="$emit('altered-system', $event)"
        />
        <ContactsACtion :class="['col-xs-4', 'text-center']" />
        <LogoutAction :class="['col-xs-4', 'text-center']" />
      </div>
    </div>

    <div v-if="!isHome && !isIndicadorPage" class="row menuMobile">
      <div class="col-xs-12 text-center">
        <a href="#" @click="goToHome()" class="controle">
          <span class="fas fa-home"></span>
          <span class="titulo">Página Inicial</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AlterSystemAction from "@/components/topo-actions/alter-system.vue";
import LogoutAction from "@/components/topo-actions/logout.vue";
import ContactsACtion from "@/components/topo-actions/contacts.vue";
import PermissionsAction from "@/components/topo-actions/permissions.vue";
import Profile from "@/components/topo-actions/profile.vue";

export default {
  name: "menu-mobile",
  components: {
    AlterSystemAction,
    LogoutAction,
    ContactsACtion,
    PermissionsAction,
    Profile
  },
  props: ["isHome", "notificationCounter"],
  computed: {
    admin: function () {
      return (
        (this.$root.getAuthData().acessoGPS &&
          this.$root.getAuthData().usuario ===
            this.$root.getAuthData().userLogged) ||
        (this.$root.getAuthData().hasAdminAccess &&
          this.$root.getAuthData().usuario ===
            this.$root.getAuthData().userLogged)
      );
    },
    isIndicadorPage: function () {
      return (
        this.$route.name === "Indicador Usuário" ||
        this.$route.name === "Indicador Admin"
      );
    },
    isAcessedByHealthWorker: function () {
      return (
        this.$root.getAuthData().usuario !== this.$root.getAuthData().userLogged
      );
    },
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.id[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    goToProfile() {
      if (
        this.$root.getAuthData().usuario === this.$root.getAuthData().userLogged
      ) {
        this.$emit("page-name", "Perfil");
        this.$router.push({
          name: `ProfileForm`,
          params: { id: this.$root.getAuthData().usuario },
        });
      }
    },
    goToResults() {
      this.$emit("page-name", "Resultados");
      if (this.$root.getAuthData().defaultSystem === "Cliente")
        this.$router.push({
          name: "Resultados",
          params: { id: this.$root.getAuthData().usuario },
        });
      else
        this.$router.push({
          name: "Relatório Admin",
        });
    },
    goToRecomendations() {
      this.$emit("page-name", "Recomendações");
      this.$router.push({
        path: `/recomendacoes`,
      });
    },
    goToPermissions() {
      this.$emit("page-name", "Permissões");
      this.$router.push({
        path: `/permissions`,
      });
    },
    goToHome() {
      this.$router.push({
        path: `/`,
      });
    },
  },
};
</script>

<style lang="scss">
.menuMobile {
  @media (max-width: 680px) {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }
  margin: 0;
  margin-top: 20px;
  > div {
    display: block;
  }
  .controle {
    color: orange;
    display: inline-block;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 13px;
    cursor: pointer;
    &.desabilitado {
      color: gray !important;
      pointer-events: none;
      cursor: none;
    }
    span.fas {
      font-size: 3rem;
      display: block !important;
      margin: auto;
    }
    span.titulo {
      font-size: 1rem;
      display: block;
    }
  }
}
// <a href="#mobile-topo" class="btn-to-top visible-xs">
//   <i class="fas fa-chevron-circle-up"></i>
// </a>
//.btn-to-top {
//  position: fixed;
//  bottom: 20px;
//  right: 20px;
//  z-index: 999;
//  color: orange !important;
//  font-size: 3rem;
//  opacity: 0.5;
//}
</style>
