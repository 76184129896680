var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.getAuthData().isHealthWorker &&
    _vm.$root.getAuthData().defaultSystem === "Cliente"
    ? _c(
        "div",
        { attrs: { id: "alter-user-comp" } },
        [
          _c("DxAutocomplete", {
            key: _vm.$root.getAuthData().defaultSystem === "Cliente",
            attrs: {
              searchEnabled: true,
              "search-expr": "nome",
              "display-expr": "nome",
              "value-expr": "nome",
              placeholder: "Digite o nome do paciente",
              "data-source": _vm.usersDataSource,
              "show-clear-button": true,
              "item-template": "item"
            },
            on: {
              "selection-changed": _vm.onSelectionChangedUsers,
              initialized: _vm.saveSelectUserInstance
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var data = ref.data
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "product-name",
                          class: {
                            "text-danger":
                              !data.permissionEdit ||
                              (data.permissionEditDenied &&
                                !data.permissionEditAccepted &&
                                !data.permissionEditPending),
                            "text-warning":
                              data.permissionEdit && data.permissionEditPending,
                            "text-success":
                              data.permissionEdit && data.permissionEditAccepted
                          }
                        },
                        [_vm._v(" " + _vm._s(data.nome) + " ")]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              855660206
            ),
            model: {
              value: _vm.selectedUser,
              callback: function($$v) {
                _vm.selectedUser = $$v
              },
              expression: "selectedUser"
            }
          }),
          _c(
            "DxPopup",
            {
              attrs: {
                visible: _vm.openPermissionValidation,
                fullScreen: _vm.$root.mobile,
                "drag-enabled": false,
                "close-on-outside-click": true,
                showCloseButton: true,
                showTitle: true,
                id: "permission-validation",
                width: "80vh",
                height: "70vh"
              },
              on: {
                "update:visible": function($event) {
                  _vm.openPermissionValidation = $event
                }
              }
            },
            [
              _c("PermissionValidation", {
                staticStyle: { "margin-top": "23px" },
                attrs: {
                  "use-qr": true,
                  "permission-url": _vm.urlValidatePermission,
                  message:
                    "Peça ao usuário para ler o código acima para fornecer permissão<br/>Ou clique no botão para copiar o link de validação:",
                  "ask-permission": false,
                  "validate-permission": false
                },
                on: { "resend-permission": _vm.resendNotification }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }