<template>
  <div>
    <div class="wrapper" :class="{ 'sidebar-mini': closeMenu }">
      <div
        class="sidebar"
        data-active-color="orange"
        data-background-color="white"
      >
        <site-logo @page-name="pageName = $event" />
        <company-photo
          :photo="$root.getAuthData().companyPhoto"
          :icon="$root.getAuthData().companyIconPhoto"
        />
        <div
          class="sidebar-wrapper"
          :style="{
            height: !$root.mobile ? 'calc(100vh - 125px)!important' : 'inherit',
          }"
        >
          <div class="user">
            <profile-photo
              :key="'profile-photo' + alteredSystem + reloadChild"
              :foto="$root.getAuthData().profilePhoto"
            />
            <user-info-menu
              :key="$route.path + reloadChild"
              :notification-counter="countPendingPermissions"
            />
          </div>
          <Menu
            :key="'Menu'"
            :closed-menu="closeMenu"
            :menu="
              $root.getAuthData().defaultSystem === 'Admin'
                ? adminMenu
                : clientMenu
            "
            :page-active="pageName"
            @breadcrumb-page-name="getBreadcrumbName($event)"
          />
        </div>
      </div>
      <div class="main-panel">
        <a id="mobile-topo" class="ancor-top visible-xs"></a>
        <nav
          class="navbar navbar-transparent navbar-absolute"
          id="menu-topo-mobile"
        >
          <div class="container-fluid">
            <minimize-menu @close-menu="closeMenu = !closeMenu" />
            <div class="navbar-header" style="background-color: white">
              <topo-mobile
                :key="'topo-mobile' + reloadChild"
                @page-name="pageName = $event"
              />
              <h3 class="page__title visible-xs">{{ pageName }}</h3>
            </div>
            <topo-menu
              v-if="!$root.mobile"
              :notificationCounter="countPendingPermissions"
              @empresa-selecionada="changeCompany"
              @change-user="userChanged"
              @altered-system="alteredSystem = !alteredSystem"
            />
          </div>
        </nav>
        <div class="content">
          <div class="container-fluid" id="content-area">
            <div class="row">
              <h3 class="page__title hidden-xs">{{ pageName }}</h3>
              <DxScrollView
                id="content_view"
                width="100%"
                :show-scrollbar="'onScroll'"
                @initialized="saveInstance"
                :scroll-by-content="!$root.mobile"
                :scroll-by-thumb="$root.mobile"
              >
                <transition name="slide-fade">
                  <div :class="[isHome ? 'row' : '']">
                    <p
                      v-if="isHomeCliente"
                      class="mensagem__usuario visible-xs"
                    >
                      Olá, {{ userFirstName }}
                    </p>
                    <div v-if="$root.mobile" style="width: 100%; margin: 5px 0">
                      <alter-user-combo
                        :key="'alter-user-combo' + alteredSystem"
                        style="width: 80%; margin: 0 auto"
                        @change-user="userChanged"
                      />
                      <alter-company-combo
                        :key="'alter-company-combo' + alteredSystem"
                        style="width: 80%; margin: 0 auto"
                        @empresa-selecionada="changeCompany"
                      />
                    </div>
                    <menu-dimensoes v-if="$root.mobile" :is-home="isHome" />
                    <menu-mobile
                      v-if="$root.mobile"
                      :key="'menu-mobile' + alteredSystem + reloadChild"
                      :is-home="isHome"
                      :notification-counter="countPendingPermissions"
                      @altered-system="alteredSystem = !alteredSystem"
                      @page-name="pageName = $event"
                    />
                    <router-view
                      style="margin-top: 11px"
                      :key="$route.path + reloadChild"
                      @breadcrumb-page-name="getBreadcrumbName($event)"
                      @permission-altered="getPendingPermissions"
                    />
                  </div>
                </transition>
              </DxScrollView>
            </div>
          </div>
          <!-- <DxPopup
            :fullScreen="$root.mobile"
            :visible.sync="popupVisible"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :showCloseButton="false"
            :showTitle="false"
            :width="900"
            :height="400"
            id="tutorial-popup"
            @content-ready="onContentReady"
          >
            <Tutorial @close="fechaBoasVindas" />
          </DxPopup> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import TopoMenu from "@/components/TopoMenu.vue";
import ProfilePhoto from "@/components/profilePhoto.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
// import Tutorial from "@/components/tutorial.vue";
// import { DxPopup } from "devextreme-vue/popup";
import SiteLogo from "@/components/logos/site-logo.vue";
import UserInfoMenu from "@/components/user-info-menu.vue";
import { confirm } from "devextreme/ui/dialog";
import menuMobile from "@/components/mobile-menu/menu-mobile.vue";
import menuDimensoes from "../components/mobile-menu/menu-dimensoes.vue";
import AlterUserCombo from "../components/topo-actions/alter-user";
import AlterCompanyCombo from "../components/topo-actions/alter-company";
// import HomeBanner from "../components/banners/home-banner.vue";
import TopoMobile from "../components/mobile-menu/topo-mobile.vue";
import CompanyPhoto from "../components/logos/companyPhoto.vue";
import MinimizeMenu from "../components/minimize-menu.vue";

export default {
  name: "Principal",
  components: {
    Menu,
    TopoMenu,
    ProfilePhoto,
    CompanyPhoto,
    DxScrollView,
    // DxPopup,
    // Tutorial,
    SiteLogo,
    UserInfoMenu,
    menuMobile,
    AlterUserCombo,
    AlterCompanyCombo,
    // HomeBanner,
    TopoMobile,
    MinimizeMenu,
    menuDimensoes,
  },
  data: function () {
    return {
      breadCrumbsList: [],
      closeMenu: false,
      menuType: "",
      itemsIndicadoresRecomendacoes: [],
      adminMenuItems: [],
      adminMenuKeys: [],
      adminMenu: [],
      clientMenu: [],
      cliMenuItems: [],
      cliMenuKeys: [],
      reloadChild: false,
      aceite: false,
      termoLido: false,
      scrollInstance: null,
      pageName: "",
      rootPageName: "",
      timer: "",
      pendingPermissions: [],
      countPendingPermissions: 0,
      firstAttempt: true,
      alteredSystem: false,
      popupVisible: this.$root.getAuthData().boasVindas,
      validationPermissionsInterval: 300000,
    };
  },
  watch: {
    $route: function (v) {
      this.pageHeaderNameFromRoute(v);
      this.breadCrumbsList = v.meta.breadcrumb;
    },
  },
  computed: {
    isHome: function () {
      return (
        this.$route.path === "/indicador-resumo" ||
        this.$route.path === "/resumo-admin"
      );
    },
    isHomeCliente: function () {
      return this.$route.path === "/indicador-resumo";
    },
    userFirstName: function () {
      const a = this.$root.getAuthData().userName.split(" ");
      const name = `${a[0]} ${a[a.length - 1]}`;
      return name;
    },
  },
  created: function () {
    this.pageHeaderNameFromRoute(this.$route);
    this.getClienteMenu();
    this.getAdminMenu(this.$root.getAuthData().userCompany);
    this.menuType = this.$root.getAuthData().defaultSystem;
    this.getPendingPermissions();
    // const inst = this;
    // this.timer = setInterval(function () {
    //   const b = inst.$root.getAuthData();
    //   if (b !== undefined && b !== null) {
    //     if (b.aceiteTermo) inst.getPendingPermissions();
    //   }
    // }, this.validationPermissionsInterval);
  },
  mounted: function () {
    this.breadCrumbsList = this.$route.meta.breadcrumb;
  },
  beforeDestroy() {
    //clearInterval(this.timer);
  },
  methods: {
    getPendingPermissions() {
      const a = this.$root.getAuthData();
      this.$NotLoadingCon
        .get(`/Permission/CountPendingPermissions/${a.usuario}`, {
          headers: {
            Authorization: `Bearer ${a.token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((resposta) => {
          if (
            this.firstAttempt &&
            resposta.count > 0 &&
            this.$root.getAuthData().usuario ===
              this.$root.getAuthData().userLogged
          ) {
            this.firstAttempt = false;
            let result = confirm(
              `<i>Existe${resposta.count > 1 ? "m" : ""} ${
                resposta.count
              } permiss${
                resposta.count > 1 ? "ões" : "ão"
              } pendentes de avaliação. Deseja avaliá-las agora?</i>`,
              "Permissões para avaliar"
            );
            result.then((dialogResult) => {
              if (dialogResult) {
                this.$router.push({ path: "/permissions" });
              }
            });
          }
          this.pendingPermissions = resposta.data;
          this.countPendingPermissions = resposta.count;
        });
    },
    toPainel: function () {
      this.$router.push({
        path: `/indicador-resumo`,
      });
    },
    routeTo: function (idx) {
      if (this.breadCrumbsList[idx].link)
        this.$router.push(this.breadCrumbsList[idx].link);
    },
    openCloseSideBarMenu: function () {
      this.closeMenu = !this.closeMenu;
    },
    getRouteMenuKey: function (arr) {
      return arr.reduce((p, c) => {
        const a = c.meta.menu.base;
        if (p.find((v) => v.name === a.name) === undefined) p.push(a);
        return p;
      }, []);
    },
    getBreadcrumbName: function (event) {
      if (event && (event[0].name !== "" || event[1].name !== "")) {
        this.breadCrumbsList = this.$route.meta.breadcrumb;
        this.breadCrumbsList = this.breadCrumbsList.concat(event);
        if (this.breadCrumbsList[this.breadCrumbsList.length - 1].name !== "")
          this.rootPageName = event[0].name;
        else this.rootPageName = event[1].name;
        if (!this.$route.name.includes("Recomendacoes")) {
          this.pageName = event[1].name;
        }
      }
    },
    getClienteMenu: function () {
      const pageRoute = this.$router.options.routes
        .filter((x) => x.path === "/")[0]
        .children.filter((x) => x.meta.menu)
        .filter(
          (x) =>
            x.meta.menu.type === "Cliente" && x.name === "Indicador Usuário"
        )[0];

      this.$ConAPI
        .get(`/Menu/GetMenu/U/${this.$root.getAuthData().usuario}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => JSON.parse(result.request.response))
        .then((resposta) => {
          this.setClienteRouteMenu();
          this.$root.indicadores = [];
          const keys = resposta.reduce((p, c) => {
            const k = { name: c.sigla, icon: c.logo };
            c.indicadores.map((v) => {
              this.cliMenuItems.push({
                name: v.nome,
                path: pageRoute.path.replace(":id", v.id),
                meta: { menu: { base: { name: c.sigla } } },
              });
              this.$root.indicadores.push(v.id);
            });
            p.push(k);
            return p;
          }, []);
          let i = 1;
          keys.map((value) => {
            this.cliMenuKeys.splice(i, 0, value);
            i++;
          });

          this.cliMenuKeys.map((value) => {
            this.clientMenu.push({
              key: value,
              items: this.cliMenuItems.filter(
                (x) =>
                  x.meta.menu.base.name === value.name &&
                  x.meta.menu.base.path === undefined
              ),
            });
          });
          this.setPageName(this.cliMenuKeys);
          this.setPageName(this.cliMenuItems);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAdminMenu: function (id) {
      const pageRoute = this.$router.options.routes
        .filter((x) => x.path === "/")[0]
        .children.filter((x) => x.meta.menu)
        .filter(
          (x) => x.meta.menu.type === "Admin" && x.name === "Indicador Admin"
        )[0];
      this.setAdminRouteMenu();
      this.adminMenu = [];
      this.adminMenuKeys.map((value) => {
        this.adminMenu.push({
          key: value,
          items: this.adminMenuItems.filter(
            (x) =>
              x.meta.menu.base.name === value.name &&
              x.meta.menu.base.path === undefined
          ),
        });
      });
      this.setPageName(this.adminMenuKeys);
      this.setPageName(this.adminMenuItems);

      if (id !== null && id !== undefined) {
        this.$ConAPI
          .get(`/Menu/GetMenu/E/${id}`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((result) => JSON.parse(result.request.response))
          .then((resposta) => {
            // this.setAdminRouteMenu();
            // const keys =
            resposta.reduce((p, c) => {
              const k = { name: c.sigla, icon: c.logo };
              c.indicadores.map((v) => {
                this.adminMenuItems.push({
                  name: v.nome,
                  path: pageRoute.path.replace(":id", v.id),
                  meta: { menu: { base: { name: c.sigla } } },
                });
                this.$root.indicadoresAdmin.push(v.id);
              });
              p.push(k);
              return p;
            }, []);
            // let i = 1;

            // keys.map((value) => {
            //   this.adminMenuKeys.splice(i, 0, value);
            //   i++;
            // });

            // this.adminMenu = [];
            // this.adminMenuKeys.map((value) => {
            //   this.adminMenu.push({
            //     key: value,
            //     items: this.adminMenuItems.filter(
            //       (x) =>
            //         x.meta.menu.base.name === value.name &&
            //         x.meta.menu.base.path === undefined
            //     ),
            //   });
            // });
            // this.setPageName(this.adminMenuKeys);
            // this.setPageName(this.adminMenuItems);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onContentReady: function (e) {
      var html = e.component.content();
      html.setAttribute("style", "padding: 0!important;");
    },
    fechaBoasVindas: function () {
      this.$ConAPI
        .get("/Acesso/BoasVindas", {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          this.popupVisible = !this.popupVisible;
          const a = this.$root.getAuthData();
          a.boasVindas = false;
          this.$root.setAuthData(a);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setClienteRouteMenu: function () {
      const resPage = this.$root.sortBy(
        this.$router.options.routes
          .filter((x) => x.path === "/")[0]
          .children.filter((x) => x.meta.menu)
          .filter((x) => x.meta.menu.visible)
          .filter((x) => x.meta.menu.base)
          .filter(
            (x) =>
              x.name === x.meta.menu.base.name && x.meta.menu.type === "Cliente"
          )
          .map((value) => value.meta.menu),
        "order"
      );
      resPage.map((value) => {
        this.cliMenuKeys.push({
          name: value.base.name,
          path: value.base.path.replace(
            ":id",
            this.$root.getAuthData().usuario
          ),
          class: value.base.class,
          icon: value.base.icon,
        });
      });
    },
    setAdminRouteMenu: function () {
      this.adminMenuKeys = [];
      this.adminMenuItems = this.$router.options.routes
        .filter((x) => x.path === "/")[0]
        .children.filter((x) => x.meta.menu)
        .filter((x) => x.meta.menu.type === "Admin")
        .filter(
          (x) =>
            x.meta.menu.visibility === "always" ||
            (this.$root.getAuthData().acessoGPS &&
              x.meta.menu.visibility === "adminGPS")
        );

      const keysAdmin = this.$root.sortBy(
        this.adminMenuItems.map((value) => value.meta.menu),
        "order"
      );
      keysAdmin.map((value) => {
        if (
          this.adminMenuKeys.find((x) => x.name === value.base.name) ===
          undefined
        ) {
          this.adminMenuKeys.push({
            name: value.base.name,
            path:
              value.base.path !== undefined
                ? value.base.path.replace(
                    ":id",
                    this.$root.getAuthData().usuario
                  )
                : undefined,
            class: value.base.class !== undefined ? value.base.class : "",
            icon: value.base.icon,
          });
        }
      });
    },
    changeCompany: function (event) {
      this.pageName = "Indicadores";
      const a = this.$root.getAuthData();
      a.userCompany = event;
      this.$root.setAuthData(a);
      this.getAdminMenu(event);
      if (this.$route.path !== "/resumo-admin")
        this.$router.push({ path: "/" });
      else this.reloadChild = !this.reloadChild;
    },
    onReachBottom: function (options) {
      this.termoLido = true;
      options.component.release(true);
    },
    saveInstance: function (e) {
      this.scrollInstance = e.component;
    },
    setPageName: function (arr) {
      const b = arr.filter(
        (value) =>
          value.path === this.$route.path.substring(1, this.$route.path.length)
      )[0];

      if (this.pageName === "" && b !== undefined) {
        this.pageName = b.name;
      }
    },
    userChanged: function () {
      this.pageName = "Indicadores";
      this.reloadChild = this.reloadChild ? false : true;
    },
    pageHeaderNameFromRoute(e) {
      if (e.name === "ProfileForm") {
        this.pageName = "Perfil";
      }
      if (e.name === "Permissões") {
        this.pageName = "Permissões";
      }
      if (e.name === "Recomendacoes Redirect") {
        this.pageName = "Recomendações";
      }
      if (e.name === "Indicadores" || e.name === "Indicadores Admin") {
        this.pageName = "Indicadores de Saúde";
      }
    },
  },
};
</script>

<style>
.desabilitado {
  color: gray !important;
  pointer-events: none;
  cursor: none;
}
</style>

<style scoped lang="scss">
.acessado {
  display: block;
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 11px;
}

#tutorial-popup .dx-popup-content {
  padding: 0 !important;
}

#menu-topo-mobile {
  min-height: 80px;
  //position: absolute !important;
  //top: 0;
}

// Distancia do conteudo para o topo
@media (max-width: 767px) {
  .main-panel .content {
    margin-top: 80px;
  }
}

// Mensagem: Olá, {{ nome do usuário }}
.mensagem__usuario {
  text-align: left;
  width: 100%;
  margin: 10px 20px 0;
  padding: 10px 0 0;
}

#content-area {
  //height: 90vh;
  //overflow: hidden;
  @media (max-width: 768px) {
    height: 90vh !important;
  }
}

#content_view {
  height: inherit;
  //height: 79vh;
  @media screen and (min-width: 1080px) and (max-height: 780px) {
    height: 85vh !important;
  }
  @media screen and (min-width: 1080px) and (min-height: 781px) {
    height: 90vh !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.7s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.indicadores-breadcrumb {
  padding: 3px 30px !important;
}

.sidebar .nav li:hover > a,
.sidebar .nav li.active > [data-toggle="collapse"] {
  color: white !important;
}
.sidebar-mini ul.nav li.main-menu.active {
  a {
    color: white;
  }
  a > svg {
    fill: #fff !important;
  }
}

//  <a id="mobile-topo" class="ancor-top visible-xs"></a>
.ancor-top {
  opacity: 0;
  width: 0;
  height: 0;
  position: relative;
  top: -50px;
}

@media (max-width: 767px) {
  #userMenu {
    margin-left: 10px;
  }

  .pop-termo-m {
    padding: 0 3px;
  }
}

.page__title {
  font-weight: 700;
  color: #ff9800;
  font-size: 2.25rem;
  line-height: 1.2;
  text-align: left;

  &.visible-xs {
    margin: 5px 6px 0 6px;
  }

  @media (min-width: 768px) {
    font-size: 32px;
    top: -35px;
    position: relative;
    text-align: left;
    padding-left: 80px;
  }
}
#minimizeSidebar {
  width: 45px;
  height: 45px;
}
#sidebarToggler {
  top: 20px;
  right: 0px;
  position: absolute;
}
.logo-mobile {
  height: 100% !important;
}
.navbar {
  padding: 8px;
}
</style>
