var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.$root.mobile && !_vm.$root.getAuthData().acessoGPS
      ? _c("div", [
          _vm.photo !== ""
            ? _c(
                "div",
                {
                  staticClass: "logo-cliente hidden-xs",
                  staticStyle: { margin: "10px 25px" }
                },
                [
                  _c("img", {
                    staticStyle: { "max-height": "90px", "max-width": "80%" },
                    attrs: { src: _vm.companyPhoto }
                  })
                ]
              )
            : _vm._e(),
          _vm.icon !== ""
            ? _c("div", { staticClass: "logo-cliente-icon" }, [
                _c("img", {
                  staticClass: "company-icon",
                  attrs: { src: _vm.companyIcon }
                })
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.photo !== "" && _vm.$root.mobile && !_vm.$root.getAuthData().acessoGPS
      ? _c("div", { staticStyle: { margin: "0 auto", "max-width": "25%" } }, [
          _c("img", {
            staticClass: "hidden-md",
            staticStyle: { height: "auto", "max-width": "100%" },
            attrs: { src: _vm.companyPhoto }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }