var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-grafico-medicoes" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm.typeData === "Grupos"
        ? _c("span", [_vm._v(_vm._s(_vm.seriesData[0].Nome))])
        : _vm._e(),
      _vm.typeData !== "Grupos"
        ? _c("span", [_vm._v(_vm._s(_vm.indicador))])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "card-content" },
      [
        _c(
          "DxChart",
          {
            staticClass: "chart",
            attrs: {
              id: "chart1",
              "data-source": _vm.dataSource,
              palette: "Violet",
              "customize-point": _vm.customizePoint
            },
            on: { initialized: _vm.saveChartInstance }
          },
          [
            _c("DxCommonSeriesSettings", {
              attrs: { type: "spline", "argument-field": "Data" }
            }),
            _c(
              "DxCommonAxisSettings",
              [_c("DxGrid", { attrs: { visible: true } })],
              1
            ),
            _vm._l(_vm.seriesData, function(tipos, index2) {
              return _c("DxSeries", {
                key: index2,
                attrs: {
                  "value-field":
                    tipos.Nome !== null && tipos.Nome !== undefined
                      ? tipos.Nome.replace("@", "")
                      : "",
                  name:
                    tipos.Nome !== null && tipos.Nome !== undefined
                      ? tipos.Nome.replace("@", "")
                      : "",
                  axis: "percent"
                }
              })
            }),
            _c("DxMargin", { attrs: { bottom: 20 } }),
            _vm.typeData === "Grupos"
              ? _c("DxValueAxis", {
                  attrs: {
                    name: "percent",
                    adjustOnZoom: false,
                    "visual-range": { startValue: 0, endValue: 100, lenght: 0 },
                    "value-type": "numeric",
                    valueMarginsEnabled: false
                  }
                })
              : _vm._e(),
            _c(
              "DxArgumentAxis",
              {
                attrs: {
                  "allow-decimals": false,
                  "axis-division-factor": 60,
                  name: "medidorEixo"
                }
              },
              [
                _c(
                  "DxLabel",
                  { attrs: { "customize-text": _vm.customizeLabel } },
                  [_c("DxFormat", { attrs: { type: "decimal" } })],
                  1
                )
              ],
              1
            ),
            _c("DxLegend", {
              attrs: {
                "vertical-alignment": "top",
                "horizontal-alignment": "center",
                visible: _vm.typeData !== "Grupos"
              }
            }),
            _c("DxExport", { attrs: { enabled: false } }),
            _c("DxTooltip", { attrs: { enabled: true } })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }