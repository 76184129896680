var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "text-orange",
        class: { "menu-item": !_vm.$root.mobile, controle: _vm.$root.mobile },
        on: {
          click: _vm.$root.logout,
          mouseenter: function($event) {
            _vm.logoutVisible = !_vm.logoutVisible
          },
          mouseleave: function($event) {
            _vm.logoutVisible = !_vm.logoutVisible
          }
        }
      },
      [
        _c("i", {
          staticClass: "fas fa-sign-out-alt orange",
          attrs: { id: "logout", "aria-hidden": "true" }
        }),
        _vm.$root.mobile
          ? _c("span", { staticClass: "titulo" }, [_vm._v("Sair")])
          : _vm._e(),
        !_vm.$root.mobile
          ? _c(
              "DxTooltip",
              {
                attrs: {
                  visible: _vm.logoutVisible,
                  "close-on-outside-click": false,
                  target: "#logout"
                }
              },
              [_vm._v("Sair")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }