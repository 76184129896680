var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DxDataGrid",
    {
      ref: "dataGrid",
      attrs: {
        columnResizingMode: "nextColumn",
        height: _vm.height,
        "data-source": _vm.dataSource,
        "show-borders": true,
        remoteOperations: true,
        wordWrapEnabled: true,
        allowColumnReordering: true,
        allowColumnResizing: true,
        hoverStateEnabled: true,
        selection: _vm.selection,
        "selected-row-keys": _vm.selected,
        "column-hiding-enabled": true,
        "customize-columns": _vm.customizeColumns
      },
      on: {
        "update:selectedRowKeys": function($event) {
          _vm.selected = $event
        },
        "update:selected-row-keys": function($event) {
          _vm.selected = $event
        },
        exporting: _vm.onExporting,
        "toolbar-preparing": _vm.onToolbarPreparing,
        initialized: _vm.saveGridInstance,
        "row-inserting": _vm.onRowInserting,
        "row-removing": _vm.onRowRemoving,
        "selection-changed": _vm.onSelectionChanged,
        "content-ready": _vm.onContentReady
      },
      scopedSlots: _vm._u(
        [
          {
            key: "masterDetailTemplate",
            fn: function(ref) {
              var master = ref.data
              return [_vm._t("default", null, { master: master })]
            }
          },
          {
            key: "enableSearchHeaders",
            fn: function() {
              return [
                _c("div", { staticClass: "enable-search-headers" }, [
                  _c("h4", [_vm._v("Ativar pesquisa na coluna")]),
                  _c(
                    "div",
                    [
                      _c("DxSwitch", {
                        on: { "value-changed": _vm.clearFilter },
                        model: {
                          value: _vm.enableHeaderSearch,
                          callback: function($$v) {
                            _vm.enableHeaderSearch = $$v
                          },
                          expression: "enableHeaderSearch"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c("DxRowDragging", {
        attrs: {
          "allow-reordering": _vm.canReorderByDrag,
          "on-reorder": _vm.onReorder,
          "show-drag-icons": true
        }
      }),
      _c("DxHeaderFilter", { attrs: { visible: _vm.enableHeaderSearch } }),
      _c("DxSorting", { attrs: { mode: _vm.canSort ? "single" : "none" } }),
      _c("DxSearchPanel", {
        attrs: {
          visible: _vm.canSearch,
          width: 240,
          placeholder: "Procurar..."
        }
      }),
      _c("DxColumnChooser", { attrs: { enabled: true } }),
      _vm.editMode === "form"
        ? _c("DxExport", { attrs: { enabled: true } })
        : _vm._e(),
      _vm.editMode !== ""
        ? _c(
            "DxEditing",
            {
              attrs: {
                refreshMode: "full",
                mode: _vm.editMode,
                "allow-adding": _vm.editConfig.adding,
                "allow-updating": _vm.editConfig.updating,
                "allow-deleting": _vm.editConfig.deleting,
                useIcons: true
              }
            },
            [
              _c("DxTexts", {
                attrs: { "confirm-delete-message": _vm.deleteMessage }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.editMode === "form"
        ? _c("DxGroupPanel", { attrs: { visible: true, emptyPanelText: " " } })
        : _vm._e(),
      _c("DxPaging", { attrs: { "page-size": _vm.$root.mobile ? 5 : 10 } }),
      _c("DxPager", {
        attrs: {
          "show-page-size-selector": !_vm.$root.mobile,
          "allowed-page-sizes": _vm.pageSizes,
          "show-info": true
        }
      }),
      _vm._l(
        _vm.columns.filter(function(x) {
          return !x.notVisible
        }),
        function(item, index) {
          return _c(
            "DxColumn",
            {
              key: index,
              attrs: {
                "data-field": item.name,
                "data-type": item.type,
                caption: item.caption,
                visible: item.showColumn,
                "customize-text": item.customizeText,
                width: item.size,
                "css-class": item.class,
                "editor-options": item.editorOptions
              }
            },
            [
              item.lookup !== undefined
                ? _c("DxLookup", {
                    attrs: {
                      "data-source": item.lookup.dataSource,
                      "display-expr": item.lookup.displayExpr,
                      "value-expr": item.lookup.valueExpr,
                      disabled: item.lookup.disabled
                    }
                  })
                : _vm._e(),
              item.required ? _c("DxRequiredRule") : _vm._e()
            ],
            1
          )
        }
      ),
      _c(
        "DxColumn",
        { attrs: { type: "buttons" } },
        [
          _vm._l(_vm.otherButtons, function(item) {
            return _c("DxButton", {
              key: item.key,
              attrs: {
                text: item.text,
                icon: item.icon,
                hint: item.hint,
                visible: item.visible,
                "css-class": item.cssClass,
                "on-click": item.command
              }
            })
          }),
          _vm.editMode === "form"
            ? _c("DxButton", {
                attrs: { name: "edit", "on-click": _vm.editObject }
              })
            : _vm._e(),
          _vm.editMode !== "form"
            ? _c("DxButton", { attrs: { name: "edit" } })
            : _vm._e(),
          _c("DxButton", { attrs: { name: "delete" } })
        ],
        2
      ),
      _c("DxMasterDetail", {
        attrs: { enabled: _vm.hasDetail, template: "masterDetailTemplate" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }