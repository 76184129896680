var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-content" },
      [
        _c(
          "DxChart",
          {
            staticClass: "chart",
            attrs: { id: "chart3", "data-source": _vm.data, palette: "Violet" }
          },
          [
            _c(
              "DxCommonAxisSettings",
              [_c("DxGrid", { attrs: { visible: true } })],
              1
            ),
            _c("DxSeries", {
              attrs: {
                "argument-field": "Data",
                "value-field": "Value",
                type: "line"
              }
            }),
            _c("DxMargin", { attrs: { bottom: 20 } }),
            _c(
              "DxArgumentAxis",
              {
                attrs: { "allow-decimals": false, "axis-division-factor": 60 }
              },
              [
                _c(
                  "DxLabel",
                  { attrs: { "customize-text": _vm.customizeLabel } },
                  [_c("DxFormat", { attrs: { type: "decimal" } })],
                  1
                )
              ],
              1
            ),
            _c("DxLegend", { attrs: { visible: false } }),
            _c("DxTooltip", { attrs: { enabled: true } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", [_vm._v("Resultado Evolutivo")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }