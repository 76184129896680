var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "wrapper", class: { "sidebar-mini": _vm.closeMenu } },
      [
        _c(
          "div",
          {
            staticClass: "sidebar",
            attrs: {
              "data-active-color": "orange",
              "data-background-color": "white"
            }
          },
          [
            _c("site-logo", {
              on: {
                "page-name": function($event) {
                  _vm.pageName = $event
                }
              }
            }),
            _c("company-photo", {
              attrs: {
                photo: _vm.$root.getAuthData().companyPhoto,
                icon: _vm.$root.getAuthData().companyIconPhoto
              }
            }),
            _c(
              "div",
              {
                staticClass: "sidebar-wrapper",
                style: {
                  height: !_vm.$root.mobile
                    ? "calc(100vh - 125px)!important"
                    : "inherit"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "user" },
                  [
                    _c("profile-photo", {
                      key:
                        "profile-photo" + _vm.alteredSystem + _vm.reloadChild,
                      attrs: { foto: _vm.$root.getAuthData().profilePhoto }
                    }),
                    _c("user-info-menu", {
                      key: _vm.$route.path + _vm.reloadChild,
                      attrs: {
                        "notification-counter": _vm.countPendingPermissions
                      }
                    })
                  ],
                  1
                ),
                _c("Menu", {
                  key: "Menu",
                  attrs: {
                    "closed-menu": _vm.closeMenu,
                    menu:
                      _vm.$root.getAuthData().defaultSystem === "Admin"
                        ? _vm.adminMenu
                        : _vm.clientMenu,
                    "page-active": _vm.pageName
                  },
                  on: {
                    "breadcrumb-page-name": function($event) {
                      return _vm.getBreadcrumbName($event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "main-panel" }, [
          _c("a", {
            staticClass: "ancor-top visible-xs",
            attrs: { id: "mobile-topo" }
          }),
          _c(
            "nav",
            {
              staticClass: "navbar navbar-transparent navbar-absolute",
              attrs: { id: "menu-topo-mobile" }
            },
            [
              _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _c("minimize-menu", {
                    on: {
                      "close-menu": function($event) {
                        _vm.closeMenu = !_vm.closeMenu
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "navbar-header",
                      staticStyle: { "background-color": "white" }
                    },
                    [
                      _c("topo-mobile", {
                        key: "topo-mobile" + _vm.reloadChild,
                        on: {
                          "page-name": function($event) {
                            _vm.pageName = $event
                          }
                        }
                      }),
                      _c("h3", { staticClass: "page__title visible-xs" }, [
                        _vm._v(_vm._s(_vm.pageName))
                      ])
                    ],
                    1
                  ),
                  !_vm.$root.mobile
                    ? _c("topo-menu", {
                        attrs: {
                          notificationCounter: _vm.countPendingPermissions
                        },
                        on: {
                          "empresa-selecionada": _vm.changeCompany,
                          "change-user": _vm.userChanged,
                          "altered-system": function($event) {
                            _vm.alteredSystem = !_vm.alteredSystem
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "container-fluid", attrs: { id: "content-area" } },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("h3", { staticClass: "page__title hidden-xs" }, [
                      _vm._v(_vm._s(_vm.pageName))
                    ]),
                    _c(
                      "DxScrollView",
                      {
                        attrs: {
                          id: "content_view",
                          width: "100%",
                          "show-scrollbar": "onScroll",
                          "scroll-by-content": !_vm.$root.mobile,
                          "scroll-by-thumb": _vm.$root.mobile
                        },
                        on: { initialized: _vm.saveInstance }
                      },
                      [
                        _c("transition", { attrs: { name: "slide-fade" } }, [
                          _c(
                            "div",
                            { class: [_vm.isHome ? "row" : ""] },
                            [
                              _vm.isHomeCliente
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mensagem__usuario visible-xs"
                                    },
                                    [
                                      _vm._v(
                                        " Olá, " +
                                          _vm._s(_vm.userFirstName) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$root.mobile
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        margin: "5px 0"
                                      }
                                    },
                                    [
                                      _c("alter-user-combo", {
                                        key:
                                          "alter-user-combo" +
                                          _vm.alteredSystem,
                                        staticStyle: {
                                          width: "80%",
                                          margin: "0 auto"
                                        },
                                        on: { "change-user": _vm.userChanged }
                                      }),
                                      _c("alter-company-combo", {
                                        key:
                                          "alter-company-combo" +
                                          _vm.alteredSystem,
                                        staticStyle: {
                                          width: "80%",
                                          margin: "0 auto"
                                        },
                                        on: {
                                          "empresa-selecionada":
                                            _vm.changeCompany
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$root.mobile
                                ? _c("menu-dimensoes", {
                                    attrs: { "is-home": _vm.isHome }
                                  })
                                : _vm._e(),
                              _vm.$root.mobile
                                ? _c("menu-mobile", {
                                    key:
                                      "menu-mobile" +
                                      _vm.alteredSystem +
                                      _vm.reloadChild,
                                    attrs: {
                                      "is-home": _vm.isHome,
                                      "notification-counter":
                                        _vm.countPendingPermissions
                                    },
                                    on: {
                                      "altered-system": function($event) {
                                        _vm.alteredSystem = !_vm.alteredSystem
                                      },
                                      "page-name": function($event) {
                                        _vm.pageName = $event
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c("router-view", {
                                key: _vm.$route.path + _vm.reloadChild,
                                staticStyle: { "margin-top": "11px" },
                                on: {
                                  "breadcrumb-page-name": function($event) {
                                    return _vm.getBreadcrumbName($event)
                                  },
                                  "permission-altered":
                                    _vm.getPendingPermissions
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }