<template>
  <div style="margin-top: 11px">
    <loading :loading="$root.loading" />
    <div
      class="col-lg-4 col-md-6 col-sm-6"
      v-for="(item, index) in data"
      :key="index"
    >
      <div
        class="card card-stats card-home-top"
        :id="item.tipo.toLowerCase().replace(/ /g, '-')"
      >
        <div class="card-header">
          <i
            class="fa"
            :class="item.logo"
            aria-hidden="true"
            style="font-size: 45px"
          ></i>
          <h3 class="card-title title">
            <span>
              {{ item.tipo }}
            </span>
            <div class="stats explanation">
              Clique nos itens para ver o detalhamento.
            </div>
          </h3>
        </div>
        <DxScrollView
          width="100%"
          height="65vh"
          :showScrollbar="'always'"
          :scroll-by-content="!$root.mobile"
          :scroll-by-thumb="$root.mobile"
        >
          <div class="card-content">
            <table class="table table-hover table-margin">
              <tbody>
                <tr
                  v-for="(item2, index2) in item.indicadores"
                  :key="index2"
                  style="font-size: large; cursor: pointer"
                  @click="goTo(`/indicador-admin/${item2.id}`)"
                >
                  <td scope="col">
                    {{ item2.nome }}
                  </td>
                  <td class="col-md-1">
                    {{ item2.valor }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </DxScrollView>
      </div>
    </div>
  </div>
</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";
import Loading from "../components/loading.vue";

export default {
  name: "IndicadoresResumoAdmin",
  components: {
    DxScrollView,
    Loading,
  },
  data: function () {
    return {
      data: [],
    };
  },
  mounted: function () {
    this.getResumoData();
  },
  methods: {
    getResumoData: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetResumoAdmin/${
            this.$root.getAuthData().userCompany
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.data = resposta;
        })
        .catch(function () {
          //console.log(error);
        });
    },
    goTo: function (url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.table-margin {
  margin: 10px 0;
}
a {
  color: rgba(0, 0, 0, 0.87);
}

.content-size {
  height: 70vh;
  overflow-y: auto;
}

.card .card-header {
  padding: 15px 15px;
  z-index: 3;
  float: unset;
}

.explanation {
  color: dimgray;
  font-size: 12px;
  font-style: italic;
}
@media (max-width: 480px) {
  .content-size {
    min-height: 40vh;
    height: auto;
    overflow-y: unset;
  }
}
</style>