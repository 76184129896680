var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-orange",
      class: { "menu-item": !_vm.$root.mobile, controle: _vm.$root.mobile },
      on: {
        click: function($event) {
          return _vm.goToPermissions()
        },
        mouseenter: function($event) {
          _vm.PermissionVisible = !_vm.PermissionVisible
        },
        mouseleave: function($event) {
          _vm.PermissionVisible = !_vm.PermissionVisible
        }
      }
    },
    [
      _c("i", {
        staticClass: "fas fa-quote-right",
        attrs: { id: "permissions" }
      }),
      _vm.$root.mobile
        ? _c("span", { staticClass: "titulo" }, [_vm._v("Permissões")])
        : _vm._e(),
      _vm.notificationCounter > 0 && !_vm.disabled
        ? _c(
            "span",
            {
              staticClass: "badge",
              class: [_vm.$root.mobile ? "badge-m" : "badge-d"]
            },
            [_vm._v(_vm._s(_vm.notificationCounter))]
          )
        : _vm._e(),
      !_vm.$root.mobile
        ? _c(
            "DxTooltip",
            {
              attrs: {
                visible: _vm.PermissionVisible,
                "close-on-outside-click": false,
                target: "#permissions"
              }
            },
            [_vm._v("Permissões")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }