<template>
  <div class="row" style="margin-bottom: 20px">
    <div class="col-md-12" style="padding: 0px">
      <div class="col-md-6" v-if="!multipleIndicadorFilter">
        <DxSelectBox
          :searchEnabled="true"
          display-expr="Name"
          value-expr="id"
          :data-source="indicadoresCombo"
          v-model="selecaoIndicadores"
          @value-changed="onIndicadoresChanged"
        >
        </DxSelectBox>
      </div>
      <div class="col-md-6" v-if="multipleIndicadorFilter">
        <DxTagBox
          :items="indicadoresCombo"
          display-expr="Name"
          value-expr="id"
          :searchEnabled="true"
          v-model="selecaoIndicadores"
          @value-changed="onIndicadoresChanged"
        />
      </div>
      <div class="col-md-6">
        <DxSelectBox
          :searchEnabled="true"
          display-expr="Name"
          value-expr="id"
          :data-source="riscoCombo"
          v-model="selecaoRisco"
          @value-changed="onRiscoChanged"
        >
        </DxSelectBox>
      </div>
    </div>
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import DxTagBox from "devextreme-vue/tag-box";
import notify from "devextreme/ui/notify";

export default {
  name: "IndicadoresFilters",
  components: {
    DxSelectBox,
    DxTagBox,
  },
  props: {
    riscoDefault: {
      default: 0,
    },
    indicadorDefault: {
      default: "0",
    },
    multipleIndicadorFilter: {
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      indicadoresCombo: [
        {
          id: "0",
          Name: "Todos os Indicadores",
        },
      ],
      riscoCombo: [
        { id: 0, Name: "Todos níveis de risco" },
        { id: 3, Name: "Alto risco" },
        { id: 2, Name: "Médio risco" },
        { id: 1, Name: "Baixo risco" },
      ],
      selecaoIndicadores: inst.multipleIndicadorFilter ? [] : "0",
      selecaoRisco: 3,
    };
  },
  mounted: function () {
    this.selecaoRisco = this.riscoDefault;
    this.selecaoIndicadores = this.multipleIndicadorFilter
      ? []
      : this.indicadorDefault;
    this.getComboIndicadores();
  },
  methods: {
    getComboIndicadores: function () {
      this.$ConAPI
        .get(`/Menu/GetMenu/U/${this.$root.getAuthData().usuario}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.getIndicadoresCombo(resposta);
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    getIndicadoresCombo: function (items) {
      this.indicadoresCombo = this.multipleIndicadorFilter
        ? []
        : this.indicadoresCombo;
      items.map((value1) => {
        value1.indicadores.map((value2) => {
          this.indicadoresCombo.push({
            id: value2.id,
            Name: `${value1.sigla} - ${value2.nome}`,
          });
        });
      });
    },
    onIndicadoresChanged: function (e) {
      if (e.event !== undefined)
        this.$emit("filter-values", {
          risco: this.selecaoRisco,
          indicador: e.value,
        });
    },
    onRiscoChanged: function (e) {
      if (e.event !== undefined)
        this.$emit("filter-values", {
          risco: e.value,
          indicador: this.selecaoIndicadores,
        });
    },
  },
};
</script>

<style>
</style>