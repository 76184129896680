import Vue from 'vue'
import VueRouter from 'vue-router'
import Principal from "@/views/principal.vue";
import Login from "@/views/login.vue";
import ResetSenha from "@/views/reset-senha.vue";
import RelatorioIndicadores from "@/views/relatorio-indicadores.vue";
import IndicadoresExterno from "@/views/indicadores-externo.vue";
import adminRoutes from '@/router/admin-routes.js';
import clientRoutes from '@/router/client-routes.js';
import AceiteTermos from '@/views/aceite-termos.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset-senha/:token?/:id?',
    name: 'ResetSenha',
    component: ResetSenha,
    meta: {
      requiresAuth: false,
      breadcrumb: [{
        name: ''
      }],
      type: ''
    }
  },
  {
    path: '/login/:page',
    name: 'Login Redirect',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/aceite',
    name: 'Aceite Termos',
    component: AceiteTermos,
    meta: {
      requiresAuth: true,
      breadcrumb: [{
        name: ''
      }],
      type: ''
    },
    beforeEnter(to, from, next) {
      const a = JSON.parse(localStorage.getItem(Vue.prototype.$keyNav));
      if (a.aceiteTermo) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/',
    name: 'Principal',
    component: Principal,
    children: [].concat(clientRoutes).concat(adminRoutes),
    meta: {
      requiresAuth: true,
      breadcrumb: [{
        name: ''
      }]
    },
    redirect: () => {
      const a = JSON.parse(localStorage.getItem(Vue.prototype.$keyNav));

      if (a === undefined || a === null) return 'login';

      if (a.defaultSystem === 'Admin') {
        return 'resumo-admin'
      }
      else {
        return 'indicador-resumo'
      }
    }
  },
  {
    path: '/indicadores/:id',
    name: 'Relatório Indicadores Externo Login',
    component: IndicadoresExterno,
    meta: {
      requiresAuth: false,
      type: 'external',
    }
  },
  {
    path: '/indicadoresGps/:id',
    name: 'Relatório Indicadores Externo',
    component: RelatorioIndicadores,
    meta: {
      requiresAuth: true,
      type: 'external',
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$UrlApl = document.location.origin + '/#';
  const auth = JSON.parse(window.localStorage.getItem(Vue.prototype.$keyNav));

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth == null) {
      next({
        path: '/login',
        query: { nextUrl: to.fullPath.substring(1, to.fullPath.length) }
      })
    } else {
      if (!auth.alterouSenha && to.path !== '/reset-senha' && to.meta.type !== "external") {
        next({ path: 'reset-senha' })
      }
      const token = auth != null ? auth.token : '';
      Vue.prototype.$ConAPI.defaults.baseURL = Vue.prototype.$UrlAPI;
      Vue.prototype.$ConAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
      Vue.prototype.$ConAPI.get('/acesso/authenticated')
        .then(() => {
          if (auth.google && !window.VUE.$gAuth.isAuthorized) {
            window.VUE.$gAuth.signOut();
            window.localStorage.removeItem(Vue.prototype.$keyNav);
            next({
              path: '/login',
              query: { nextUrl: to.fullPath }
            })
          } else if (!auth.aceiteTermo && to.path !== '/aceite' && to.meta.type !== "external") {
            next({ path: 'aceite' })
          } else if (to.meta.type === auth.defaultSystem) {
            next()
          } else if (to.meta.type === "" || to.meta.type === "external") {
            next();
          } else {
            next({ path: '/' })
          }
        }
        )
        .catch(function () {
          if (typeof (window.VUE.$gAuth) != 'undefined')
            window.VUE.$gAuth.signOut();
          window.localStorage.removeItem(Vue.prototype.$keyNav);
          next({
            path: '/login',
            query: { nextUrl: to.fullPath }
          })
        })
    }
  } else {
    if (auth === null && to.name !== 'Login' && to.name !== 'ResetSenha' && to.name !== 'Relatório Indicadores Externo Login')
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }
      });

    if (auth !== null) {
      if (auth.alterouSenha && to.name === 'ResetSenha') {
        next({ path: '/' })
      } else {
        next()
      }
    }

    if (auth === null && (to.name === 'Login' || to.name === 'ResetSenha' || to.name === 'Relatório Indicadores Externo Login')) {
      next();
    }
  }
})
export default router
