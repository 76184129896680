<template>
  <div>
    <Grid
      :columns="columns"
      :action="action"
      class="grid-resize"
      :other-buttons="otherButtons"
      v-on:add-item="addItem"
      v-on:edit-item="editItem"
      :reload="reloadEmpresaGrid"
      :delete-message="'Deseja desativar esta empresa?'"
    />
    <DxPopup
      :fullScreen="$root.mobile"
      :visible.sync="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="900"
      :height="400"
      :title="popupTitle"
    >
      <Grid
        :reload="reloadGrid"
        :columns="gridPopupColumns"
        :action="gridPopupAction"
        :filter-value="filterByClienteId"
        :filter-field="filterDetailBy"
        :edit-mode="detailMode"
        :parent-id-field="parentIdField"
        :init-inserting-row="initInsertingPopupRow"
        :can-search="false"
        :can-sort="false"
        :edit-config="editConfigPopup"
      />
    </DxPopup>
    <DxPopup
      :fullScreen="$root.mobile"
      :visible.sync="popupIndicadoresVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="900"
      :height="500"
      :title="popupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <Grid
          :reload="reloadIndicadoresGrid"
          :data="indicadoresDataSource"
          :grid-actions="gridIndicadoresActions"
          :columns="[
            { name: 'id', notVisible: true },
            { name: 'tipo', showColumn: true },
            { name: 'indicadorNome', showColumn: true },
          ]"
          :edit-mode="''"
          :selection="{
            mode: 'multiple',
            showCheckBoxesMode: 'always',
          }"
          :selected-rows="selectedRows"
          @selected-rows-ids="selectedRowsIds"
        />
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
import ODataStore from "devextreme/data/odata/store";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { alert } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";

export default {
  name: "Empresa",
  components: {
    Grid,
    DxPopup,
    DxScrollView,
  },
  data() {
    const inst = this;

    return {
      columns: [
        { name: "Id", notVisible: true },
        { name: "RazaoSocial", defaultSort: true, showColumn: true },
        { name: "NomeFantasia", showColumn: true },
        { name: "Ativo", showColumn: true },
        {
          name: "EnvioAutomaticoEmail",
          caption: "E-mail Automático",
          showColumn: true,
        },
        { name: "Cidade", showColumn: true },
        { name: "UF.Sigla", caption: "UF", showColumn: true },
        { name: "Email", caption: "E-mail", showColumn: true },
        { name: "Matriz.RazaoSocial", caption: "Matriz", showColumn: true },
        { name: "CNPJ", showColumn: false },
        { name: "Endereco", showColumn: false },
        { name: "Complemento", showColumn: false },
        { name: "Bairro", showColumn: false },
        { name: "CEP", showColumn: false },
        { name: "Telefone", showColumn: true },
        { name: "Site", showColumn: true },
      ],
      action: "GPS_PessoaJuridica",
      otherButtons: [
        {
          text: "Acesso",
          icon: "group",
          hint: "Acesso",
          visible: true, //getAcesso()
          key: new Guid().toString(),
          command: function (e) {
            inst.filterByClienteId = e.row.key.Id;
            inst.editConfigPopup = {
              adding: true,
              updating: false,
              deleting: true,
            };
            inst.gridPopupColumns = [
              { name: "Id", notVisible: true },
              { name: "_PessoaJuridica", notVisible: true },
              { name: "ObrigarMetodoSalvar", notVisible: true },
              {
                name: "_PessoaFisica",
                showColumn: true,
                caption: "Usuário",
                required: true,
                lookup: {
                  allowClearing: true,
                  dataSource: {
                    store: new ODataStore({
                      url: `${inst.$UrlAPI}/GPS_PessoaFisica`,
                      version: 4,
                      key: ["Id"],
                      keyType: {
                        Id: "Guid",
                      },
                      beforeSend: function (e) {
                        e.headers = {
                          Authorization: `Bearer ${
                            inst.$root.getAuthData().token
                          }`,
                        };
                      },
                    }),
                    select: ["Id", "Nome", "Ativo", "PrestadorSaude"],
                    filter: [
                      ["Ativo", "=", true],
                      "&",
                      ["IdPessoaJuridica", "=", inst.filterByClienteId],
                    ],
                    sort: [{ selector: "Nome", desc: false }],
                    paginate: true,
                  },
                  displayExpr: "Nome",
                  valueExpr: "Id",
                },
              },
              {
                name: "ResponsavelContrato",
                caption: "Administrador",
                showColumn: true,
                type: "boolean",
              },
              {
                name: "ResponsavelMedico",
                caption: "Gestor Médico",
                showColumn: true,
                type: "boolean",
              },
            ];
            inst.gridPopupAction = "GPS_PessoaJuridicaAcesso";
            inst.parentIdField = "_PessoaJuridica";
            inst.initInsertingPopupRow = function (e) {
              e.data = {
                ResponsavelContrato: false,
                ResponsavelMedico: false,
                ObrigarMetodoSalvar: false,
              };
            };
            inst.reloadGrid = !inst.reloadGrid;
            inst.showPopUp("Acesso");
          },
        },
        {
          text: "Pacote",
          icon: "columnfield",
          hint: "Vínculo Pacote",
          visible: true, //getAcesso()
          key: new Guid().toString(),
          command: function (e) {
            inst.filterByClienteId = e.row.key.Id;
            inst.editConfigPopup = {
              adding: true,
              updating: true,
              deleting: true,
            };
            inst.gridPopupColumns = [
              { name: "Id", notVisible: true },
              { name: "_PessoaJuridica", notVisible: true },
              {
                name: "_Pacote",
                required: true,
                showColumn: true,
                caption: "Pacote",
                lookup: {
                  allowClearing: true,
                  dataSource: {
                    store: new ODataStore({
                      url: `${inst.$UrlAPI}/Gps_Pacote`,
                      version: 4,
                      key: ["Id"],
                      keyType: {
                        Id: "Guid",
                      },
                      beforeSend: function (e) {
                        e.headers = {
                          Authorization: `Bearer ${
                            inst.$root.getAuthData().token
                          }`,
                        };
                      },
                    }),
                    select: ["Id", "Nome"],
                    filter: [["Ativo", "=", true]],
                    sort: [{ selector: "Nome", desc: false }],
                    paginate: true,
                  },
                  displayExpr: "Nome",
                  valueExpr: "Id",
                },
              },
            ];
            inst.gridPopupAction = "Gps_PacotePessoaJuridica";
            inst.parentIdField = "_PessoaJuridica";
            inst.reloadGrid = !inst.reloadGrid;
            inst.showPopUp("Vínculo Pacote");
          },
        },
        {
          text: "Indicadores Obrigatórios",
          icon: "aligncenter",
          hint: "Indicadores Obrigatórios",
          visible: true,
          key: new Guid().toString(),
          command: function (e) {
            inst.selectedRows = [];
            inst.getIndicadoresDataSource(e.row.key.Id);
            inst.popupTitle = "Indicadores Obrigatórios";
            inst.popupIndicadoresVisible = !inst.popupIndicadoresVisible;
          },
        },
      ],
      popupVisible: false,
      popupTitle: "",
      reloadGrid: false,
      gridAccessAction: "GPS_PessoaJuridica",
      gridPopupColumns: [],
      gridPopupAction: "",
      parentIdField: "",
      initInsertingPopupRow: null,
      filterDetailBy: "PessoaJuridica.Id",
      filterByClienteId: null,
      detailMode: "batch",
      popupIndicadoresVisible: false,
      indicadoresDataSource: [],
      reloadIndicadoresGrid: false,
      gridIndicadoresActions: [
        {
          widget: "dxButton",
          options: {
            icon: "save",
            hint: "Salvar Indicadores Obrigatórios",
            onClick: function () {
              if (inst.selectedRows.length > 0) {
                inst.saveIndicadores();
              } else {
                alert(
                  "É preciso haver pelo menos 1 indicador selecionado",
                  "Atenção"
                );
              }
            },
          },
          location: "after",
        },
        {
          widget: "dxButton",
          options: {
            icon: "close",
            hint: "Retirar Indicadores obrigatórios",
            onClick: function () {
              inst.deleteIndicadores();
            },
          },
          location: "after",
        },
      ],
      selectedRows: [],
      clickedCompany: null,
      reloadEmpresaGrid: false,
      editConfigPopup: {
        adding: true,
        updating: false,
        deleting: true,
      },
    };
  },
  mounted: function () {
    if (this.$root.getAuthData().acessoGPS) {
      this.columns.filter(
        (value) => value.name === "Ativo"
      )[0].showColumn = true;
      this.reloadEmpresaGrid = !this.reloadEmpresaGrid;
    }
  },
  methods: {
    addItem: function () {
      this.$router.push({ path: "/empresa/form" });
    },
    editItem: function (e) {
      this.$router.push({ path: `/empresa/form/${e.key.Id._value}` });
    },
    showPopUp: function (name) {
      this.popupTitle = name;
      this.popupVisible = true;
    },
    getIndicadoresDataSource: function (id) {
      this.clickedCompany = id;
      this.indicadoresDataSource = [];
      this.$ConAPI
        .get(`/EmpresaIndicadoresObrigatoriedade/GetIndicadoresEmpresa/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((result) => JSON.parse(result.request.response))
        .then((data) => {
          this.indicadoresDataSource = data;
          this.selectedRows = data.filter((x) => x.selected);
          this.reloadIndicadoresGrid = !this.reloadIndicadoresGrid;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    selectedRowsIds: function (e) {
      this.selectedRows = e;
    },
    deleteIndicadores: function () {
      this.$ConAPI
        .delete(
          `/EmpresaIndicadoresObrigatoriedade/DeleteIndicadoresObrigatorios/${this.clickedCompany}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          notify(result.data, "success", 4000);
          this.popupIndicadoresVisible = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveIndicadores: function () {
      this.$ConAPI
        .post(
          `/EmpresaIndicadoresObrigatoriedade/SaveIndicadoresObrigatorios/`,
          {
            Empresa: this.clickedCompany,
            Indicadores: this.selectedRows.map((v) => v.id),
          },
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          notify(result.data, "success", 4000);
          this.popupIndicadoresVisible = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>