var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header card-detalhes" }, [
        _c("div", { staticClass: "pull-left" }, [
          _c("h4", [
            _vm._v(" " + _vm._s(_vm.person) + " "),
            _vm.$root.mobile ? _c("br") : _vm._e(),
            !_vm.$root.mobile ? _c("span", [_vm._v("-")]) : _vm._e(),
            _vm._v(" " + _vm._s(_vm.indicador) + " ")
          ]),
          _c("h5", [_vm._v(_vm._s(_vm.dataMedicao))])
        ]),
        _c("div", { staticClass: "pull-right" }, [
          _c("img", {
            class: {
              "title-img": !_vm.$root.mobile,
              "title-img-m": _vm.$root.mobile
            },
            attrs: { src: require("../assets/img/logo.png") }
          })
        ])
      ]),
      _c("div", { staticClass: "card-content" }, [
        !_vm.isBiomedic
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "DxChart",
                  {
                    attrs: {
                      id: "chart",
                      "data-source": _vm.data,
                      "customize-point": _vm.customizePoint
                    }
                  },
                  [
                    _c("DxCommonSeriesSettings", {
                      attrs: {
                        "ignore-empty-points": true,
                        "argument-field": "indice",
                        "value-field": "valor",
                        type: "bar"
                      }
                    }),
                    !_vm.$root.mobile
                      ? _c("DxSize", { attrs: { height: 300, width: 700 } })
                      : _vm._e(),
                    _c("DxSeriesTemplate", { attrs: { "name-field": "nome" } }),
                    _c(
                      "DxArgumentAxis",
                      {
                        attrs: {
                          "allow-decimals": false,
                          "axis-division-factor": 60
                        }
                      },
                      [
                        _c(
                          "DxLabel",
                          [_c("DxFormat", { attrs: { type: "decimal" } })],
                          1
                        )
                      ],
                      1
                    ),
                    _c("DxValueAxis", { attrs: { max: 100 } }),
                    _c("DxLegend", { attrs: { visible: false } }),
                    _c("DxTooltip", { attrs: { enabled: true } })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.isBiomedic
          ? _c("div", { staticClass: "row" }, [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _c(
                  "tbody",
                  _vm._l(_vm.data, function(item, i) {
                    return _c("tr", { key: i }, [
                      _c("td", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(i + 1) + " - " + _vm._s(item.nome))
                      ]),
                      _c(
                        "td",
                        { style: { color: item.cor }, attrs: { scope: "col" } },
                        [_vm._v(" " + _vm._s(item.valor) + " ")]
                      ),
                      _c("td", { attrs: { scope: "col" } }, [
                        _vm._v(" " + _vm._s(item.recomendacao) + " ")
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e(),
        _vm.isBiomedic
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "table-medidor-data" }, [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.data, function(item, idx) {
                        return _c("th", { key: idx, attrs: { scope: "col" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.jejum
                                  ? item.nome.replace("@", "") + " em jejum"
                                  : item.nome.replace("@", "")
                              ) +
                              " "
                          )
                        ])
                      }),
                      0
                    )
                  ]),
                  _c(
                    "tbody",
                    [
                      _c(
                        "tr",
                        _vm._l(_vm.data, function(item, i) {
                          return _c("td", { key: i, attrs: { scope: "col" } }, [
                            _vm._v(" " + _vm._s(item.valor) + " ")
                          ])
                        }),
                        0
                      ),
                      _vm._l(_vm.data, function(item, id) {
                        return _c("tr", { key: id }, [
                          item.recomendacao !== null
                            ? _c(
                                "td",
                                { attrs: { colspan: _vm.data.length + 1 } },
                                [
                                  _c("table", { staticClass: "table" }, [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              scope: "col",
                                              colspan: "3"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.cor === "#4caf50",
                                                    expression:
                                                      "item.cor === '#4caf50'"
                                                  }
                                                ],
                                                staticClass: "pull-left"
                                              },
                                              [
                                                _c("i", {
                                                  class:
                                                    "fas fa-compass " +
                                                    Math.random(),
                                                  style: {
                                                    color: "#4caf50",
                                                    "font-size": "14px"
                                                  },
                                                  attrs: {
                                                    id: Math.random(),
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.cor === "#e0c619",
                                                    expression:
                                                      "item.cor === '#e0c619'"
                                                  }
                                                ],
                                                staticClass: "pull-left"
                                              },
                                              [
                                                _c("i", {
                                                  class:
                                                    "fas fa-compass " +
                                                    Math.random(),
                                                  style: {
                                                    color: "#e0c619",
                                                    "font-size": "14px"
                                                  },
                                                  attrs: {
                                                    id: Math.random(),
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            ),
                                            item.cor === "#f44336"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "pull-left" },
                                                  [
                                                    _c("i", {
                                                      class:
                                                        "fas fa-compass " +
                                                        Math.random(),
                                                      style: {
                                                        color: "#f44336",
                                                        "font-size": "14px"
                                                      },
                                                      attrs: {
                                                        id: Math.random(),
                                                        "aria-hidden": "true"
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "7px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.nome) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              scope: "col",
                                              colspan: "3"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.recomendacao) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "card-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary nextBtn pull-right",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("close", true)
              }
            }
          },
          [_vm._v(" Fechar ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Módulo")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Pontos")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Recomendação")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }