<template>
  <div>
    <loading :loading="loading" />
    <DxTreeList
      id="treelist"
      :data-source="dataSource"
      :show-borders="true"
      key-expr="id"
      parent-id-expr="parentId"
      has-items-expr="hasItems"
      root-value=""
      :column-hiding-enabled="true"
      :word-wrap-enabled="true"
      :ref="treeListRefKey"
      @toolbar-preparing="onToolbarPreparing"
      @row-removing="onRowRemoving"
    >
      <DxSearchPanel :visible="true" />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :allow-adding="true"
        mode="form"
      />
      <DxSorting mode="none" />
      <DxRowDragging
        :on-reorder="onReorder"
        :allow-drop-inside-item="false"
        :allow-reordering="true"
        :show-drag-icons="true"
      />
      <DxRemoteOperations :filtering="true" />
      <DxColumn data-field="nome" caption="Nome" :allow-resizing="true" />
      <DxColumn
        data-field="descricao"
        caption="Descrição"
        :allow-resizing="true"
      />
      <DxColumn
        data-field="nrMedicoes"
        caption="Número de medições"
        :allow-resizing="true"
      />
      <DxColumn
        data-field="status"
        caption="Status"
        data-type="text"
        :customize-text="customizeStatusText"
        :allow-resizing="true"
      />
      <DxColumn type="buttons" :allow-resizing="true">
        <DxButton
          name="duplicate"
          :on-click="duplicateObject"
          :visible="duplicateIndicadorVisible"
          hint="Copiar Indicador"
          icon="copy"
        />
        <DxButton
          name="add"
          :on-click="addObject"
          :visible="addIndicadorVisible"
          hint="Adicionar Indicador"
        />
        <DxButton name="edit" :on-click="editObject" />
        <DxButton name="delete" />
      </DxColumn>
    </DxTreeList>
    <DxPopup
      :visible.sync="openTypeForm"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'80vh'"
      :height="'70vh'"
      :title="typePopupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <TipoIndicador
          @close="fecharPopupTipo"
          :id="typeId"
          :reload-data="typeReload"
        />
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxRemoteOperations,
  DxColumn,
  DxRowDragging,
  DxSorting,
  DxEditing,
  DxButton,
  DxSearchPanel,
} from "devextreme-vue/tree-list";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { alert } from "devextreme/ui/dialog";
import TipoIndicador from "@/views/forms/tipo-indicador.vue";
import notify from "devextreme/ui/notify";
import Loading from "@/components/loading.vue";

export default {
  name: "GerenciarIndicadores",
  components: {
    DxTreeList,
    DxRemoteOperations,
    DxColumn,
    DxRowDragging,
    DxSorting,
    DxEditing,
    DxButton,
    DxSearchPanel,
    DxPopup,
    TipoIndicador,
    DxScrollView,
    Loading,
  },
  data() {
    const inst = this;
    return {
      dataSource: {
        key: "id",
        load: function (loadOptions) {
          const d = {
            parentId:
              loadOptions.parentIds != "" && loadOptions.parentIds != undefined
                ? loadOptions.parentIds[0]
                : null,
            filter: loadOptions.filter[0].filterValue,
            parents: loadOptions.parentIds,
          };
          if (
            (d.filter !== null && d.filter !== undefined) ||
            (d.parents !== null && d.parents !== undefined)
          ) {
            return inst.$ConAPI
              .post(`/GerenciarIndicadores/GetIndicadoresData`, d, {
                headers: {
                  Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                },
              })
              .then((response) => JSON.parse(response.request.response))
              .catch(() => {
                throw "Data Loading Error";
              });
          }
        },
      },
      openTypeForm: false,
      typePopupTitle: "",
      typeId: null,
      typeReload: false,
      treeListRefKey: "treeList",
      loading: false,
    };
  },
  computed: {
    treeList: function () {
      return this.$refs[this.treeListRefKey].instance;
    },
  },
  methods: {
    customizeStatusText(e) {
      return e.value ? "Ativo" : "Inativo";
    },
    onReorder(e) {
      let visibleRows = e.component.getVisibleRows(),
        sourceData = e.itemData,
        targetData = visibleRows[e.toIndex].data;

      if (
        (sourceData.parentId === "" && targetData.parentId !== "") ||
        (sourceData.parentId !== "" && targetData.parentId === "")
      ) {
        alert(
          "Não é possível trocar a ordem entre indicador e Tipo",
          "Atenção"
        );
      } else if (sourceData.parentId !== targetData.parentId) {
        alert(
          "Não é possível reordenar indicadores de tipos diferentes.",
          "Atenção"
        );
      } else {
        const lst = visibleRows
          .filter((v) => v.data.parentId === sourceData.parentId)
          .map((v) => v.data);
        let sourceIndex = lst.indexOf(sourceData),
          targetIndex = lst.indexOf(targetData);
        lst.splice(sourceIndex, 1);
        lst.splice(targetIndex, 0, sourceData);

        const data = {
          type: sourceData.parentId === "" ? "T" : "I",
          ids: lst.map((v) => v.id),
        };

        this.$ConAPI
          .post(`/GerenciarIndicadores/ReorderIndicadores`, data, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then(() => {
            e.component.refresh();
          })
          .catch(() => {
            throw "Data Loading Error";
          });
      }
    },
    addObject(e) {
      this.$router.push({
        path: `gerenciar-indicadores/form/${e.row.data.id}`,
      });
    },
    addType() {
      this.typePopupTitle = "Cadastro Tipo de Indicador";
      this.typeId = null;
      this.openTypeForm = !this.openTypeForm;
    },
    editObject(e) {
      if (e.row.data.parentId === "") {
        this.typePopupTitle = "Editar Tipo de Indicador";
        this.typeId = e.row.data.id;
        this.typeReload = !this.typeReload;
        this.openTypeForm = !this.openTypeForm;
      } else {
        this.$router.push({
          path: `gerenciar-indicadores/form/${e.row.data.parentId}/${e.row.data.id}`,
        });
      }
    },
    onToolbarPreparing: function (e) {
      let toolbarItems = e.toolbarOptions.items;
      const inst = this;

      let addButton = toolbarItems.filter((x) => x.name === "addRowButton")[0];
      addButton.options.hint = "Adicionar Tipo Indicador";
      addButton.options.onClick = function () {
        inst.addType();
      };

      const newBtn = {
        widget: "dxButton",
        options: {
          icon: "refresh",
          hint: "Atualizar cálculos dos indicadores",
          onClick: function () {
            inst.loading = true;
            inst.$ConAPI
              .get(`/GerenciarIndicadores/UpdateIndicadoresCalculos`, {
                headers: {
                  Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                },
              })
              .then(() => {
                notify("Cálculos atualizados com sucesso", "success", 4000);
                inst.loading = false;
              })
              .catch(() => {
                notify("Internal Server Error", "error", 4000);
              });
          },
        },
        location: "after",
      };

      toolbarItems.unshift(newBtn);
    },
    fecharPopupTipo() {
      this.openTypeForm = !this.openTypeForm;
      this.treeList.refresh();
    },
    onRowRemoving(e) {
      e.cancel = true;
      if (e.data.nrMedicoes > 0 && e.data.parentId !== "") {
        alert("Não é possível excluir um indicador com medições", "Atenção");
      } else if (e.data.hasItems && e.data.parentId === "") {
        alert("Não é possível excluir um tipo de indicador", "Atenção");
      } else {
        if (e.data.parentId === "") {
          this.$ConAPI
            .delete(`/Gps_IndicadorTipo(Id=${e.data.id})`, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Removido com sucesso", "success", 4000);
              this.treeList.refresh();
            })
            .catch((response) => {
              const error = JSON.parse(response.request.response);
              notify(error.details.message, "error", 4000);
            });
        } else {
          this.$ConAPI
            .delete(`/Gps_Indicador(Id=${e.data.id})`, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
                "Content-type": "application/json;odata=verbose",
              },
            })
            .then(() => {
              notify("Removido com sucesso", "success", 4000);
              this.treeList.refresh();
            })
            .catch((response) => {
              const error = JSON.parse(response.request.response);
              notify(error.details.message, "error", 4000);
            });
        }
      }
    },
    addIndicadorVisible(e) {
      return e.row.data.parentId === "";
    },
    duplicateIndicadorVisible(e) {
      return e.row.data.parentId !== "";
    },
    duplicateObject(e) {
      const a = this.$root.getAuthData();
      this.loading = true;
      this.$ConAPI
        .get(
          `/GerenciarIndicadores/DuplicateIndicador/${e.row.key}/${a.usuario}`,
          {
            headers: {
              Authorization: `Bearer ${a.token}`,
            },
          }
        )
        .then(() => {
          notify("Indicador Duplicado com sucesso", "success", 4000);
          this.loading = false;
          this.treeList.refresh();
        })
        .catch(() => {
          notify("Internal Server Error", "error", 4000);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>