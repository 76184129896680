<template>
  <div>
    <div v-if="!exibeFaixaUnicoBloco && dadosTabela.length > 0">
      <h3 v-if="showTitle">Valores de referência</h3>
      <table
        class="table"
        v-for="(item, index) in dadosTabela"
        :key="index"
        v-show="item.ValoresReferencia.length > 0"
      >
        <thead>
          <tr>
            <th scope="col" style="color: #b66cb3">
              {{ item.Nome }}
            </th>
            <th scope="col" style="color: #b66cb3">Classificação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item2, index2) in item.ValoresReferencia" :key="index2">
            <td scope="col">
              <span
                class="refValue"
                :class="{ zeroValue: item2.ValorInicial === 0 }"
                >{{ item2.ValorInicial }}</span
              >
              <span class="refValue">-</span>
              <span class="refValue">{{ item2.ValorFinal }}</span>
              <span class="refValue">{{
                item2.Unidade !== null && item2.Unidade !== undefined
                  ? item2.Unidade
                  : ""
              }}</span>
            </td>
            <td scope="col">
              <i
                class="fas fa-compass"
                :class="{
                  'fa-flip-vertical': item2.CorExibicao === '#f44336',
                  'rotate-45': item2.CorExibicao === '#e0c619',
                }"
                :style="{
                  color: item2.CorExibicao ? item2.CorExibicao : 'gray',
                  'font-size': '20px',
                }"
                aria-hidden="true"
              ></i>
              {{ item2.Nome }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="exibeFaixaUnicoBloco && dadosTabela.length > 0">
      <h3 v-if="showTitle">Valores de referência</h3>
      <table class="table">
        <thead>
          <tr>
            <th
              scope="col"
              style="color: #b66cb3"
              v-for="(item, idx) in getUniqueDataKeys(dadosTabela[0])"
              :key="idx"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in dadosTabela" :key="idx">
            <td
              scope="col"
              v-for="(item2, idx2) in getUniqueDataKeys(dadosTabela[0])"
              :key="idx2"
            >
              <span class="refValue" v-if="item2 !== 'Classificação'">
                {{ item[item2] }}
              </span>
              <div v-if="item2 === 'Classificação'">
                <i
                  class="fas fa-compass"
                  :style="{
                    color: item[item2].CorExibicao
                      ? item[item2].CorExibicao
                      : 'gray',
                    'font-size': '20px',
                  }"
                  aria-hidden="true"
                ></i>
                {{ item[item2].Nome }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabelaReferencias",
  props: {
    data: {
      default: function () {
        return [];
      },
      required: true,
    },
    showTitle: {
      default: true,
    },
    exibeFaixaUnicoBloco: {
      default: false,
    },
  },
  data: function () {
    return {
      dadosTabela: [],
    };
  },
  watch: {
    data: function (v) {
      const a = this.checkWhatRefUseBy(v);
      this.dadosTabela = this.exibeFaixaUnicoBloco
        ? this.uniqueBlockTypeData(a)
        : a;
    },
  },
  created: function () {
    const a = this.checkWhatRefUseBy(this.data);
    this.dadosTabela = this.exibeFaixaUnicoBloco
      ? this.uniqueBlockTypeData(a)
      : a;
  },
  methods: {
    checkWhatRefUseBy: function (indicador) {
      if (indicador.Grupos !== undefined) {
        if (
          indicador.Grupos.find((x) => x.ValoresReferencia.length > 0) !==
          undefined
        )
          return indicador.Grupos;
      }
      if (indicador.Medidores !== undefined) {
        if (
          indicador.Medidores.find((x) => x.ValoresReferencia.length > 0) !==
          undefined
        )
          return indicador.Medidores;
      }
      if (indicador.Medicoes !== undefined) return indicador.Medicoes;

      return [];
    },
    uniqueBlockTypeData: function (arr) {
      const a = arr.reduce((p1, c1) => {
        if (c1.ValoresReferencia.length > p1.length) {
          c1.ValoresReferencia.map((value, i) => {
            let ref = arr.reduce((p2, c2) => {
              p2[c2.Nome] = `${c2.ValoresReferencia[i].ValorInicial} - ${
                c2.ValoresReferencia[i].ValorFinal
              } ${
                c2.ValoresReferencia[i].Unidade !== null &&
                c2.ValoresReferencia[i].Unidade !== undefined
                  ? c2.ValoresReferencia[i].Unidade
                  : ""
              }`;
              return p2;
            }, {});
            ref["Classificação"] = value;
            p1.push(ref);
          });
        }
        return p1;
      }, []);
      return a;
    },
    getUniqueDataKeys: function (obj) {
      return Object.keys(obj);
    },
  },
};
</script>

<style scoped>
.refValue {
  padding: 0 3px;
  margin: 0 2px;
}

.zeroValue {
  margin-right: 15px;
}
</style>