var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "modalTermo",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "exampleModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "DxScrollView",
                    {
                      attrs: {
                        width: "100%",
                        height: _vm.$root.getAuthData().aceiteTermo
                          ? "70vh"
                          : "55vh",
                        showScrollbar: "always",
                        "scroll-by-content": !_vm.$root.mobile,
                        "scroll-by-thumb": _vm.$root.mobile
                      },
                      on: { "reach-bottom": _vm.onReachBottom }
                    },
                    [_c("div", { domProps: { innerHTML: _vm._s(_vm.termo) } })]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "modalPolitica",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "DxScrollView",
                    {
                      attrs: {
                        width: "100%",
                        height: _vm.$root.getAuthData().aceiteTermo
                          ? "70vh"
                          : "55vh",
                        showScrollbar: "always",
                        "scroll-by-content": !_vm.$root.mobile,
                        "scroll-by-thumb": _vm.$root.mobile
                      },
                      on: { "reach-bottom": _vm.onReachBottom }
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.politica) }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "modalTermoLabel" } },
        [_vm._v("Termos de uso")]
      ),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "modalPoliticaLabel" } },
        [_vm._v(" Política de Privacidade ")]
      ),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }