var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", staticStyle: { "margin-bottom": "20px" } },
    [
      _c("div", { staticClass: "col-md-12", staticStyle: { padding: "0px" } }, [
        !_vm.multipleIndicadorFilter
          ? _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("DxSelectBox", {
                  attrs: {
                    searchEnabled: true,
                    "display-expr": "Name",
                    "value-expr": "id",
                    "data-source": _vm.indicadoresCombo
                  },
                  on: { "value-changed": _vm.onIndicadoresChanged },
                  model: {
                    value: _vm.selecaoIndicadores,
                    callback: function($$v) {
                      _vm.selecaoIndicadores = $$v
                    },
                    expression: "selecaoIndicadores"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.multipleIndicadorFilter
          ? _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("DxTagBox", {
                  attrs: {
                    items: _vm.indicadoresCombo,
                    "display-expr": "Name",
                    "value-expr": "id",
                    searchEnabled: true
                  },
                  on: { "value-changed": _vm.onIndicadoresChanged },
                  model: {
                    value: _vm.selecaoIndicadores,
                    callback: function($$v) {
                      _vm.selecaoIndicadores = $$v
                    },
                    expression: "selecaoIndicadores"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("DxSelectBox", {
              attrs: {
                searchEnabled: true,
                "display-expr": "Name",
                "value-expr": "id",
                "data-source": _vm.riscoCombo
              },
              on: { "value-changed": _vm.onRiscoChanged },
              model: {
                value: _vm.selecaoRisco,
                callback: function($$v) {
                  _vm.selecaoRisco = $$v
                },
                expression: "selecaoRisco"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }