<template>
  <DxDataGrid
    :ref="'dataGrid'"
    columnResizingMode="nextColumn"
    :height="height"
    :data-source="dataSource"
    :show-borders="true"
    :remoteOperations="true"
    :wordWrapEnabled="true"
    :allowColumnReordering="true"
    :allowColumnResizing="true"
    :hoverStateEnabled="true"
    :selection="selection"
    :selected-row-keys.sync="selected"
    :column-hiding-enabled="true"
    :customize-columns="customizeColumns"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing"
    @initialized="saveGridInstance"
    @row-inserting="onRowInserting"
    @row-removing="onRowRemoving"
    @selection-changed="onSelectionChanged"
    @content-ready="onContentReady"
  >
    <DxRowDragging
      :allow-reordering="canReorderByDrag"
      :on-reorder="onReorder"
      :show-drag-icons="true"
    />
    <DxHeaderFilter :visible="enableHeaderSearch" />
    <DxSorting :mode="canSort ? 'single' : 'none'" />
    <DxSearchPanel
      :visible="canSearch"
      :width="240"
      placeholder="Procurar..."
    />
    <DxColumnChooser :enabled="true" />
    <DxExport v-if="editMode === 'form'" :enabled="true" />
    <DxEditing
      v-if="editMode !== ''"
      refreshMode="full"
      :mode="editMode"
      :allow-adding="editConfig.adding"
      :allow-updating="editConfig.updating"
      :allow-deleting="editConfig.deleting"
      :useIcons="true"
    >
      <DxTexts :confirm-delete-message="deleteMessage" />
    </DxEditing>
    <DxGroupPanel
      v-if="editMode === 'form'"
      :visible="true"
      :emptyPanelText="' '"
    />
    <DxPaging :page-size="$root.mobile ? 5 : 10" />
    <DxPager
      :show-page-size-selector="!$root.mobile"
      :allowed-page-sizes="pageSizes"
      :show-info="true"
    />

    <DxColumn
      v-for="(item, index) in columns.filter((x) => !x.notVisible)"
      :key="index"
      :data-field="item.name"
      :data-type="item.type"
      :caption="item.caption"
      :visible="item.showColumn"
      :customize-text="item.customizeText"
      :width="item.size"
      :css-class="item.class"
      :editor-options="item.editorOptions"
    >
      <DxLookup
        v-if="item.lookup !== undefined"
        :data-source="item.lookup.dataSource"
        :display-expr="item.lookup.displayExpr"
        :value-expr="item.lookup.valueExpr"
        :disabled="item.lookup.disabled"
      />
      <DxRequiredRule v-if="item.required" />
    </DxColumn>

    <DxColumn type="buttons">
      <DxButton
        v-for="item in otherButtons"
        :key="item.key"
        :text="item.text"
        :icon="item.icon"
        :hint="item.hint"
        :visible="item.visible"
        :css-class="item.cssClass"
        :on-click="item.command"
      />
      <DxButton name="edit" :on-click="editObject" v-if="editMode === 'form'" />
      <DxButton name="edit" v-if="editMode !== 'form'" />
      <DxButton name="delete" />
    </DxColumn>

    <DxMasterDetail :enabled="hasDetail" template="masterDetailTemplate" />
    <template #masterDetailTemplate="{ data: master }">
      <slot :master="master"></slot>
    </template>
    <template #enableSearchHeaders>
      <div class="enable-search-headers">
        <h4>Ativar pesquisa na coluna</h4>
        <div>
          <DxSwitch v-model="enableHeaderSearch" @value-changed="clearFilter" />
        </div>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxColumnChooser,
  DxSearchPanel,
  DxEditing,
  DxButton,
  DxTexts,
  DxGroupPanel,
  DxPaging,
  DxPager,
  DxMasterDetail,
  DxLookup,
  DxSorting,
  DxRequiredRule,
  DxRowDragging,
  DxHeaderFilter,
} from "devextreme-vue/data-grid";
import ODataStore from "devextreme/data/odata/store";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import notify from "devextreme/ui/notify";
import { DxSwitch } from "devextreme-vue/switch";

export default {
  name: "Grid",
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxColumnChooser,
    DxSearchPanel,
    DxEditing,
    DxButton,
    DxTexts,
    DxGroupPanel,
    DxPaging,
    DxPager,
    DxMasterDetail,
    DxLookup,
    DxSorting,
    DxRequiredRule,
    DxRowDragging,
    DxSwitch,
    DxHeaderFilter,
  },
  props: {
    columns: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    action: {
      type: String,
      default: "",
      required: false,
    },
    editMode: {
      type: String,
      default: "form",
      required: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
      required: false,
    },
    filterValue: {
      required: false,
    },
    filterField: {
      type: String,
      default: "",
      required: false,
    },
    height: {
      type: String,
      default: "",
      required: false,
    },
    otherButtons: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    gridActions: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    reload: {
      type: Boolean,
      default: false,
      required: false,
    },
    parentIdField: {
      default: null,
    },
    initInsertingRow: {
      default: null,
    },
    data: {
      default: null,
    },
    selection: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectedRows: {
      type: Array,
      default: function () {
        return [];
      },
    },
    canReorderByDrag: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: true,
    },
    canSort: {
      type: Boolean,
      default: true,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    parents: {
      type: Array,
      default: function () {
        return [];
      },
    },
    customizeColumns: {
      default: null,
    },
    deleteMessage: {
      default: "Deseja realmente excluir esse registro?",
    },
    editConfig: {
      default: function () {
        return {
          adding: true,
          updating: true,
          deleting: true,
        };
      },
    },
    hasHeaderFilter: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    reload: function () {
      this.refresh();
    },
  },
  data() {
    return {
      dataSource: null,
      pageSizes: [5, 10, 20],
      dataGridInstance: null,
      selected: [],
      enableHeaderSearch: false,
    };
  },
  mounted: function () {
    this.refresh();
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Employees");

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "GPSMEDData.xlsx"
          );
        });
      });

      this.loggingExportedData(e.component.getDataSource());

      e.cancel = true;
    },
    loggingExportedData(dataSource) {
      dataSource.load().done((allData) => {
        const a = {
          dataType: this.action,
          data: JSON.stringify(
            allData.map((value) => {
              delete value["@odata.type"];
              return value;
            })
          ),
        };

        this.$ConAPI
          .post("/logcontrol/logexporteddata", a, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .catch(function (e) {
            console.log(e);
            // notify("Acesso negado", "error", 4000);
          });

        console.log(a);
      });
    },
    onToolbarPreparing: function (e) {
      let toolbarItems = e.toolbarOptions.items;
      const inst = this;
      if (this.editMode == "form") {
        let addButton = toolbarItems.filter(
          (x) => x.name === "addRowButton"
        )[0];

        addButton.options.onClick = function () {
          inst.$emit("add-item");
        };
      }

      if (this.gridActions.length > 0) {
        this.gridActions.map((value) => {
          toolbarItems.push(value);
        });
      }

      if (this.hasHeaderFilter) {
        toolbarItems.push({
          location: "before",
          template: "enableSearchHeaders",
        });
      }

      if (this.canSearch) {
        const searchPanel = toolbarItems.filter(
          (x) => x.name === "searchPanel"
        )[0];
        const i = toolbarItems.indexOf(searchPanel);
        toolbarItems.splice(i, 1);
        toolbarItems.unshift(searchPanel);
      }
    },
    editObject: function (e) {
      this.$emit("edit-item", e.row);
    },
    saveGridInstance: function (e) {
      this.dataGridInstance = e.component;
    },
    setDataSource: function () {
      const instance = this;
      const cols = instance.columns.map((v) => v.name);
      const sort = instance.columns
        .filter((v) => v.defaultSort)
        .map((v) => {
          return { selector: v.name, desc: false };
        });
      instance.dataSource = {
        store: new ODataStore({
          url: `${instance.$UrlAPI}/${instance.action}`,
          version: 4,
          key: ["Id"],
          keyType: {
            Id: "Guid",
          },
          beforeSend: function (e) {
            e.headers = {
              Authorization: `Bearer ${instance.$root.getAuthData().token}`,
            };
          },
        }),
        select: cols,
        sort: sort,
      };
      if (
        instance.filterValue !== undefined &&
        instance.filterField !== "" &&
        instance.filters.length === 0
      ) {
        instance.dataSource = {
          ...instance.dataSource,
          filter: [[instance.filterField, "=", instance.filterValue]],
        };
      }
      if (
        instance.filterValue === undefined &&
        instance.filterField === "" &&
        instance.filters.length > 0
      ) {
        instance.dataSource = {
          ...instance.dataSource,
          filter: instance.filters,
        };
      }
    },
    refresh: function () {
      if (this.action !== "") this.setDataSource();
      else if (this.data !== null) this.dataSource = this.data;

      this.dataGridInstance.option("dataSource", this.dataSource);
      this.dataGridInstance.option("onInitNewRow", function (e) {
        if (
          this.parentIdField !== null &&
          this.parentIdField !== undefined &&
          this.parents.length === 0
        )
          e.data[this.parentIdField] = this.filterValue;
        if (
          this.parentIdField === null &&
          this.parentIdField === undefined &&
          this.parents.length > 0
        ) {
          this.parents.map((value) => {
            e.data[value.field] = value.value;
          });
        }
      });
      if (
        this.initInsertingRow !== null &&
        this.initInsertingRow !== undefined
      ) {
        this.dataGridInstance.option("onInitNewRow", this.initInsertingRow);
      }

      this.dataGridInstance.refresh();
    },
    onRowInserting: function (e) {
      if (
        this.parentIdField !== null &&
        this.parentIdField !== undefined &&
        this.parents.length === 0
      )
        e.data[this.parentIdField] = this.filterValue;
      if (
        this.parentIdField === null &&
        this.parentIdField === undefined &&
        this.parents.length > 0
      ) {
        this.parents.map((value) => {
          e.data[value.field] = value.value;
        });
      }
    },
    onSelectionChanged: function () {
      this.$emit(
        "selected-rows-ids",
        this.dataGridInstance.getSelectedRowKeys()
      );
    },
    onContentReady: function () {
      this.selectedRows.map((value) => {
        this.selected.push(value);
      });
    },
    onRowRemoving: function (e) {
      if (this.isUser) {
        e.cancel = true;
        this.$ConAPI
          .delete(
            `/GPS_PessoaFisica(${e.data.Id._value})/GPS_Pessoa_FisicaJuridicas/$ref?$id=${e.data.IdPessoaJuridica._value}`,
            {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
              },
            }
          )
          .then((res) => {
            notify(res.data.message, "success", 4000);
            this.refresh();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onReorder(e) {
      let visibleRows = e.component.getVisibleRows(),
        sourceData = e.itemData,
        targetData = visibleRows[e.toIndex].data;

      const lst = visibleRows
        .filter((v) => v.data.parentId === sourceData.parentId)
        .map((v) => v.data);

      let sourceIndex = lst.indexOf(sourceData),
        targetIndex = lst.indexOf(targetData);
      lst.splice(sourceIndex, 1);
      lst.splice(targetIndex, 0, sourceData);

      const data = {
        type: this.action,
        ids: lst.map((v) => v.Id),
      };
      this.$ConAPI
        .post(`/GerenciarIndicadores/ReorderGruposIndicadores`, data, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then(() => {
          this.refresh();
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    clearFilter() {
      this.$refs["dataGrid"].instance.clearFilter();
    },
  },
};
</script>

<style>
.enable-search-headers {
  width: 400px;
  display: flex;
}
.enable-search-headers div {
  top: 1px;
  justify-content: flex-start;
}
.enable-search-headers h4 {
  margin-right: 10px;
}
</style>