<template>
  <div class="info">
    <a data-toggle="collapse" href="#collapseExample" class="collapsed">
      <span style="margin: 0 3px 0 7px">{{ userShortenedName }}</span>
      <span
        class="acessado"
        v-if="$root.getAuthData().usuario !== $root.getAuthData().userLogged"
        >Acessado por {{ $root.getAuthData().userLoggedName }}</span
      >
      <!-- <b
        class="caret"
        v-if="$root.getAuthData().usuario === $root.getAuthData().userLogged"
      ></b> -->
      <br />
    </a>
    <!-- <div class="collapse text-center" id="collapseExample">
      <ul
        class="nav"
        id="userMenu"
        v-if="$root.getAuthData().usuario === $root.getAuthData().userLogged"
      >
        <li>
          <div style="cursor: pointer" v-on:click="alterProfile">
            <i class="fas fa-user-edit"></i>
            <span style="margin-left: 7px">Perfil</span>
          </div>
        </li>
        <li>
          <div
            style="cursor: pointer; margin-top: 10px"
            v-on:click="alterPermissions"
          >
            <i class="fas fa-quote-right"></i>
            <span style="margin-left: 7px">Permissões</span>
            <span v-if="notificationCounter > 0" class="badge">{{
              notificationCounter
            }}</span>
          </div>
        </li>
      </ul>
    </div> -->
    <ModaisTermos />
  </div>
</template>

<script>
import ModaisTermos from "@/components/ModaisTermos.vue";

export default {
  name: "UserInfoMenu",
  components: {
    ModaisTermos,
  },
  props: ["notificationCounter"],
  computed: {
    userShortenedName: function () {
      const a = this.$root.getAuthData().userName.split(" ");
      return `${a[0]} ${a[a.length - 1]}`;
    },
  },
  methods: {
    alterProfile: function () {
      this.pageName = "Perfil";
      this.$router.push({
        path: `/usuario/profile/${this.$root.getAuthData().usuario}`,
      });
    },
    alterPermissions: function () {
      this.pageName = "Permissões";
      this.$router.push({
        path: `/permissions`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.acessado {
  display: block;
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 11px;
}
#userMenu {
  text-align: left;
  //margin-left: 25px;
  padding-left: 35px;
}
.sidebar-mini #userMenu {
  padding-left: 10px;
  width: 200px;
}
.badge {
  position: absolute;
  left: 100px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}
</style>
