<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>Gráfico evolutivo</h3>
      </div>
      <div class="card-content">
        <DxChart
          id="chart1"
          class="chart"
          :data-source="dataSource"
          palette="Violet"
          @initialized="saveChartInstance"
        >
          <DxCommonSeriesSettings type="spline" argument-field="Data" />
          <DxCommonAxisSettings>
            <DxGrid :visible="true" />
          </DxCommonAxisSettings>
          <DxSeries
            v-for="(tipos, index2) in data"
            :key="index2"
            :value-field="
              tipos.Nome !== null && tipos.Nome !== undefined
                ? tipos.Nome.replace('@', '')
                : ''
            "
            :name="
              tipos.Nome !== null && tipos.Nome !== undefined
                ? tipos.Nome.replace('@', '')
                : ''
            "
          />
          <DxMargin :bottom="20" />
          <DxArgumentAxis
            :allow-decimals="false"
            :axis-division-factor="60"
            name="medidorEixo"
          >
            <DxLabel :customize-text="customizeLabel">
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxLegend
            vertical-alignment="top"
            horizontal-alignment="center"
            :visible="true"
          />
          <DxTooltip :enabled="true" />
        </DxChart>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxGrid,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from "devextreme-vue/chart";
import notify from "devextreme/ui/notify";

export default {
  name: "CardGraficoEvolutivoMedidor",
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxGrid,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
  },
  props: {
    id: {
      default: null,
      required: true,
    },
    reload: {
      default: false,
    },
  },
  data: function () {
    return {
      data: [],
      dataSource: [],
      referenciasData: [],
      loaded: false,
      hasReferencias: false,
      chartInstance: null,
    };
  },
  watch: {
    reload: function () {
      this.getData();
    },
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      this.$ConAPI
        .get(
          `/DadosIndicadores/GetIndicadorData/4/${
            this.$root.getAuthData().usuario
          }/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((result) => {
          var resposta = JSON.parse(result.request.response);
          this.referenciasData = resposta;
          this.hasReferencias = this.validateReferenciasTable();
          this.dataSource = this.groupDataToChart(resposta.Medidores, "Nome");
          this.data = resposta.Medidores;
          this.loaded = true;
          this.chartInstance.refresh();
        })
        .catch(function (error) {
          notify(error, "error", 4000);
        });
    },
    customizeLabel: function (e) {
      const d = new Date(e.value);
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    groupDataToChart: function (arr, key) {
      if (arr.Dados === null) return [];
      return arr.reduce((r, a) => {
        if (a.visible) {
          a.Dados.map((value) => {
            let h = {};
            h[a[key].replace("@", "")] = value.Value;
            h["Data"] = value.Data;
            r.push(h);
          });
        }
        return r;
      }, []);
    },
    validateReferenciasTable: function () {
      if (this.referenciasData !== undefined && this.referenciasData !== null) {
        if (
          this.referenciasData.Medidores !== undefined &&
          this.referenciasData.Medidores !== null
        ) {
          if (
            this.referenciasData.Medidores[0].ValoresReferencia !== undefined &&
            this.referenciasData.Medidores[0].ValoresReferencia !== null
          ) {
            return (
              this.referenciasData.Medidores[0].ValoresReferencia.lenght > 0
            );
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    saveChartInstance: function (e) {
      this.chartInstance = e.component;
    },
  },
};
</script>

<style>
</style>