var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-content" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "DxChart",
            {
              attrs: {
                id: "chart",
                "data-source": _vm.chartData,
                "customize-point": _vm.customizePoint
              }
            },
            [
              _c("DxCommonSeriesSettings", {
                attrs: {
                  "ignore-empty-points": true,
                  "argument-field": "name",
                  "value-field": "value",
                  type: "bar",
                  barWidth: 20
                }
              }),
              _c("DxSeriesTemplate", { attrs: { "name-field": "name" } }),
              _c(
                "DxArgumentAxis",
                {
                  attrs: { "allow-decimals": false, "axis-division-factor": 60 }
                },
                [
                  _c(
                    "DxLabel",
                    [_c("DxFormat", { attrs: { type: "decimal" } })],
                    1
                  )
                ],
                1
              ),
              _c("DxValueAxis", { attrs: { max: 100 } }),
              _c("DxLegend", { attrs: { visible: false } }),
              _c("DxTooltip", { attrs: { enabled: true } }),
              _c("DxSize", { attrs: { height: 200 } })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.data, function(item, idx) {
              return _c("tr", { key: idx }, [
                _c("td", { attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(idx + 1) + " ")
                ]),
                _c("td", { attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(item.name) + " ")
                ]),
                _c("td", { attrs: { scope: "col" } }, [
                  _c("i", {
                    staticClass: "fas fa-compass",
                    style: {
                      color: item.cor ? item.cor : "gray",
                      "font-size": "20px"
                    },
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", [_vm._v("Resultado mais recente")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }