var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "loader-wrapper"
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fulfilling-bouncing-circle-spinner" }, [
      _c("div", { staticClass: "circle" }),
      _c("div", { staticClass: "orbit" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }