var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container visible-xs" }, [
    _vm.isHome
      ? _c("div", { staticClass: "row menuMobile" }, [
          _c(
            "div",
            {},
            [
              _c("profile", {
                staticClass: "col-xs-4 text-center",
                class: _vm.isAcessedByHealthWorker ? "desabilitado" : "",
                attrs: {
                  disabled: _vm.isAcessedByHealthWorker,
                  "notification-counter": _vm.notificationCounter
                }
              }),
              _c("div", { staticClass: "col-xs-4 text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "controle",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.goToResults()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "fas fa-chart-line" }),
                    _c("span", { staticClass: "titulo" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$root.getAuthData().defaultSystem === "Cliente"
                            ? "Resultados"
                            : "Relatório"
                        )
                      )
                    ])
                  ]
                )
              ]),
              _vm.$root.getAuthData().defaultSystem === "Cliente"
                ? _c("div", { staticClass: "col-xs-4 text-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "controle",
                        on: {
                          click: function($event) {
                            return _vm.goToRecomendations()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "fas fa-lightbulb" }),
                        _c("span", { staticClass: "titulo" }, [
                          _vm._v("Recomendações")
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _c("permissions-action", {
                staticClass: "col-xs-4 text-center",
                class: _vm.isAcessedByHealthWorker ? "desabilitado" : "",
                attrs: {
                  disabled: _vm.isAcessedByHealthWorker,
                  "notification-counter": _vm.notificationCounter
                },
                on: {
                  "page-name": function($event) {
                    return _vm.$emit("page-name", $event)
                  }
                }
              }),
              _c("AlterSystemAction", {
                class: ["col-xs-4", "text-center"],
                on: {
                  "altered-system": function($event) {
                    return _vm.$emit("altered-system", $event)
                  }
                }
              }),
              _c("ContactsACtion", { class: ["col-xs-4", "text-center"] }),
              _c("LogoutAction", { class: ["col-xs-4", "text-center"] })
            ],
            1
          )
        ])
      : _vm._e(),
    !_vm.isHome && !_vm.isIndicadorPage
      ? _c("div", { staticClass: "row menuMobile" }, [
          _c("div", { staticClass: "col-xs-12 text-center" }, [
            _c(
              "a",
              {
                staticClass: "controle",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.goToHome()
                  }
                }
              },
              [
                _c("span", { staticClass: "fas fa-home" }),
                _c("span", { staticClass: "titulo" }, [
                  _vm._v("Página Inicial")
                ])
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }