<template>
  <div>
    <Grid
      :columns="columns"
      :action="action"
      class="grid-resize"
      :edit-mode="'batch'"
      :reload="reloadGrid"
      :parent-id-field="'_Indicador'"
      :filter-value="filterValue"
      :filter-field="'Indicador.Id'"
      :can-sort="false"
      :can-search="false"
    ></Grid>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
import ODataStore from "devextreme/data/odata/store";

export default {
  name: "DependenciasIndicador",
  components: {
    Grid,
  },
  props: {
    indicador: {
      default: null,
      required: true,
    },
  },
  data: function () {
    const inst = this;
    return {
      reloadGrid: false,
      action: "GPS_IndicadorDependencia",
      filterValue: new Guid(inst.indicador),
      columns: [
        { name: "Id", notVisible: true },
        { name: "_Indicador", notVisible: true },
        {
          name: "_IndicadorDependente",
          showColumn: true,
          required: true,
          lookup: {
            allowClearing: true,
            dataSource: {
              store: new ODataStore({
                url: `${inst.$UrlAPI}/GPS_Indicador`,
                version: 4,
                key: ["Id"],
                keyType: {
                  Id: "Guid",
                },
                beforeSend: function (e) {
                  e.headers = {
                    Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  };
                },
              }),
              select: ["Id", "Nome", "Ativo"],
              filter: [["Ativo", "=", true]],
              sort: [{ selector: "Nome", desc: false }],
              paginate: true,
              pageSize: 10,
            },
            displayExpr: "Nome",
            valueExpr: "Id",
          },
        },
      ],
    };
  },
};
</script>

<style>
</style>