<template>
  <div
    v-if="
      ($root.getAuthData().acessoGPS &&
        $root.getAuthData().usuario === $root.getAuthData().userLogged) ||
      ($root.getAuthData().hasAdminAccess &&
        $root.getAuthData().usuario === $root.getAuthData().userLogged)
    "
  >
    <div
      :class="{ 'menu-item': !$root.mobile, controle: $root.mobile }"
      @click="alterSystem"
      @mouseenter="altSystemVisible = !altSystemVisible"
      @mouseleave="altSystemVisible = !altSystemVisible"
    >
      <i
        v-if="!$root.mobile"
        id="altSystem"
        class="fas fa-suitcase text-orange"
      ></i>
      <span v-if="$root.mobile" class="fas fa-suitcase"></span>
      <!-- <div class="menu-text" v-if="$root.mobile">
        {{ tooltipChangeSystem }}
      </div> -->
      <span class="titulo" v-if="$root.mobile">{{ mobileTxt }}</span>
      <DxTooltip
        v-if="!$root.mobile"
        :key="tooltipChangeSystem"
        :visible="altSystemVisible"
        :close-on-outside-click="false"
        target="#altSystem"
        >{{ tooltipChangeSystem }}</DxTooltip
      >
    </div>
  </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  name: "AlterSystemAction",
  components: {
    DxTooltip,
  },
  data: function () {
    const inst = this;
    return {
      altSystemVisible: false,
      tooltipChangeSystem:
        inst.$root.getAuthData().defaultSystem === "Cliente"
          ? "Alterar para Admin"
          : "Alterar para Pessoal",
      mobileTxt:
        inst.$root.getAuthData().defaultSystem === "Cliente"
          ? "Admin"
          : "Pessoal",
    };
  },
  methods: {
    alterSystem: function () {
      this.$root.resetLoggedUser();
      const a = this.$root.getAuthData();
      if (a.defaultSystem === "Cliente") {
        this.tooltipChangeSystem = `Alterar para Pessoal`;
        this.mobileTxt = `Pessoal`;
        a.defaultSystem = "Admin";
        this.$root.setAuthData(a);
      } else {
        this.tooltipChangeSystem = `Alterar para Admin`;
        this.mobileTxt = `Admin`;
        a.defaultSystem = "Cliente";
        this.$root.setAuthData(a);
      }
      this.$emit("altered-system", true);
      window.location = this.$UrlApl + "/";
    },
  },
};
</script>

<style scoped>
</style>
