import { render, staticRenderFns } from "./cliente.vue?vue&type=template&id=2081a7d0&"
import script from "./cliente.vue?vue&type=script&lang=js&"
export * from "./cliente.vue?vue&type=script&lang=js&"
import style0 from "./cliente.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2081a7d0')) {
      api.createRecord('2081a7d0', component.options)
    } else {
      api.reload('2081a7d0', component.options)
    }
    module.hot.accept("./cliente.vue?vue&type=template&id=2081a7d0&", function () {
      api.rerender('2081a7d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cliente.vue"
export default component.exports