<template>
  <form @submit="onFormSubmit($event)">
    <div>
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header" style="text-align: left">
              <h4 class="card-title">Dados</h4>
            </div>
            <div class="card-content">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group label-floating">
                    <label class="control-label">Nome*</label>
                    <DxTextBox v-model="formData.Nome" :maxLength="240">
                      <DxValidator>
                        <DxRequiredRule message="Campo Obrigatório" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </div>
                <div class="col-md-4" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.Principal"
                  />
                  <label style="float: left">Exibir como principal</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Tipo Indicador</label>
                    <DxSelectBox
                      :data-source="tiposDataSource"
                      display-expr="Sigla"
                      value-expr="Id"
                      v-model="formData._Tipo"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Sigla</label>
                    <DxTextBox v-model="formData.Sigla" :maxLength="200">
                    </DxTextBox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Ordem</label>
                    <DxNumberBox v-model="formData.Ordem" :read-only="true" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Direcionamento</label>
                    <DxSelectBox
                      :items="direcionamentoItems"
                      display-expr="text"
                      value-expr="value"
                      v-model="formData.Direcionamento"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Preenchimento:</label>
                    <DxRadioGroup
                      :items="preenchimentoItens"
                      layout="horizontal"
                      display-expr="text"
                      value-expr="value"
                      v-model="formData.Calculado"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Ativo</label>
                    <DxSwitch class="switch" v-model="formData.Ativo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Comportamentos</h4>
            </div>
            <div class="card-content">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label"
                      >Mostrar para os seguintes sexos</label
                    >
                    <DxSelectBox
                      :items="sexoItems"
                      display-expr="text"
                      value-expr="value"
                      v-model="formData.Sexo"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group label-floating">
                    <label class="control-label">Casas Decimais</label>
                    <DxNumberBox v-model="formData.CasasDecimais" :max="9999" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.Mostra_Jejum"
                  />
                  <label style="float: left"
                    >Mostrar Campo Jejum na medição</label
                  >
                </div>
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.MostrarFaixaIndicador"
                  />
                  <label style="float: left">Mostrar Faixa Indicador</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.MostrarFaixaGrupo"
                  />
                  <label style="float: left">Mostrar Faixa Sub-Indicador</label>
                </div>
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.MostrarFaixaMedidor"
                  />
                  <label style="float: left">Mostrar Faixa Medidor</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.ExibirFaixaUnicoBloco"
                  />
                  <label style="float: left">Exibir Medidores Agrupados</label>
                </div>
                <div class="col-md-6" style="margin: 15px 0">
                  <DxCheckBox
                    id="checkExibiPrincipal"
                    style="float: left; margin: 3px 7px"
                    v-model="formData.UtilizaQuestionario"
                  />
                  <label style="float: left"
                    >Exibir Medição como Questionário</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-content">
              <div class="row">
                <div class="col-md-12">
                  <DxButton
                    id="save"
                    :use-submit-behavior="true"
                    text="Salvar"
                    class="btn btn-warning pull-right"
                    >Salvar</DxButton
                  >
                  <DxButton
                    id="back"
                    text="Voltar"
                    class="btn btn-warning pull-right"
                    @click="back"
                    >Voltar</DxButton
                  >
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-profile">
            <div
              id="imageBox"
              class="indicador-image-box"
              :class="{
                'companie-image-bkg-grey': !showPhoto,
                'companie-image-bkg-white': showPhoto,
              }"
            >
              <img
                id="profilePhoto"
                v-show="showPhoto"
                class="img"
                :src="photo"
              />
              <span v-show="!showPhoto">
                <i
                  class="fas fa-image default-indicador-image-icon"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <div class="card-content">
              <DxFileUploader
                ref="photoFileUploader"
                :multiple="false"
                label-text
                accept="image/*"
                upload-mode="instantly"
                select-button-text="Selecione um arquivo"
                invalid-file-extension-message="Extensão de arquivo não aceita"
                :upload-url="urlUploadImport"
                :allowed-file-extensions="['.jpg', '.png']"
                :upload-headers="uploadImportHeader"
                @uploaded="onUploaded"
                @value-changed="photoChanged"
              />
              <DxButton
                v-if="showPhoto"
                id="remove-photo-file"
                text="Remover arquivo"
                class="btn btn-secondary pull-right btn-back separated"
                @click="removeImage('Photos')"
                >Remover arquivo</DxButton
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header" style="text-align: left">
              <h4 class="card-title">Descrição e Cálculo</h4>
            </div>
            <div class="card-content">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group label-floating">
                    <label class="control-label">Descrição</label>
                    <DxTextArea
                      :autoResizeEnabled="true"
                      v-model="formData.Descricao"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group label-floating">
                    <label class="control-label">Formula de Cálculo</label>
                    <DxTextArea
                      :autoResizeEnabled="true"
                      v-model="formData.Formula"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxCheckBox from "devextreme-vue/check-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxFileUploader from "devextreme-vue/file-uploader";
import DxSwitch from "devextreme-vue/switch";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxTextArea from "devextreme-vue/text-area";
import notify from "devextreme/ui/notify";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";

export default {
  name: "DadosIndicador",
  components: {
    DxTextBox,
    DxNumberBox,
    DxCheckBox,
    DxSelectBox,
    DxFileUploader,
    DxSwitch,
    DxRadioGroup,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    DxButton,
  },
  props: {
    id: {
      default: null,
    },
    type: {
      default: null,
      required: true,
    },
  },
  data: function () {
    const inst = this;
    return {
      action: "GPS_Indicador",
      preenchimentoItens: [
        { text: "Manual", value: false },
        { text: "Automatico", value: true },
      ],
      direcionamentoItems: [
        { text: "Ascendente", value: "C" },
        { text: "Descendente", value: "D" },
      ],
      sexoItems: [
        { text: "Todos", value: "X" },
        { text: "Masculino", value: "M" },
        { text: "Feminino", value: "F" },
      ],
      photoDefaultPath: `${inst.$root.Controle.UrlAPI}/Upload/GetPhoto?type=indicadores&image=`,
      photo: "",
      urlUploadImport: `${inst.$root.Controle.UrlAPI}/Upload/UploadPhoto`,
      uploadImportHeader: {
        Authorization: `Bearer ${inst.$root.getAuthData().token}`,
      },
      tiposDataSource: inst.$root.tipoIndicadoresDataSource,
      formData: inst.defaultFormData(),
      showPhoto: false,
    };
  },
  computed: {
    photoFileUploader: function () {
      return this.$refs["photoFileUploader"].instance;
    },
  },
  created: function () {
    this.loadIndicador(this.id);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    onFormSubmit(e) {
      e.preventDefault();
      if (this.id === null) {
        this.$ConAPI
          .post(`${this.action}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Adicionado com sucesso", "success", 4000);
            this.$emit("indicador-adicionado", true);
            this.back();
          })
          .catch(function (error) {
            notify(error.response.value, "error", 4000);
          });
      } else {
        this.$ConAPI
          .patch(`${this.action}(Id=${this.id})`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
              "Content-type": "application/json;odata=verbose",
            },
          })
          .then(() => {
            notify("Alterado com sucesso", "success", 4000);
          })
          .catch(function (error) {
            notify(error.response.value, "error", 4000);
          });
      }
    },
    photoChanged: function (e) {
      if (e.value.length > 0) {
        const inst = this;
        if (e.value.length) {
          var reader = new FileReader();
          reader.onload = function (e) {
            inst.photo = e.target.result;
          };
          reader.readAsDataURL(e.value[0]);
        }
        this.showPhoto = true;
      } else {
        this.showPhoto = false;
      }
    },
    onUploaded: function (e) {
      if (this.$root.isJson(e.request.response)) {
        var response = JSON.parse(e.request.response);
        if (response.status == 200) {
          notify(response.msg, "success", 4000);
          this.formData.Imagem = response.path;
        } else {
          notify(response.msg, "error", 4000);
        }
      }
    },
    setFormData(v = {}) {
      const inst = this;
      Object.keys(inst.formData).map((value) => {
        const i = Object.keys(v).indexOf(value);
        if (i !== null && i !== undefined) {
          inst.formData[value] = v[value];
        }
      });
    },
    defaultFormData: function () {
      return {
        _Tipo: this.type,
        Nome: "",
        Sigla: "",
        Ordem: 0,
        Descricao: "",
        Mostra_Jejum: false,
        Sexo: "X",
        Formula: "",
        Ativo: true,
        UtilizaQuestionario: false,
        Principal: false,
        Calculado: false,
        Direcionamento: "",
        MostrarFaixaMedidor: false,
        MostrarFaixaGrupo: false,
        MostrarFaixaIndicador: false,
        Imagem: "",
        CasasDecimais: 0,
        ExibirFaixaUnicoBloco: false,
      };
    },
    loadIndicador(id) {
      if (id !== null) {
        this.$ConAPI
          .get(`/${this.action}(Id=${id})`, {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          })
          .then((res) => {
            this.setFormData(res.data);
            if (
              this.formData.Imagem !== "" &&
              this.formData.Imagem !== null &&
              this.formData.Imagem !== undefined &&
              this.formData.Imagem !== "null"
            ) {
              this.photo = `${this.photoDefaultPath}${this.formData.Imagem}`;
              this.showPhoto = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.formData = this.defaultFormData();
      }
    },
    removeImage: function (type) {
      this.$ConAPI
        .get(
          `/Upload/RemoveImage?image=${
            type === "Photos" ? this.formData.Imagem : this.formData.Icone
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$root.getAuthData().token}`,
            },
          }
        )
        .then((res) => JSON.parse(res.request.response))
        .then((result) => {
          if (result.found) {
            notify(result.message, "success", 4000);
          }
          this.photoFileUploader.reset();
          this.formData.Imagem = "";
        })
        .catch((err) => {
          const e = JSON.parse(err.request.response);
          notify(e.error.details.message, "error", 4000);
        });
    },
  },
};
</script>

<style>
.card-header {
  text-align: left;
}
.default-indicador-image-icon {
  font-size: 50px;
  color: white;
  margin: 50% 0;
}
.indicador-image-box {
  height: 323px;
  width: 323px;
  margin: 10px auto;
  padding: 2px;
  border-radius: 5px;
}
.hide {
  display: none;
}
</style>

<style scoped>
.card {
  margin: 10px 0;
}

.card .card-header {
  padding: 15px 20px 5px;
  /*    cursor:pointer;*/
}

.card .card-header:first-child {
  padding: 15px;
}

.card-profile .card-avatar {
  margin: 40px auto 0 !important;
}

.picedit_box {
  width: 100% !important;
}

.ico-picedit-camera {
  display: none !important;
}

.profile-data {
  display: none;
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.nav-pills {
  margin: 15px;
  display: flex;
  justify-content: center;
}

.icons-wizard {
  font-size: 20px;
}

.error-icon {
  color: red;
}

.btns-container {
  margin: 15px 0;
}

.add-remove-fields-btns {
  margin: 0 5px;
  padding: 3px;
  font-size: 11px;
}

.card .card-header {
  float: unset !important;
}

@media (max-width: 480px) {
  .nav-pills {
    margin: 15px;
    display: flex;
    justify-content: space-evenly;
  }

  .card .card-header {
    float: unset !important;
  }
}
</style>