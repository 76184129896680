<template>
  <div
    id="alter-user-comp"
    v-if="
      $root.getAuthData().isHealthWorker &&
      $root.getAuthData().defaultSystem === 'Cliente'
    "
  >
    <DxAutocomplete
      :key="$root.getAuthData().defaultSystem === 'Cliente'"
      :searchEnabled="true"
      search-expr="nome"
      display-expr="nome"
      value-expr="nome"
      placeholder="Digite o nome do paciente"
      v-model="selectedUser"
      :data-source="usersDataSource"
      :show-clear-button="true"
      @selection-changed="onSelectionChangedUsers"
      @initialized="saveSelectUserInstance"
      item-template="item"
    >
      <template #item="{ data }">
        <div
          class="product-name"
          :class="{
            'text-danger':
              !data.permissionEdit ||
              (data.permissionEditDenied &&
                !data.permissionEditAccepted &&
                !data.permissionEditPending),
            'text-warning': data.permissionEdit && data.permissionEditPending,
            'text-success': data.permissionEdit && data.permissionEditAccepted,
          }"
        >
          {{ data.nome }}
        </div>
      </template>
    </DxAutocomplete>
    <DxPopup
      :visible.sync="openPermissionValidation"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'80vh'"
      :height="'70vh'"
    >
      <PermissionValidation
        style="margin-top: 23px"
        :use-qr="true"
        :permission-url="urlValidatePermission"
        :message="'Peça ao usuário para ler o código acima para fornecer permissão<br/>Ou clique no botão para copiar o link de validação:'"
        :ask-permission="false"
        :validate-permission="false"
        @resend-permission="resendNotification"
      />
    </DxPopup>
  </div>
</template>

<script>
import { DxAutocomplete } from "devextreme-vue/autocomplete";
import { confirm, custom } from "devextreme/ui/dialog";
import { DxPopup } from "devextreme-vue/popup";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import PermissionValidation from "@/components/permission-validation.vue";
import notify from "devextreme/ui/notify";

export default {
  name: "AlterUserCombo",
  components: {
    DxAutocomplete,
    DxPopup,
    PermissionValidation,
  },
  data: function name() {
    const inst = this;
    const uds = new DataSource({
      store: new CustomStore({
        key: "Id",
        load: function (loadOptions) {
          if (loadOptions.searchValue.length > 3) {
            return inst.$ConAPI
              .post(
                `/Permission/GetCompanyUsers`,
                {
                  Company: inst.$root.getAuthData().userCompany,
                  Skip: loadOptions.skip,
                  Take: loadOptions.take,
                  Nome: loadOptions.searchValue,
                },
                {
                  headers: {
                    Authorization: `Bearer ${inst.$root.getAuthData().token}`,
                  },
                }
              )
              .then((result) => {
                return JSON.parse(result.request.response);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        },
        byKey: function () {
          return {};
        },
      }),
      paginate: true,
      pageSize: 10,
    });
    return {
      usersDataSource: uds,
      selectedUser: null,
      openPermissionValidation: false,
      urlPermission: `${inst.$root.$UrlApl}${
        inst.$root.$UrlApl[inst.$root.$UrlApl.length - 1] === "/" ? "" : "/"
      }validate-solicitation/A/`,
      urlValidatePermission: "",
      pendingPermissionId: "",
    };
  },
  created: function () {
    this.initialize();
  },
  methods: {
    initialize: function () {
      if (
        this.$root.getAuthData().usuario !== this.$root.getAuthData().userLogged
      ) {
        this.resetUserLogged();
        this.$emit("change-user", true);
      }
    },
    saveSelectUserInstance: function (e) {
      this.selectUserInstance = e.component;
    },
    onSelectionChangedUsers: function (e) {
      this.pendingPermissionId = "";
      if (e.selectedItem !== null) {
        if (
          !e.selectedItem.permissionEdit ||
          (e.selectedItem.permissionEditDenied &&
            !e.selectedItem.permissionEditAccepted &&
            !e.selectedItem.permissionEditPending)
        ) {
          this.selectedUser = null;
          this.selectUserInstance.option("value", null);
          let result = confirm(
            "<i>Não existem pedidos de acesso no momento. Deseja solicitá-la?</i>",
            "Pedido de acesso"
          );
          result.then((dialogResult) => {
            const inst = this;
            if (dialogResult) {
              this.$ConAPI
                .post(
                  `/Permission/AskPermission/`,
                  { id: e.selectedItem.id, editar: true },
                  {
                    headers: {
                      Authorization: `Bearer ${this.$root.getAuthData().token}`,
                    },
                  }
                )
                .then(() => {
                  notify("Pedido feito com sucesso", "success", 4000);
                  inst.selectedUser = null;
                })
                .catch(function (error) {
                  inst.selectedUser = null;
                  notify(error.data.message, "error", 4000);
                });
            } else {
              this.selectedUser = "";
            }
          });
        } else if (
          !e.selectedItem.permissionEditAccepted &&
          e.selectedItem.permissionEditPending
        ) {
          let myDialog = custom({
            title: "Aguardando aprovação",
            messageHtml: "<i>Sua solicitação está aguardando aprovação.</i>",
            buttons: [
              {
                text: "Fechar",
                onClick: () => {
                  this.selectedUser = "";
                },
              },
              {
                text: "Validar com usuário",
                onClick: () => {
                  this.urlValidatePermission =
                    this.urlPermission + e.selectedItem.permission;
                  this.openPermissionValidation = true;
                  this.selectedUser = "";
                  this.pendingPermissionId = e.selectedItem.permission;
                },
              },
            ],
          });
          myDialog.show();
        } else {
          this.$ConAPI
            .get(`/Permission/log-acesso/${e.selectedItem.id}`, {
              headers: {
                Authorization: `Bearer ${this.$root.getAuthData().token}`,
              },
            })
            .then(() => {
              const a = this.$root.getAuthData();
              a.usuario = e.selectedItem.id;
              a.userName = e.selectedItem.nome;
              a.profilePhoto = e.selectedItem.foto;
              a.defaultSystem = "Cliente";
              this.$root.setAuthData(a);
              if (this.$route.path !== "/indicador-resumo") {
                this.$router.push({
                  path: `/`,
                });
              } else {
                this.$emit("change-user", true);
              }
            })
            .catch(function (error) {
              this.selectedUser = null;
              notify(error.data.message, "error", 4000);
            });
        }
      } else {
        this.resetUserLogged();
        if (this.$route.path !== "/indicador-resumo") {
          this.$router.push({
            path: `/`,
          });
        } else {
          this.$emit("change-user", true);
        }
      }
    },
    resetUserLogged: function () {
      this.$root.resetLoggedUser();
      const a = this.$root.getAuthData();
      a.defaultSystem = "Cliente";
      this.$root.setAuthData(a);
    },
    resendNotification: function () {
      if (this.pendingPermissionId !== "") {
        const a = this.$root.getAuthData();
        this.$ConAPI
          .get(`/Permission/ResendNotification/${this.pendingPermissionId}`, {
            headers: {
              Authorization: `Bearer ${a.token}`,
            },
          })
          .then((r) => notify(r.data.message, "success", 4000))
          .catch(() =>
            notify("Ocorreu um erro no reenvio da solicitação", "error", 4000)
          );
      }
    },
  },
};
</script>

<style scoped lang="scss">
div#alter-user-comp {
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
}
div#alter-user-comp .dx-texteditor.dx-editor-underlined:after {
  border-bottom: unset;
}
div#alter-user-comp .dx-texteditor.dx-editor-underlined {
  border: 1px solid #ff9000;
  border-radius: 20px;
  padding-left: 0.5em;
}
</style>
