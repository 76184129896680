<template>
  <div>
    <div
      class="modal fade"
      id="modalTermo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTermoLabel">Termos de uso</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <DxScrollView
              width="100%"
              :height="$root.getAuthData().aceiteTermo ? '70vh' : '55vh'"
              :showScrollbar="'always'"
              @reach-bottom="onReachBottom"
              :scroll-by-content="!$root.mobile"
              :scroll-by-thumb="$root.mobile"
            >
              <div v-html="termo"></div>
            </DxScrollView>
          </div>
          <!--        <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>-->
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalPolitica"
      tabindex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalPoliticaLabel">
              Política de Privacidade
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <DxScrollView
              width="100%"
              :height="$root.getAuthData().aceiteTermo ? '70vh' : '55vh'"
              :showScrollbar="'always'"
              @reach-bottom="onReachBottom"
              :scroll-by-content="!$root.mobile"
              :scroll-by-thumb="$root.mobile"
            >
              <div v-html="politica"></div>
            </DxScrollView>
          </div>
          <!--        <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DxCheckBox } from "devextreme-vue/check-box";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "ModaisTermos",
  components: {
    // DxCheckBox,
    DxScrollView,
  },
  props: ["termo", "politica"],
  data: function () {
    return {
      termoLido: false,
      aceite: false,
    };
  },
  methods: {
    onReachBottom: function (options) {
      this.termoLido = true;
      options.component.release(true);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  .modal-content {
    //padding: 16px;
    .modal-header {
      text-align: center;
      .modal-title {
        display: inline-block;
        //text-align: left;
        color: #ff9800;
        font-weight: 500;
        font-size: 20px;
      }
      .close {
        right: -5px;
        font-size: 25px;
        color: #3c4858;
        position: relative;
      }
    }

    .modal-body {
      padding-bottom: 24px;
    }
  }
}

fade:not(.show) {
  opacity: 1;
}
</style>
