var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Grid", {
    staticClass: "grid-resize",
    attrs: {
      columns: _vm.columns,
      action: _vm.action,
      "filter-value": _vm.getFilterValue(),
      "filter-field": "PessoaJuridica.Id",
      editMode: "batch",
      "init-inserting-row": _vm.initInsertingRow
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }