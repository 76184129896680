<template>
  <div class="container-fluid">
    <div class="row" style="margin-bottom: 16px">
      <div class="col-md-12">
        <ul
          class="nav nav-pills nav-pills-warning nav-pills-icons menu-tabs"
          role="tablist"
        >
          <li
            class="nav-item"
            :class="{ active: currentActive === item.ref }"
            v-for="(item, index) in tabs.filter((x) => x.visible)"
            :key="index"
          >
            <a
              class="nav-link"
              data-toggle="tab"
              :href="'#' + item.ref"
              role="tablist"
              @click="currentActive = item.ref"
            >
              <i
                v-if="item.icon"
                class="fa logo-size"
                :class="item.icon"
                aria-hidden="true"
              ></i>
              <span v-if="!$root.mobile" class="">{{ item.nome }}</span>
              <span v-if="$root.mobile">{{ item.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="page-categories">
          <div class="tab-content tab-subcategories">
            <div
              class="tab-pane"
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: currentActive === item.ref }"
              :id="item.ref"
            >
              <slot :name="item.ref"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      default: function () {
        return [];
      },
      required: true,
    },
  },
  data: function () {
    return {
      currentActive: "",
    };
  },
  created: function () {
    this.currentActive = this.tabs.filter((v) => v.active)[0].ref;
  },
};
</script>

<style scoped lang="scss">
.menu-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px){
    flex-direction: row;
    justify-content: center;
  }
}
.nav-item {
  @media screen and (min-width: 768px) {
    width: 33% !important;
  }
  height: 45px;
}

.nav-pills > li > a {
  border-radius: 50px !important;
}

.container-fluid .row {
  margin-bottom: 7px;
}

.logo-size {
  margin: 5px;
  font-size: 20px;
  margin-right: 7px;
}
</style>
