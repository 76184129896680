<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Master",
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@import "./assets/css/material-dashboard.css";
@import "./assets/css/style.css";
@import "./assets/sass/style.scss";
</style>
