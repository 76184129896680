var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        staticClass: "grid-resize",
        attrs: {
          columns: _vm.columns,
          action: _vm.action,
          reload: _vm.reloadGrid,
          filters: _vm.filters
        },
        on: { "add-item": _vm.addItem, "edit-item": _vm.editItem }
      }),
      _c(
        "DxPopup",
        {
          attrs: {
            visible: _vm.openForm,
            fullScreen: _vm.$root.mobile,
            "drag-enabled": false,
            "close-on-outside-click": false,
            showCloseButton: true,
            showTitle: true,
            id: "permission-validation",
            width: "85vh",
            height: "70vh",
            title: _vm.popupTitle
          },
          on: {
            "update:visible": function($event) {
              _vm.openForm = $event
            }
          }
        },
        [
          _c(
            "DxScrollView",
            {
              attrs: {
                width: "100%",
                height: "100%",
                showScrollbar: "always",
                "scroll-by-content": !_vm.$root.mobile,
                "scroll-by-thumb": _vm.$root.mobile
              }
            },
            [
              _c("FaixasForm", {
                attrs: {
                  indicador: _vm.indicador,
                  id: _vm.faixaId,
                  grupo: _vm.grupo,
                  medidor: _vm.medidor,
                  "reload-data": _vm.reloadForm
                },
                on: { close: _vm.fecharPopup }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }