var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("DxTabPanel", {
            attrs: {
              "data-source": _vm.tabs,
              "selected-index": _vm.selectedIndex,
              "animation-enabled": true,
              "swipe-enabled": true
            },
            on: {
              "update:selectedIndex": function($event) {
                _vm.selectedIndex = $event
              },
              "update:selected-index": function($event) {
                _vm.selectedIndex = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function(ref) {
                  var company = ref.data
                  return [_c("span", [_vm._v(_vm._s(company.name))])]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var company = ref.data
                  return [
                    _c(
                      "div",
                      [
                        company.name === "Dados"
                          ? _c("DadosIndicador", {
                              attrs: { id: _vm.id, type: _vm.type },
                              on: {
                                "indicador-adicionado":
                                  _vm.hasIndicadorCadastrado
                              }
                            })
                          : _vm._e(),
                        company.name === "Faixas Indicador"
                          ? _c("FaixasIndicador", {
                              attrs: { indicador: _vm.id }
                            })
                          : _vm._e(),
                        company.name === "Módulos"
                          ? _c("GrupoIndicador", {
                              attrs: { indicador: _vm.id }
                            })
                          : _vm._e(),
                        company.name === "Dependências"
                          ? _c("DependenciasIndicador", {
                              attrs: { indicador: _vm.id }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }