var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row controles-indicadores" }, [
    _c("div", { staticClass: "col-xs-4 text-left" }, [
      _c(
        "div",
        {
          staticClass: "controle",
          on: {
            click: function($event) {
              return _vm.navegate("prev")
            }
          }
        },
        [
          _c("span", { staticClass: "fas fa-chevron-circle-left" }),
          _c("span", { staticClass: "titulo" }, [_vm._v("Anterior")])
        ]
      )
    ]),
    _c("div", { staticClass: "col-xs-4 text-center" }, [
      _c(
        "a",
        {
          staticClass: "controle",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              return _vm.goToHome()
            }
          }
        },
        [
          _c("span", { staticClass: "fas fa-home" }),
          _c("span", { staticClass: "titulo" }, [_vm._v("Página inicial")])
        ]
      )
    ]),
    _c("div", { staticClass: "col-xs-4 text-right" }, [
      _c(
        "div",
        {
          staticClass: "controle",
          on: {
            click: function($event) {
              return _vm.navegate("next")
            }
          }
        },
        [
          _c("span", { staticClass: "fas fa-chevron-circle-right" }),
          _c("span", { staticClass: "titulo" }, [_vm._v("Próximo")])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }