var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card", class: { "card-dados": !_vm.$root.mobile } },
    [
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _c("span", { staticClass: "stats explanation" }, [
            _vm._v("Clique nos itens para ver no gráfico")
          ]),
          _c(
            "DxDataGrid",
            {
              class: { "tabela-grupos": _vm.type === "Grupos" },
              attrs: {
                "show-column-headers": false,
                "data-source": _vm.data,
                selection: {
                  mode: _vm.type === "Grupos" ? "single" : "multiple"
                },
                "show-borders": false,
                "hover-state-enabled": true,
                "key-expr": "Id",
                width: "100%",
                height: _vm.$root.mobile ? "inherit" : "250px",
                "selected-row-keys": _vm.selectedKeys
              },
              on: { "selection-changed": _vm.onSelectionChanged },
              scopedSlots: _vm._u([
                {
                  key: "cellTemplate",
                  fn: function(ref) {
                    var data = ref.data
                    return [
                      data.value !== null
                        ? _c("img", {
                            staticClass: "icon-compass",
                            attrs: {
                              src:
                                _vm.$root.Controle.UrlAPI +
                                "/Upload/GetPhoto?type=icon&image=" +
                                data.value.substring(1) +
                                ".png"
                            }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c("DxScrolling", { attrs: { showScrollbar: "always" } }),
              _c("DxColumn", {
                attrs: { "data-field": "Nome", caption: "Title" }
              }),
              _vm.type !== "Grupos"
                ? _c("DxColumn", {
                    attrs: { "data-field": "UltimaMedicao", caption: "Valor" }
                  })
                : _vm._e(),
              _c("DxColumn", {
                attrs: {
                  width: 40,
                  "data-field": "CorExibicao",
                  "cell-template": "cellTemplate"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }