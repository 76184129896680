<template>
  <div class="card">
    <div id="collapseEndereco" class="card-content">
      <h4 class="card-title">Endereço</h4>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label class="control-label">Endereço</label>
            <DxTextBox v-model="data.Endereco" :disabled="restrictData" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Complemento</label>
            <DxTextBox v-model="data.Complemento" :disabled="restrictData" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Bairro</label>
            <DxTextBox v-model="data.Bairro" :disabled="restrictData" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Cidade</label>
            <DxTextBox v-model="data.Cidade" :disabled="restrictData" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">País</label>
            <DxSelectBox
              :data-source="paises"
              :show-clear-button="true"
              display-expr="Sigla"
              value-expr="Id"
              v-model="data.Pais"
              :disabled="restrictData"
              @item-click="countryClick"
            >
              <template #item="{ data }">
                {{ data.Sigla }} - {{ data.Nome }}
              </template>
            </DxSelectBox>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">UF</label>
            <DxSelectBox
              :data-source="ufs"
              :show-clear-button="true"
              display-expr="Sigla"
              value-expr="Id"
              v-model="data._UF"
              :disabled="data.Pais === null || restrictData"
              @focus-in="ufFocus"
            />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">CEP</label>
            <DxTextBox
              v-model="data.CEP"
              :use-masked-value="false"
              mask="00000-000"
              mask-invalid-message="Cep no formato inválido"
              :disabled="restrictData"
            >
              <DxValidator>
                <DxPatternRule
                  :pattern="cepPattern"
                  message="Cep no formato inválido"
                />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxValidator, DxPatternRule } from "devextreme-vue/validator";

export default {
  name: "EnderecoComponent",
  components: {
    DxTextBox,
    DxSelectBox,
    DxValidator,
    DxPatternRule,
  },
  props: {
    data: Object,
    restrictData: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    const inst = this;
    return {
      cepPattern: /^\d{5}-\d{3}|(\d{8})$/,
      paises: inst.$root.paisDataSource,
      ufs: inst.$root.ufDataSource,
    };
  },
  methods: {
    ufFocus() {
      if (this.data.Pais !== null)
        this.$root.updateUfDatasourceBy(this.data.Pais);
    },
    countryClick() {
      this.data._UF = null;
    },
  },
};
</script>

<style>
</style>