<template>
  <div class="loader-wrapper" v-show="loading">
    <div class="fulfilling-bouncing-circle-spinner">
      <div class="circle"></div>
      <div class="orbit"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["loading"],
};
</script>

<style>
.loader-wrapper {
  z-index: 999;
}
</style>