<template>
  <div>
    <Grid
      :columns="columns"
      :action="action"
      class="grid-resize"
      :reload="reloadGrid"
      :filters="filters"
      v-on:add-item="addItem"
      v-on:edit-item="editItem"
    ></Grid>

    <DxPopup
      :visible.sync="openForm"
      :fullScreen="$root.mobile"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :showCloseButton="true"
      :showTitle="true"
      id="permission-validation"
      :width="'85vh'"
      :height="'70vh'"
      :title="popupTitle"
    >
      <DxScrollView
        width="100%"
        height="100%"
        :showScrollbar="'always'"
        :scroll-by-content="!$root.mobile"
        :scroll-by-thumb="$root.mobile"
      >
        <FaixasForm
          :indicador="indicador"
          :id="faixaId"
          @close="fecharPopup"
          :grupo="grupo"
          :medidor="medidor"
          :reload-data="reloadForm"
        />
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Guid from "devextreme/core/guid";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import FaixasForm from "@/views/forms/faixa.vue";

export default {
  name: "FaixasIndicador",
  components: {
    Grid,
    FaixasForm,
    DxScrollView,
    DxPopup,
  },
  props: {
    indicador: {
      default: null,
      required: true,
    },
    grupo: {
      default: null,
    },
    medidor: {
      default: null,
    },
  },
  watch: {
    grupo: function (v) {
      const inst = this;
      this.filters = this.getFilters(v);
      this.initInsertingRow = function (e) {
        e.data = {
          _Indicador: new Guid(inst.indicador),
          Ativo: true,
          Jejum: false,
        };
        if (v !== null && v !== undefined) {
          e.data = { ...e.data, _IndicadorGrupo: v };
        }
      };
      this.reloadGrid = !this.reloadGrid;
    },
    medidor: function (v) {
      const inst = this;
      this.filters = this.getFilters(null, v);
      this.initInsertingRow = function (e) {
        e.data = {
          _Indicador: new Guid(inst.indicador),
          Ativo: true,
          Jejum: false,
        };
        if (v !== null && v !== undefined) {
          e.data = { ...e.data, _IndicadorMedidor: v };
        }
      };
      this.reloadGrid = !this.reloadGrid;
    },
  },
  data: function () {
    const inst = this;
    return {
      reloadGrid: false,
      action: "Gps_IndicadorFaixaReferencia",
      filters: inst.getFilters(inst.grupo, inst.medidor),
      columns: [
        { name: "Id", notVisible: true },
        { name: "_Indicador", notVisible: true },
        { name: "_IndicadorGrupo", notVisible: true },
        { name: "_IndicadorMedidor", notVisible: true },
        { name: "Nome", showColumn: true, defaultSort: true },
        { name: "Jejum", showColumn: true, type: "boolean" },
        {
          name: "Grau",
          caption: "Risco",
          showColumn: true,
          lookup: {
            allowClearing: true,
            dataSource: [
              { text: "", value: 0, color: "#FFFFFF" },
              { text: "Alto", value: 3, color: "#f44336" },
              { text: "Médio", value: 2, color: "#e0c619" },
              { text: "Baixo", value: 1, color: "#4caf50" },
            ],
            displayExpr: "text",
            valueExpr: "value",
          },
        },
        {
          name: "Sexo",
          showColumn: true,
        },
        {
          name: "ValorInicial",
          caption: "Valor Inicial",
          showColumn: true,
          type: "number",
        },
        {
          name: "ValorFinal",
          caption: "Valor Final",
          showColumn: true,
          type: "number",
        },
        {
          name: "IdadeInicial",
          caption: "Idade Inicial",
          showColumn: true,
          type: "number",
        },
        {
          name: "IdadeFinal",
          caption: "Idade Final",
          showColumn: true,
          type: "number",
        },
        { name: "Recomendacao", caption: "Recomendação", showColumn: true },
        {
          name: "RecomendacaoLink",
          caption: "Link Recomendação",
          showColumn: true,
        },
        { name: "CorExibicao", caption: "Cor Exibição", showColumn: true },
        {
          name: "Ativo",
          caption: "Ativo",
          showColumn: true,
          type: "boolean",
        },
      ],
      popupTitle: "",
      openForm: false,
      reloadForm: false,
      faixaId: null,
    };
  },
  methods: {
    getFilters(grupo, medidor) {
      const f = [["Indicador.Id", "=", new Guid(this.indicador)]];
      if (grupo !== null && grupo !== undefined) {
        f.push(["IndicadorGrupo.Id", "=", grupo]);
      } else {
        f.push(["IndicadorGrupo.Id", "=", null]);
      }
      if (medidor !== null && medidor !== undefined) {
        f.push(["IndicadorMedidor.Id", "=", medidor]);
      } else {
        f.push(["IndicadorMedidor.Id", "=", null]);
      }
      return f;
    },
    addItem: function () {
      this.popupTitle = "Cadastro Faixa";
      this.reloadForm = !this.reloadForm;
      this.openForm = true;
    },
    editItem: function (e) {
      this.popupTitle = "Editar Faixa";
      this.faixaId = e.data.Id;
      this.reloadForm = !this.reloadForm;
      this.openForm = true;
    },
    fecharPopup() {
      this.openForm = !this.openForm;
      this.reloadGrid = !this.reloadGrid;
    },
  },
};
</script>

<style>
</style>