var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Nova Senha*")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.pass,
                expression: "pass"
              }
            ],
            ref: "passwordRef",
            staticClass: "form-control",
            class: { invalid: _vm.errorSenha, valid: _vm.validSenha },
            attrs: {
              type: "password",
              id: "password",
              autocomplete: "new-password"
            },
            domProps: { value: _vm.pass },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              keyup: _vm.keymonitor,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.pass = $event.target.value
              }
            }
          }),
          _c(
            "div",
            {
              staticClass: "strength",
              class: { invisible: !_vm.inputFocused },
              attrs: { id: "password-strength" }
            },
            [_c("span", { ref: "meterPass" })]
          ),
          _c(
            "span",
            { staticClass: "msg error", class: { invisible: !_vm.errorSenha } },
            [_vm._v(_vm._s(_vm.errorSenhaMsg))]
          ),
          _c(
            "span",
            {
              ref: "msgStrength",
              staticClass: "msg",
              class: { invisible: !_vm.showForca }
            },
            [_vm._v(_vm._s(_vm.forcaSenha))]
          ),
          _c("div", { staticClass: "validation" }, [
            _c(
              "span",
              {
                staticClass: "msg",
                class: {
                  error: !_vm.hasMoreThenEigthChars,
                  "valid-rule": _vm.hasMoreThenEigthChars && _vm.pass.length > 0
                }
              },
              [_vm._v("Caracteres: " + _vm._s(_vm.numberOfChars))]
            ),
            _c(
              "span",
              {
                staticClass: "msg",
                class: {
                  error: !_vm.hasUpperCase,
                  "valid-rule": _vm.hasUpperCase && _vm.pass.length > 0
                }
              },
              [_vm._v("Maiúsculas")]
            ),
            _c(
              "span",
              {
                staticClass: "msg",
                class: {
                  error: !_vm.hasLowerCase,
                  "valid-rule": _vm.hasLowerCase && _vm.pass.length > 0
                }
              },
              [_vm._v("Minúsculas")]
            ),
            _c(
              "span",
              {
                staticClass: "msg",
                class: {
                  error: !_vm.hasNumbers,
                  "valid-rule": _vm.hasNumbers && _vm.pass.length > 0
                }
              },
              [_vm._v("Números")]
            ),
            _c(
              "span",
              {
                staticClass: "msg",
                class: {
                  error: !_vm.hasSymbols,
                  "valid-rule": _vm.hasSymbols && _vm.pass.length > 0
                }
              },
              [_vm._v("Caracteres Especiais")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Confirmar Senha*")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmation,
                expression: "confirmation"
              }
            ],
            ref: "confirmRef",
            staticClass: "form-control",
            class: { invalid: _vm.errorConfirm, valid: _vm.validConfirm },
            attrs: {
              type: "password",
              id: "confirm",
              autocomplete: "new-password"
            },
            domProps: { value: _vm.confirmation },
            on: {
              keyup: _vm.compareWithPass,
              focus: _vm.compareWithPass,
              blur: _vm.compareWithPass,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.confirmation = $event.target.value
              }
            }
          }),
          _c(
            "span",
            {
              staticClass: "msg error",
              class: { invisible: !_vm.errorConfirm }
            },
            [_vm._v(_vm._s(_vm.errorConfirmMsg))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }