<template>
  <div>
    <loading :loading="$root.loading" />
    <div class="container-fluid">
      <div class="wrapper wrapper-full-page login row">
        <div class="col-md-6 login-forms">
          <div class="form-login">
            <div
              class="login-header text-center"
              data-background-color="orange"
            >
              <img src="../assets/img/logo_white.png" style="width: 80%" />
              <h3>Como está a minha saúde?</h3>
            </div>
            <div class="form-group">
              <label for="email">E-mail</label>
              <input
                type="email"
                id="email"
                class="form-control"
                v-model="login"
              />
            </div>
            <div class="form-group">
              <label for="senha">Senha</label>
              <input
                type="password"
                class="form-control"
                v-model="senha"
                id="senha"
                name="loginPass"
                @keyup.enter="Logar"
              />
            </div>
            <div class="text-center">
              <button
                @click="Logar"
                class="btn btn-block btn-login btn-full btn-wd btn-lg"
              >
                Entrar
              </button>
              <a href="#" @click="EsqueciSenha">Esqueci minha senha</a>
              <div class="google" v-if="!$root.isProduction">
                <button
                  class="btn btn-login btn-google-login"
                  @click="LoginGoogle"
                >
                  <img src="../assets/img/google.svg" alt="Google" />
                  Login com Google
                </button>
              </div>
              <p class="text-center">
                <pwa-install></pwa-install>
              </p>
              <!-- <footer class="footer">
                <div class="container">
                  <p class="copyright pull-right">
                    &copy;
                    {{ new Date().getFullYear() }}
                    GPS Med, um produto
                    <a href="http://www.interativasaude.com.br"
                      >Interativa Saúde.</a
                    >
                  </p>
                </div>
              </footer>-->
            </div>
          </div>
        </div>

        <div class="col-md-6 d-none d-md-block login-banner" id="login-bg">
          <h3 class="txt-login">
            A sua bússola
            <!-- <br /> -->
            da saúde.
          </h3>
        </div>
      </div>
    </div>
    <div class="update-dialog" v-if="this.$root.$data.Controle.prompt">
      <div class="update-dialog__content">
        Uma nova versão foi encontrada. Recarregar para abrir?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="this.$root.update"
        >
          Atualizar
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="this.$root.$data.Controle.prompt = false"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import notify from "devextreme/ui/notify";
import loading from "../components/loading.vue";

export default {
  components: { loading },
  name: "Login",
  //props: ["login", "senha"],
  data() {
    return { login: "", senha: "" };
  },
  created: function () {
    //  alert(parent.origin == this.$root.$UrlApl);
    var LocalToken = window.localStorage.getItem(
      this.$root.$data.Controle.keyNav
    );
    if (LocalToken != null) {
      var Dados = JSON.parse(LocalToken);
      this.$root.$data.Controle.TokenAcesso = Dados.token;
      this.$root.$data.Controle.NomeUsuario = Dados.userName;
      this.$root.$data.Controle.Foto = Dados.profilePhoto;
      this.$root.$data.Controle.Logado = Dados.logado;
    }
  },
  methods: {
    Logar: function () {
      if (this.validateEmail(this.login)) {
        var data = JSON.stringify({
          Usuario: this.login,
          Senha: this.senha,
          google: false,
        });
        this.$ConAPI
          .post("/acesso/login", data)
          .then((res) => this.handdleLogin(JSON.parse(res.request.response)))
          .catch(function () {
            notify("Acesso negado", "error", 4000);
          });
      } else {
        notify("E-mail no formato inválido", "error", 4000);
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    LoginExterno: function (Acesso, id) {
      var data = Acesso;
      if (data.Sistema !== undefined) {
        window.VUE.$ConAPI
          .post("/acesso/login", data)
          .then((res) =>
            this.handdleLoginExterno(JSON.parse(res.request.response), id)
          )
          .catch(function () {
            notify("Acesso negado", "error", 4000);
          });
      }
    },
    EsqueciSenha: function () {
      if (this.validateEmail(this.login) && this.login.length > 0) {
        var data = JSON.stringify({ Usuario: this.login });
        this.$ConAPI
          .post("/Acesso/EsqueciSenha", data)
          .then((result) => {
            if (result.data.status === 400) {
              notify(result.data.message, "error", 4000);
            } else {
              notify(result.data.message, "success", 4000);
            }
          })
          .catch(function () {
            notify("Não foi possível redefinir sua senha", "error", 4000);
          });
      } else {
        notify("E-mail no formato inválido", "error", 4000);
      }
    },
    LoginGoogle() {
      this.$gAuth
        .signIn()
        .then((res) => res.getBasicProfile())
        .then((resposta) => {
          this.$ConAPI
            .post("/acesso/login", {
              usuario: resposta.Mt,
              google: true,
            })
            .then((res) => JSON.parse(res.request.response))
            .then((resposta) => {
              this.handdleLogin(resposta);
            })
            .catch(function () {
              notify("Acesso negado", "error", 4000);
              this.$gAuth.signOut();
            });
        })
        .catch((value) => {
          if (value.error !== "popup_closed_by_user") {
            notify(value.error, "error", 4000);
          }
          notify("Acesso negado", "error", 4000);
        });
    },
    handdleLogin(resposta) {
      this.$root.$data.Controle.TokenAcesso = resposta.token;
      this.$root.$data.Controle.RefreshToken = resposta.refresh;

      window.localStorage.setItem(
        this.$root.$data.Controle.keyNav,
        JSON.stringify(resposta)
      );

      this.$ConAPI
        .get(`/acesso/obter-dados-usuario`, {
          headers: {
            Authorization: `Bearer ${this.$root.getAuthData().token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((resposta) => {
          this.$root.$data.Controle.NomeUsuario = resposta.userName;
          this.$root.$data.Controle.Foto = resposta.profilePhoto;
          this.$root.$data.Controle.Logado = resposta.logado;
          let acesso = this.$root.getAuthData();
          window.localStorage.setItem(
            this.$root.$data.Controle.keyNav,
            JSON.stringify({ ...acesso, ...resposta })
          );
          if (resposta.alterouSenha) this.$root.refreshToken();
          if (!resposta.alterouSenha) {
            window.location = `${this.$UrlApl}/reset-senha`;
            return;
          }
          if (this.$route.query.nextUrl !== undefined) {
            if (resposta.aceiteTermo) {
              window.location = `${this.$UrlApl}/${this.$route.query.nextUrl}`;
              return;
            }
            this.$router.push({
              path: "/aceite",
              query: { nextUrl: this.$route.query.nextUrl },
            });
            return;
          }

          window.location = `${this.$UrlApl}/`;
        })
        .catch(function (error) {
          console.log("message logout");
          this.$gAuth.signOut();
          notify(error.response.data.value, "error", 4000);
        });
    },
    handdleLoginExterno(resposta, id) {
      window.VUE.Controle.TokenAcesso = resposta.token;
      window.VUE.Controle.RefreshToken = resposta.refresh;

      window.localStorage.setItem(
        window.VUE.Controle.keyNav,
        JSON.stringify(resposta)
      );

      window.VUE.$ConAPI
        .get(`/acesso/obter-dados-usuario`, {
          headers: {
            Authorization: `Bearer ${resposta.token}`,
          },
        })
        .then((res) => JSON.parse(res.request.response))
        .then((resposta) => {
          window.VUE.Controle.NomeUsuario = resposta.userName;
          window.VUE.Controle.Foto = resposta.profilePhoto;
          window.VUE.Controle.Logado = resposta.logado;
          let acesso = window.VUE.getAuthData();
          window.localStorage.setItem(
            window.VUE.Controle.keyNav,
            JSON.stringify({ ...acesso, ...resposta })
          );
          window.VUE.$router.push({ path: `/indicadoresGps/${id}` });

          // window.VUE.$router.go(-1);
        })
        .catch(function (error) {
          notify(error.response.data.value, "error", 4000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-google-login {
  color: #444444;
  width: 100%;
  padding: 10px 50px;
  border-radius: 30px;
  img {
    height: 16px;
    margin-right: 20px;
    display: inline-block;
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 20%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.txt-login {
  width: 71%;
  margin: 16% auto 0;
}
</style>
