var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.getAuthData().defaultSystem === "Admin"
    ? _c(
        "div",
        { attrs: { id: "alter-company-comp" } },
        [
          _c("DxSelectBox", {
            key: _vm.$root.getAuthData().defaultSystem === "Admin",
            attrs: {
              searchEnabled: true,
              "display-expr": "nome",
              "value-expr": "id",
              disabled: _vm.companies.length === 1,
              "data-source": _vm.companies
            },
            on: { "value-changed": _vm.onValueChanged },
            model: {
              value: _vm.selectedCompany,
              callback: function($$v) {
                _vm.selectedCompany = $$v
              },
              expression: "selectedCompany"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }